<template>
  <v-row align="start" justify="start" class="pt-5">
    <v-col cols="12" class="d-flex align-start justify-start" v-if="isCompanyAdminRegionOrAbove">
      <v-col cols="8" lg="3" md="4" sm="5" class="pt-0 px-0">
        <account-select v-model="selectedAccounts" :api-key="apiKey"></account-select>
      </v-col>
    </v-col>
    <v-col cols="4" lg="2">
      {{ $t('user_notifications.reports.daily_report') }}
    </v-col>
    <v-col cols="8" lg="10" class="ltr d-flex" :class="{ 'justify-end': isRtl }">
      <v-switch class="mt-0"
        :disabled="mediumEmaildisabled"
        v-model="notifications.companyReport.medium"
        false-value="null"
        true-value="email">
      </v-switch>
      <div class="text-caption red--text" v-if="mediumEmaildisabled">
        {{ $t('user_notifications.missing_email') }}
      </div>
    </v-col>
    <v-col cols="12" class="mb-10 mb-sm-0">
      <v-footer padless fixed class="save-notifications">
        <v-col cols="12" class="text-right">
          <v-btn color="blue darken-1" depressed tile @click="save" :loading="isLoading" :disabled="isLoading">
            <v-icon v-if="showNotificationsSaved">
              check
            </v-icon>
            <span v-else>
              {{ $t('save') }}
            </span>
          </v-btn>
        </v-col>
      </v-footer>
    </v-col>
  </v-row>

</template>
<script>
import AccountSelect from '@/components/shared/AccountSelect.vue'
import useAuth from '@/composables/useAuth.js'

import useUserNotification from '@/composables/useUserNotification.js'

import { isObject } from '@/helpers/Utils.js'
import { isRtl } from '@/i18n.js'

import { ref, computed } from 'vue'

const NOTIFIED_MODEL = 'Company'

export default {
  components: {
    'account-select': AccountSelect
  },
  props: {
    apiKey: {
      type: String,
      required: true
    }
  },
  setup(props) {
    const { isCompanyAdminRegionOrAbove } = useAuth()
    const {
      loadNotifications, saveNotifications, isLoading,
      mediumEmaildisabled
    } = useUserNotification(props.apiKey)

    const showNotificationsSaved = ref(false)
    const selectedAccounts = ref([])

    const notifications = ref({
      companyReport: { medium: 'null' }
    })

    isLoading.value = true
    loadNotifications(NOTIFIED_MODEL)
      .then(({ reponseByModel, apiKeys }) => {
        if (isCompanyAdminRegionOrAbove.value === true) {
          const selectedAccountsVal = selectedAccounts.value
          selectedAccountsVal.splice(0, selectedAccountsVal.length)
          apiKeys.forEach((apiKey) => selectedAccountsVal.push(apiKey))
        }
        const notificationsForCompanyModel = reponseByModel[NOTIFIED_MODEL] || []
        const dailyCompanyReport = notificationsForCompanyModel.find((notification) => notification.field_name === 'report' && notification.field_value === 'daily_report')
        const notificationsVal = notifications.value

        if (isObject(dailyCompanyReport) === true) {
          notificationsVal.companyReport.medium = dailyCompanyReport.medium
        }
      })
      .finally(() => (isLoading.value = false))

    const saveNotificationsParams = () => {
      const notificationsToSave = { scopes: {} }

      notificationsToSave.scopes[NOTIFIED_MODEL] = [{ field_name: 'report', field_value: 'daily_report' }]
      notificationsToSave[NOTIFIED_MODEL] = []

      const notificationsVal = notifications.value

      if (notificationsVal.companyReport.medium !== 'null') {
        notificationsToSave[NOTIFIED_MODEL].push({ field_name: 'report', field_value: 'daily_report', medium: notificationsVal.companyReport.medium })
      }
      return notificationsToSave
    }

    // Methods
    const save = () => {
      isLoading.value = true
      const notificationsToSave = saveNotificationsParams()
      const apiKeys = isCompanyAdminRegionOrAbove.value === true ? selectedAccounts.value : [props.apiKey]
      saveNotifications({ params: notificationsToSave, apiKeys })
        .then(() => {
          showNotificationsSaved.value = true
          setTimeout(() => (showNotificationsSaved.value = false), 3000)
        })
        .finally(() => (isLoading.value = false))
    }

    // Computed

    const saveDisabled = computed(() => isLoading.value === true || mediumEmaildisabled.value === true)
    return {
      isLoading,
      isCompanyAdminRegionOrAbove,
      showNotificationsSaved,
      selectedAccounts,
      notifications,
      mediumEmaildisabled,
      saveDisabled,
      isRtl,
      save
    }
  }
}
</script>
