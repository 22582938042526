<template>
  <v-row justify="space-between" align="center" class="broken-item-stats" :class="{ 'rtl': isRtl }">
    <v-col cols="12" lg="7" class="row align-stretch justify-space-between mt-0 deadline-stats">
      <v-col cols="12" class="py-0 t-700-18 pps-6">
        {{ $t('company_broken_items.deadline') }}
      </v-col>
      <v-col cols="3">
        <broken-item-single-stat class="pointer" :class="{ selected: noDeadlineSelected }"
          :title="$t('company_broken_items.no_deadline')"
          :stat-value="noDeadlineCount"
          icon="calendar_today"
          icon-circle-color="#0E4AFD"
          @click.native="toggleDeadline('no_deadline')">
        </broken-item-single-stat>
      </v-col>
      <v-col cols="3">
        <broken-item-single-stat class="pointer" :class="{ selected: deadlineThisWeekSelected }"
          :title="$t('company_broken_items.deadline_this_week')"
          :stat-value="deadlineThisWeek"
          icon="date_range"
          :icon-circle-color="deadlineColors.thisWeek"
          @click.native="toggleDeadline('this_week')">
        </broken-item-single-stat>
      </v-col>
      <v-col cols="3">
        <broken-item-single-stat class="pointer" :class="{ selected: deadlineTodaySelected }"
          :title="$t('company_broken_items.deadline_today')"
          :stat-value="deadlineToday"
          icon="today"
          :icon-circle-color="deadlineColors.today"
          @click.native="toggleDeadline('today')">
        </broken-item-single-stat>
      </v-col>
      <v-col cols="3">
        <broken-item-single-stat class="pointer" :class="{ selected: deadlinePassedSelected }"
          :title="$t('company_broken_items.deadline_passed')"
          :stat-value="deadlinePassed"
          icon="event_busy"
          :icon-circle-color="deadlineColors.passed"
          @click.native="toggleDeadline('passed')">
        </broken-item-single-stat>
      </v-col>
    </v-col>
    <v-col cols="12" lg="5" class="row align-stretch justify-space-between mt-0">
      <v-col cols="12" class="py-0 t-700-18 pps-6">
        {{ $t('company_broken_items.status') }}
      </v-col>
      <v-col cols="4">
        <broken-item-single-stat class="pointer" :class="{ selected: isOpenSelected }"
          :title="$t('broken_item_dialog_dialog.status_open')"
          :stat-value="openCount"
          :background-color="statusColors.open"
          icon="feedback"
          @click.native="toggleStatus(statuses.open)">
        </broken-item-single-stat>
      </v-col>
      <v-col cols="4">
        <broken-item-single-stat class="pointer" :class="{ selected: isInProgressSelected }"
          :title="$t('broken_item_dialog_dialog.status_in_progress')"
          :stat-value="inProgressCount"
          :background-color="statusColors.in_progress"
          icon="error"
          @click.native="toggleStatus(statuses.in_progress)">
        </broken-item-single-stat>
      </v-col>
      <v-col cols="4">
        <broken-item-single-stat class="pointer" :class="{ selected: isDoneSelected }"
          :title="$t('broken_item_dialog_dialog.status_done')"
          :stat-value="doneCount"
          :background-color="statusColors.done"
          icon="check_circle"
          @click.native="toggleStatus(statuses.done)">
        </broken-item-single-stat>
      </v-col>
    </v-col>
  </v-row>
</template>
<style lang="scss">
.deadline-stats {
  position: relative;

  @media only screen and (min-width: 1264px) {
    &::before {
      content: '';
      position: absolute;
      bottom: 14%;
      right: 0;
      width: 1.5px;
      height: 50%;
      background-color: #DADADA;
    }
  }
}

.rtl {
  .deadline-stats {
    @media only screen and (min-width: 1264px) {
      &::before {
        left: 0;
        right: unset;
      }
    }
  }
}
</style>
<script>
import useCompanyBrokenItem from '@/composables/useCompanyBrokenItem.js'
import { statusColors, deadlineColors } from '@/store/modules/BrokenItem.js'
import { statuses } from '@/store/modules/BrokenItem.js'
import { isRtl } from '@/i18n.js'
import { computed } from 'vue'
export default {
  props: {
    apiKey: {
      type: String,
      required: true
    }
  },
  components: {
    'broken-item-single-stat': () => import('@/components/broken_items/stats/BrokenItemSingleStat.vue'),
  },
  setup(props) {
    const { stats, brokenItemStatusFilter, deadlineDateFilter, applyFilters } = useCompanyBrokenItem(props.apiKey)

    const isOpenSelected = computed(() => brokenItemStatusFilter.value.includes(statuses.open))
    const isInProgressSelected = computed(() => brokenItemStatusFilter.value.includes(statuses.in_progress))
    const isDoneSelected = computed(() => brokenItemStatusFilter.value.includes(statuses.done))
    const anyStatusSelected = computed(() => isOpenSelected.value || isInProgressSelected.value || isDoneSelected.value)
    const openCount = computed(() => anyStatusSelected.value && !isOpenSelected.value ? '' : stats.value.status_count?.open)
    const inProgressCount = computed(() => anyStatusSelected.value && !isInProgressSelected.value ? '' : stats.value.status_count?.in_progress)
    const doneCount = computed(() => anyStatusSelected.value && !isDoneSelected.value ? '' : stats.value.status_count?.done)

    const noDeadlineFilterSelected = computed(() => deadlineDateFilter.value === null)
    const noDeadlineSelected = computed(() => deadlineDateFilter.value === 'no_deadline')
    const deadlineThisWeekSelected = computed(() => deadlineDateFilter.value === 'this_week')
    const deadlineTodaySelected = computed(() => deadlineDateFilter.value === 'today')
    const deadlinePassedSelected = computed(() => deadlineDateFilter.value === 'passed')

    const showNoDeadline = computed(() => noDeadlineFilterSelected.value || noDeadlineSelected.value)
    const showDeadlineThisWeek = computed(() => noDeadlineFilterSelected.value || deadlineThisWeekSelected.value)
    const showDeadlineToday = computed(() => noDeadlineFilterSelected.value || deadlineTodaySelected.value)
    const showDeadlinePassed = computed(() => noDeadlineFilterSelected.value || deadlinePassedSelected.value)
    const noDeadlineCount = computed(() => showNoDeadline.value ? stats.value.deadline_count?.no_deadline || 0 : '')
    const deadlineThisWeek = computed(() => showDeadlineThisWeek.value ? stats.value.deadline_count?.after_deadline || 0 : '')
    const deadlineToday = computed(() => showDeadlineToday.value ? stats.value.deadline_count?.at_deadline || 0 : '')
    const deadlinePassed = computed(() => showDeadlinePassed.value ? stats.value.deadline_count?.before_deadline || 0 : '')
    const toggleStatus = (status) => {
      if (brokenItemStatusFilter.value.includes(status)) {
        brokenItemStatusFilter.value = brokenItemStatusFilter.value.filter((s) => s !== status)
      } else {
        brokenItemStatusFilter.value = [...brokenItemStatusFilter.value, status]
      }
      applyFilters()
    }

    const toggleDeadline = (deadlineValue) => {
      if (deadlineDateFilter.value === deadlineValue) {
        deadlineDateFilter.value = null
      } else {
        deadlineDateFilter.value = deadlineValue
      }
      applyFilters()
    }
    return {
      stats,
      statusColors,
      isOpenSelected,
      isInProgressSelected,
      isDoneSelected,
      statuses,
      brokenItemStatusFilter,
      isRtl,
      openCount,
      inProgressCount,
      doneCount,
      noDeadlineCount,
      deadlineThisWeek,
      deadlineToday,
      deadlinePassed,
      noDeadlineSelected,
      deadlineThisWeekSelected,
      deadlineTodaySelected,
      deadlinePassedSelected,
      deadlineColors,
      toggleStatus,
      toggleDeadline
    }
  }
}
</script>