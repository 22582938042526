<template>
  <v-row align="center" justify="center" v-if="loadingSubdomain">
    <v-progress-circular indeterminate color="primary"></v-progress-circular>
  </v-row>
  <v-row align="start" justify="center" class="fault-settings" v-else>
    <v-col cols="12" class="page-title">
      {{ $t('account_fault_devices.broken_items_settings.page_title') }}
    </v-col>
    <v-col cols="12" class="text-h6 text-center">
      {{ $t('account_fault_devices.broken_items_settings.anonymous_explain') }}
    </v-col>
    <v-col cols="12" md="4" sm="6" v-if="isCompanyAdminRegionOrAbove" class="mt-4">
      <page-account-select></page-account-select>
    </v-col>
    <v-col cols="12" v-if="isCompanyAdminRegionOrAbove"></v-col>
    <v-col cols="8" sm="6" md="5" lg="4" class="row no-gutter align-center justify-space-between">
      <v-text-field
        class="d-inline-block flex-grow-1 flex-shrink-0"
        :rules="[requiredField, domainValidation]"
        counter="30"
        :label="$t('name')"
        v-model="subDomain"
        :suffix="MAIL_ADDRESS_SUFFIX">
      </v-text-field>
      <v-btn icon :disabled="saveDisabled" @click="copySubDomain"
        class="d-inline-block flex-grow-0 flex-shrink-1">
        <v-icon>content_copy</v-icon>
      </v-btn>
      <v-col cols=12 class="text-caption">
        {{ $t('account_fault_devices.broken_items_settings.anonymous_explain_field') }}
      </v-col>
    </v-col>
    <v-col cols="12" class="pa-0"></v-col>
    <v-col cols="8" sm="6" md="5" lg="4">
      <v-btn color="red darken-1" depressed tile @click="deleteSubdomain" :loading="isDeleteLoading"
        :disabled="deleteDisabled">
        <v-icon v-if="showSaveDeleteSuccess">
          check
        </v-icon>
        <span v-else>
          {{ $t('delete') }}
        </span>
      </v-btn>
      <v-btn color="blue darken-1" class="ml-4"
        depressed tile @click="save" :loading="isLoading" :disabled="saveDisabled">
        <v-icon v-if="showSaveSuccess">
          check
        </v-icon>
        <span v-else>
          {{ $t('save') }}
        </span>
      </v-btn>
    </v-col>
    <v-col cols="12" class="pa-0"></v-col>
    <v-col cols="12" sm="6" md="5" lg="4">
      <qr-code-generator ref="qrRef"></qr-code-generator>
    </v-col>
    <v-snackbar
      v-model="snackBarData.show" :timeout="snackBarData.timeout" :color="snackBarData.color">
      <div class="text-center">
        {{ $t(snackBarData.keyText) }}
      </div>
    </v-snackbar>
  </v-row>
</template>
<style lang="scss">
.fault-settings {
  margin-top: 80px;

  @media only screen and (max-width: 600px) {
    margin-top: 120px;

  }
}
</style>
<script>

import PageAccountSelect from '@/components/shared/PageAccountSelect.vue'
import QrCodeGenerator from '@/components/shared/QrCodeGenerator.vue'

import {
  show as showSubdomain, upsert as upsertSubdomain, destroy as destroySubdomain
} from '@/api_client/BrokenItemAnonymousCreateSubDomain.js'

import { requiredField } from '@/classes/InputValidators.js'

import { isString } from '@/helpers/Utils.js'

import { i18n } from '@/i18n.js'

import useAuth from '@/composables/useAuth.js'

import { handler as errHandler } from '@/classes/ErrorHandler.js'

import { ref, computed, watch, onMounted } from 'vue'

const VALID_SUBDOMAIN = /^[a-zA-Z0-9-_]+$/i
const MAIL_ADDRESS_SUFFIX = import.meta.env.VITE_APP_FAULTS_ANONYMOUS_CREATE_MAIL
export default {
  components: {
    'page-account-select': PageAccountSelect,
    'qr-code-generator': QrCodeGenerator
  },
  setup() {
    const loadingSubdomain = ref(false)
    const isLoading = ref(false)
    const isDeleteLoading = ref(false)
    const subDomain = ref(null)
    const showSaveSuccess = ref(false)
    const showSaveDeleteSuccess = ref(false)
    const hasDomain = ref(false)
    const qrDisabled = ref(true)
    const qrRef = ref(null)
    const snackBarData = ref({
      show: false, timeout: 3000,
      keyText: 'share_content.clipboard_copy',
      color: 'success'
    })
    const { apiKey, isCompanyAdminRegionOrAbove } = useAuth()

    const loadSubdomain = () => {
      loadingSubdomain.value = true
      showSubdomain(apiKey.value)
        .then(({ data: { data: { name } } }) => {
          subDomain.value = name
          loadingSubdomain.value = false
          hasDomain.value = true
        })
        .catch((err) => {
          if (err?.request?.status === 404) {
            hasDomain.value = false
            return
          }
          errHandler(err)
        })
        .finally(() => (loadingSubdomain.value = false))
    }
    const onSaveSuccess = () => {
      showSaveSuccess.value = true
      setTimeout(() => (showSaveSuccess.value = false), 3000)
    }

    const onDeleteSaveSuccess = () => {
      showSaveDeleteSuccess.value = true
      setTimeout(() => (showSaveDeleteSuccess.value = false), 3000)
    }

    const updateQrCode = () => {
      const qrCodeData = hasDomain.value ? `mailto:${subDomain.value}${MAIL_ADDRESS_SUFFIX}` : null
      if (qrRef.value === null) {
        setTimeout(() => qrRef.value.updateQrCodeData(qrCodeData))
      } else {
        qrRef.value.updateQrCodeData(qrCodeData)
      }
    }

    // Methods

    const save = () => {
      isLoading.value = true
      upsertSubdomain(apiKey.value, subDomain.value)
        .then(() => {
          onSaveSuccess()
          hasDomain.value = true
          updateQrCode()
        })
        .catch((err) => {
          const errors = err?.response?.data?.errors
          if (Array.isArray(errors) && errors.some((cause) => cause === 'name_taken')) {
            snackBarData.value.keyText = 'account_fault_devices.broken_items_settings.domain_taken'
            snackBarData.color = 'error'
            snackBarData.value.show = true
          }
          errHandler(err)
        })
        .finally(() => (isLoading.value = false))
    }

    const deleteSubdomain = () => {
      isDeleteLoading.value = true
      destroySubdomain(apiKey.value)
        .then(() => {
          onDeleteSaveSuccess()
          hasDomain.value = false
          subDomain.value = null
        })
        .catch(errHandler)
        .finally(() => (isDeleteLoading.value = false))
    }

    const domainValidation = (value) => {
      if (!isString(value)) return true
      if (value.length > 30 || value.length === 0) return false
      return VALID_SUBDOMAIN.test(value) || i18n.t('account_fault_devices.broken_items_settings.invalid_subdomain')
    }

    const showSnackbar = () => {
      snackBarData.value.keyText = 'share_content.clipboard_copy'
      snackBarData.value.show = true
      snackBarData.value.color = 'success'
    }
    const copySubDomain = () => {
      navigator.clipboard.writeText(`${subDomain.value}${MAIL_ADDRESS_SUFFIX}`)
      showSnackbar()
    }


    // Computed

    const saveDisabled = computed(() => {
      if (isLoading.value || isDeleteLoading.value) return true
      if (!isString(subDomain.value) || subDomain.value.trim().length === 0) return true
      const domainValid = domainValidation(subDomain.value)
      return domainValid !== true
    })

    const deleteDisabled = computed(() => {
      if (isLoading.value || isDeleteLoading.value) return true
      if (hasDomain.value === false) return true
      return false
    })

    onMounted(loadSubdomain)
    watch(hasDomain, updateQrCode)
    return {
      isLoading,
      loadingSubdomain,
      subDomain,
      saveDisabled,
      showSaveSuccess,
      showSaveDeleteSuccess,
      deleteDisabled,
      isDeleteLoading,
      snackBarData,
      qrDisabled,
      isCompanyAdminRegionOrAbove,
      qrRef,
      MAIL_ADDRESS_SUFFIX,
      deleteSubdomain,
      save,
      copySubDomain,
      domainValidation,
      requiredField
    }
  }
}
</script>

<style></style>
