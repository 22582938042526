import { index as indexUserFeatures } from '@/api_client/Feature.js'

import { isString } from '@/helpers/Utils.js'

import { ref } from 'vue'

let currentApiKey = null
let featuresLoaded = false

const featuresObject = ref(Object.create(null))

export default function useFeatures (apiKey) {
  const resetValues = () => {
    featuresObject.value = Object.create(null)
    featuresLoaded = false
  }

  if (isString(apiKey) && currentApiKey !== apiKey) {
    resetValues()
    currentApiKey = apiKey
  }

  // Methods
  const loadFeatures = async () => {
    if (featuresLoaded) return
    const featureResponse = await indexUserFeatures(currentApiKey)
    const newFeatures = {}
    featureResponse.data.forEach((feature) => (newFeatures[feature] = true))

    featuresObject.value = { ...featuresObject.value, ...newFeatures }
    featuresLoaded = true
  }

  const hasFeature = (featureName) => featuresObject.value[featureName] === true
  const hasFeatures = (featureNames) => {
    const result = {}
    if (!Array.isArray(featureNames)) return result
    const features = featuresObject.value
    featureNames.forEach((featureName) => (result[featureName] = features[featureName] === true))
    return result
  }
  return {
    featuresObject,
    loadFeatures,
    hasFeature,
    hasFeatures
  }
}
