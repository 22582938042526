<template>
  <v-row v-if="emptyMessages" align="center" justify="center" class="fill-height">
    <h3>{{ $t('app_chat.empty_messages') }}</h3>
  </v-row>
  <v-row v-else align="start" justify="center" no-gutters class="messages-row" :class="isRtl ? 'rtl' : 'ltr'">
    <image-carousel-dialog ref="imageCarouselDialog">
    </image-carousel-dialog>
    <chat-message-read-by-dialog :api-key="apiKey" ref="chatMessageReadByDialog">
    </chat-message-read-by-dialog>
    <template v-for="({ rowType, value, id }) in messagesList">
      <chat-message-layout v-if="rowType === rowTypes.message"
        :key="id"
        :api-key="apiKey"
        :message="value"
        @image-click="showImages"
        @info-click="onInfoClick"
        @copy-click="onCopyClick">
      </chat-message-layout>
      <v-chip v-else-if="rowType === rowTypes.date" :ripple="false" class="date-row t-400-14" :key="`date_${id}`">
        {{ value }}
      </v-chip>
      <v-col cols="12" class="text-center py-5" v-else-if="rowType === rowTypes.loadMore" :key="`intersect_${id}`"
        v-intersect.once="onIntersect">
      </v-col>
      <v-col cols="10" class="text-center py-5" v-else-if="rowType === rowTypes.loadingMore" :key="`load_more_${id}`">
        <v-progress-circular indeterminate color="primary"></v-progress-circular>
      </v-col>
    </template>
    <v-snackbar v-model="snackBarData.show" :timeout="snackBarData.timeout" color="success">
      <div class="text-center">
        {{ $t('share_content.clipboard_copy') }}
      </div>
    </v-snackbar>
  </v-row>
</template>

<style lang="scss">
.messages-row {
  position: relative;

  .date-row {
    position: sticky;
    top: 0;
    z-index: 4;
    width: 120px;
    margin-bottom: 15px;
    margin-top: 15px;
    justify-content: center;
    background-color: #F4F6F8 !important;
  }
}
</style>

<script>

import ChatMessageLayout from '@/components/app_chat/chat_message/ChatMessageLayout.vue'
import ImageCarouselDialog from '@/components/shared/ImageCarouselDialog.vue'

import useAppChat from '@/composables/useAppChat.js'
import useAccount from '@/composables/useAccount.js'

import { ISO8601dateToText, ISO8601dateTimeStringToISODate } from '@/helpers/DateTime.js'

import { isString } from '@/helpers/Utils.js'

import { isRtl } from '@/i18n.js'

import { EventBus, ACTIVE_APP_CHAT_GROUP_CHANGED, APP_CHAT_NEW_MESSAGE_SENT, NEW_APP_CHET_MESSAGE_RECEIVED } from '@/EventBus.js'

import { computed, onUnmounted, ref } from 'vue'

const rowTypes = { date: 1, message: 2, loadMore: 3, loadingMore: 4 }

const isDuringIntersectionLoading = ref(false)

export default {
  props: {
    apiKey: {
      type: String,
      required: true
    }
  },
  components: {
    'chat-message-layout': ChatMessageLayout,
    'image-carousel-dialog': ImageCarouselDialog,
    'chat-message-read-by-dialog': () => import('@/components/app_chat/read_by_dialog/ChatMessageReadByDialog.vue')
  },
  setup(props, { emit }) {
    const imageCarouselDialog = ref(null)
    const chatMessageReadByDialog = ref(null)
    const snackBarData = ref({ show: false, timeout: 3000 })
    const { activeConversation, appendReceivedMessage, loadNextMessagePageForActiveGroup } = useAppChat(props.apiKey)

    const showSnackbar = () => (snackBarData.value.show = true)
    const scrollToBottom = () => {
      const messagesListElement = document.querySelector('.group-messages')
      if (messagesListElement === null) return

      messagesListElement.scrollTop = messagesListElement.scrollHeight
    }

    const onNewMessageReceived = ({ group_id: groupId, message }) => {
      if (isLoading.value === true) return

      const activeConversationVal = activeConversation.value
      if (activeConversationVal.group.id !== groupId) return

      const messages = activeConversationVal.messages
      const messageAllreadyReceived = messages.some((currentMessage) => currentMessage.id === message.id)
      if (messageAllreadyReceived === true) return

      appendReceivedMessage({ groupId, message })
        .then(scrollToBottom)
    }

    // Methods

    const showImages = ({ images, index }) => imageCarouselDialog.value.openDialog({ imageUrls: images, index })
    const onInfoClick = (message) => chatMessageReadByDialog.value.openDialog({ message, chatGroupId: activeConversation.value.group.id })
    const onCopyClick = (message) => {
      const content = isString(message.content_translated) ? message.content_translated : message.content
      navigator.clipboard.writeText(content)
      showSnackbar()
    }
    const onIntersect = () => {
      if (isLoading.value === true || isDuringIntersectionLoading.value === true) return
      isDuringIntersectionLoading.value = true
      loadNextMessagePageForActiveGroup()
        .then(() => setTimeout(() => (isDuringIntersectionLoading.value = false), 0))
    }

    // Computed

    const isLoading = computed(() => activeConversation.value.isLoading)
    const emptyMessages = computed(() => messagesList.value.length === 0)
    const showLoadMorePages = computed(() => {
      const activeConversationVal = activeConversation.value
      if (activeConversationVal.messages.length === 0 || isLoading.value === true || isDuringIntersectionLoading.value === true) return false

      const { page, totalPages } = activeConversationVal.pagination
      return page < totalPages
    })

    const messagesList = computed(() => {
      const messages = activeConversation.value.messages
      if (messages.length === 0) return messages

      const result = []

      if (showLoadMorePages.value === true) {
        result.push({ rowType: rowTypes.loadMore, value: '', id: 'loadMore' })
      } else if (isDuringIntersectionLoading.value === true) {
        result.push({ rowType: rowTypes.loadingMore, value: '', id: 'loadingMore' })
      }
      const { accountCountryCode } = useAccount()
      const countryCode = accountCountryCode.value
      let currentDate = ISO8601dateTimeStringToISODate(messages[0].created_at_date_raw)
      let currentDateFormmated = ISO8601dateToText({ fromDate: currentDate, countryCode })
      result.push({ rowType: rowTypes.date, value: currentDateFormmated, id: currentDate })

      messages.forEach((message) => {
        const isoDate = ISO8601dateTimeStringToISODate(message.created_at_date_raw)
        if (isoDate !== currentDate) {
          currentDate = isoDate
          currentDateFormmated = ISO8601dateToText({ fromDate: currentDate, countryCode })
          result.push({ rowType: rowTypes.date, value: currentDateFormmated, id: currentDate })
        }
        result.push({ rowType: rowTypes.message, value: message, id: message.id })
      })
      return result
    })
    EventBus.off(ACTIVE_APP_CHAT_GROUP_CHANGED, scrollToBottom)
    EventBus.off(APP_CHAT_NEW_MESSAGE_SENT, scrollToBottom)
    EventBus.off(NEW_APP_CHET_MESSAGE_RECEIVED, onNewMessageReceived)

    EventBus.on(ACTIVE_APP_CHAT_GROUP_CHANGED, scrollToBottom)
    EventBus.on(APP_CHAT_NEW_MESSAGE_SENT, scrollToBottom)
    EventBus.on(NEW_APP_CHET_MESSAGE_RECEIVED, onNewMessageReceived)
    onUnmounted(() => {
      EventBus.off(ACTIVE_APP_CHAT_GROUP_CHANGED, scrollToBottom)
      EventBus.off(APP_CHAT_NEW_MESSAGE_SENT, scrollToBottom)
      EventBus.off(NEW_APP_CHET_MESSAGE_RECEIVED, onNewMessageReceived)
    })
    return {
      isLoading,
      emptyMessages,
      messagesList,
      rowTypes,
      isRtl,
      showLoadMorePages,
      imageCarouselDialog,
      chatMessageReadByDialog,
      snackBarData,
      onIntersect,
      showImages,
      onInfoClick,
      onCopyClick
    }
  }
}
</script>
