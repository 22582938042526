import Client from '@/api_client/client.js'

const index = (apiKey, itemId, itemOperationId) => {
  const INDEX_PATH = `/accounts/${apiKey}/items/${itemId}/item_operations/${itemOperationId}/item_operation_list_items`
  return Client.get(INDEX_PATH)
}

const batchUpsert = (apiKey, itemId, itemOperationId, params) => {
  const BATCH_UPSERT_PATH = `/accounts/${apiKey}/items/${itemId}/item_operations/${itemOperationId}/item_operation_list_items/batch_upsert`
  return Client.put(BATCH_UPSERT_PATH, params)
}

const updateItemsOrder = (apiKey, itemId, itemOperationId, params) => {
  const BATCH_UPSERT_PATH = `/accounts/${apiKey}/items/${itemId}/item_operations/${itemOperationId}/item_operation_list_items/update_items_order`
  return Client.put(BATCH_UPSERT_PATH, params)
}

export {
  index,
  batchUpsert,
  updateItemsOrder
}
