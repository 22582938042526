import Client from '@/api_client/client.js'

function index ({ apiKey, clientCurrentDate, clientDate, rangeScope, filters }) {
  const params = { client_current_date: clientCurrentDate, range_scope: rangeScope, client_date: clientDate, filters }
  const ACCOUNT_JOB_OPS_COUNT_PATH = `/accounts/${apiKey}/account_jobs_operations_for_date_range`
  return Client.put(ACCOUNT_JOB_OPS_COUNT_PATH, params)
}

export {
  index
}
