<template>
  <v-rating
    class="avg-rating"
    :value="avgStarsRating"
    half-increments
    readonly
    length="5"
    size="16"
    color="#0040FF">
  </v-rating>
</template>
<style lang="scss">
.avg-rating {
  button {
    padding: 4px !important;
  }
}
</style>
<script>
import { itemTypes } from '@/models/ItemOperationListItemTypes.js'
import { computed } from 'vue'

export default {
  props: {
    itemsList: {
      type: Array,
      required: false,
      default: () => []
    }
  },
  setup(props, { emit }) {
    const markedStarRating = (itemOpList) => itemOpList.item_type === itemTypes.rating && itemOpList.bool_value === true

    // Computed
    const avgStarsRating = computed(() => {
      const ratingItems = props.itemsList.filter(markedStarRating)
      const sum = ratingItems.reduce((acc, item) => acc + item.int_value, 0)
      return sum / ratingItems.length
    })

    return {
      avgStarsRating
    }
  }
}
</script>
