import { index as AccountClientIndex, updateOrder } from '@/api_client/AccountClient.js'
import { totalOperationsForAccounts } from '@/api_client/OperationSchedule.js'
import { companyTotals as faultyDeviceCompanyTotals } from '@/api_client/AccountFaultyDevice.js'

import { getClientDate, isDateInTheFuture, isDateIsToday, dateToISO8601String } from '@/helpers/DateTime.js'

import { isString } from '@/helpers/Utils.js'

import { ref, computed, nextTick } from 'vue'

const DEFAULT_PAGE = 1
const DEFAULT_PER_PAGE = 100
const uiModes = { list: 'list', grid: 'grid' }

const getInitialDate = () => {
  const now = new Date();
  const hour = now.getHours();
  const isBetweenMidnightAndFive = hour >= 0 && hour < 5;
  return isBetweenMidnightAndFive ? new Date(now.setDate(now.getDate() - 1)) : now
}

const state = ref({
  accountsList: [],
  pagination: { page: DEFAULT_PAGE, per_page: DEFAULT_PER_PAGE, total: null, totalPages: null },
  filter: { companyDistrictIds: [] },
  clientDate: getInitialDate(),
  isLoading: false
})
const uiMode = ref(uiModes.grid)

async function loadAllAccounts(apiKey, reset = false) {
  state.value.isLoading = true
  if (reset === true) {
    state.value.pagination.page = DEFAULT_PAGE
  }

  const responses = await loadAccountsData(apiKey)

  const allAccounts = responses[0].data.data
  const accountsStats = responses[1].data.data
  const faultyDevicesTotals = responses[2].data.data
  setPaginationState(responses[0].data.meta)
  const newAccounts = allAccounts.map((account) => {
    const accountStats = accountsStats[account.api_key] || {}
    const statData = {
      faultyDevicesTotals: faultyDevicesTotals[account.api_key] || 0
    }
    assignOperationsTotal(statData, accountStats)
    account.district_name = isString(account.district_name) ? account.district_name : ''
    account.company_district_id = Number.isInteger(account.company_district_id) ? account.company_district_id : null
    return Object.assign(account, statData)
  })
  if (reset === true) {
    state.value.accountsList.splice(0, state.value.accountsList.length)
  }
  state.value.accountsList = state.value.accountsList.concat(newAccounts)
  state.value.isLoading = false
}

async function loadAccountsData(apiKey) {
  const requestParams = loadAccountsRequestParams()
  const response = await AccountClientIndex(apiKey, requestParams)
  const apiKeys = response.data.data.map((account) => account.api_key)
  const extraDataResponse = await Promise.all([
    totalOperationsForAccountsRequest({ apiKey, apiKeys }),
    faultyDeviceCompanyTotals(apiKey, { apiKeys })
  ])
  return [response].concat(extraDataResponse)
}

async function updateOperationsList({ apiKey }) {
  state.value.isLoading = true
  const apiKeys = state.value.accountsList.map((account) => account.api_key)
  const response = await totalOperationsForAccountsRequest({ apiKey, apiKeys })
  setOperationsCountForAllAccounts(response.data.data)
  state.value.isLoading = false
}

function setFilters({ companyDistrictIds }) {
  state.value.filter.companyDistrictIds = companyDistrictIds
}

function totalOperationsForAccountsRequest({ apiKey, apiKeys }) {
  const clientDate = state.value.clientDate
  const requestParams = {
    client_date: getClientDate(clientDate),
    client_date_is_today: isDateIsToday(clientDate),
    client_date_is_future: isDateInTheFuture(clientDate),
    api_keys: apiKeys
  }
  return totalOperationsForAccounts(apiKey, requestParams)
}

function loadAccountsRequestParams() {
  const requestParams = {
    per_page: DEFAULT_PER_PAGE,
    page: state.value.pagination.page
  }

  const companyDistrictIds = state.value.filter.companyDistrictIds
  if (Array.isArray(companyDistrictIds) && companyDistrictIds.length > 0) {
    requestParams.company_districts_ids = companyDistrictIds
  }
  return requestParams
}

function assignOperationsTotal(account, totalOperations) {
  account.totalTasks = totalOperations.tasks_count
  account.totalCrossAccountTasks = totalOperations.cross_account_tasks_count
  account.totalFinishedTasks = totalOperations.finished_tasks_count
  account.totalFinishedCrossAccountTasks = totalOperations.finished_cross_account_tasks_count
}

function setOperationsCountForAllAccounts(accountsTotalOperations) {
  state.value.accountsList.forEach((account) => {
    const accountStats = accountsTotalOperations[account.api_key] || {}
    assignOperationsTotal(account, accountStats)
  })
}

function setClientDate(newDate) {
  state.value.clientDate = getCurrentDate(newDate)
}

const loadNextPage = (apiKey) => {
  if (state.value.pagination.page < state.value.pagination.totalPages) {
    state.value.pagination.page++
    state.value.isLoading = true
    nextTick(() => loadAllAccounts(apiKey))
  }
}

function getCurrentDate(date = new Date()) {
  const currentDate = dateToISO8601String(date)
  return new Date(`${currentDate}T00:00:00`)
}

function setPaginationState({ page, per_page: perPage, total }) {
  const pagination = state.value.pagination

  pagination.page = page
  pagination.perPage = perPage
  pagination.total = total

  if (total === 0 || total <= pagination.perPage) {
    pagination.totalPages = 0
  } else {
    pagination.totalPages = Math.ceil(total / pagination.perPage)
  }
}
const changeAccountsOrder = (apiKey) => {
  const accountList = state.value.accountsList
  const newOrder = accountList.map((account) => account.api_key)

  state.value.isLoading = true
  const params = { account_api_keys: newOrder }
  return updateOrder({ apiKey, params })
    .then(() => loadAllAccounts(apiKey, true))
    .finally(() => (state.value.isLoading = false))
}

// Computed
const isGridModeView = computed(() => uiMode.value === uiModes.grid)
const getAccountList = computed(() => state.value.accountsList)
const getPagination = computed(() => state.value.pagination)
const getClientDateComp = computed(() => state.value.clientDate)
const getIsLoading = computed(() => state.value.isLoading)
const totalItems = computed(() => state.value.pagination.total)

export {
  DEFAULT_PER_PAGE,
  getAccountList,
  getPagination,
  getClientDateComp,
  getIsLoading,
  totalItems,
  isGridModeView,
  uiMode,
  loadAllAccounts,
  setFilters,
  setClientDate,
  updateOperationsList,
  loadNextPage,
  changeAccountsOrder
}
