<template>
  <tr class="texts-start">
    <td>
      <drive-file-icon :file-name="file.name"></drive-file-icon>
      <span class="pps-1 t-700-16">{{ file.name }}</span>
    </td>
    <td class="t-500-16 color5">
      {{ createdAtString }}
    </td>
    <td>
      {{ file.creator?.first_name }} {{ file.creator?.last_name }}
    </td>
    <td>
      <v-menu close-on-content-click>
        <template v-slot:activator="{ on }">
          <v-icon v-on="on" color="black">more_vert</v-icon>
        </template>
        <v-list class="edit-operation">
          <v-list-item ripple @click="onDownload">
            <v-list-item-icon>
              <v-icon>download</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ $t('download') }}</v-list-item-title>
          </v-list-item>
          <v-list-item ripple @click="onDelete" v-if="showDeleteOption">
            <v-list-item-icon>
              <v-icon>delete</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ $t('delete') }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </td>
  </tr>
</template>
<script>
import DriveFileIcon from '@/components/drive/shared/DriveFileIcon.vue'
import { dateToLocalString } from '@/helpers/DateTime.js'

import useAuth from '@/composables/useAuth.js'

import { computed } from 'vue'
export default {
  props: {
    file: {
      type: Object,
      required: true
    }
  },
  components: {
    'drive-file-icon': DriveFileIcon
  },
  setup(props, { emit }) {
    const { isUserCompanyAdmin, currentUser } = useAuth()

    // Methods
    const onDelete = () => emit('delete-file', props.file)
    const onDownload = () => window.open(props.file.download_link, '_blank')

    // Computed
    const createdAtString = computed(() => dateToLocalString(new Date(props.file.created_at)))

    const showDeleteOption = computed(() => {
      if (isUserCompanyAdmin.value) return true
      const currentUserId = currentUser.value?.id
      return Number.isInteger(currentUserId) && currentUserId === props.file.creator?.user_id
    })
    return {
      createdAtString,
      showDeleteOption,
      onDelete,
      onDownload
    }
  }
}
</script>