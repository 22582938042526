<template>
  <span>
    <schedule-new-staff-operation-dialog ref="scheduleNewStaffOperation" v-if="!isAccountMode">
    </schedule-new-staff-operation-dialog>
    <schedule-new-operation-dialog ref="scheduleNewOperation" :api-key="apiKey" v-if="isAccountMode && !apiChanging">
    </schedule-new-operation-dialog>
    <v-btn color="black" class="add-task"
      @click="addTask"
      dark
      small
      fab>
      <v-icon>add</v-icon>
    </v-btn>
  </span>
</template>
<style lang="scss">
.add-task {
  position: fixed;
  z-index: 4;
  bottom: 86px;
  right: 10px;

  @media only screen and (max-width: 600px) {
    bottom: 80px;
  }
}

.rtl {
  .add-task {
    right: unset;
    left: 10px;
  }
}
</style>
<script>
import ScheduleNewOperationDialog from '@/components/operation_schedule_dialog/ScheduleNewOperationDialog.vue'
import ScheduleNewStaffOperationDialog from '@/components/operation_schedule_dialog/ScheduleNewStaffOperationDialog.vue'

import { ref, watch, nextTick, computed } from 'vue'

const taskMode = {
  hq: 'hq',
  staff: 'staff',
  account: 'account'
}


export default {
  props: {
    apiKey: {
      type: String,
      required: true
    },
    taskMode: {
      type: String,
      required: true,
      validator: (value) => Object.values(taskMode).includes(value),
    }
  },
  components: {
    'schedule-new-staff-operation-dialog': ScheduleNewStaffOperationDialog,
    'schedule-new-operation-dialog': ScheduleNewOperationDialog
  },
  setup(props) {
    const scheduleNewStaffOperation = ref(null)
    const scheduleNewOperation = ref(null)
    const apiChanging = ref(false)

    // Methods
    const addTask = () => {
      if (props.taskMode === taskMode.hq) {
        scheduleNewStaffOperation.value.openCrossAccountCreate()
      } else if (props.taskMode === taskMode.staff) {
        scheduleNewStaffOperation.value.openStaffAccountCreate()
      } else {
        scheduleNewOperation.value.openDialog()
      }
    }

    // Computed

    const isAccountMode = computed(() => props.taskMode === taskMode.account)

    watch(() => props.apiKey, () => {
      if (isAccountMode.value !== true) return
      apiChanging.value = true
      nextTick(() => apiChanging.value = false)
    })

    return {
      scheduleNewStaffOperation,
      scheduleNewOperation,
      apiChanging,
      isAccountMode,
      addTask
    }
  }
}
</script>
