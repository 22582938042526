import { i18n } from '@/i18n.js'

const requiredField = (value) => {
  if (Array.isArray(value)) {
    if (value.length === 0) {
      return i18n.t('validator.required')
    }
    return true
  }
  if (value === undefined || value === null) {
    return i18n.t('validator.required')
  }
  if (value === false) {
    return true
  }
  if (typeof value === 'object') {
    for (const _ in value) return true // eslint-disable-line no-unused-vars, no-unreachable-loop
    return false
  }
  if (String(value).trim().length === 0) {
    return i18n.t('validator.required')
  }
  return true
}

export {
  requiredField
}
