<template>
  <v-col cols="10"
    class="chat-message"
    v-bind:class="{ 'texts-end mms-auto': !isMessageFromCurrentUser }">
    <v-chip
      :color="isMessageFromCurrentUser ? 'primary' : ''"
      dark
      class="pa-4 mb-2 texts-start">
      <v-row no-gutters>
        <v-col cols="12">
          {{ message.content }}
        </v-col>
        <v-col cols="12">
          <sub class="mt-4">
            {{ message.created_at_date }} {{ message.created_at_time }}
          </sub>
          <sub class="mt-4 pps-3">
            {{ message.displayName }}
          </sub>
        </v-col>
      </v-row>
      <v-btn icon class="pl-1"
        @click="deleteMessage"
        v-if="isMessageFromCurrentUser"
        :disabled="deleteMessageIsLoading"
        :loading="deleteMessageIsLoading">
        <v-icon>delete</v-icon>
      </v-btn>
    </v-chip>
  </v-col>
</template>
<style lang="scss">
.chat-message {
  .v-chip {
    height: auto;
    white-space: normal;

    sub {
      font-size: 10px;
    }
  }
}
</style>
<script>
import useAuth from '@/composables/useAuth.js'
import { computed } from 'vue'

export default {
  props: {
    message: {
      type: Object,
      required: true
    },
    deleteMessageIsLoading: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  setup(props, { emit }) {
    const { currentUser } = useAuth()

    // Methods
    const deleteMessage = () => emit('delete-message', props.message.id)

    // Computed
    const isMessageFromCurrentUser = computed(() => props.message.user_id === currentUser.value.id)
    return {
      currentUser,
      isMessageFromCurrentUser,
      deleteMessage
    }
  }
}
</script>
