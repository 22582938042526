import Client from '@/api_client/client.js'

function index ({ lang, apiKey }) {
  const SYSTEM_GUIDANCE_INDEX_PATH = `accounts/${apiKey}/system_guidances`
  return Client.get(SYSTEM_GUIDANCE_INDEX_PATH, { params: { lang } })
}

export {
  index
}
