<template>
  <v-container fluid class="full-height pb-0 px-0 ops-history"
    :class="{ 'rtl': isRtl, 'ltr': !isRtl }">
    <v-row align="start" justify="center" class="full-height" v-if="$vuetify.breakpoint.mdAndUp">
      <v-col cols="12" class="page-title">
        {{ $t("operation_history.page_title") }}
      </v-col>
      <v-col cols="12" class="row align-start justify-start" v-if="showAccountSelect">
        <v-col cols="12" md="4" sm="6" class="mt-4 align-self-end">
          <page-account-select></page-account-select>
        </v-col>
      </v-col>
      <v-col cols="6" class="full-height" lg="5" md="4">
        <operations-history-top-bar :api-key="apiKey"></operations-history-top-bar>
      </v-col>
      <v-col cols="6" class="full-height" lg="7" md="8">
        <div class="d-flex flex-column full-height">
          <v-col class="flex-grow-1 flex-shrink-0 overflow-y-auto ltr">
            <operations-history-list :api-key="apiKey"></operations-history-list>
          </v-col>
          <v-col class="flex-grow-0 flex-shrink-1 mb-15" v-if="showPagination">
            <v-pagination
              :value="pagination.page"
              circle
              total-visible="10"
              :length="pagination.totalPages"
              v-on:input="pageChange">
            </v-pagination>
          </v-col>
        </div>
      </v-col>
    </v-row>
    <v-row align="start" justify="center" v-else>
      <v-col cols="12" class="page-title mt-sm-14 mt-0 py-0">
        {{ $t("operation_history.page_title") }}
      </v-col>
      <v-col cols="12">
        <operations-history-top-bar :api-key="apiKey" class="mt-sm-10"></operations-history-top-bar>
      </v-col>
      <v-col cols="12" class="pt-1">
        <operations-history-list :api-key="apiKey"></operations-history-list>
      </v-col>
      <v-col cols="10" class="mb-5" v-if="showPagination">
        <v-pagination
          :value="pagination.page"
          circle
          total-visible="6"
          :length="pagination.totalPages"
          v-on:input="pageChange">
        </v-pagination>
      </v-col>
    </v-row>
  </v-container>
</template>
<style lang="scss">
.ops-history {
  @media only screen and (max-width: 960px) {
    padding-top: 44px;
  }

  @media only screen and (max-width: 600px) {
    padding-top: 140px;
  }

  padding-top: 130px;
}
</style>
<script>
import OperationsHistoryTopBar from '@/components/operations_history/OperationsHistoryTopBar.vue'
import OperationsHistoryList from '@/components/operations_history/OperationsHistoryList.vue'
import PageAccountSelect from '@/components/shared/PageAccountSelect.vue'

import useOperationHistory from '@/composables/useOperationHistory.js'
import useAuth from '@/composables/useAuth.js'

import { isRtl } from '@/i18n'

import { useRoute } from 'vue-router/composables'
import { computed } from 'vue'
export default {
  components: {
    'operations-history-top-bar': OperationsHistoryTopBar,
    'operations-history-list': OperationsHistoryList,
    'page-account-select': PageAccountSelect
  },
  setup() {
    const router = useRoute()
    const { apiKey, isCompanyAdminRegionOrAbove } = useAuth()

    const { pagination, pageChange } = useOperationHistory(apiKey.value)
    const showPagination = computed(() => pagination.value.totalPages > 0)
    const showAccountSelect = computed(() => isCompanyAdminRegionOrAbove.value && !router.meta.staffOnly)
    return {
      apiKey,
      showPagination,
      pagination,
      isRtl,
      showAccountSelect,
      pageChange
    }
  }
}
</script>
