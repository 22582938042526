import Client from '@/api_client/client.js'

function create (apiKey, supplierId, params) {
  const DEVICE_OPERATION_CREATE_PATH = `/accounts/${apiKey}/account_suppliers/${supplierId}/item_operations`
  return Client.post(DEVICE_OPERATION_CREATE_PATH, params)
}

function update (apiKey, supplierId, operationId, params) {
  const DEVICE_OPERATION_UPDATE_PATH = `/accounts/${apiKey}/account_suppliers/${supplierId}/item_operations/${operationId}`
  return Client.put(DEVICE_OPERATION_UPDATE_PATH, params)
}
function destroy (apiKey, supplierId, operationId) {
  const DEVICE_OPERATION_DELETE_PATH = `/accounts/${apiKey}/account_suppliers/${supplierId}/item_operations/${operationId}`
  return Client.delete(DEVICE_OPERATION_DELETE_PATH)
}

export {
  create,
  update,
  destroy
}
