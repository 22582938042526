import Client from '@/api_client/client.js'

const search = ({ apiKey, context, term = null }) => {
  const TAGS_SEARCH_PATH = `accounts/${apiKey}/tags/search`
  return Client.get(TAGS_SEARCH_PATH, { params: { term, context } })
}

export {
  search
}
