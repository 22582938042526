import { getShiftColor } from '@/store/modules/OrganizationJob.js'

import { search as JobClientSearch } from '@/api_client/AccountJob.js'

import { daysOfTheWeek } from '@/helpers/DateTime.js'

import { ref } from 'vue'

const isLoadedPerApiKey = {}
const departmentIdsPerApiKey = {}
const isSearchForOperationForApiKey = {}
const accountJobs = {}
let jobsPerDay = {}

const activeSelectJobs = ref([])

const initJobsPerDay = () => {
  const jobsPerDay = {}
  daysOfTheWeek().forEach((day) => (jobsPerDay[day.name] = { isLoaded: false, jobs: [] }))
  return jobsPerDay
}

const fromJobResponse = (job) => ({
  id: job.id,
  name: job.name,
  shiftName: job.shift_name,
  color: getShiftColor(job.shift_name),
  departmentName: job.department_name,
  departmentId: job.department_id
})

const jobsWithDepartmentHeaders = (jobs) => {
  let departmentId = null
  const result = []
  jobs.forEach(job => {
    const currentJob = fromJobResponse(job)
    if (Number.isInteger(currentJob.departmentId) && currentJob.departmentId !== departmentId) {
      result.push({ id: currentJob.departmentId, header: currentJob.departmentName })
      departmentId = currentJob.departmentId
    }
    result.push(currentJob)
  })
  return result
}

setTimeout(() => {
  jobsPerDay = initJobsPerDay()
}, 0)

const setIsFirstXJobsLoaded = ({ apiKey, isLoaded, departmentIds, searchForOperation }) => {
  isLoadedPerApiKey[apiKey] = isLoaded
  departmentIdsPerApiKey[apiKey] = departmentIds
  isSearchForOperationForApiKey[apiKey] = searchForOperation
  for (const day in jobsPerDay) {
    jobsPerDay[day].isLoaded = false
  }
}

const departmentIdsEqualForApiKey = (cachedDepartmentIds, departmentIds) => {
  const depIdsForApiKey = Array.isArray(cachedDepartmentIds) ? cachedDepartmentIds.sort() : []
  const departmentIdsArray = Array.isArray(departmentIds) ? departmentIds.sort() : []
  return depIdsForApiKey.length === departmentIdsArray.length && depIdsForApiKey.every((id, index) => departmentIdsArray[index] === id)
}

const searchForOperationEqul = (cachedSearchForOperation = false, searchForOperation = false) => {
  return cachedSearchForOperation === searchForOperation
}

const loadFirstXJobs = async ({ apiKey, departmentIds, searchForOperation } = {}) => {
  if (isLoadedPerApiKey[apiKey] && departmentIdsEqualForApiKey(departmentIdsPerApiKey[apiKey], departmentIds) &&
      searchForOperationEqul(isSearchForOperationForApiKey[apiKey], searchForOperation)) {
    activeSelectJobs.value = accountJobs[apiKey]
    return
  }
  const requestParams = {
    per_page: 60,
    department_ids: departmentIds
  }
  if (searchForOperation === true) {
    requestParams.search_for_operation = true
  }
  const jobsResponse = await JobClientSearch(apiKey, requestParams)

  setIsFirstXJobsLoaded({ apiKey, isLoaded: true, departmentIds, searchForOperation })
  accountJobs[apiKey] = jobsWithDepartmentHeaders(jobsResponse.data.data)
  const activeSelectJobsVal = activeSelectJobs.value
  activeSelectJobsVal.splice(0, activeSelectJobsVal.length)
  accountJobs[apiKey].forEach((job) => activeSelectJobsVal.push(job))
}

const loadFirstXJobsForDay = async ({ apiKey, day } = {}) => {
  const jobPerDay = jobsPerDay[day]
  if (jobPerDay.isLoaded) {
    activeSelectJobs.value = jobPerDay.jobs
    return
  }
  const jobsResponse = await JobClientSearch(apiKey, { day, per_page: 60 })

  jobsPerDay[day].isLoaded = true
  jobsPerDay[day].jobs = jobsWithDepartmentHeaders(jobsResponse.data.data)
  activeSelectJobs.value = jobsPerDay[day].jobs
}

export {
  loadFirstXJobs,
  loadFirstXJobsForDay,
  setIsFirstXJobsLoaded,
  activeSelectJobs
}
