<template>
  <v-row align="center" justify="start" no-gutters>
    <v-col class="flex-grow-1 flex-shrink-0">
      <v-chip small :color="accountColor" v-if="showAccountName" class="t-500-13 white--text">
        {{ accountNameAndDistrict }}
      </v-chip>
      <div class="pt-2" v-if="showTag || showCategory">
        <v-chip small color="#111" v-if="showCategory" class="t-500-13 white--text px-2">
          {{ brokenItem.item_category_name }}
        </v-chip>
        <span class="px-1" v-if="showTag">
          {{ tagText }}
        </span>
      </div>
      <div class="font-weight-bold pt-2">
        <span>{{ brokenItem.item_name }}</span>
        <span v-if="brokenItem.item_department">
          - {{ brokenItem.item_department }}
        </span>
        <v-icon v-if="showUrgentIcon" size="20" color="#FF1744" class="ml-1">local_fire_department</v-icon>
      </div>
      <div class="show-line-break pt-2 t-700-13" v-if="showDescription">
        {{ brokenItem.description }}
      </div>
    </v-col>
    <v-col class="flex-grow-0 flex-shrink-1 align-self-start">
      <media-preview v-if="showMediaPreview"
        @click.native="mediaClick"
        :media-urls="mediaPreviewUrls">
      </media-preview>
    </v-col>
  </v-row>
</template>
<script>

import { statusSecondColors } from '@/store/modules/BrokenItem.js'

import { isString } from '@/helpers/Utils.js'

import { computed } from 'vue'
export default {
  props: {
    brokenItem: {
      type: Object,
      required: true
    }
  },
  components: {
    'media-preview': () => import('@/components/shared/MediaPreview.vue'),
  },
  setup(props, { emit }) {
    // Methods
    const mediaClick = () => emit('media-click', mediaPreviewUrls)

    // Computed
    const accountColor = computed(() => statusSecondColors[props.brokenItem.status])
    const showAccountName = computed(() => isString(props.brokenItem.account_name))
    const showDescription = computed(() => isString(props.brokenItem.description))
    const showUrgentIcon = computed(() => props.brokenItem.priority === 'high')
    const mediaPreviewUrls = computed(() => {
      const media = (props.brokenItem.images || [])
        .map((image) => ({ url: image.url, type: 'image' }))
      const video = props.brokenItem.attached_video_url
      if (isString(video) && video.length > 0) media.push({ url: video, type: 'video' })
      return media
    })
    const showMediaPreview = computed(() => mediaPreviewUrls.value.length > 0)
    const accountNameAndDistrict = computed(() => {
      if (!showAccountName.value) return ''
      const result = [props.brokenItem.account_name]
      if (isString(props.brokenItem.district_name)) {
        result.push(props.brokenItem.district_name)
      }
      return result.join(' - ')
    })
    const showCategory = computed(() => isString(props.brokenItem.item_category_name))
    const showTag = computed(() => Array.isArray(props.brokenItem.tags) && props.brokenItem.tags.length > 0)
    const tagText = computed(() => {
      if (!showTag.value) return ''
      return `#${props.brokenItem.tags[0]}`
    })


    return {
      showAccountName,
      accountNameAndDistrict,
      showCategory,
      showTag,
      tagText,
      showDescription,
      showUrgentIcon,
      mediaPreviewUrls,
      showMediaPreview,
      accountColor,
      mediaClick
    }
  }
}
</script>
