import Client from '@/api_client/client.js'

const upsert = (apiKey, { chatGroupId, messageId }) => {
  const UPSERT_GROUP_CHAT_MESSAGE_TRACK_PATH = `/accounts/${apiKey}/chat_groups/${chatGroupId}/chat_group_messages_track/upsert`
  const params = { chat_group_message_track: { message_id: messageId } }
  return Client.put(UPSERT_GROUP_CHAT_MESSAGE_TRACK_PATH, params)
}

const unreadGroupCount = (apiKey) => {
  const UNREAD_GROUP_COUNT_PATH = `/accounts/${apiKey}/chat_groups/unread_group_count`
  return Client.get(UNREAD_GROUP_COUNT_PATH)
}

export {
  upsert,
  unreadGroupCount
}
