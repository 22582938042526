<template>
  <v-row align="center" justify="space-between" class="weekly-time">
    <time-selection ref="timeDialog" v-model="time"></time-selection>
    <v-col cols="12" class="text-left font-weight-bold pb-0">
      <div> {{ $t('org_job_new_dialog.shift_times') }}</div>
    </v-col>
    <v-col cols="12">
      <span v-for="weekDay in daysOfWeek" :key="weekDay.name" class="time-select">
        <div class="text-center font-weight-bold">{{weekDay.translatedName}}</div>
        <v-text-field
          :label="$t('org_job_new_dialog.start')"
          readonly
          class="start-time"
          single-line
          :rules="[validateTimeFormat]"
          :value="weeklySchedule[weekDay.name].start_time"
          @click="openTimeDialog(weekDay.name, true)"
          :error-messages="onlyOneShiftScheduleFilled((weekDay.name), true) ? [$t('validator.required')] : []">
        </v-text-field>
        <v-text-field
          :label="$t('org_job_new_dialog.end')"
          readonly
          single-line
          :rules="[validateTimeFormat]"
          :value="weeklySchedule[weekDay.name].end_time"
          @click="openTimeDialog(weekDay.name, false)"
          :error-messages="onlyOneShiftScheduleFilled((weekDay.name), false) ? [$t('validator.required')] : []">
        </v-text-field>
        <v-btn x-small outlined tile
          color="black white--text" class="mx-0 mt-o mb-4"
          @click="applyTimeForAllDays(weekDay.name)"
          :disabled="applyForAllDaysDisabled(weekDay.name)">
          {{$t('org_job_new_dialog.all_days')}}
        </v-btn>
      </span>
    </v-col>
  </v-row>
</template>

<style lang="scss">
.weekly-time {
  .time-select {
    display:inline-block;
    padding-right: 6px;
    padding-left: 6px;
    width:calc(100% / 7);
  }
  @media only screen and (max-width: 600px) {
    .time-select {
      width:calc(100% / 3);
      margin-top: 10px;
      padding-right: 8px;
      padding-left: 8px;
      .v-input.v-text-field.start-time {
        padding-top: 0;
      }
      .v-text-field__details {
        display: none;
      }
    }
  }
}
</style>
<script>
import TimeSelectionDialog from '@/components/account_jobs/account_job_create_dialog/AccountJobsCreateTimeSelectionDialog.vue'
import useAccount from '@/composables/useAccount.js'

import { daysOfTheWeek } from '@/helpers/DateTime.js'

import { handler as errHandler } from '@/classes/ErrorHandler.js'

import { i18n } from '@/i18n.js'

import { ref } from 'vue'

const validTimeRegex = /^(2[0-3]|[01]?[0-9]):([0-5]?[0-9])$/

export default {
  props: {
    weeklySchedule: {
      type: Object,
      required: true
    }
  },
  components: {
    'time-selection': TimeSelectionDialog
  },
  setup (props, { emit }) {
    const { weekStartDay } = useAccount()
    const daysOfWeek = daysOfTheWeek(weekStartDay.value)
    const time = ref('')
    const timeDialog = ref(null)

    const updateSingeTime = ({ day, isStartTime, time: timeVal }) => emit('update', { day, isStartTime, time: timeVal })

    const setTimeForAll = (isStartTime, timeToSet) => {
      daysOfWeek.forEach(dayOfWeek => {
        updateSingeTime({ day: dayOfWeek.name, isStartTime, time: timeToSet })
      })
    }
    // Methods

    const validateTimeFormat = (value) => {
      const trimedValueLength = (value || '').trim().length
      if (trimedValueLength === 0) return true
      if (value.length === 5 && validTimeRegex.test(value)) {
        return true
      }
      return i18n.t('validator.invlid_time')
    }

    const openTimeDialog = (day, isStartTime) => {
      const currentDay = props.weeklySchedule[day]
      let timeValue = ''
      let userFormattedTime = ''
      if (isStartTime) {
        timeValue = currentDay.start_time
        userFormattedTime = currentDay.start_time_user_formatted || ''
      } else {
        timeValue = currentDay.end_time
        userFormattedTime = currentDay.end_time_user_formatted || ''
      }

      time.value = ''
      if (validateTimeFormat(timeValue) === true) {
        time.value = timeValue
      }
      timeDialog.value.open(userFormattedTime)
        .then(() => updateSingeTime({ day, isStartTime, time: time.value }))
        .catch(errHandler)
    }

    const onlyOneShiftScheduleFilled = (index, isStartField) => {
      const weeklySchedule = props.weeklySchedule[index]
      const startLength = (weeklySchedule.start_time || '').trim().length
      const endLength = (weeklySchedule.end_time || '').trim().length
      if (isStartField && endLength === 5 && startLength === 0) {
        return true
      }
      if (!isStartField && startLength === 5 && endLength === 0) {
        return true
      }
      return false
    }

    const applyForAllDaysDisabled = (index) => {
      const startTime = (props.weeklySchedule[index].start_time || '').trim()
      const endTime = (props.weeklySchedule[index].end_time || '').trim()
      return startTime.length !== 5 || endTime.length !== 5
    }

    const applyTimeForAllDays = (index) => {
      const daySchedule = props.weeklySchedule[index]
      setTimeForAll(true, daySchedule.start_time)
      setTimeForAll(false, daySchedule.end_time)
    }
    return {
      daysOfWeek,
      timeDialog,
      time,
      validateTimeFormat,
      openTimeDialog,
      onlyOneShiftScheduleFilled,
      applyForAllDaysDisabled,
      applyTimeForAllDays
    }
  }
}
</script>
