import { getWeeklyScheduleFromSource } from '@/store/modules/WeeklySchedule.js'
import { daysOfTheWeek } from '@/helpers/DateTime.js'

import { isObject } from '@/helpers/Utils.js'

import { i18n } from '@/i18n.js'
const shiftColorsMap = {
  morning: '#CBE4F2',
  noon: '#E8D0A1',
  evening: '#EDB6B6',
  night: '#C9A1E8'
}

const shiftSortOrderMap = {
  morning: 1,
  noon: 2,
  evening: 3,
  night: 4
}
const VALID_TIME = /^(\d{2}):(\d{2})$/
const jobTypes = { accountJob: 'AccountJob', accountSupplier: 'AccountSupplier' }

const newJobFields = ['id', 'shift_id', 'name']

function JobModel(src) {
  const srcOrEmpty = src || {}
  const shiftName = (srcOrEmpty.shift || {}).name
  const accountDepartment = (srcOrEmpty.account_department || { id: null, name: null })

  const weeklySchedule = srcOrEmpty.weekly_schedule || {}
  Object.assign(this,
    {
      weekly_schedule: getWeeklyScheduleFromSource(weeklySchedule),
      shift: { name: shiftName },
      account_department: accountDepartment
    })
  newJobFields.forEach((field) => {
    this[field] = srcOrEmpty[field]
  })
}

JobModel.prototype.shiftSortOrder = function () {
  return shiftSortOrderMap[this.shift.name]
}
JobModel.prototype.translateShift = function () {
  return i18n.t('shifts.' + this.shift.name)
}
JobModel.prototype.serialize = function () {
  const params = JSON.parse(JSON.stringify(this))
  params.weekly_schedule = formatWeeklySchedule(params.weekly_schedule)
  delete params.shift
  const accountDepartmentId = isObject(this?.account_department) ? this.account_department.id : null // eslint-disable-line camelcase
  params.account_department_id = Number.isInteger(accountDepartmentId) ? accountDepartmentId : null
  delete params.account_department
  return params
}
function translateShift(shiftName) {
  return i18n.t('shifts.' + shiftName)
}

function translateShiftWithShiftPrefix(shiftName) {
  return i18n.t('shifts.with_prefix.' + shiftName)
}

function getShiftColor(shiftName) {
  return shiftColorsMap[shiftName]
}
function shiftSortOrder(shiftName) {
  return shiftSortOrderMap[shiftName]
}

function newJobModel(src) {
  return new JobModel(src)
}

function formatWeeklySchedule(weeklySchedule) {
  const formmatedweeklySchedule = {}
  const daysOfWeek = daysOfTheWeek()
  daysOfWeek.forEach((day) => {
    const dayName = day.name
    const shiftSchedule = weeklySchedule[dayName]
    const formattedShiftSchedule = {}
    if (VALID_TIME.test(shiftSchedule.start_time || '') && VALID_TIME.test(shiftSchedule.end_time || '')) {
      formattedShiftSchedule.start_time = shiftSchedule.start_time
      formattedShiftSchedule.end_time = shiftSchedule.end_time
      formmatedweeklySchedule[dayName] = formattedShiftSchedule
    }
  })
  return formmatedweeklySchedule
}

const getShiftSortOrderMap = () => {
  return {
    morning: 1,
    noon: 2,
    evening: 3,
    night: 4
  }
}
export {
  getShiftColor,
  shiftSortOrderMap,
  getShiftSortOrderMap,
  translateShift,
  translateShiftWithShiftPrefix,
  shiftSortOrder,
  newJobModel,
  jobTypes
}
