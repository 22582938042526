<template>
  <v-row align="start" justify="center">
    <v-col cols="10">
      <v-data-table class="company-table elevation-1"
        :headers="tableHeaders"
        :items="accountsList"
        :items-per-page="DEFAULT_PER_PAGE"
        :server-items-length="totalItems"
        hide-default-footer
        mobile-breakpoint="0"
        :loading-text="$t('loading')"
        item-key="id"
        :loading="tableIsLoading">
        <template v-slot:body="{ items }">
          <draggable
            :list="accountsList"
            :disabled="!isUserCompanyAdmin"
            tag="tbody"
            handle=".drag-handle"
            @change="onListOrderChanged">
            <tr @click="accountClick(item)"
              v-for="item in items"
              :key="item.id"
              class="pointer">
              <td>
                <v-avatar size="32" class="mme-2">
                  <v-img :src="accountStoreImage(item)"></v-img>
                </v-avatar>
                <span class="t-700-16">{{ item.name }}</span>
              </td>
              <td>{{ item.district_name }}</td>
              <td>
                <div class="ltr texts-start">
                  {{ item.totalFinishedCrossAccountTasks }} &nbsp;/&nbsp; {{ item.totalCrossAccountTasks }}
                </div>
              </td>
              <td>
                <div class="ltr texts-start">
                  {{ item.totalFinishedTasks }} &nbsp;/&nbsp; {{ item.totalTasks }}
                </div>
              </td>
              <td @click="brokenItemClick(item)" class="tickets-col">
                <v-row align="center" justify="space-between" no-gutters>
                  <v-col class="texts-start flex-grow-0 flex-shrink-1 text-no-wrap">
                    <span v-if="item.faultyDevicesTotals > 0"
                      class="text-decoration-underline">{{ $t('company_table.view') }}</span> {{
                        item.faultyDevicesTotals }}
                  </v-col>
                  <v-col class="ppe-2 texts-end">
                    <v-btn icon @click.stop="openAccountEditDialog(item)">
                      <v-icon>edit</v-icon>
                    </v-btn>
                    <v-btn text icon class="black--text drag-handle" v-if="isUserCompanyAdmin">
                      <v-icon>drag_indicator</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </td>
            </tr>
          </draggable>
        </template>
      </v-data-table>
    </v-col>
  </v-row>
</template>
<style lang="scss">
.company-table {
  .v-data-table__wrapper {
    thead {
      .header {
        background-color: rgba(0, 64, 255, 0.05);
        font-size: 16px;
        font-weight: 700;
      }
    }

    table {
      tbody {
        td {
          padding-top: 8px !important;
          padding-bottom: 8px !important;
        }

        td {
          text-align: start;
          font-size: 16px;
          font-weight: 500;

          &.tickets-col {
            min-width: 200px;
          }
        }
      }
    }
  }
}
</style>
<script>
import draggable from 'vuedraggable'
import useAuth from '@/composables/useAuth.js'
import { getAccountList, changeAccountsOrder, getIsLoading as tableIsLoading, totalItems, DEFAULT_PER_PAGE } from '@/components/company_stores/AccountSelectionStore.js'

import { isString } from '@/helpers/Utils.js'
import { i18n } from '@/i18n.js'

export default {
  components: {
    draggable
  },
  props: {
    apiKey: {
      type: String,
      required: true
    }
  },
  setup(props, { emit }) {
    const tableHeaders = [
      { align: 'start', text: i18n.t('company_table.headers.name'), class: 'header texts-start', sortable: false },
      { align: 'start', text: i18n.t('company_table.headers.location'), class: 'header', sortable: false },
      { align: 'start', text: i18n.t('company_table.headers.hq_tasks'), class: 'header', sortable: false },
      { align: 'start', text: i18n.t('company_table.headers.account_tasks'), class: 'header', sortable: false },
      { align: 'start', text: i18n.t('company_table.headers.total_faults'), class: 'header', sortable: false }
    ]
    const { isUserCompanyAdmin } = useAuth()

    // Methods
    const onListOrderChanged = () => changeAccountsOrder(props.apiKey)
    const openAccountEditDialog = (account) => emit('edit-account', account)
    const brokenItemClick = (account) => emit('broken-item-click', account)
    const accountClick = (account) => emit('account-click', account)

    const accountStoreImage = (account) => {
      const customStoreImage = account.custom_store_image
      if (isString(customStoreImage) && customStoreImage.trim().length > 0) {
        return customStoreImage.replace('image/upload/f_auto,q_auto,dpr_auto,c_limit,w_400,h_400', 'image/upload/f_auto,q_auto,dpr_auto')
      } else {
        return account.store_image
      }
    }
    return {
      DEFAULT_PER_PAGE,
      tableHeaders,
      tableIsLoading,
      totalItems,
      accountsList: getAccountList,
      isUserCompanyAdmin,
      onListOrderChanged,
      accountStoreImage,
      accountClick,
      brokenItemClick,
      openAccountEditDialog
    }
  }
}
</script>