<template>
  <v-row align="start" justify="start" no-gutters class="job-day-wrapper bg-color19 mt-4" v-if="showDay">
    <v-col cols="12">
      {{ dayName }}
    </v-col>
    <v-col cols="12" class="t-500-13">
      {{ daySchedule.start_time_user_formatted }} - {{ daySchedule.end_time_user_formatted }}
    </v-col>
    <v-col cols="12" class="t-500-13">
      <span v-if="showMinutes">
        {{ $t('org_structure_jobs.shift_duration', { hours: shiftDuration.hours, minutes: shiftDuration.minutes }) }}
      </span>
      <span v-else>
        {{ $t('org_structure_jobs.shift_duration_hours', { hours: shiftDuration.hours }) }}
      </span>
    </v-col>
  </v-row>
</template>
<style lang="scss">
.job-day-wrapper {
  border-radius: 8px;
  border: 1px solid black;
  padding: 8px;
}
</style>
<script>

import { isString } from '@/helpers/Utils'
import { durationBetweenTimes } from '@/helpers/DateTime.js'

import { computed } from 'vue'
export default {
  props: {
    daySchedule: {
      type: Object,
      required: true
    },
    dayName: {
      type: String,
      required: true
    }
  },
  setup(props) {
    // Methods    

    const showDay = computed(() => isString(props.daySchedule.start_time) && isString(props.daySchedule.end_time))
    const shiftDuration = computed(() => {
      if (!showDay.value) {
        return { hours: 0, minutes: 0 }
      }
      return durationBetweenTimes({ startTime: props.daySchedule.start_time, endTime: props.daySchedule.end_time })
    })

    const showMinutes = computed(() => shiftDuration.value.minutes > 0)
    return {
      showDay,
      shiftDuration,
      showMinutes
    }
  }
}
</script>