import { isString, isObject } from '@/helpers/Utils.js'
import { getPageQueryParamsObject } from '@/helpers/Url.js'

import router from '@/router.js'
function tabKey (customKey) {
  if (isString(customKey)) return customKey
  return router.history.current.name
}

function preLoadTabFromUrl (validTabs) {
  const sessionKey = `${tabKey()}-from-url`
  const isAlreadyLoadedFromUrl = sessionStorage.getItem(sessionKey) !== null
  if (isAlreadyLoadedFromUrl === true) return

  sessionStorage.setItem(sessionKey, true)
  const pageParameters = getPageQueryParamsObject()
  if (isObject(pageParameters) && isString(pageParameters.selected_tab) &&
      pageParameters.selected_tab in validTabs) {
    saveTab({ currentTab: pageParameters.selected_tab })
  }
}

function loadTab ({ defaultTab, customKey }) {
  const tabFromMem = sessionStorage.getItem(tabKey(customKey))
  return isString(tabFromMem) && tabFromMem.length > 0 ? tabFromMem : defaultTab
}

function saveTab ({ currentTab, customKey }) {
  sessionStorage.setItem(tabKey(customKey), currentTab)
}

function unsetTab ({ customKey } = {}) {
  sessionStorage.removeItem(tabKey(customKey))
}
export {
  loadTab,
  saveTab,
  unsetTab,
  preLoadTabFromUrl
}
