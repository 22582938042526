<template>
  <span>
    <media-carousel-dialog ref="mediaCarouselRef"> </media-carousel-dialog>
    <confirm-delete ref="deleteDialog"></confirm-delete>
    <chat-dialog ref="chatDialog"
      record-type="BrokenItem"
      @mark-read-all="markReadAllChatMessages"
      @update-message-count="updateMessageCount">
    </chat-dialog>
    <v-data-table class="company-broken-items-table elevation-1"
      :class="isRtl ? 'rtl' : 'ltr'"
      :headers="tableHeaders"
      :items="brokenItemsList"
      :items-per-page="DEFAULT_PER_PAGE"
      :server-items-length="totalItems"
      hide-default-footer
      mobile-breakpoint="0"
      :loading-text="$t('loading')"
      item-key="id"
      :loading="isListLoading || isListLoadingNextPage">
      <template v-slot:item="{ item }">
        <company-broken-items-table-row
          :broken-item="item"
          :show-account-data="showAccountData"
          :allow-edit="showEdit"
          :allow-delete="showDelete"
          :show-close-option="showClose"
          @media-click="onMediaClick"
          @open-chat="openChatDialog"
          @change-status="changeBrokenItemStatus"
          @edit-broken-item="onEditBrokenItem"
          @delete-broken-item="onDeleteBrokenItem">
        </company-broken-items-table-row>
      </template>
    </v-data-table>
  </span>
</template>

<style lang="scss">
.company-broken-items-table {
  .v-data-table__wrapper {
    thead {
      .header {
        background-color: rgba(0, 64, 255, 0.05);
        font-size: 16px;
        font-weight: 700;
      }
    }

    table {
      tbody {
        td {
          padding-top: 8px !important;
          padding-bottom: 8px !important;
        }
      }
    }
  }
}
</style>

<script>
import useCompanyBrokenItem from '@/composables/useCompanyBrokenItem.js'
import useAuth from '@/composables/useAuth.js'
import { statuses } from '@/store/modules/BrokenItem.js'

import { isString, isObject } from '@/helpers/Utils.js'
import { isRtl, i18n } from '@/i18n.js'

import { computed, ref } from 'vue'
export default {
  components: {
    'company-broken-items-table-row': () => import('@/components/broken_items/table_view/BrokenItemsTableRow.vue'),
    'chat-dialog': () => import('@/components/chat_dialog/ChatDialog.vue'),
    'media-carousel-dialog': () => import('@/components/shared/MediaCarouselDialog.vue'),
    'confirm-delete': () => import('@/components/shared/ConfirmDelete.vue')
  },
  props: {
    showDelete: {
      type: Boolean,
      default: false
    },
    showClose: {
      type: Boolean,
      default: false
    },
    showEdit: {
      type: Boolean,
      default: false
    },
    showAccountData: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit }) {
    const mediaCarouselRef = ref(null)
    const chatDialog = ref(null)
    const deleteDialog = ref(null)

    const { apiKey } = useAuth()
    const { DEFAULT_PER_PAGE, brokenItemsList, isListLoading, isListLoadingNextPage, pagination, deleteBrokenItem,
      setBrokenItemStatusToClose, applyFilters } = useCompanyBrokenItem(apiKey.value)
    const tableHeaders = [
      { align: 'center', text: i18n.t('broken_item_table.headers.location'), value: 'location', class: 'header', sortable: false },
      { align: 'center', text: i18n.t('broken_item_table.headers.description'), value: 'images', class: 'header', sortable: false },
      { align: 'center', text: i18n.t('broken_item_table.headers.assigned_to'), value: 'assigned', class: 'header', sortable: false },
      { align: 'center', text: i18n.t('broken_item_table.headers.comments'), value: 'comments', class: 'header', sortable: false },
      { align: 'center', text: i18n.t('broken_item_table.headers.open_date'), value: 'open_date', class: 'header', sortable: false },
      { align: 'center', text: i18n.t('broken_item_table.headers.status'), value: 'status', class: 'header', sortable: false }
    ]

    if (props.showAccountData) {
      tableHeaders.unshift({ align: 'center', text: i18n.t('broken_item_table.headers.account_name'), value: 'name', class: 'header', sortable: false })
    }

    const setCloseDateForToday = async (brokenItem) => {
      const title = i18n.t('devices_faulty_device_dialog.close_ticket')
      const content = i18n.t('devices_faulty_device_dialog.close_ticket_approve')
      const deleteBtnText = i18n.t('devices_faulty_device_dialog.send')
      const shouldDelete = await deleteDialog.value.open({ title, content, deleteBtnText })
      if (!shouldDelete) return

      setBrokenItemStatusToClose(brokenItem)
        .finally(() => deleteDialog.value.close())
    }

    // Methods
    const onMediaClick = (mediaArray) => mediaCarouselRef.value.openDialog(mediaArray)
    const onEditBrokenItem = (brokenItem) => emit('edit-broken-item', brokenItem)

    const onDeleteBrokenItem = async (brokenItem) => {
      const shouldDelete = await deleteDialog.value.open()
      if (!shouldDelete) return
      deleteBrokenItem(brokenItem)
        .finally(() => deleteDialog.value.close())
    }
    const changeBrokenItemStatus = ({ brokenItem, status }) => {
      if (status === statuses.close) {
        return setCloseDateForToday(brokenItem)
      }
      brokenItem.updateStatus({ newStatus: status, apiKey: brokenItem.account_api_key })
        .then(applyFilters)
    }

    const openChatDialog = (brokenItem) => {
      chatDialog.value.openDialog({ recordId: brokenItem.id, apiKey: brokenItem.account_api_key })
    }

    const updateMessageCount = ({ recordId, messageCount }) => {
      const brokenItem = brokenItemsList.value.find((brokenItem) => brokenItem.id === recordId)
      if (isObject(brokenItem)) brokenItem.updateMessageCount(messageCount)
    }

    const markReadAllChatMessages = (recordId) => {
      const brokenItem = brokenItemsList.value.find((brokenItem) => brokenItem.id === recordId)
      if (isObject(brokenItem)) brokenItem.markAllMessagesAsRead()
    }

    // Computed
    const totalItems = computed(() => pagination.value.total)
    return {
      DEFAULT_PER_PAGE,
      mediaCarouselRef,
      chatDialog,
      deleteDialog,
      brokenItemsList,
      tableHeaders,
      isListLoading,
      isListLoadingNextPage,
      isRtl,
      totalItems,
      onMediaClick,
      openChatDialog,
      updateMessageCount,
      markReadAllChatMessages,
      changeBrokenItemStatus,
      onEditBrokenItem,
      onDeleteBrokenItem
    }
  }
}

</script>
