import { getAllStatuses, getAllPriorities } from '@/store/modules/BrokenItem.js'
import { companyAccountsStatusCount } from '@/api_client/AccountFaultyDevice.js'

import {
  indexWithBrokenItems as itemsClientIndex,
  destroy as itemsClientDestroy,
  showGeneral as itemsClientShowGeneralItem
} from '@/api_client/Device.js'
import { dateToISO8601String } from '@/helpers/DateTime.js'
import { destroy as itemCategoryClientDelete } from '@/api_client/ItemCategory.js'
import { deadlineApiFilter } from '@/classes/DeadlineFilter.js'

import useAuth from '@/composables/useAuth.js'
import { isString, isDate } from '@/helpers/Utils.js'

import { ref, computed } from 'vue'

const DEFAULT_PER_PAGE = 70
const DEFAULT_PAGE = 1

const defaultPagination = () => ({ page: DEFAULT_PAGE, perPage: DEFAULT_PER_PAGE, total: null, totalPages: null })
const isLoading = ref(false)
const pagination = ref(defaultPagination())
const itemList = ref([])
const selectedApiKey = ref(null)
const accountList = ref([])
const isAccountsLoading = ref(false)

// filters
const filters = ref({
  onlyBrokenItemAssignedToMe: false,
  onlyBrokenItemOpenedByMe: false,
  brokenItemStatusFilter: [],
  brokenItemPriorityFilter: null,
  companyDistrictFilter: [],
  accountsFilter: [],
  deadlineDateFilter: null,
  openDateStartFilter: null,
  openDateEndFilter: null,
  closeDateStartFilter: null,
  closeDateEndFilter: null,
  textSearchFilter: null,
  companyProvidersFilter: []
})


export const generalItemId = ref(null)

export default function useItem(apiKey) {
  const availableStatuses = ref(getAllStatuses({ addClosedStatus: true }))
  const availablePriorities = ref(getAllPriorities())
  const { isCompanyAdminRegionOrAbove } = useAuth()
  const resetValues = () => {
    isLoading.value = false
    pagination.value = defaultPagination()
    itemList.value = []
    selectedApiKey.value = null
    const filtersVal = filters.value
    filtersVal.onlyBrokenItemAssignedToMe = false
    filtersVal.onlyBrokenItemOpenedByMe = false
    filtersVal.brokenItemStatusFilter.splice(0, filtersVal.brokenItemStatusFilter.length)
    filtersVal.brokenItemPriorityFilter = null
    filtersVal.companyDistrictFilter.splice(0, filtersVal.companyDistrictFilter.length)
    filtersVal.accountsFilter = []
    filtersVal.deadlineDateFilter = null
    filtersVal.openDateStartFilter = null
    filtersVal.openDateEndFilter = null
    filtersVal.closeDateStartFilter = null
    filtersVal.closeDateEndFilter = null
    filtersVal.textSearchFilter = null
    filtersVal.companyProvidersFilter.splice(0, filtersVal.companyProvidersFilter.length)
  }


  if (isString(apiKey) && selectedApiKey.value === null) {
    resetValues()
    selectedApiKey.value = apiKey
  }

  const getTotalPages = ({ total, perPage }) => {
    const totalItems = total || 0
    if (totalItems === 0 || totalItems <= perPage) {
      return 0
    }
    return Math.ceil(totalItems / perPage)
  }

  const setPaginationFromResponse = (meta) => {
    const paginationVal = pagination.value
    paginationVal.total = meta.total
    paginationVal.page = meta.page
    paginationVal.perPage = meta.per_page
    paginationVal.totalPages = getTotalPages(paginationVal)
  }

  const addDeadlineDateFilter = (requestParams) => {
    const apiParams = deadlineApiFilter(filters.value.deadlineDateFilter)
    if (apiParams === null) return
    requestParams = Object.assign(requestParams, apiParams)
  }

  const apiFilterParams = () => {
    const filterParams = {}
    const filtersVal = filters.value
    if (filtersVal.onlyBrokenItemAssignedToMe === true) filterParams.assigned_to_me = true
    if (filtersVal.onlyBrokenItemOpenedByMe === true) filterParams.opened_by_me = true
    if (filtersVal.brokenItemStatusFilter.length > 0) filterParams.status = filtersVal.brokenItemStatusFilter
    if (filtersVal.brokenItemPriorityFilter !== null) filterParams.priority = filtersVal.brokenItemPriorityFilter
    if (filtersVal.companyDistrictFilter.length > 0) filterParams.company_districts_ids = filtersVal.companyDistrictFilter
    if (isString(filtersVal.textSearchFilter) && filtersVal.textSearchFilter.trim().length > 0) filterParams.text_search = filtersVal.textSearchFilter
    if (isDate(filtersVal.openDateStartFilter)) filterParams.open_date = [dateToISO8601String(filtersVal.openDateStartFilter)]
    if (isDate(filtersVal.openDateEndFilter) && isDate(filtersVal.openDateStartFilter)) filterParams.open_date.push(dateToISO8601String(filtersVal.openDateEndFilter))

    if (isDate(filtersVal.closeDateStartFilter)) filterParams.close_date = [dateToISO8601String(filtersVal.closeDateStartFilter)]
    if (isDate(filtersVal.closeDateEndFilter) && isDate(filtersVal.closeDateStartFilter)) filterParams.close_date.push(dateToISO8601String(filtersVal.closeDateEndFilter))
    addDeadlineDateFilter(filterParams)
    if (filtersVal.companyProvidersFilter.length > 0) filterParams.company_providers_ids = filtersVal.companyProvidersFilter.map((provider) => provider.id)
    return filterParams
  }

  const apiParams = () => {
    const params = { page: pagination.value.page, per_page: pagination.value.perPage }
    const apiFilterParamsObj = apiFilterParams()
    return { ...params, ...apiFilterParamsObj }
  }

  const resetTableAndReload = () => {
    pagination.value.page = DEFAULT_PAGE
    itemList.value = []
    loadItemsPage()
  }

  // Methods
  const deleteCategory = async (categoryId) => {
    await itemCategoryClientDelete(currentApiKey.value, categoryId)
    resetTableAndReload()
  }

  const deleteItem = async (id) => {
    await itemsClientDestroy(currentApiKey.value, id)
    resetTableAndReload()
  }

  const loadItemsPage = async () => {
    isLoading.value = true
    const requestParams = apiParams()
    const response = await itemsClientIndex(selectedApiKey.value, requestParams)
    itemList.value = itemList.value.concat(response.data.data)

    setPaginationFromResponse(response.data.meta)
    isLoading.value = false
  }

  const changeSelectedAccount = (newApiKey, force = false) => {
    if (!isString(newApiKey)) return
    if (selectedApiKey.value === newApiKey && force !== true) return
    selectedApiKey.value = newApiKey
    isLoading.value = false
    pagination.value = defaultPagination()
    itemList.value = []
    loadItemsPage()
  }

  const loadNextPage = () => {
    if (pagination.value.page < pagination.value.totalPages) {
      pagination.value.page++
      isLoading.value = true
      loadItemsPage()
    }
  }

  const applyFilters = () => {
    if (!isCompanyAdminRegionOrAbove.value) {
      resetTableAndReload()
      return
    }
    const apiFilterParamsObj = apiFilterParams()
    apiFilterParamsObj.api_keys = filters.value.accountsFilter.map((apiKey) => apiKey)
    loadAccounts(apiFilterParamsObj)
      .then(() => {
        if (accountList.value.length === 0) {
          itemList.value = []
          return
        }
        const selectedApiKeyVal = selectedApiKey.value
        const hasAccount = accountList.value.some((account) => account.apiKey === selectedApiKeyVal)
        if (!hasAccount) {
          selectedApiKey.value = accountList.value[0].apiKey
        }
        resetTableAndReload()
      })

  }

  const initPageData = (apiKeyVal) => {
    resetValues()
    if (!isCompanyAdminRegionOrAbove.value) {
      changeSelectedAccount(apiKeyVal, true)
      return
    }
    loadAccounts().then(() => {
      const apiKey = accountList.value[0]?.api_key
      changeSelectedAccount(apiKey, true)
    })
  }

  const loadGeneralItem = async (forceLoad = false) => {
    if (Number.isInteger(generalItemId.value) && forceLoad !== true) return

    const response = await itemsClientShowGeneralItem(apiKey)
    generalItemId.value = response.data.data.id
  }

  const loadAccounts = (filters = {}) => {
    isAccountsLoading.value = true
    accountList.value = []
    const accountListVal = accountList.value
    return companyAccountsStatusCount(apiKey, filters)
      .then(({ data: { data } }) => {
        data.forEach(account => {
          const result = JSON.parse(JSON.stringify(account))
          result.apiKey = account.api_key
          const faultsCount = account.faults_statuses || {}
          result.faultsCount = Object.values(faultsCount)
            .reduce((accumulator, currentValue) => accumulator + currentValue, 0)
          accountListVal.push(JSON.parse(JSON.stringify(result)))
        })
      })
      .finally(() => (isAccountsLoading.value = false))
  }

  // Computed
  const currentApiKey = computed(() => selectedApiKey.value)
  return {
    isLoading,
    itemList,
    currentApiKey,
    pagination,
    filters,
    availableStatuses,
    availablePriorities,
    isAccountsLoading,
    accountList,
    deleteCategory,
    deleteItem,
    loadItemsPage,
    loadNextPage,
    resetTableAndReload,
    applyFilters,
    initPageData,
    loadGeneralItem,
    loadAccounts,
    changeSelectedAccount
  }
}