<template>
  <v-select class="required"
    :items="options"
    :value="value"
    item-value="id"
    :menu-props="{ 'contentClass': isRtl ? 'rtl' : 'ltr' }"
    @input="onUpdate"
    @change="onChange"
    item-text="translatedName"
    single-line>
    <template v-slot:item="{ item }">
      <v-row align="start" justify="start" no-gutters>
        <v-col cols="12" class="color5 t-600-18 px-2">
          {{ item.translatedName }}
        </v-col>
        <v-col cols="12" v-if="item.id === 'feedback'" class="py-0 px-2 mt-2 color3 t-500-16">
          {{ $t('item_type_select.feedback_optional') }}
        </v-col>
      </v-row>
    </template>
  </v-select>
</template>
<script>
import { itemTypes } from '@/models/ItemOperationListItemTypes.js'
import { i18n, isRtl } from '@/i18n.js'
export default {
  props: {
    value: {
      type: String,
      required: false
    }
  },
  setup(_, { emit }) {
    const options = [{ id: itemTypes.default, translatedName: i18n.t('item_type_select.single_checklist') },
    { id: itemTypes.image, translatedName: i18n.t('item_type_select.image') },
    { id: itemTypes.yes_no, translatedName: i18n.t('item_type_select.yes_no') },
    { id: itemTypes.title, translatedName: i18n.t('item_type_select.title') },
    { id: itemTypes.rating, translatedName: i18n.t('item_type_select.rating') },
    { id: itemTypes.feedback, translatedName: i18n.t('item_type_select.feedback') }
    ]

    // Methods
    const onUpdate = (newValue) => emit('input', newValue)
    const onChange = (newValue) => emit('change', newValue)
    return {
      options,
      isRtl,
      onUpdate,
      onChange
    }
  }
}
</script>
