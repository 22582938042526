<template>
  <v-col class="flex-grow-0 flex-shrink-1 d-flex justify-center side-part pa-0
      align-self-stretch"
    :class="{
      'reported': isReported,
      'pointer': showPointer
    }"
    :style="{ 'background-color': operationColor }">
    <div v-if="isLoading" @click="toggleComplete" class="side-content row no-gutters">
      <v-progress-circular size="34" indeterminate color="black"></v-progress-circular>
    </div>
    <label :for="idForImageLabel" class="side-content" :class="{ 'pointer': !isDisabled }"
      @click="toggleComplete" v-else-if="showImageLabel">
      <v-icon :color="iconColor" class="image-capture"> photo_camera </v-icon>
      <input type="file" :id="idForImageLabel" accept="image/*"
        :disabled="isDisabled" capture="capture" @change="toggleComplete">
    </label>
    <div v-else @click="toggleComplete" class="side-content row no-gutters">
      <v-col cols="12">
        <v-icon :color="iconColor">
          {{ iconString }}
        </v-icon>
        <div v-if="hasItemOperationList" class="color1 t-500-13 text-center"
          :class="{ 'color1': !isDisabled, 'color3': isDisabled }">
          {{ itemOperationListDoneVsTotal }}
        </div>
      </v-col>
    </div>
  </v-col>
</template>
<script>
import { itemTypes } from '@/models/ItemOperationListItemTypes.js'
import { ISO8601StringToDate, isValidISO8601Date, isDateInThePast } from '@/helpers/DateTime.js'

import { defaultColor, newDefaultColor } from '@/store/modules/DeviceOperation.js'
import { isHexColor } from '@/helpers/Utils.js'

import { EventBus, TASK_TOGGLE_COMPLETE_CLICK, TASK_TOGGLE_COMPLETE_PREVENTED_CLICK } from '@/EventBus.js'

import { computed } from 'vue'

const reportableItem = (item) => item.item_type !== itemTypes.title && item.item_type !== itemTypes.feedback

const reportedColorIcon = '#616365'
const notReportedColorIcon = '#0040FF'

export default {
  props: {
    task: {
      type: Object,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    allowToggleComplete: {
      type: Function,
      required: false,
      default: null
    }
  },
  setup(props) {
    const isAllowToggleComplete = () => props.allowToggleComplete === null || props.allowToggleComplete(props.task)

    // Methods

    const toggleComplete = (event) => {
      const toggleNotAllowed = !isAllowToggleComplete()
      if (toggleNotAllowed || (isDisabled.value && !hasItemOperationList.value)) {
        event.preventDefault()
        if (toggleNotAllowed) EventBus.emit(TASK_TOGGLE_COMPLETE_PREVENTED_CLICK, props.task)
        return false
      }

      EventBus.emit(TASK_TOGGLE_COMPLETE_CLICK, { event, task: props.task })
      return true
    }

    // Computed
    const isLoading = computed(() => props.task.operation.processing || props.task.operation.uploading_image)
    const isDisabled = computed(() => isLoading.value || props.disabled === true || endDatePassed.value)
    const isReported = computed(() => props.task.isReported())
    const hasItemOperationList = computed(() => props.task.tasksList().filter(reportableItem).length > 0)
    const showPointer = computed(() => hasItemOperationList.value || !isDisabled.value)
    const iconColor = computed(() => {
      if (isDisabled.value) return '#9E9E9E'
      return isReported.value ? reportedColorIcon : notReportedColorIcon
    })
    const showImageLabel = computed(() => props.task.requireImage() && !isReported.value)
    const itemOperationListDoneVsTotal = computed(() => {
      const list = props.task.tasksList().filter(reportableItem)
      const done = list.filter((item) => item.bool_value === true && Number.isInteger(item.report_id))
      return `${done.length}/${list.length}`
    })

    const operationColor = computed(() => {
      const color = props.task.operationRef.operation_color
      if (color === defaultColor) return newDefaultColor

      if (isHexColor(color)) return color
      return newDefaultColor

    })

    const iconString = computed(() => {
      if (isReported.value) return 'check_circle';

      if (props.task.requireImage()) return 'photo_camera'
      if (hasItemOperationList.value) return 'format_list_bulleted'

      return 'radio_button_unchecked'
    })

    const endDatePassed = computed(() => {
      const endDateString = props.task.operationRef.end_date
      if (!endDateString || !isValidISO8601Date(endDateString)) return false
      const endDate = ISO8601StringToDate(endDateString)
      return isDateInThePast(endDate)
    })

    return {
      idForImageLabel: Math.floor(Math.random() * 10000),
      isLoading,
      iconString,
      hasItemOperationList,
      itemOperationListDoneVsTotal,
      iconColor,
      isDisabled,
      isReported,
      showPointer,
      operationColor,
      showImageLabel,
      toggleComplete
    }
  }
}
</script>