import Client from '@/api_client/client.js'
const USER_LOGIN_PATH = '/auth/verify_code'
const USER_LOGOUT_PATH = '/auth/sign_out'
const USER_VERIFY_PATH = '/auth/send_verification_code'
const USER_SEND_EMAIL_LINK_PATH = '/auth/send_magic_link'

const login = (loginParams) => Client.post(USER_LOGIN_PATH, loginParams)
const logout = () => Client.delete(USER_LOGOUT_PATH)
const sendVerificationCode = ({ phone, channel }) => Client.put(USER_VERIFY_PATH, { phone, channel })
const sendEmailLink = ({ phone, language }) => Client.put(USER_SEND_EMAIL_LINK_PATH, { phone, language })

export {
  login,
  logout,
  sendVerificationCode,
  sendEmailLink
}
