<template>
  <v-select
    :value="value"
    :items="items"
    attach
    :label="$t('schedule_new_op_dialog.tags')"
    clearable
    :height="height"
    :multiple="multiple"
    :menu-props="menuPropsWithClass"
    @change="emitChange"
    @input="update">
    <template v-slot:item="{ item, attrs }">
      <v-row no-gutters justify="start" align="center" v-if="multiple" :class="{ 'rtl': isRtl }">
        <v-col cols="10" class="font-weight-bold texts-start">
          {{ item.text }}
        </v-col>
        <v-col cols="2" class="texts-end">
          <v-icon v-if="attrs.inputValue">check_box</v-icon>
          <v-icon v-else>check_box_outline_blank</v-icon>
        </v-col>
      </v-row>
      <v-row align="center" justify="start" no-gutters v-else>
        {{ item.text }}
      </v-row>
    </template>
    <template v-slot:selection="{ item, index }" v-if="multiple && showSelectCount">
      <div v-if="index === 0" class="v-select__selection v-select__selection--comma">
        {{ $t('schedule_new_op_dialog.x_tags_selected', { tags_count: value.length }) }}
      </div>
    </template>
  </v-select>
</template>

<script>

import { translateReportTag } from '@/store/modules/ReportTag.js'
import { isString, isObject } from '@/helpers/Utils.js'

import { isRtl } from '@/i18n.js'

import { computed } from 'vue'
export default {
  props: {
    value: {
      type: [String, Array],
      required: false,
      default: null
    },
    multiple: {
      type: Boolean,
      required: false,
      default: false
    },
    menuProps: {
      type: Object,
      required: false,
      default: () => ({})
    },
    height: {
      type: Number | String,
      required: false,
      default: undefined
    },
    showSelectCount: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  setup(props, { emit }) {
    // Methods
    const update = (newValue) => emit('input', newValue)
    const emitChange = (value) => emit('change', value)
    const reset = () => update('')
    const tags = [
      { text: translateReportTag('report'), value: 'report' },
      { text: translateReportTag('monitor'), value: 'monitor' },
      { text: translateReportTag('procedure'), value: 'procedure' }
    ]

    // Computed
    const menuPropsWithClass = computed(() => {
      const result = isObject(props.menuProps) ? Object.assign(props.menuProps, {}) : {}
      result.contentClass = isString(result.contentClass) ? result.contentClass : ''
      result.contentClass = `${result.contentClass} ${isRtl.value ? 'rtl' : 'ltr'}`
      return result
    })

    return {
      isRtl,
      menuPropsWithClass,
      items: tags,
      update,
      emitChange,
      reset
    }
  }
}
</script>
