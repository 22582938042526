
import useFeatures from '@/composables/useFeatures.js'
import useAuth from '@/composables/useAuth.js'

import { isString } from '@/helpers/Utils.js'

import { computed } from 'vue'

let currentApiKey = null

export default function currentUserPermissions(apiKey) {
  const { featuresObject, loadFeatures } = useFeatures(apiKey)

  if (isString(apiKey) && currentApiKey !== apiKey) {
    currentApiKey = apiKey
    loadFeatures()
  }

  const { isCompanyAdminRegionOrAbove, isUserAccountAdmin, isUserAccountManager, isUserAccountEmployee } = useAuth()
  // Methods

  const loadPermissions = () => loadFeatures()
  // Computed

  const canCreateItem = computed(() => {
    if (isCompanyAdminRegionOrAbove.value) return true
    const features = featuresObject.value
    if (isUserAccountAdmin.value) return features.account_admin_can_create_items === true
    if (isUserAccountManager.value) return features.account_manager_can_create_items === true
    if (isUserAccountEmployee.value) return features.account_employee_can_create_items === true
    return false
  })
  const canEditItem = computed(() => {
    if (isCompanyAdminRegionOrAbove.value) return true
    if (isUserAccountAdmin.value) return featuresObject.value.account_admin_can_edit_items === true
    return false
  })
  const canDeleteItem = computed(() => {
    if (isCompanyAdminRegionOrAbove.value) return true
    if (isUserAccountAdmin.value) return featuresObject.value.account_admin_can_delete_items === true
    return false
  })

  const canCloseBrokenItem = computed(() => {
    if (isCompanyAdminRegionOrAbove.value || isUserAccountAdmin.value) return true
    return featuresObject.value.broken_item_close === true
  })

  const canDeleteBrokenItem = computed(() => {
    if (isCompanyAdminRegionOrAbove.value || isUserAccountAdmin.value) return true
    return featuresObject.value.broken_item_delete === true
  })

  const canUpdateBrokenItem = computed(() => {
    if (isCompanyAdminRegionOrAbove.value || isUserAccountAdmin.value) return true
    return featuresObject.value.broken_item_update === true
  })

  const canCreateBrokenItem = computed(() => {
    if (isCompanyAdminRegionOrAbove.value || isUserAccountAdmin.value) return true
    return featuresObject.value.broken_item_create === true
  })

  return {
    canCreateItem,
    canEditItem,
    canDeleteItem,
    canCloseBrokenItem,
    canDeleteBrokenItem,
    canUpdateBrokenItem,
    canCreateBrokenItem,
    loadPermissions
  }
}
