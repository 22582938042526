<template>
  <v-dialog
    v-model="dialogOpen"
    persistent
    :content-class="contentClass"
    width="290px">
    <v-date-picker v-model="selectedDate" :range="range" class="employee-schedule-date"
      :type="type" show-week scrollable show-current :min="minDate" :max="maxDate">
      <v-row no-gutters align="end" justify="end" class="text-right">
        <v-col cols="12" class="text-left" v-if="showCurrentDateButton">
          <v-btn tile color="red" outlined small class="ml-1" :disabled="disabled"
            @click="showCurrentDate">
            {{ $t('org_structure_jobs.reset_date') }}
          </v-btn>
        </v-col>
        <v-col cols="12" class="text-left" v-if="showDeleteDateButton">
          <v-btn tile color="red" outlined small class="mr-1" :disabled="deleteDateDisabled"
            @click="deleteDate">
            {{ $t('org_structure_jobs.delete_date') }}
          </v-btn>
        </v-col>
        <v-col cols="12">
          <v-btn text color="primary" @click="cancel" :disabled="disabled">{{ $t('cancel') }}</v-btn>
          <v-btn depressed tile color="primary" @click="apply" :disabled="disabled">{{ $t('ok') }}</v-btn>
        </v-col>
      </v-row>
    </v-date-picker>
  </v-dialog>
</template>
<style lang="scss">
.employee-schedule-date {
  .v-picker__actions.v-card__actions {
    padding-top: 0;
  }
}
</style>
<script>
import { dateToISO8601String, ISO8601StringToDate } from '@/helpers/DateTime.js'

import { ref, computed } from 'vue'

export default {
  props: {
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    value: {
      type: [Date, Array],
      required: false,
      default: null
    },
    minDate: {
      type: String,
      required: false,
      default: null
    },
    maxDate: {
      type: String,
      required: false,
      default: null
    },
    showCurrentDateButton: {
      type: Boolean,
      required: false,
      default: true
    },
    showDeleteDateButton: {
      type: Boolean,
      required: false,
      default: false
    },
    type: {
      type: String,
      required: false,
      default: 'date'
    },
    range: {
      type: Boolean,
      required: false,
      default: false
    },
    contentClass: {
      type: String,
      required: false,
      default: ''
    }
  },
  setup(props, { emit }) {
    const dialogOpen = ref(false)
    const selectedDate = ref(null)

    const returnValueForSingleDate = (dateString) => {
      const date = ISO8601StringToDate(`${dateString}-01`)
      if (props.minDate === null) return date
      const minDateDate = ISO8601StringToDate(props.minDate)
      if (minDateDate.getTime() > date.getTime()) return minDateDate

      return date
    }

    const getSelectionDate = () => {
      if (selectedDate.value === null) return

      if (props.type !== 'month') {
        if (props.range !== true) return ISO8601StringToDate(selectedDate.value)
        return selectedDate.value.map(ISO8601StringToDate)
      }

      if (props.range !== true) {
        return returnValueForSingleDate(selectedDate.value)
      }
      return selectedDate.value.map((date) => returnValueForSingleDate(date))
    }

    // Methods
    const cancel = () => (dialogOpen.value = false)
    const openDialog = () => {
      dialogOpen.value = true
      if (props.value === null) return
      if (props.range !== true) {
        selectedDate.value = dateToISO8601String(props.value)
      } else if (Array.isArray(props.value)) {
        selectedDate.value = props.value
      }
    }
    const apply = () => {
      dialogOpen.value = false
      const newDate = getSelectionDate()
      emit('change', newDate)
    }
    const showCurrentDate = () => {
      const currentDateString = dateToISO8601String(new Date())
      selectedDate.value = props.range === true ? [currentDateString] : currentDateString
    }
    const deleteDate = () => {
      dialogOpen.value = false
      selectedDate.value = null
      emit('clear')
    }

    // Comnputed
    const deleteDateDisabled = computed(() => {
      if (props.disabled === true) return true
      return selectedDate.value === null
    })

    return {
      dialogOpen,
      selectedDate,
      deleteDateDisabled,
      cancel,
      openDialog,
      apply,
      showCurrentDate,
      deleteDate
    }
  }
}
</script>
