<template>
  <v-row align="start" justify="space-between" no-gutters class="item-category-row py-2 px-4 mx-2">
    <v-col class="t-500-20 flex-grow-1 flex-shrink-0">
      {{ item.name }}
    </v-col>
    <v-col class="flex-grow-0 flex-shrink-1" v-if="showOptions">
      <v-menu min-width="100">
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" small icon>
            <v-icon color="black">more_vert</v-icon>
          </v-btn>
        </template>
        <v-list :class="{ 'rtl': isRtl, 'ltr': !isRtl }">
          <v-list-item
            @click="onEditClick"
            class="px-2">
            <v-list-item-content>
              {{ $t('edit') }}
            </v-list-item-content>
            <v-btn icon class="px-1" small>
              <v-icon>edit</v-icon>
            </v-btn>
          </v-list-item>
          <v-list-item
            @click="onDeleteClick"
            class="px-2">
            <v-list-item-content>
              {{ $t('delete') }}
            </v-list-item-content>
            <v-btn icon class="px-1" small>
              <v-icon>delete</v-icon>
            </v-btn>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-col>
  </v-row>
</template>
<style lang="scss">
.item-category-row {
  background-color: #E8E8E8;
}
</style>
<script>
import { isRtl } from '@/i18n.js'
export default {
  props: {
    item: {
      type: Object,
      required: true
    },
    showOptions: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit }) {
    const onEditClick = () => emit('edit-category', props.item)
    const onDeleteClick = () => emit('delete-category', props.item)

    return {
      isRtl,
      onEditClick,
      onDeleteClick
    }
  }
}
</script>