<template>
  <v-card class="link-preview pointer" @click="goToUrl">
    <v-card-text class="pa-3">
      <v-row align="center" justify="start" no-gutters>
        <v-col cols="9" v-if="onlyDomainName">
          <div class="font-weight-bold">
            {{ domainName }}
          </div>
        </v-col>
        <v-col cols="9" v-else>
          <div class="font-weight-bold">
            {{ linkData.title }}
          </div>
          <div class="truncate">
            {{ linkData.description }}
          </div>
          <div class="text-caption text-right">
            {{ domainName }}
          </div>
        </v-col>
        <v-col cols="3">
          <v-img :src="linkData.image" max-height="100px" max-width="100px" contain></v-img>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<style lang="scss">
.link-preview {
  .truncate {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
}
</style>
<script>

import { domainNameFromUrl } from '@/helpers/Url.js'
import { isString } from '@/helpers/Utils.js'
import { computed } from 'vue'
export default {
  props: {
    linkData: {
      type: Object,
      required: true
    }
  },
  setup(props) {
    // Methods
    const goToUrl = () => window.open(props.linkData.url, '_blank')
    const isEmptyString = (val) => isString(val) ? val.trim().length === 0 : true
    // Computed
    const domainName = computed(() => domainNameFromUrl(props.linkData.url))
    const onlyDomainName = computed(() => isEmptyString(props.linkData.title) && isEmptyString(props.linkData.description))
    return {
      domainName,
      onlyDomainName,
      goToUrl
    }
  }
}
</script>
