import Client from '@/api_client/client.js'

const batchCreate = (apiKey, urls) => {
  const INDEX_BY_ACCOUNT_JOB_IDS_PATH = `/accounts/${apiKey}/url_shortener/batch_create`
  return Client.post(INDEX_BY_ACCOUNT_JOB_IDS_PATH, { urls })
}

export {
  batchCreate
}
