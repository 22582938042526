import Client from '@/api_client/client.js'

const index = (apiKey) => {
  const OPERATION_TYPES_INDEX_PATH = '/item_operation_types'
  return Client.get(OPERATION_TYPES_INDEX_PATH)
}

export {
  index
}
