
import { search as searchItemCategories } from '@/api_client/ItemCategory.js'

import { isString } from '@/helpers/Utils.js'

import { ref } from 'vue'

const allItemCategories = ref([])
const isLoading = ref(false)

let shouldReload = true
let currentApiKey = null

export default function useItemCategorySearchStore (apiKey) {
  const invalidateCache = () => {
    shouldReload = true
  }

  const resetValues = () => {
    isLoading.value = false
    allItemCategories.value.splice(0, allItemCategories.value.length)
    invalidateCache()
  }

  if (isString(apiKey) && currentApiKey !== apiKey) {
    resetValues()
    currentApiKey = apiKey
  }

  // Methods
  const loadAllItemCategories = async () => {
    if (shouldReload === false) return
    isLoading.value = true
    return searchItemCategories(currentApiKey)
      .then((response) => {
        const allItemCategoriesVal = allItemCategories.value
        allItemCategoriesVal.splice(0, allItemCategoriesVal.length)
        response.data.data.forEach((item) => allItemCategoriesVal.push(item))
        shouldReload = false
      })
      .finally(() => (isLoading.value = false))
  }

  const changeApiKey = (newApiKey) => {
    if (isString(newApiKey) && currentApiKey !== newApiKey) {      
      currentApiKey = newApiKey
      resetValues()
      invalidateCache()
    }
  }
  return {
    allItemCategories,
    isLoading,
    loadAllItemCategories,
    invalidateCache,
    changeApiKey
  }
}
