<template>
  <v-dialog v-model="dialog" max-width="350" persistent>
    <v-card class="edit-chat-dialog"
      :class="{ 'rtl': isRtl, 'ltr': !isRtl }">
      <v-card-title class="text-center pb-5 blue-grey lighten-5">
        <span class="headline full-width">
          {{ dialogTitle }}
        </span>
      </v-card-title>
      <v-card-text class="pa-2">
        <v-row align="center" justify="start">
          <v-col cols="12">
            <v-file-input
              class="pointer"
              v-model="fileToUpload"
              :rules="[requiredField]"
              show-size
              :label="$t('drive.file_label')">
            </v-file-input>
          </v-col>
          <v-col cols="12" class="text-center t-700-16 py-0" v-if="!isFromFolder">
            {{ $t('drive.access') }}
          </v-col>
          <v-col cols="12" class="pb-0" v-if="!isFromFolder">
            <v-select
              v-model="file.roles"
              item-text="translatedName"
              item-value="name"
              multiple
              single-line
              :items="rolesOptions"
              :disabled="isLoading"
              :menu-props="{ contentClass: isRtl ? 'rtl' : '' }"
              :rules="[requiredField]"
              :label="$t('drive.roles_access')"
              @change="onRoleChange">
            </v-select>
          </v-col>
          <v-col cols=12 class="pt-0" v-if="!isFromFolder">
            <v-switch v-if="showAllAccountsSelect"
              :disabled="isLoading || isCompanyAdminFolder"
              v-model="file.allAccounts" class="d-inline-block ltr"
              @change="onAllAccountsChange"
              :label="$t('drive.all_accounts')">
            </v-switch>
            <account-select v-if="showAccountSelect"
              :api-key="apiKey"
              multiple
              :disabled="isLoading || file.allAccounts || isCompanyAdminFolder"
              :show-select-all="false"
              v-model="file.accounts">
            </account-select>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="pt-2 pr-2 pb-4">
        <v-progress-circular v-if="showUploadFileProgress"
          :rotate="-90"
          :size="36"
          :width="3"
          :value="uploadProgress"
          color="primary">
          {{ uploadProgress }}
        </v-progress-circular>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text :disabled="isLoading" @click="close">
          {{ $t('cancel') }}
        </v-btn>
        <v-btn color="blue darken-1" depressed tile :disabled="saveDisabled"
          @click="save">
          {{ $t('save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import AccountSelect from '@/components/shared/AccountSelect.vue'
import { requiredField } from '@/classes/InputValidators.js'
import { isFile, isObject } from '@/helpers/Utils.js'
import { roleAndBelowRoles } from '@/store/modules/EmployeeRole.js'

import useCompany from '@/composables/useCompany.js'
import useAuth from '@/composables/useAuth.js'
import useCompanyDriveFile from '@/composables/useCompanyDriveFile.js'

import { handler as errHandler } from '@/classes/ErrorHandler.js'

import { uploadFile as uploadFileToRemoteServer } from '@/general_client/FileUploader.js'

import { isRtl, i18n } from '@/i18n.js'

import { ref, computed } from 'vue'
const DialogModes = { create: 1, update: 2 }
const defaultFileParams = () => ({ id: null, name: '', allAccounts: false, roles: [], accounts: [] })
export default {
  components: {
    'account-select': AccountSelect
  },
  props: {
    apiKey: {
      type: String,
      required: true
    }
  },
  setup(props) {
    const { isUserCompanyAdmin, currentUserRole } = useAuth()
    const { isMultipleMemberships } = useCompany(props.apiKey)
    const dialog = ref(false)
    const isLoading = ref(false)
    const uploadProgress = ref(0)
    const dialogMode = ref()
    const file = ref(defaultFileParams())
    const rolesOptions = roleAndBelowRoles(currentUserRole.value)
    const fileToUpload = ref(null)
    const parentFolderId = ref(null)

    const { createFile } = useCompanyDriveFile(props.apiKey)
    const apiParams = () => {
      const fileVal = file.value
      const result = {
        name: fileToUpload.value.name,
        file_size: fileToUpload.value.size,
      }
      if (dialogMode.value === DialogModes.create && isFromFolder.value) {
        result.drive_folder_id = parentFolderId.value
      }
      if (!isFromFolder.value) {
        result.belongs_to_all_accounts = showAllAccountsSelect.value ? fileVal.allAccounts : false
        result.api_keys = showAccountSelect.value ? fileVal.accounts : props.apiKey
        result.roles = fileVal.roles
      }
      return result
    }

    const onFileUploadProgress = (progress) => {
      var percentCompleted = Math.round((progress.loaded * 100) / progress.total)
      uploadProgress.value = percentCompleted
    }

    const uploadFile = async (fileUploadData) => {
      if (!isObject(fileUploadData)) return
      return uploadFileToRemoteServer(fileToUpload.value, fileUploadData.file_url, onFileUploadProgress)

    }
    const loadArgsFromParentFolder = (parentFolder) => {
      if (!isObject(parentFolder) || !Number.isInteger(parentFolder.id)) return
      parentFolderId.value = parentFolder.id

      const fileVal = file.value
      if (parentFolder.belongs_to_all_roles) {
        fileVal.roles = rolesOptions.map(role => role.name)
      } else {
        fileVal.roles = parentFolder.roles
      }
      if (parentFolder.belongs_to_all_accounts) {
        fileVal.allAccounts = parentFolder.belongs_to_all_accounts
      } else {
        fileVal.accounts = parentFolder.accounts.map(account => account.api_key)
      }
    }

    // Methods
    const onRoleChange = () => {
      if (!isCompanyAdminFolder.value) return
      file.value.allAccounts = true
    }

    const openDialog = (parentFolderArg) => {
      dialog.value = true
      dialogMode.value = DialogModes.create
      loadArgsFromParentFolder(parentFolderArg)
    }

    const openForUpdate = async (parentFolderArg) => {
      dialog.value = true
      dialogMode.value = DialogModes.update
      parentFolderId.value = parentFolderArg
    }

    const onAllAccountsChange = (newAllAccounts) => {
      if (!newAllAccounts) return
      file.value.accounts = []
    }
    const save = () => {
      isLoading.value = true
      createFile(apiParams())
        .then(({ data: { file_upload_data: fileUploadData } }) => uploadFile(fileUploadData))
        .then(close)
        .catch(errHandler)
        .finally(() => {
          isLoading.value = false
          uploadProgress.value = 0
        })
    }

    const close = () => {
      dialog.value = false
      isLoading.value = false
      uploadProgress.value = 0
      file.value = defaultFileParams()
      fileToUpload.value = null
      parentFolderId.value = null
    }

    // Computed
    const dialogTitle = computed(() => dialogMode.value === DialogModes.create ?
      i18n.t('drive.upload_file') : i18n.t('drive.update_file')
    )
    const isCompanyAdminFolder = computed(() => isUserCompanyAdmin.value && !isFromFolder.value &&
      file.value.roles.length === 1 &&
      file.value.roles[0] === 'company_admin')

    const showAccountSelect = computed(() => isMultipleMemberships.value)
    const showAllAccountsSelect = computed(() => isUserCompanyAdmin.value)

    const saveDisabled = computed(() => {
      if (isLoading.value) return true
      if (!isFile(fileToUpload.value)) return true
      if (isFromFolder.value) return false
      const fileVal = file.value
      if (fileVal.roles.length === 0) return true
      if (showAccountSelect.value && fileVal.accounts.length === 0 && !fileVal.allAccounts) return true

      return false
    })

    const showUploadFileProgress = computed(() => uploadProgress.value > 0 && uploadProgress.value < 100)
    const isFromFolder = computed(() => Number.isInteger(parseInt(parentFolderId.value)))
    return {
      isRtl,
      dialog,
      dialogTitle,
      file,
      isLoading,
      saveDisabled,
      rolesOptions,
      isFromFolder,
      isCompanyAdminFolder,
      showAccountSelect,
      showAllAccountsSelect,
      showUploadFileProgress,
      uploadProgress,
      fileToUpload,
      requiredField,
      onRoleChange,
      openDialog,
      openForUpdate,
      onAllAccountsChange,
      save,
      close
    }
  }
}
</script>