const itemTypes = {
  image: 'image',
  title: 'title',
  default: 'single_option_checklist',
  rating: 'rating',
  yes_no: 'yes_no',
  feedback: 'feedback'
}

const stringValueMaxLength = 2000
export {
  itemTypes,
  stringValueMaxLength
}
