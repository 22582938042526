import Client from '@/api_client/client.js'

function index (apiKey, params) {
  const CROSS_ACCOUNT_INDEX_OPERATION_TOTALS_BY_DAY_PATH = `/accounts/${apiKey}/cross_account_operation_schedules`
  return Client.post(CROSS_ACCOUNT_INDEX_OPERATION_TOTALS_BY_DAY_PATH, params)
}

function indexSingleCrossAccountOperationsByDay (apiKey, crossAccountOperationId, params) {
  const CROSS_ACCOUNT_INDEX_OPERATION_BY_DAY_PATH = `/accounts/${apiKey}/cross_account_operation_schedules/${crossAccountOperationId}/index_single_cross_account_operations_by_day`
  return Client.post(CROSS_ACCOUNT_INDEX_OPERATION_BY_DAY_PATH, params)
}

export {
  index,
  indexSingleCrossAccountOperationsByDay
}
