import { daysOfTheWeek } from '@/helpers/DateTime.js'

function createEmptyWeeklySchedule () {
  const emptyWeeklySchedule = { }

  daysOfTheWeek().forEach((dayOfWeek) => {
    emptyWeeklySchedule[dayOfWeek.name] = { start_time: null, end_time: null }
  })
  return emptyWeeklySchedule
}

function getWeeklyScheduleFromSource (weeklySchedule) {
  const result = createEmptyWeeklySchedule()
  weeklySchedule = weeklySchedule || {}
  daysOfTheWeek().forEach((day) => {
    const dayName = day.name
    if (!weeklySchedule[dayName]) return

    result[dayName].id = weeklySchedule[dayName].id
    result[dayName].shiftSpanTwoDays = weeklySchedule[dayName].shift_is_two_days
    result[dayName].start_time = weeklySchedule[dayName].start_time
    result[dayName].start_time_user_formatted = weeklySchedule[dayName].start_time_user_formatted
    result[dayName].end_time = weeklySchedule[dayName].end_time
    result[dayName].end_time_user_formatted = weeklySchedule[dayName].end_time_user_formatted
  })
  return result
}

function getShiftDateTimes ({ startTime, endTime, shiftSpanTwoDays, date }) {
  const startTimeDate = getDateObjWithTimeFromShift(date, startTime)
  const endDate = new Date(date.getTime())
  if (shiftSpanTwoDays === true || endTime === '00:00') {
    endDate.setDate(endDate.getDate() + 1)
  }
  const endTimeDate = getDateObjWithTimeFromShift(endDate, endTime)
  return { startTimeDate, endTimeDate }
}

function getDateObjWithTimeFromShift (date, shiftTime) {
  const startTime = shiftTime.split(':')
  const result = new Date(date.getTime())
  const hours = startTime[0]
  const minutes = startTime[1]
  result.setHours(Number(hours), Number(minutes), 0, 0)
  return result
}

export {
  createEmptyWeeklySchedule,
  getWeeklyScheduleFromSource,
  getShiftDateTimes
}
