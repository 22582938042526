import { createToken } from '@/api_client/UserLiveConnection.js'
import { EventBus, USER_CONNECTED, USER_DISCONNECTED, APP_CHAT_USER_JOINED_GROUP, NEW_APP_CHET_MESSAGE_RECEIVED } from '@/EventBus.js'
import { isString } from '@/helpers/Utils.js'
import useAuth, { isAuthenticated } from '@/composables/useAuth.js'

import { handler as errHandler } from '@/classes/ErrorHandler.js'

const pingInterval = 3 * 60 * 1000 // Every 3 minutes
let socket = null
let keepAliveInterval = null
let reConnectAttampts = 0
let timer = 1
let tokenExpiration = -1
let lastToken = null
let isConnecting = false
const maxReconnectAttempts = 3

const isConnected = () => socket?.readyState === WebSocket.OPEN

const actionToEvent = {
  ping: null,
  user_joined_group: APP_CHAT_USER_JOINED_GROUP,
  new_message: NEW_APP_CHET_MESSAGE_RECEIVED
}

const startConnection = async () => {
  if (isConnected() || isConnecting === true) return
  if (import.meta.env.MODE === 'development') return
  isConnecting = true

  const token = await getConnectionToken()
  const wssUrl = `${import.meta.env.VITE_APP_WEBSOCKET_URL}?token=${token}`
  socket = new WebSocket(wssUrl)
  socket.onopen = onOpen
  socket.onmessage = onMessage
  socket.onclose = onClose
  socket.onerror = (err) => {
    isConnecting = false
    errHandler(err)
  }
}

const onOpen = () => {
  isConnecting = false
  reConnectAttampts = 0
  keepAlive()
}

const onMessage = (event) => {
  try {
    const message = JSON.parse(event.data)
    messageHandler(message)
  } catch (e) {
    errHandler(e)
  }
}

const onClose = () => {
  isConnecting = false
  clearInterval(keepAliveInterval)
  reConnect()
}

const reConnect = () => {
  const shouldReconnect = reConnectAttampts < maxReconnectAttempts && timer && isUserLoggedIn()
  if (shouldReconnect !== true) return
  reConnectAttampts += 1
  timer = setTimeout(startConnection, 1e3)
}

const ping = () => socket.send(JSON.stringify({ action: 'ping' }))

function keepAlive() {
  if (isConnected() !== true) return

  clearInterval(keepAliveInterval)
  keepAliveInterval = setInterval(ping, pingInterval)
}

const endConnection = () => {
  timer = clearTimeout(timer)
  keepAliveInterval = clearInterval(keepAliveInterval)
  if (isConnected() !== true) return
  socket.close()
}

const getConnectionToken = () => {
  if (tokenValid()) return lastToken
  const { apiKey } = useAuth()
  return createToken(apiKey.value)
    .then(({ data: { data: { token, expiration_time: expirationTime } } }) => {
      lastToken = token
      tokenExpiration = expirationTime
      return token
    })
    .catch(errHandler)
}

const isUserLoggedIn = () => isAuthenticated.value === true

const onMounted = () => {
  EventBus.off(USER_CONNECTED)
  EventBus.off(USER_DISCONNECTED)

  EventBus.on(USER_CONNECTED, startConnection)
  EventBus.on(USER_DISCONNECTED, endConnection)
}

const messageHandler = ({ action = null, data = null } = {}) => {
  if ((action in actionToEvent) === false) return
  const event = actionToEvent[action]
  if (event === null) return

  EventBus.emit(event, data)
}

const tokenValid = () => {
  if (!Number.isInteger(tokenExpiration) || !isString(lastToken)) return false
  const nowUnixTime = Math.floor(Date.now() / 1000)
  return tokenExpiration > nowUnixTime
}

export { startConnection, endConnection, onMounted }
