<template>
  <v-row align="start" justify="center" no-gutters class="cross-account-tasks-small-devices">
    <v-col cols="12" v-if="isLoading">
      <v-skeleton-loader v-for="i in 3" :key="i" class="mt-3"
        type="card">
      </v-skeleton-loader>
    </v-col>
    <empty-task-page v-else-if="isNoTasks"></empty-task-page>
    <v-col cols="12" v-else>
      <div>
        <div class="row justify-space-between px-1 pb-4 t-500-16">
          <span>{{ $t('tasks_page.progress') }}</span>
          <span class="ltr">{{ stats.finishedTasksCount }} / {{ stats.tasksCount }}</span>
        </div>
        <tasks-progress hide-numbers with-border :is-loading="isLoading" :tasks-count="stats.tasksCount"
          :finished-tasks-count="stats.finishedTasksCount">
        </tasks-progress>
      </div>
      <v-expansion-panels v-model="panel">
        <v-expansion-panel class="mt-3"
          v-for="(crossAccountTask, index) in crossAccountOperationsTotals"
          @click="onCrossAccountClick(crossAccountTask)"
          :key="crossAccountTask.cross_account_operation_id">
          <v-expansion-panel-header>
            <single-cross-account-task
              :task="crossAccountTask"
              :client-date="clientDateIso"
              :selected="crossAccountTask.cross_account_operation_id === activeCrossAccountTaskId"
              @edit-click="onEditCrossAccountTaskClick"
              @delete-click="onDeleteCrossAccountTaskClick"
              @copy-click="onCopyCrossAccountTaskClick"
              @task-click="onTaskClick">
            </single-cross-account-task>
            <template v-slot:actions>
              &nbsp;
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content class="tasks-expansion">
            <tasks-list v-if="panel === index" :api-key="apiKey">
            </tasks-list>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-col>
  </v-row>
</template>
<style lang="scss">
.cross-account-tasks-small-devices {
  margin: 75px 16px 25px 16px !important;
}
</style>
<script>
import SingleCrossAccountTask from '@/components/corss_account_tasks/cross_account_task_list/SingleCrossAccountTask.vue'
import EmptyTaskPage from '@/components/shared/EmptyTaskPage.vue'
import TasksProgress from '@/components/tasks/TasksProgress.vue'

import useCrossAccountDailyTask from '@/composables/useCrossAccountDailyTask.js'

import { ref, computed } from 'vue'
export default {
  components: {
    'single-cross-account-task': SingleCrossAccountTask,
    'empty-task-page': EmptyTaskPage,
    'tasks-progress': TasksProgress
  },
  props: {
    apiKey: {
      type: String,
      required: true
    }
  },
  setup(props, { emit }) {
    const panel = ref(null)
    const { isLoading, clientDateIso, crossAccountOperationsTotals, activeCrossAccountTaskId, stats,
      onCrossAccountClick, applyFiltersForTotalOperations } = useCrossAccountDailyTask(props.apiKey)

    // Methods
    const onEditCrossAccountTaskClick = (task) => emit('edit-cross-account-task-click', task)
    const onDeleteCrossAccountTaskClick = (task) => emit('delete-cross-account-task-click', task)
    const onCopyCrossAccountTaskClick = (task) => emit('copy-cross-account-task-click', task)
    const onTaskClick = (task) => emit('task-click', task)

    // Computed
    const isNoTasks = computed(() => crossAccountOperationsTotals.value.length === 0)

    applyFiltersForTotalOperations()
    return {
      isLoading,
      panel,
      clientDateIso,
      crossAccountOperationsTotals,
      activeCrossAccountTaskId,
      isNoTasks,
      stats,
      onCrossAccountClick,
      onEditCrossAccountTaskClick,
      onDeleteCrossAccountTaskClick,
      onCopyCrossAccountTaskClick,
      onTaskClick

    }
  }
}
</script>