<template>
  <v-row align="center" justify="start" no-gutters class="message-input">
    <v-expand-transition>
      <v-col class="flex-grow-1 flex-shrink-0 px-0" v-if="showAudioControls">
        <chat-audio-recorder :api-key="apiKey"></chat-audio-recorder>
      </v-col>
    </v-expand-transition>
    <v-col class="flex-grow-1 flex-shrink-0 align-content-end ppe-3" v-if="!showAudioControls">
      <v-textarea
        counter="5000"
        class="rounded-xl message-text"
        v-model="currentMessage"
        solo
        hide-details
        flat
        height="40px"
        :disabled="isSendingMessage"
        :label="$t('chat_dialog.message_placeholder')"
        no-resize
        rows="1">
      </v-textarea>
    </v-col>
    <v-col class="flex-grow-0 flex-shrink-1 pps-2 d-flex justify-end align-center">
      <v-btn fab small elevation="0" class="white--text chat-send-btn" color="#0040FF"
        :loading="isSendingMessage"
        :disabled="sendMessageDisabled"
        @click="onSendMessage">
        <v-icon>send</v-icon>
      </v-btn>
    </v-col>
    <v-col class="flex-grow-0 flex-shrink-1 pps-1 d-flex justify-space-between align-center" v-if="!showAudioControls">
      <multiple-image-uploader ref="multipleImageUploader"
        class="pps-4"
        :max-images="6"
        :disabled="imageUploadDisabled"
        icon="image"
        icon-color="#3E8BFE"
        @image-uploaded="onImageUploaded"
        @image-removed="imageRemoved">
      </multiple-image-uploader>
      <video-input class="pps-4" v-model="attahcedVideo"
        :disabled="videoInputDisabled"
        icon-color="#FFB300">
      </video-input>
      <v-badge
        :value="isFileAttached"
        offset-x="26px"
        offset-y="12px">
        <template v-slot:badge>
          <v-icon color="error" @click="fileCloseClick">close</v-icon>
        </template>
        <v-file-input class="mt-0 pt-1 pps-1 message-file" hide-input :rules="sizeValidationRules" @click="fileClick"
          :disabled="fileInputDisabled" v-model="attachedFile">
        </v-file-input>
      </v-badge>
      <v-btn icon v-if="audioRecordingSupported" class="pt-1" color="#DC1072" @click="onShowAudio"
        :disabled="showAudioDisabled">
        <v-icon>mic</v-icon>
      </v-btn>
    </v-col>
  </v-row>
</template>
<style lang="scss">
.rtl {
  .message-input {
    .chat-send-btn {
      transform: rotate(180deg) !important;
    }
  }
}

.message-input {
  .message-text {
    .v-input__control {
      min-height: unset !important;
    }

    textarea {
      margin: 0 !important;
    }

    label {
      padding-right: 8px;
      top: 11px !important;
    }
  }

  .message-file {
    button {
      color: #45D141;
    }
  }
}
</style>
<script>
import MultipleImageUploader from '@/components/shared/MultipleImageUploader.vue'
import ChatAudioRecorder from '@/components/app_chat/group_conversation/ChatAudioRecorder.vue'
import MessageInputState from '@/components/app_chat/message_imput/MessageInputState.js'

import { audioRecorderSupport } from '@/helpers/FeatureDetection.js'

import { ref } from 'vue'

import { i18n } from '@/i18n.js'

const rowTypes = { date: 1, message: 2 }
const MAX_FILE_SIZE = 1024 * 1024 * 10 // 10MB

export default {
  props: {
    apiKey: {
      type: String,
      required: true
    }
  },
  components: {
    'multiple-image-uploader': MultipleImageUploader,
    'chat-audio-recorder': ChatAudioRecorder,
    'video-input': () => import('@/components/shared/VideoInput.vue')
  },
  setup(props) {
    const {
      isLoading, isSendingMessage, currentMessage, sendMessageDisabled, attachedFile, attahcedVideo,
      fileInputDisabled, videoInputDisabled, imageUploadDisabled, isFileAttached, sendMessage, onImageUploaded,
      imageRemoved, fileClick, showAudioControls, onShowAudio, showAudioDisabled
    } = new MessageInputState(props.apiKey)
    const multipleImageUploader = ref(null)

    // Methods
    const fileCloseClick = () => attachedFile.value = null
    const onSendMessage = () => {
      sendMessage(multipleImageUploader.value?.getImageFiles())
        .then(() => multipleImageUploader.value?.resetImages())
    }
    const sizeValidationRules = [
      (value) => {
        if (value === null || (value.size > 0 && value.size < MAX_FILE_SIZE)) return true
        return i18n.t('schedule_new_op_dialog.max_file_size')
      }
    ]

    return {
      isLoading,
      isSendingMessage,
      currentMessage,
      sendMessageDisabled,
      rowTypes,
      attachedFile,
      attahcedVideo,
      fileInputDisabled,
      videoInputDisabled,
      imageUploadDisabled,
      isFileAttached,
      sizeValidationRules,
      multipleImageUploader,
      showAudioControls,
      showAudioDisabled,
      onShowAudio,
      onImageUploaded,
      imageRemoved,
      fileClick,
      audioRecordingSupported: audioRecorderSupport(),
      onSendMessage,
      fileCloseClick,
    }
  }
}
</script>
