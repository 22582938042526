<template>
  <v-dialog v-model="dialog"
    persistent
    width="350">
    <v-card :class="{ 'rtl': isRtl, 'ltr': !isRtl }">
      <v-card-title class="text-center pb-5 blue-grey lighten-5">
        <span class="headline full-width">
          {{ $t('single_task_notification.title') }}
        </span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row align="center" justify="space-between" no-gutters>
            <v-col cols="12">
              <v-checkbox v-model="onCompleteTaskNotification"
                :label="$t('single_task_notification.on_complete')">
              </v-checkbox>
            </v-col>
            <v-col cols="12">
              <v-checkbox v-model="onUnCompleteTaskNotification"
                :disabled="taskUnCompleteDisabled"
                :label="$t('single_task_notification.on_un_complete')">
              </v-checkbox>
            </v-col>
          </v-row>
        </v-container>
        <v-card-actions class="pt-2 pr-2">
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="close(false)">
            {{ $t('cancel') }}
          </v-btn>
          <v-btn color="blue darken-1" depressed tile @click="save">
            {{ $t('save') }}
          </v-btn>
        </v-card-actions>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import { isRtl } from '@/i18n'
import { ref } from 'vue'

export default {
  props: {
    taskUnCompleteDisabled: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    const dialog = ref(false)
    const onCompleteTaskNotification = ref(false)
    const onUnCompleteTaskNotification = ref(false)
    let resolveFunc = null

    // Methods
    const save = () => close(true)
    const openDialog = ({ taskComplete, taskUncomplete }) => {
      dialog.value = true
      onCompleteTaskNotification.value = taskComplete
      onUnCompleteTaskNotification.value = taskUncomplete
      return new Promise((resolve, reject) => {
        resolveFunc = resolve
      })
    }

    const close = (isSave) => {
      dialog.value = false
      const result = {
        saved: isSave,
        taskComplete: onCompleteTaskNotification.value,
        taskUncomplete: onUnCompleteTaskNotification.value
      }
      resolveFunc(result)
    }

    return {
      dialog,
      onCompleteTaskNotification,
      onUnCompleteTaskNotification,
      isRtl,
      openDialog,
      close,
      save
    }
  }
}
</script>