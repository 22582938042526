<template>
  <v-row align="start" justify="start">
    <v-col cols="12" class="blog-content color5 t-400-18">
      <div v-if="!isReadMore" class="show-line-break" ref="contentDiv" v-html="linkifiedContent"
        :class="{ 'truncate': hasMoreThan6Lines }">
      </div>
      <v-expand-transition>
        <div v-if="isReadMore" class="show-line-break" v-html="linkifiedContent"></div>
      </v-expand-transition>
    </v-col>
    <v-col cols="12" v-if="hasMoreThan6Lines"
      @click="toggleReadMore" class="pointer pt-0 blue--text">
      {{ isReadMore ? $t('blog_post.read_less') : $t('blog_post.read_more') }}
    </v-col>
  </v-row>
</template>
<style lang="scss">
.blog-content {
  max-width: 100%;
  line-height: 22px;

  .truncate {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 6;
    -webkit-box-orient: vertical;
  }
}
</style>
<script>
import { linkyfyString } from '@/helpers/LinkifyText.vue'
import { ref, onMounted, computed } from 'vue'

const maxLines = 6
const lineHeight = 22
const MaxDivHeight = maxLines * lineHeight
export default {
  props: {
    content: {
      type: String,
      required: true,
      default: ''
    }
  },
  setup(props) {
    const isReadMore = ref(false)
    const contentDiv = ref(null)
    const hasMoreThan6Lines = ref(false)

    // Methods

    const toggleReadMore = () => (isReadMore.value = !isReadMore.value)
    onMounted(() => {
      const divHeight = contentDiv.value.offsetHeight
      hasMoreThan6Lines.value = divHeight > MaxDivHeight
    })
    const linkifiedContent = computed(() => linkyfyString(props.content))
    return {
      isReadMore,
      contentDiv,
      linkifiedContent,
      hasMoreThan6Lines,
      toggleReadMore
    }
  }
}
</script>
