<template>
  <v-select
    class="range-select"
    :value="value"
    :items="dateRangeScopeOptions"
    :menu-props="{ 'contentClass': isRtl ? 'rtl' : 'ltr' }"
    @change="onChange"
    @input="onInput"
    outlined
    dense
    single-line>
  </v-select>
</template>
<style lang="scss">
.range-select {
  border-radius: 10px;

  .v-text-field__details {
    display: none;
  }

  fieldset {
    border-color: black;
  }

  @media only screen and (max-width: 600px) {
    font-weight: 700;
    font-size: 13px;

    .v-input__control {
      .v-input__slot {
        padding-right: 4px !important;
        padding-left: 4px !important;
      }
    }
  }
}
</style>
<script>

import { i18n, isRtl } from '@/i18n.js'
export default {
  props: {
    value: {
      type: String,
      required: true,
      validator: (value) => {
        return ['day', 'week', 'month'].includes(value)
      }
    }
  },
  setup(_, { emit }) {
    const dateRangeScopeOptions = [{ text: i18n.t('account_operations.date_range_day'), value: 'day' },
    { text: i18n.t('account_operations.date_range_week'), value: 'week' },
    { text: i18n.t('account_operations.date_range_month'), value: 'month' }]

    // Methods
    const onChange = (value) => emit('change', value)
    const onInput = (value) => emit('input', value)

    return {
      dateRangeScopeOptions,
      isRtl,
      onChange,
      onInput
    }
  }
}
</script>
