<template>
  <div>
    <v-snackbar
      v-model="errorSnackBar"
      :timeout="3000">
      {{ $t('reported_item_op_list.save_error') }}
      <v-btn
        color="indigo" text @click="closeSnackBar">
        {{ $t('close') }}
      </v-btn>
    </v-snackbar>
    <v-dialog v-model="dialog" max-width="550" content-class="report-item-list" persistent>
      <image-carousel-dialog ref="imageCarouselDialog"
        @delete-image="deleteImage">
      </image-carousel-dialog>
      <div class="white scrollable" v-bind:class="{ rtl: isRtl }">
        <v-card-text class="px-1">
          <v-container class="pb-0 wrapper">
            <single-reported-item
              v-for="(itemOperationListItem, index) in itemOperationList"
              :key="itemOperationListItem.id"
              :list-item="itemOperationListItem"
              :index="index"
              :is-disabled="isDisabled"
              :read-only="readOnly"
              @upload-image="uploadImage"
              @image-click="showImages"
              @change-field="changeItemField">
            </single-reported-item>
          </v-container>
        </v-card-text>
      </div>
      <v-card-actions class="pr-0 white" v-bind:class="{ rtl: isRtl }">
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" depressed tile class="mx-5" @click="close" v-if="readOnly">
          {{ $t('close') }}
        </v-btn>
        <v-btn color="blue darken-1" depressed tile class="mx-5" @click="save" v-else
          :loading="isLoading" :disabled="saveDisabled">
          {{ $t('save') }}
        </v-btn>
      </v-card-actions>
    </v-dialog>
  </div>
</template>
<style lang="scss">
.report-item-list {
  background-color: #FFFFFF !important;
  border-color: #FFFFFF !important;

  .wrapper.rtl {
    .item-desc {
      padding-right: 12px;
    }
  }

  .wrapper:not(.rtl) {
    .item-desc {
      padding-left: 12px;
    }
  }

  .scrollable {
    overflow: auto;
    height: 100%;
    max-height: 400px;
  }
}
</style>
<script>
import SingleReportedItem from '@/components/shared/item_operation_list_dialog/SingleReportedItem.vue'
import ImageCarouselDialog from '@/components/shared/ImageCarouselDialog.vue'

import { getClientDate, ISO8601StringToDate } from '@/helpers/DateTime.js'

import { itemTypes, stringValueMaxLength } from '@/models/ItemOperationListItemTypes.js'

import { isString, isObject } from '@/helpers/Utils.js'

import { onDialogClose, onDialogOpen } from '@/classes/DialogScrollBug.js'

import { handler as errHandler } from '@/classes/ErrorHandler.js'
import { isRtl } from '@/i18n.js'

import Vue, { ref, computed } from 'vue'

export default {
  components: {
    'single-reported-item': SingleReportedItem,
    'image-carousel-dialog': ImageCarouselDialog
  },
  props: {
    readOnly: {
      type: Boolean,
      required: false,
      default: false
    },
    clientDate: {
      type: String,
      required: false,
      default: null
    }
  },
  setup(props) {
    let resolveFunc = null
    let operationId = null
    let listItemShowingImage = null
    let reactiveOperation = null
    const dialog = ref(false)
    const itemOperationList = ref([])
    const errorSnackBar = ref(false)
    const imageCarouselDialog = ref(null)
    const isLoading = ref(false)


    const isReportedItem = (itemOperationList) => Number.isInteger(itemOperationList.report_id)
    const updateParamsForItem = (item) => ({ id: item.report_id, bool_value: item.bool_value, string_value: item.string_value, int_value: item.int_value })
    const haveStringValue = (itemOperationList) => isString(itemOperationList.string_value) && itemOperationList.string_value.length > 0
    const haveIntValue = (itemOperationList) => Number.isInteger(itemOperationList.int_value)
    const notTitle = (itemOperationList) => itemOperationList.item_type !== itemTypes.title
    const isUnReportedItem = (itemOperationList) => !Number.isInteger(itemOperationList.report_id)
    const isItemChanged = (itemOperationList) => itemOperationList.bool_value !== itemOperationList.oldBoolValue ||
      itemOperationList.string_value !== itemOperationList.oldStringValue || itemOperationList.int_value !== itemOperationList.oldIntValue

    const hasAnyValue = (itemOperationList) => itemOperationList.bool_value === true ||
      itemOperationList.bool_value === false ||
      haveStringValue(itemOperationList) || haveIntValue(itemOperationList)

    const dateForOperation = () => {
      if (props.clientDate === null) return getClientDate()

      const dateObj = ISO8601StringToDate(props.clientDate)
      return getClientDate(dateObj)
    }

    const createParamsForItem = (item) => {
      let boolValue = false
      if (item.bool_value === true || item.bool_value === false) {
        boolValue = item.bool_value
      }
      return {
        bool_value: boolValue,
        string_value: item.string_value,
        int_value: item.int_value,
        item_operation_list_item_id: item.id
      }
    }

    const updateListItemAttrs = (newListItem) => {
      const foundItem = itemOperationList.value.find((item) => item.id === newListItem.item_operation_list_item_id)
      if (!isObject(foundItem)) return
      foundItem.string_value = newListItem.string_value
      foundItem.oldStringValue = newListItem.string_value
      foundItem.oldIntValue = newListItem.int_value
      foundItem.report_id = newListItem.id
      foundItem.images = (newListItem.images || []).map((image) => Object.assign({}, image))
    }

    const uploadImageToItem = (item, imageFile, requestParams) => {
      const imagesCount = item.images.length
      const imageId = item.images.length > 0 ? item.images[item.images.length - 1].id : null
      return reactiveOperation.uploadReportListItemImage({ itemListId: item.id, requestParams, imageFile, clientDate: dateForOperation(), imageId, imagesCount })
        .catch((err) => {
          errHandler(err)
          errorSnackBar.value = true
        })
    }



    const updateSingleReportItem = async ({ item, addImage = false, imageIdToRemove = null }) => {
      const date = dateForOperation()
      const requestParams = { reported_item_operation_list_item: updateParamsForItem(item) }
      if (addImage === true) {
        requestParams.add_image = true
      } else if (imageIdToRemove !== null) {
        requestParams.remove_image_by_id = imageIdToRemove
      }
      return reactiveOperation.updateReportListItem({ itemListId: item.report_id, requestParams, clientDate: date })
    }

    const createSingleReportItem = async (item) => {
      const date = dateForOperation()
      const requestParams = { reported_item_operation_list_item: createParamsForItem(item), report_date: date }
      return reactiveOperation.createReportListItem({ requestParams, clientDate: date })
        .catch((err) => {
          errHandler(err)
          errorSnackBar.value = true
        })
    }

    const closeDialog = (isUpdated = false) => {
      onDialogClose()
      dialog.value = false
      isLoading.value = false
      reactiveOperation = null
      resolveFunc(isUpdated)
    }

    const getCreateParams = () => itemOperationList.value.filter(isUnReportedItem).filter(notTitle).filter(hasAnyValue)
      .map(createParamsForItem)

    const getUpdateParams = () => itemOperationList.value.filter(notTitle).filter(isReportedItem).filter(isItemChanged)
      .map(updateParamsForItem)

    const afterOpenDialog = ({ itemList, opId }) => {
      onDialogOpen()
      dialog.value = true

      itemOperationList.value = itemList.map((item) => {
        const resultItem = Object.assign({}, item)
        resultItem.oldStringValue = item.string_value
        resultItem.oldBoolValue = item.bool_value
        resultItem.oldIntValue = item.int_value
        resultItem.imageIsUploading = false
        resultItem.images = (item.images || []).map((image) => Object.assign({}, image))
        return resultItem
      })
      operationId = opId
      return new Promise((resolve, reject) => {
        resolveFunc = resolve
      })
    }
    // Methods
    const closeSnackBar = () => (errorSnackBar.value = false)

    const openDialog = (operationSchedule) => {
      const itemList = operationSchedule.item_operation_list || []
      return afterOpenDialog({ itemList, opId: operationSchedule.operation_id })
    }

    const openDialogWithReactiveOperation = (operation) => {
      reactiveOperation = operation
      const itemList = operation.operationRef.item_operation_list || []
      return afterOpenDialog({ itemList, opId: operation.operationRef.operation_id })
    }

    const uploadImage = ({ event, item }) => {
      const file = event?.target?.files[0]
      if (!file || item.imageIsUploading === true) return
      item.bool_value = true
      changeItemField({ item, field: 'imageIsUploading', value: true })
      const promise = isReportedItem(item) ? updateSingleReportItem({ item, addImage: true }) : createSingleReportItem(item)
      promise
        .then((response) => {
          if (!isObject(response)) return
          const { imageSignature, listItem } = response
          return uploadImageToItem(item, file, imageSignature)
            .then(() => updateListItemAttrs(listItem))
        })
        .finally(() => changeItemField({ item, field: 'imageIsUploading', value: false }))
    }

    const changeItemField = ({ item, field, value }) => {
      if (field in item) {
        item[field] = value
      } else {
        Vue.set(item, field, value)
      }
    }

    const showImages = (listItem) => {
      listItemShowingImage = listItem
      const imageUrls = listItem.images.map((image) => image.url)
      imageCarouselDialog.value.openDialog({ imageUrls, index: 0, showDelete: props.readOnly !== true })
    }

    const deleteImage = (imageIndex) => {
      const item = listItemShowingImage
      if (item.images.length === 1) {
        item.bool_value = false
      }
      item.imageIsUploading = true
      updateSingleReportItem({ item, imageIdToRemove: item.images[imageIndex].id })
        .then((response) => {
          if (!isObject(response)) return
          const { listItem } = response
          updateListItemAttrs(listItem)
        })
        .catch(errHandler)
        .finally(() => {
          item.imageIsUploading = false
        })
    }
    const close = () => closeDialog(false)
    const save = () => {
      const createParams = getCreateParams()
      const updateParams = getUpdateParams()
      if (createParams.length === 0 && updateParams.length === 0) {
        closeDialog()
        return
      }
      isLoading.value = true
      const date = dateForOperation()
      const requestParams = { update_params: updateParams, create_params: createParams, report_date: date }

      reactiveOperation.upsertReportListItem({ requestParams, clientDate: date }).then(() => closeDialog(true))
        .catch((err) => {
          errHandler(err)
          closeDialog()
          errorSnackBar.value = true
        })
    }

    // Computed
    const isDisabled = computed(() => props.readOnly || isLoading.value)
    const saveDisabled = computed(() => isDisabled.value || isLoading.value ||
      itemOperationList.value.some((item) => {
        if (item.imageIsUploading === true) return true
        if (isString(item.string_value) && item.string_value.length > stringValueMaxLength) return true
        return false
      }))

    return {
      dialog,
      isLoading,
      itemOperationList,
      errorSnackBar,
      saveDisabled,
      isDisabled,
      itemTypes,
      isRtl,
      imageCarouselDialog,
      changeItemField,
      openDialog,
      openDialogWithReactiveOperation,
      closeSnackBar,
      uploadImage,
      showImages,
      deleteImage,
      close,
      save
    }
  }
}
</script>
