import { index } from '@/api_client/AccountJobsOperationForDateRange.js'

import { getShiftSortOrderMap, translateShift } from '@/store/modules/OrganizationJob.js'

import { isString } from '@/helpers/Utils.js'
import { dateToISO8601String } from '@/helpers/DateTime.js'

import { handler as errHandler } from '@/classes/ErrorHandler.js'

import { ref, computed } from 'vue'

const isLoading = ref(false)
const calanderEvents = ref([])
const clientDate = ref(null)
const clientCurrentDate = ref(dateToISO8601String(new Date()))
const dateRange = ref({ start: null, end: null })

let nextColorIndex = 0
let jobIdToColorMap = {}
let currentApiKey = null

const jobColors = ['#34a6b626', '#24956526', '#242d9726', '#8f2f2f26', '#6d952c26', '#242d9726',
  '#61636526', '#b3b63426', '#b6723426', '#8534b626']

const getJobColor = (jobId) => {
  if (jobIdToColorMap[jobId]) return jobIdToColorMap[jobId]
  const color = jobColors[nextColorIndex]
  jobIdToColorMap[jobId] = color
  nextColorIndex = (nextColorIndex + 1) % jobColors.length
  return color
}

export default function useJobCalander(apiKey) {
  const shiftOrderMap = getShiftSortOrderMap()
  const resetValues = () => {
    isLoading.value = false
    calanderEvents.value.splice(0)
    clientDate.value = null
    dateRange.value = { start: null, end: null }
    jobIdToColorMap = {}
    nextColorIndex = 0
  }

  if (isString(apiKey) && currentApiKey !== apiKey) {
    resetValues()
    currentApiKey = apiKey
  }

  const sortCalanderEvents = () => {
    calanderEvents.value.sort((a, b) => {
      return (a.departmentName || '').localeCompare(b.departmentName || '') ||
        shiftOrderMap[a.shift] - shiftOrderMap[b.shift] ||
        a.jobName.localeCompare(b.jobName)
    })
  }
  const indexHandler = ({ data: { data: { jobs: jobsData, operations, operations_descriptions: operationsDescriptions, date_range: dateRangeResponse } } }) => {
    calanderEvents.value.splice(0)
    dateRange.value.start = dateRangeResponse.start
    dateRange.value.end = dateRangeResponse.end
    const calanderEventsVal = calanderEvents.value
    for (const date in operations) {
      const singleDateOperations = operations[date]
      for (const jobId in singleDateOperations) {
        const jobDateOperationsData = singleDateOperations[jobId]
        const jobData = jobsData[jobId]
        const operationsPreview = jobDateOperationsData.operation_ids.map(operationId => {
          const operation = operationsDescriptions[operationId]
          const hasName = isString(operation.name) && operation.name.length > 0
          const hasDescription = isString(operation.description) && operation.description.length > 0
          return { nameAndDescription: `${hasName ? operation.name : ''} ${hasName && hasDescription ? ' - ' : ''} ${hasDescription ? operation.description : ''}` }
        })
        const event = {
          jobId,
          jobName: jobData.name,
          operationIdsSize: jobDateOperationsData.operation_size,
          operationsPreview,
          shift: jobData.shift,
          shiftTranslated: translateShift(jobData.shift),
          start: date,
          end: date
        }
        if (isString(jobData.department_name)) {
          event.departmentName = jobData.department_name
        }
        calanderEventsVal.push(event)
      }
    }
    sortCalanderEvents()
  }

  // Methods
  const loadJobCount = ({ filters: { account_job_ids: accountJobIds } = {}, dateRangeScope } = {}) => {
    isLoading.value = true
    clientCurrentDate.value = dateToISO8601String(new Date())
    const requestParams = {
      clientCurrentDate: clientCurrentDate.value,
      clientDate: dateToISO8601String(clientDate.value),
      rangeScope: dateRangeScope,
      apiKey: currentApiKey
    }
    if (Array.isArray(accountJobIds) && accountJobIds.length > 0) {
      requestParams.filters = { account_job_ids: accountJobIds }
    }
    index(requestParams)
      .then(indexHandler)
      .catch(errHandler)
      .finally(() => (isLoading.value = false))
  }

  const changeApiKey = (newApiKey) => (currentApiKey = newApiKey)

  // Computed

  const eventsByDate = computed(() => {
    const events = {}
    calanderEvents.value.forEach((event) => {
      const date = event.start
      event.color = getJobColor(event.jobId)
      if (!events[date]) events[date] = []
      events[date].push(event)
    })
    return events
  })

  return {
    isLoading,
    clientDate,
    clientCurrentDate,
    dateRange,
    eventsByDate,
    changeApiKey,
    loadJobCount
  }
}
