<template>
  <v-row align="start" justify="start" no-gutters class="push-history-list">
    <v-col cols="12" class="row align-start justify-start" v-for="segment in segmentedPushList"
      :key="segment.header">
      <v-col cols="12" class="t-800-20 pb-0 mt-0" :class="{ 'mb-2': dense }">
        <span v-if="segment.isNewSegment" class="black white--text new-segment">
          {{ segment.header }}
        </span>
        <span v-else class="black--text">
          {{ segment.header }}
        </span>
      </v-col>
      <v-col cols="12" v-for="push in segment.items"
        :key="push.id" class="px-0" :class="{ 'py-0': dense }">
        <single-notifications-history
          class="my-0"
          :push-notification="push"
          :dense="dense"
          @toggle-viewed="onToggleViewed"
          @delete-click="onDeleteClick"
          @push-click="onPushClick">
        </single-notifications-history>
      </v-col>
    </v-col>
  </v-row>
</template>
<style lang="scss">
.push-history-list {
  .new-segment {
    border-radius: 3px;
    padding: 4px;
    position: relative;
    display: inline-block;
    padding-right: 20px;

    &::after {
      content: "";
      position: absolute;
      right: 0;
      top: 0;
      width: 0;
      height: 0;
      border-width: 18px 10px 18px 0;
      border-color: transparent rgb(244, 246, 248) transparent transparent;
      border-style: solid;
    }
  }
}

.rtl {
  .new-segment {
    padding-right: 4px;
    padding-left: 20px;

    &::after {
      left: 0;
      right: unset;
      border-width: 18px 0 18px 10px;
      border-color: transparent transparent transparent rgb(244, 246, 248);
    }
  }
}
</style>
<script>
import SingleNotificationsHistory from '@/components/push_notifications_history_page/SingleNotificationsHistory.vue'

import usePushNotificationHistoryTopBar from '@/composables/usePushNotificationHistoryTopBar.js'
import { isObject } from '@/helpers/Utils.js'

import { isDateIsToday } from '@/helpers/DateTime.js'

import useAuth from '@/composables/useAuth.js'

import { useRouter } from 'vue-router/composables'

import { i18n } from '@/i18n'
import { computed } from 'vue'
export default {
  props: {
    apiKey: {
      type: String,
      required: true
    },
    pushHistoryList: {
      type: Array,
      required: true
    },
    dense: {
      type: Boolean,
      default: false
    },
  },
  components: {
    'single-notifications-history': SingleNotificationsHistory
  },
  setup(props, { emit }) {
    const router = useRouter()
    const { lastSeenPushId } = usePushNotificationHistoryTopBar(props.apiKey)
    const { isCompanyAdminRegionOrAbove } = useAuth()

    const isNewPush = (push) => lastSeenPushId.value === null || push.id > lastSeenPushId.value

    // Methods
    const onToggleViewed = (push) => emit('toggle-viewed', push)
    const onDeleteClick = (push) => emit('delete-click', push)

    const onPushClick = (push) => {
      const pushData = push.push_data
      const isCompanyAdminRegionOrAboveValue = isCompanyAdminRegionOrAbove.value
      switch (pushData.model) {
        case 'broken_item':
          router.push({ name: isCompanyAdminRegionOrAboveValue === true ? 'staff/company_faulty_devices' : 'account_faulty_devices' })
          break
        case 'operation_schedule':
        case 'account_job_completed_shift':
          const params = {}
          if (isCompanyAdminRegionOrAboveValue && pushData.is_staff_task === 'true') {
            params.tab = 'staffTask'
          } else if (isCompanyAdminRegionOrAboveValue && pushData.is_cross_account === 'true') {
            params.tab = 'hqTasks'
          } else {
            params.tab = 'accountsTasks'
          }
          router.push({ name: 'tasks', params })
          break
        case 'app_chat':
          router.push({ name: isCompanyAdminRegionOrAboveValue === true ? 'staff/chat' : 'chat' })
          break
        case 'blog_post':
          router.push({ name: isCompanyAdminRegionOrAboveValue === true ? 'staff/blog' : 'blog' })
          break
      }
    }

    // Computed
    const segmentedPushList = computed(() => {
      const newPush = { header: i18n.t('new'), items: [], isNewSegment: true }
      const today = { header: i18n.t('today'), items: [] }
      const thisWeek = { header: i18n.t('this_week'), items: [] }
      const older = { header: i18n.t('older'), items: [] }
      const yesterday = new Date()
      yesterday.setDate(yesterday.getDate() - 1)
      yesterday.setHours(23, 59, 59, 999)
      const lastWeek = new Date()
      lastWeek.setDate(lastWeek.getDate() - 7)
      lastWeek.setHours(0, 0, 0, 0)

      props.pushHistoryList.forEach(push => {
        const parsedDate = new Date(push.created_at)
        if (isNewPush(push)) {
          newPush.items.push(push)
        } else if (isDateIsToday(parsedDate)) {
          today.items.push(push)
        } else if (parsedDate >= lastWeek && parsedDate <= yesterday) {
          thisWeek.items.push(push)
        } else {
          older.items.push(push)
        }
      })
      return [newPush, today, thisWeek, older].filter(section => section.items.length > 0)
    })
    return {
      segmentedPushList,
      onToggleViewed,
      onDeleteClick,
      onPushClick
    }
  }
}
</script>