import Client from '@/api_client/client.js'

const index = ({ apiKey, params = {} }) => {
  const PUSH_NOTIFICATION_HISTORY_INDEX_PATH = `/accounts/${apiKey}/push_notification_history`
  return Client.put(PUSH_NOTIFICATION_HISTORY_INDEX_PATH, params)
}

const update = ({ apiKey, id, params = {} }) => {
  const PUSH_NOTIFICATION_HISTORY_UPDARE_PATH = `/accounts/${apiKey}/push_notification_history/${id}`
  return Client.put(PUSH_NOTIFICATION_HISTORY_UPDARE_PATH, params)
}

const destroy = ({ apiKey, id }) => {
  const PUSH_NOTIFICATION_HISTORY_UPDARE_PATH = `/accounts/${apiKey}/push_notification_history/${id}`
  return Client.delete(PUSH_NOTIFICATION_HISTORY_UPDARE_PATH)
}

const unseenCount = (apiKey) => {
  const PUSH_NOTIFICATION_HISTORY_UNSEEN_COUNT_PATH = `/accounts/${apiKey}/push_notification_history/unseen_count`
  return Client.get(PUSH_NOTIFICATION_HISTORY_UNSEEN_COUNT_PATH)
}

const updateUnseenCount = (apiKey) => {
  const PUSH_NOTIFICATION_HISTORY_UNSEEN_COUNT_PATH = `/accounts/${apiKey}/push_notification_history/update_unseen_count`
  return Client.put(PUSH_NOTIFICATION_HISTORY_UNSEEN_COUNT_PATH)
}



export {
  index,
  update,
  destroy,
  unseenCount,
  updateUnseenCount
}
