<template>
  <v-list>
    <v-list-item
      v-for="user in users" :key="user.user_id">
      <v-avatar color="yellow darken-2" size="40">
        <v-img v-if="user.avatar_url" :src="user.avatar_url">
        </v-img>
        <span v-else class="white--text text-h5">
          {{ user.first_name.charAt(0) }}{{ user.last_name.charAt(0) }}
        </span>
      </v-avatar>
      <v-list-item-content class="mms-4 font-weight-medium">
        {{ user.first_name }} {{ user.last_name }}
        <v-list-item-title></v-list-item-title>
      </v-list-item-content>
    </v-list-item>
  </v-list>
</template>
<script>
export default {
  props: {
    users: {
      type: Array,
      required: true
    }
  }
}
</script>
