export default {
  save: "Guardar",
  cancel: "Cancelar",
  ok: "De acuerdo",
  delete: "Borrar",
  no_result: "No hay resultados",
  loading: "Cargando",
  exect_date: "Tareas de fecha",
  daily_operations: "Tareas diarias",
  shift_start: "Iniciar",
  shift_middle: "Medio",
  shift_end: "Fin",
  shift_transfer: "Transferencia de turno",
  during_shift: "Durante el turno",
  currnent_day: "{dayString}",
  close: "Cerrar",
  select_all: "Seleccionar todo",
  add: "Agregar",
  yes: "Sí",
  no: "No",
  title: "Título",
  report: "Informe",
  create: "Crear",
  edit: "Editar",
  faults: "Fallos",
  area: "Área",
  name: "nombre",
  reset: "Reiniciar",
  all: "Todo",
  employee: "Empleados",
  departments: "Departamentos",
  account_name: "Nombre de la cuenta",
  time: 'Tiempo',
  today: "Hoy",
  yesterday: "Ayer",
  this_week: 'Esta semana',
  older: 'Más viejo',
  tasks: "Tareas",
  chat: "Chat",
  menu: "Menú",
  download: "Descargar",
  copy: "Copiar",
  permissions: "Permisos",
  email: "Correo electrónico",
  notifications: "Notificaciones",
  community: "Comunidad",
  file_attach: "Adjuntar archivo",
  comments: 'Comentarios',
  share: "Compartir",
  show_more_details: 'Mostrar más detalles',
  show_more: 'Mostrar más',
  view: "Ver",
  info: "Información",
  total: 'Total',
  done: 'Hecho',
  completed: 'Completado',
  on_going: 'En curso',
  from: 'De:',
  to: 'Hasta:',
  next: 'NEXT',
  back: 'atrás',
  optional: '* Opcional',
  reset_all: 'Restablecer todo',
  x_selected: '{count} seleccionados',
  hours: 'Horas',
  x_hours: '%{hours} hras',
  x_hour_and_minutes: '%{hours} hras %{minutes} min',
  x_minutes: '%{minutes} min',
  x_days: '%{days} days',
  x_months: '%{months} meses',
  x_years: '%{years} años',
  new: 'Nuevo',
  date: 'Fecha',
  login: {
    phone: "Teléfono",
    user_not_found: "Usuario no encontrado",
    invalid_code: "Código erróneo",
    enter_phone: 'Introduce tu número de móvil para iniciar sesión',
    send_to: 'Enviar código a:'
  },
  verify_code: {
    check_email: "Enlace de inicio de sesión enviado a su correo",
    enter_code: 'Ingresa el código que te acabamos de enviar por mensaje de texto.',
    your_phone: 'Tu número de teléfono: %{phone}',
    didnt_recieve: '¿No recibiste un mensaje de texto? ',
    didnt_recieve_1: 'Enviar un correo electrónico',
    wrong_code: 'Codigo erroneo'
  },
  org_structure_jobs: {
    total_jobs: "Total roles",
    sunday_col: "Dom",
    monday_col: "Lun",
    tuesday_col: "Mar",
    wednesday_col: "Mié",
    thursday_col: "Jue",
    friday_col: "Vie",
    saturday_col: "Sáb",
    reset_date: "Hoy",
    delete_date: "Eliminar fecha",
    delete_job_title: "Confirmar eliminación del trabajo",
    delete_job_content: "Se eliminarán todas las tareas relacionadas con este trabajo.",
    page_title: 'Roles',
    staff_page_title: 'Roles de personal',
    shift_duration: '%{hours}H %{minutes}M',
    shift_duration_hours: '%{hours}H'
  },
  org_structure_suppliers: {
    add_suppliers: "Agregar proveedor",
    total_suppliers: "Total proveedores",
    order_phone: "Teléfono para ordenar"
  },
  org_structure_providers: {
    total_providers: "Total proveedores",
    page_title: 'Proveedores de servicio'
  },
  devices: {
    general_name: "General"
  },
  work_panel: {
    tasks_tab: "Tareas",
    work_schedule: "Turnos"
  },
  devices_faulty_device_dialog: {
    total_calls: "Llamadas totales",
    off_time: "Fuera de tiempo",
    total_cost: "Coste total",
    open_date: "Fecha abierta",
    description: "Descripción",
    whats_done: "Descripción de la reparación",
    status: "Estado",
    cost: "Costo",
    close_date: "Fecha de cierre",
    close_ticket: "Cerrar ticket",
    days_open: "{days_open} días",
    close_ticket_approve: "El ticket se cerrará y pasará a la historia.",
    send: "Enviar"
  },
  broken_item_dialog_dialog: {
    create_title: "Abrir nueva llamada",
    update_title: "Actualizar una llamada abierta",
    price: "Costo",
    description: "Describe el problema",
    progress_description: "Descripción Reparación",
    comment: "Escribir un comentario",
    status: "Estado",
    status_open: "Abierto",
    status_close: "Cerrado",
    status_in_progress: "En curso",
    status_done: "Hecho",
    priority: "Prioridad",
    priority_low: "Baja urgencia",
    priority_high: "Alta urgencia",
    what_broke: "Qué se rompió",
    assigned_user: "Quién es responsable de esto",
    fix_images: "Imágenes de elementos fijos",
    add_invoice: "Adjuntar una factura",
    deadline_date: "Solucionar hasta fecha",
    deadline_time: 'Solucionar hasta tiempo',
    deadline_date_val: "Reparar hasta: {deadlineDate}",
    add_video: "Agregar película",
    open_days: 'Abierto {days} días',
    repair: "Reparar"
  },
  shifts: {
    morning: "mañana",
    noon: "mediodía",
    evening: "tarde",
    night: "noche",
    with_prefix: {
      morning: "turno de mañana",
      noon: "turno de mediodía",
      evening: "turno de tarde",
      night: "turno nocturno"
    }
  },
  org_job_new_dialog: {
    create_title: "Agregar rol",
    update_title: "Actualizar rol",
    job_name: "Nombre de rol",
    choose_shift: "turno",
    start: "Iniciar",
    end: "Fin",
    sun: "Dom",
    mon: "Lun",
    tue: "Mar",
    wed: "Mié",
    thu: "Jue",
    fri: "Vie",
    sat: "Sáb",
    shift_times: "horario de turnos",
    all_days: "Todos los días",
    add_department: "Agregar nuevo departamento"
  },
  org_supplier_new_dialog: {
    create_title: "Agregar proveedor",
    update_title: "Actualizar proveedor",
    supplier_name: "Nombre del proveedor",
    supplier_phone: "Teléfono",
    supplier_order_phone: "Teléfono para pedidos"
  },
  org_provider_new_dialog: {
    create_title: "Agregar proveedor",
    update_title: "Proveedor de actualizaciones",
    provider_name: "Nombre del proveedor",
    provider_phone: "Teléfono",
    contact_name: "Nombre de contacto",
    comments: 'Comentarios'
  },
  validator: {
    invlid_time: "insertar tiempo válido",
    invlid_number: "insertar un número válido",
    required: "Insertar valor"
  },
  device_device_new_dialog: {
    create_title: "Agregar objeto",
    update_title: "Actualizar objeto",
    device_name: "Qué se rompió",
    device_department: "Ubicación",
    name_taken_err: "Nombre ya en uso"
  },
  confirm_delete: {
    title: "Confirmar eliminación"
  },
  devices_new_operation: {
    op_type: "Tipo",
    op_desc: "Descripción",
    op_type_maintenance: "Mantenimiento",
    op_type_cleaning: "Limpiar",
    op_type_approvals_and_reviews: "Aprobaciones",
    op_type_bills_and_service_agreements: "Facturas",
    op_type_season_changing: "Cambio de estación",
    op_type_integrity_check: "Controlar",
    op_type_stocktaking: "Inventario",
    op_type_stock_order: "Ordenar",
    op_type_stock_receiving: "Recepción",
    op_type_stock_encoding_and_arrangement: "Acuerdo",
    op_type_stock_refund: "Reembolso",
    op_type_routine: "Sin tipo de operación",
    op_type_replenishment: "Reposición",
    op_type_expired_check: "Comprobación de caducidad",
    op_type_phenogram: "Planograma",
    op_type_turn_on: "Encender",
    op_type_turn_off: "Apagar",
    op_type_open: "Abierto",
    op_type_close: "Cerrar",
    op_type_reports: "Informes",
    op_type_training: "Capacitación",
    op_type_weekly_operation: "Tarea semanal",
    op_type_stock_receiving_encoding_and_arrangement: "Recepción y distribución",
    op_type_stock_order_receiving_and_arrangement: "Recepción y distribución de pedidos.",
    op_type_stock_order_and_receiving: "Pedidos y Recepción",
    op_type_stock_encoding_and_arrangement_and_order: "Distribución y Pedido",
    op_type_stock_order_and_refund: "Pedido y Reembolso",
    op_type_stock_encoding_and_arrangement_and_receiving: "Distribución y Recepción",
    op_type_stock_receiving_and_refund: "Recepción y Reembolso",
    op_type_stock_encoding_and_arrangement_and_refund: "Distribución y Reembolso",
    op_type_stock_encoding_and_arrangement_and_order_and_receiving: "Distribución, Pedido y Recepción",
    op_type_stock_order_and_receiving_and_refund: "Pedido, Recepción y Reembolso",
    op_type_stock_encoding_and_arrangement_and_receiving_and_refund: "Distribución, Recepción y Reembolso",
    op_type_stock_encoding_and_arrangement_and_order_and_refund: "Distribución, Pedido y Reembolso",
    op_type_stock_encoding_and_arrangement_and_order_and_receiving_and_refund: "Distribución, Pedido, Recepción y Reembolso",
    op_type_preparations: "Preparativos"
  },
  job_op_schedule: {
    choose_job: "Elegir rol"
  },
  op_schedule_frequency_dialog: {
    placeholder: "Elegir frecuencia"
  },
  schedule_new_op_dialog: {
    title: "Agregar operación",
    choose_job: "Elegir trabajo",
    set_account_manager: "No hay roles de administrador definidos",
    no_account_selected: "No hay cuentas seleccionadas",
    max_file_size: "El tamaño máximo del archivo es 10 MB",
    x_jobs_selected: "{jobs_count} trabajos",
    related_item: "Objeto asignado",
    new_item: "Nombre del artículo",
    execution_time: "Tiempo de ejecución",
    select_end_date: "Puede seleccionar la fecha de finalización",
    tags: "Etiquetas",
    tag_report: "Informe",
    tag_monitor: "Monitorear",
    tag_procedure: "Procedimiento",
    x_tags_selected: '{tags_count} etiquetas'
  },
  languages: {
    en: "Inglés",
    he: "עברית",
    es: 'Español',
    language: "Idioma"
  },
  supplier_operation_schedule: {
    create_title: "Programar operación",
    op_desc: "Descripción"
  },
  item_search: {
    placeholder: "Buscar objeto",
    add_item: "Agregar nuevo objeto",
    delete_title: "Eliminar objeto",
    delete_content: "Esto eliminaría todos las fallos leídos."
  },
  supplier_search: {
    placeholder: "Buscar suministrador"
  },
  provider_search: {
    placeholder: "Buscar proveedor"
  },
  user_search: {
    placeholder: "Buscar usuario",
    x_users_selected: '{usersCount} usuarios'
  },
  employees: {
    name_col: "Nombre completo",
    add_employee_from_another_store: "De otra cuenta",
    total_employee: "Total empleados",
    invite: "Invitar",
    pending: "Pendiente",
    page_title: 'Empleados',
    staff_page_title: 'Empleados de la sede'
  },
  employees_dialog: {
    create_title: "Crear un empleado",
    update_title: "Actualizar un empleado",
    first_name: "Nombre",
    last_name: "Apellido",
    phone: "Teléfono",
    permission: "Permiso",
    choose_jobs_placeholder: "Elegir roles",
    birth_date: "Fecha de nacimiento",
    duplicate_phone: "Ya existe un usuario con el mismo teléfono"
  },
  employees_other_store_dialog: {
    title: "Agregar empleado de otra cuenta"
  },
  employees_roles: {
    company_admin: "Administrador de la empresa",
    company_region_admin: 'Administrador de la región',
    account_admin: "Gerente",
    account_manager: "Departamento",
    account_employee: "Empleado"
  },
  work_schedule_search_employee_dialog: {
    dialog_title: "Horario para {jobShift} {jobName}",
    placeholder: "Escriba el nombre del empleado",
    all_week_schedule: "Toda la semana"
  },
  reported_item_op_list: {
    save_error: "Se produjo un error al actualizar la lista."
  },
  item_op_list_dialog: {
    title: "Lista",
    feedback_description: "Comentarios de tareas"
  },
  operation_frequency: {
    week_x_frequency: "Una vez cada {frequency} semanas",
    week_x_frequency_with_day: "Cada {frequency} semanas el {dayName}",
    exect_date_frequency: "Fecha específica",
    every_year_no_date: "Todos los años",
    every_year_x_selected: "{datesCount} fechas seleccionadas",
    every_week: "Una vez por semana",
    every_week_with_day: "Cada {dayName}",
    every_day: "Cada día",
    every_year: "Cada año en {date}",
    week_x_frequency_start_period: "Operación abierta {day}, {date}"
  },
  job_selection_search: {
    x_jobs_selected: "{job_count} roles"
  },
  account_selection_select: {
    placeholder: "Seleccionar cuenta",
    x_accounts_selected: "{account_count} cuentas"
  },
  account_fault_devices: {
    faulty_device_tab: "Tickets de fallo",
    faulty_device_history_tab: "historia",
    faulty_device_reports_tab: "Informes",
    broken_items_assigned_to_me: "Asignado a mí",
    broken_items_opened_by_me: "Abierto por mí",
    broken_items_dates: "Rango de fechas",
    open_date: 'fecha de creación',
    close_date: 'Fecha de cierre',
    general_search: 'Búsqueda general',
    broken_items_settings: {
      anonymous_explain: "Permita que usuarios externos creen fallos en su cuenta enviando un correo electrónico a una dirección de correo electrónico especial",
      anonymous_explain_field: "Agregar nombre antes de @",
      invalid_subdomain: "Dirección no válida, solo se permiten letras, números, \"_\" y \"-\"",
      page_title: 'Configuración de fallos',
      domain_taken: 'Esta dirección ya está ocupada.'
    }
  },
  account_selection: {
    staff_tasks: "Tareas de la sede",
    store_tasks: "Tareas de cuenta",
    stores: "Cuentas",
    my_tasks_tab: "Tareas personales",
    hq_tasks_tab: "Tareas de la sede",
    accounts_tasks_tab: "Tareas de cuenta",
    my_tasks_tab_small: "Personales",
    hq_tasks_tab_small: "Sede",
    accounts_tasks_tab_small: "Cuenta",
    empty_area: "Sin área",
    select_area: "Seleccionar área",
    x_selected_areas: "{area_count} áreas",
    no_area: "Sin área",
    daily_progress: 'Progreso diario'
  },
  account_selection_employee: {
    select_account: "Seleccionar cuenta"
  },
  install_prompt: {
    chrome: "Añadir a la pantalla principal",
    ios: "Instalar esta aplicación web: toque {shareIcon}",
    ios1: "luego Agregar a la pantalla de inicio"
  },
  company_faulty_devices: {
    store_select: "Seleccionar cuenta"
  },
  show_faulty_device: {
    empty: "Sin dispositivos defectuosos"
  },
  company_settings: {
    company_name: "Nombre de empresa"
  },
  chat_dialog: {
    title: "Chat",
    message_placeholder: "Escribir un mensaje"
  },
  account_operations: {
    cancel_filter: "Restablecer filtro",
    status: "Estado",
    image: "Imagen",
    check_list: "Lista",
    status_done: "Hecho",
    status_not_reported: "No reportado",
    cross_account: "Operaciones de Sede",
    suppliers: "Operaciones de proveedores",
    with_image: "Con imagen",
    without_image: "Sin imagen",
    with_checklist: "Con lista",
    job_list: "Lista de trabajos",
    op_original_date: "Fecha original: {date}",
    op_original_date_with_end_date: "Fecha original: {date} - {endDate}",
    staff_operation: "Operación Sede: {first_name} {last_name}",
    assigned_to: "Asignado a: {executor}",
    mark_shift_completed: "Turno reportado como completo",
    operation_from: "De: {first_name} {last_name}",
    date_range_day: "Día",
    date_range_week: "Semana",
    date_range_month: "Mes",
    done_by: "hecho por {first_name} {last_name}",
    created_by: 'Creado por {firstName} {lastName}'
  },
  job_shift_select: {
    x_shifts_selected: "{shift_count} turnos"
  },
  version_check: {
    update_available: "Hay una nueva versión disponible",
    reload: "Actualizar"
  },
  duplicate_operation_dialog: {
    title: "Copiar operación a otro rol"
  },
  color_picker: {
    clear: "Limpiar"
  },
  out_of_shift_action: {
    title: "La acción está fuera del horario del turno.",
    title_before_start: "El turno aún no ha comenzado<br>Esta hoja de trabajo pertenece a <span class=\"red--text font-weight-bold\">{startDay}</span><br>para el turno que comienza a las <span class= \"red--text font-weight-bold\">{startTime}</span>",
    return_work_panel: "Volver al panel de trabajo",
    dismiss: "Vale, permanecer en este turno.",
    pass_shift: "Cambiar al turno del día anterior"
  },
  edit_operation_dialog: {
    invalid_day: "{job_name} no trabaja el {día}"
  },
  additional_allowed_jobs: {
    title: "Horario de trabajo para {jobName}"
  },
  account_edit_dialog: {
    title: "Editar cuenta"
  },
  create_district_dialog: {
    title: "Crear área",
    placeholder: "Nombre del área"
  },
  staff_account_job_dialog: {
    title: "Agregar trabajo de cuenta de personal",
    title_for_update: "Actualizar trabajo de cuenta de personal"
  },
  employee_schedules: {
    no_schedules: "Sin horarios"
  },
  user_notifications: {
    missing_email: "Proporcione el correo electrónico en la pestaña de registro de empleados.",
    sms: "sms",
    email: "correo electrónico",
    broken_item: {
      tab_title: "Dispositivos defectuosos",
      chat_notification: "Notificaciones de chat",
      chat_notification_user_mentioned: "Solo chats participados",
      low_priority: "Alerta cuando se abre un nuevo ticket de \"urgencia baja\"",
      high_priority: "Alerta cuando se abre un nuevo ticket de \"alta urgencia\"",
      deadline: "Abrir fallos en fecha límite",
      notify_not_only_assigned: "Notificar sobre fallos no solo asignados a mí",
      status_change: 'Notificar sobre cambio de estado'
    },
    job: {
      tab_title: "Trabajos",
      tab_title_for_comapny_admins: "Cuentas",
      chat_notification: "Notificaciones de chat de tareas",
      chat_notification_cross_account: "Sólo operaciones de Sede",
      cross_account_operation_notification: "Notificar cuando se reciba la operación de Sede",
      birthdate_notification: "Enviarme un correo electrónico cuando los empleados cumplan años",
      end_of_shift: "Notificación de finalización de tareas de trabajo",
      task_create: "Notificar cuando se crea una nueva tarea",
      task_daily: "Notificaciones diarias de tareas pendientes"
    },
    staff_job: {
      tab_title: "Trabajos del personal",
      staff_task_daily: "Notificaciones diarias de tareas pendientes",
      staff_task_create: "Notificar cuando se crea una nueva tarea"
    },
    app_chat: {
      tab_title: "Chat",
      join_group: "Notificar cuando me uno a un grupo",
      new_message: "Avisar cuando recibo un mensaje nuevo"
    },
    communnity: {
      tab_title: "Comunitarias",
      new_post: "Notificar cuando se crea una nueva publicación"
    },
    reports: {
      tab_title: "Informes",
      daily_report: "Correo electrónico de informe general diario"
    }
  },
  bottom_navigation: {
    work_schedule: "Turnos",
    tasks: "Tareas",
    faulty_device: "Fallos",
    accounts: "Cuentas",
    chat: "Chat"
  },
  work_schedule_request_statuses: {
    pending: "Solicitud pendiente",
    approved: "Solicitud aceptada",
    denied: "Solicitud rechazada"
  },
  account_department_select: {
    placeholder: "Elegir departamento",
    create: "Crear departamento",
    update: "Actualizar departamento",
    confirm_delete: "¿Desea eliminar el departamento {departmentName} de los roles?",
    error_name_taken: "El nombre del departamento ya está en uso",
    x_selected: '{count} departamentos'
  },
  total_tasks_by_department: {
    empty_department: "Sin Departamento",
    ongoing_html: "En curso <span class=\"ongoing\">{count}</span>",
    completed_html: "Completado <span class=\"completed\">{count}</span>"
  },
  menu_page: {
    profile: "Perfil",
    operation_history: "Historial de tareas",
    accounts: "Elegir cuenta",
    title_general: "General",
    title_preferences: "Preferencias",
    title_admin: "Admin",
    title_hq_admin: "Administrador en Sede",
    tasks: "Editar tareas",
    tasks_subtitle: "Ver y editar todas las tareas",
    team: "Miembros del equipo",
    team_subtitle: "Agregar y editar miembros del equipo",
    team_subtitle_employee: "Ver miembros del equipo",
    jobs: "Trabajos",
    jobs_subtitle: "Ver y editar trabajos",
    service_providers: "Proveedores de servicio",
    service_providers_subtitle: "Ver y editar proveedores de servicios de cuenta",
    suppliers: "Proveedores",
    suppliers_subtitle: "Directorio de proveedores",
    company_details: "Nombre de empresa",
    general_settings: 'Configuración general',
    hq_jobs: "Trabajos de Sede",
    hq_members: "Miembros del equipo de Sede",
    hq_members_subtitle: "Agregar y editar miembros del equipo de Sede",
    help: "Ayuda",
    faults_settings: "Configuración de fallos",
    task_bank: 'Banco de tareas',
    push_history: 'historial de notificaciones',
    drive: 'Archivos',
    logout: 'Cerrar sesión'
  },
  user_profile: {
    full_name: "Nombre completo",
    phone: "Número de teléfono",
    delete_account: "Eliminar cuenta",
    delete_confirm: "Esto eliminará permanentemente todos sus datos.",
    confirm: "Confirmar"
  },
  account_department_page: {
    total: "Total departamentos",
    name_col: "Nombre de Departamento",
    page_title: 'Departamentos'
  },
  app_chat: {
    create_group: "Nuevo grupo",
    search_group_placeholder: "Buscar usuario o grupo",
    search_group_divide: "Grupos activos",
    search_user_divide: "Otros usuarios",
    empty_messages: "Ningún mensaje",
    group_details: "Detalles del grupo",
    exit_group: "Salir de grupo",
    delete_group: "Eliminar grupo",
    delete_group_confirm: "¿Desea eliminar el grupo \"{groupName}\"?",
    delete_group_confirm_detail: "Se eliminará todo el historial de mensajes.",
    exit_group_confirm: "¿Desea salir del grupo \"{groupName}\"",
    exit_group_confirm_detail: "Se eliminará todo el historial de mensajes.",
    new_group_dialog: {
      title_create: "Crear nuevo grupo de chat",
      title_read_only: "Detalles del grupo",
      title_update: "Actualizar grupo",
      group_name: "Nombre del grupo"
    },
    seen_by_dialog: {
      title: "Información del mensaje",
      read_by: "Leído por",
      pending: "Pendiente"
    }
  },
  user_live_notifications: {
    new_group_html: "Te has unido al grupo de chat <span class='font-weight-bold'>{groupName}</span>"
  },
  language_select: {
    placeholder: "Elegir idioma"
  },
  operation_history: {
    search_placeholder: "Buscar tarea por descripción",
    no_date_selected: "Ninguna fecha seleccionada",
    op_type_cross_account: "Tareas de Sede",
    op_type_staff: "Mis tareas",
    no_description: "Sin descripción",
    created_by_me: "Tareas creadas por mí",
    page_title: 'Historial de tareas'
  },
  share_content: {
    clipboard_copy: "Contenido copiado al portapapeles"
  },
  broken_item_share: {
    ticket_from: 'Fallo from: %{companyName} | %{accountName}',
    ticket_from_no_company: 'Fallo from: %{companyOrAccountName}',
    opened_at: "Abierto en: %{open_date}",
    opened_by: "Abierto por: %{open_by}",
    description: "Descripción: %{description}",
    responsibility: "Responsabilidad:",
    comment: "Comentario: %{comment}",
    fix_description: "Descripción de la solución: %{fix_description}",
    price: "Precio: %{price}",
    fault_images: "Imágenes de fallo",
    fault_fix_images: "Imágenes de corrección de fallos",
    fault_attached_invoice: "Factura de fallo",
    fault_attached_video: "Vídeo de fallo",
    related_provider: 'Proveedor de servicio',
    related_providers: 'Proveedores de servicio:',
    fault_fix_until: 'Solucionar hasta: %{fixUntil}'
  },
  image_uploader: {
    max_images: "Subir hasta %{maxImages} imágenes"
  },
  audio_recorder: {
    permission_denied: "Permiso de audio denegado"
  },
  jobs_calander: {
    show_all: "Mostrar todo (%{count})"
  },
  job_operation_edit: {
    delete_operation: "Eliminar tarea"
  },
  feature_select: {
    enable_features: "Habilitar funciones",
    broken_item_history: "Panel de historial de elementos rotos",
    broken_items: "Elementos rotos",
    read: "Leer",
    edit: "Editar",
    delete: "Borrar",
    create: "Crear",
    close: "Cerrar ticket"
  },
  operation_share: {
    title: "Compartir tarea",
    hq: "Tarea de Sede",
    start_time: "Hora de inicio a las: %{startTime}",
    creator: "de: %{firstName} %{lastName}",
    not_reported: "Estado: no terminado",
    reported: "Estado: Hecho por %{firstName} %{lastName}",
    report_image: "Reportar imagen:",
    attahced_file: "Archivo adjunto:",
    checklist: "Lista (%{done}/%{total}):",
    guidance_images: "Imágenes de orientación:"
  },
  company_permissions: {
    create_object: "Crear objeto",
    account_admin: "Permiso de gerente",
    account_manager: "Permiso del departamento",
    account_employee: "Permiso de empleado"
  },
  cross_account_page: {
    my_operations: "Creado por mí",
    delete_cross_operation: "Eliminar tarea",
    delete_cross_operation_detail: "Esto eliminará todas las tareas relacionadas."
  },
  single_task_notification: {
    title: "Establecer alertas para esta tarea",
    on_complete: "Al completar la tarea",
    on_un_complete: "En tarea incompleta"
  },
  install_app_prompt: {
    title: "Hola, nuestra aplicación móvil está disponible en todas las tiendas.",
    close: "Entendido, no volver a mostrar"
  },
  blog_post_create_dialog: {
    new_post_title: "Nueva publicación",
    post_update_title: 'Actualizar publicación',
    new_comment_title: "Responder a la publicación",
    comment_update_title: 'Actualizar comentario',
    content_placeholder: "¿De qué desea hablar?",
    select_all: "Seleccionar todas las cuentas"
  },
  tags_select: {
    placeholder: "Elegir etiqueta"
  },
  blog_post: {
    read_more: "Leer más",
    read_less: "Leer menos",
    like: "Me gusta",
    reply: "Responder",
    load_comments: "Cargar comentarios",
    likes: "Me Gustas",
    staff_account: "Cuenta de personal",
    no_posts: "Aún no hay publicaciones",
    be_first: "¡Sea el primero en publicar!",
    my_posts: "Mis publicaciones",
    my_likes: "Mis Me Gusta",
    actions: 'Acciones'
  },
  blog_post_share: {
    title: "Compartir publicación",
    post_by: "Publicado por: %{firstName} %{lastName}",
    created_at: "Creado en: %{date}",
    post_tags: "Etiquetas: %{tags}",
    images: "Imágenes",
    video: "vídeo: %{videoUrl}",
    likes_count: "%{likesCount} Me gusta",
    comments_count: "%{commentsCount} Comentarios"
  },
  video_upload: {
    max_size: "El tamaño máximo del vídeo es 100 MB"
  },
  item_type_select: {
    title: "Mostrar como título",
    single_checklist: "Casilla de verificación única",
    image: "Requiere una imagen",
    rating: "Campo de calificación",
    yes_no: "Campo Sí/No",
    feedback: "Campo de comentarios",
    feedback_optional: '* Los comentarios son opcionales'
  },
  item_category_search: {
    placeholder: "Buscar categoría",
    add_item_category: 'Agregar nueva categoría',
    delete_title: 'Eliminar categoría',
    delete_content: 'Esto eliminaría esta categoría de todos los objetos relacionados'
  },
  new_item_category: {
    create_title: 'Crear categoría',
    update_title: 'Actualizar categoría',
    name: 'Nombre'
  },
  company_broken_items: {
    deadline_date_label: 'Fallos con fecha límite',
    deadline_date_passed: 'Faults with passed deadline date',
    deadline_date_today: 'Fallas para solucionar hoy',
    deadline_date_coming_week: 'Fecha límite para la próxima semana',
    deadline_date_no_deadline: 'Fallas sin fecha límite',
    no_deadline: 'No deadline',
    deadline_this_week: 'Arreglar esta semana',
    deadline_today: 'Arreglar hoy',
    deadline_passed: 'Fecha pasada',
    total: 'fallas totales',
    avg_open_time: 'Tiempo medio de fijación (días)',
    urgent_faults: 'Fallas urgentes',
    status: 'Estado',
    deadline: 'Fecha límite',
    after_deadline: 'Después de la fecha límite'
  },
  broken_item: {
    created_by: 'Creado por:',
    assigned_to: 'Asignado a:',
    repair_until: 'Reparar hasta:',
    options: 'Opciones',
    close_ticket: 'Cerrar ticket (pasar a la historia)',
    service_provider: 'Proveedor de servicio:',
    time_left: 'Tiempo restante'
  },
  delete_distrcit_dialog: {
    title: 'Eliminar área',
    content: 'Esto eliminará el área.'
  },
  broken_item_table: {
    headers: {
      account_name: 'Cuenta',
      location: 'ubicación',
      description: 'Descripción',
      assigned_to: 'Asignado a',
      comments: 'Comments',
      open_date: 'Cita abierta',
      status: 'Estado'
    }
  },
  company_stats: {
    hq: 'Sede',
    account: 'Cuentas',
    faults: 'fallas',
  },
  company_table: {
    view: 'Ver',
    headers: {
      name: 'nombre de la cuenta',
      location: 'área',
      hq_tasks: 'Tareas de la sede',
      account_tasks: 'Tareas de cuenta',
      total_faults: 'fallas'
    }
  },
  items_page: {
    pick_account: 'Elegir cuenta',
    general: 'General',
    total_calls: "Llamadas totales",
    off_time: "Fuera de tiempo",
    total_cost: "Coste total"
  },
  tasks_page: {
    delete_task_image: '¿Estás seguro de que deseas eliminar esta imagen?',
    empty_tasks: 'No tienes tareas para hoy',
    jobs: 'trabajos',
    departments: 'Departamentos',
    progress: 'Progreso'
  },
  task_bank: {
    title: 'Banco de tareas',
    every_day_frequency: 'Cada día',
    every_week_frequency: 'Cada semana',
    every_x_weeks_frequency: 'Cada X semanas',
    every_year_frequency: 'Cada año',
    exect_date_frequency: 'Fecha específica',
    created_by: 'Creado por {firstName} {lastName}',
    no_tasks: 'No hay tareas',
    serach_placeholder: 'buscar por descripción de tarea',
    assigned_to_x_jobs: 'Asignado a {jobsCount} trabajos'
  },
  hq_task_job_group: {
    create_title: 'Crear nuevo grupo de trabajo',
    update_title: 'Actualizar grupo de trabajo',
    job_groups: 'Grupos de trabajo',
    delete_group: 'Delete {groupName} job group'
  },
  default_deadline: {
    broken_item_settings: 'Configuración de fallos',
    high_priority: 'Alta urgencia fallas',
    low_priority: 'Baja urgencia fallas',
    deadline_time: 'Hora predeterminada para cerrar los fallos (horas)',
    deadline_notification: 'Enviar notificación en caso de que el fallo no sea resuelto después (hours)',
  },
  push_history: {
    title: 'historial de notificaciones',
    no_notifications: 'Sin notificaciones',
    is_viewed: 'Es visto',
    viewed: 'Visto',
    not_viewed: 'No visto',
    mark_as_viewed: 'Marcar como visto',
    assigned_ticket: 'Ticket asignado a usted en %{accountName} para %{itemName}',
    ticket_new_message: 'Nuevo mensaje recibido para el ticket en %{accountName} para %{itemName}',
    new_ticket: 'Nuevo ticket creado en %{accountName} para %{itemName}',
    updated_ticket: 'Ticket actualizado en %{accountName} para %{itemName}',
    ticket_deadline: 'El ticket en %{accountName} para %{itemName} debe solucionarse antes del %{deadlineDate}',
    ticket_timed_notification: 'Notificación adicional para el ticket en %{accountName} para %{itemName} que aún está abierto desde %{openDate}',
    new_blog_post: 'Nueva entrada de blog creada por %{firstName} %{lastName}',
    new_app_chat_group_message: 'Nuevo mensaje de %{firstName} %{lastName} a las %{groupName}',
    new_app_chat_message: 'Nuevo mensaje de %{firstName} %{lastName}',
    app_chat_join_group: 'Te uniste al grupo de chat %{groupName}',
    task_chat_message: 'Nuevo mensaje recibido para la tarea en %{accountName} para %{jobName} %{jobShift}',
    new_staff_task: 'Nueva tarea personal creada por %{firstName} %{lastName} para %{jobName}',
    new_corss_account_task: 'Nueva tarea de HQ creada en %{accountName} para %{jobName} %{jobShift}',
    new_task: 'Nueva tarea creada en %{accountName} para %{jobName} %{jobShift}',
    task_not_completed: 'La tarea no se informó como realizada en %{accountName} para %{jobName} %{jobShift}',
    task_completed: 'La tarea se informó como realizada en %{accountName} para %{jobName} %{jobShift}',
    shift_completed: 'Se informó que el turno se realizó a las %{accountName} para %{jobName} %{jobShift}'
  },
  drive: {
    title: 'Files',
    upload: 'Subir',
    create_folder: 'Crear nueva carpeta',
    update_folder: 'Actualizar carpeta',
    folder_name: 'Nombre de la carpeta',
    access: 'Acceso a carpetas',
    all_accounts: 'Todas las cuentas',
    created_by: 'Por: {firstName} {lastName}',
    no_files: 'No hay archivos',
    one_file: 'Un archivo',
    many_files: '{count} archivos',
    all_accounts: 'Todas las cuentas',
    all_roles: 'Todos los permisos',
    delete_folder: 'Eliminar la carpeta {folderName}',
    delete_folder_details: 'Esto eliminaría todo el contenido de la carpeta.',
    upload_file: 'Subir archivo',
    update_file: 'Actualizar archivo',
    file_label: 'Haga clic para cargar el archivo',
    delete_file: 'Eliminar el archivo {fileName}',
    owner: 'Dueño',
    link_copied: 'Enlace copiado al portapapeles',
    owner_search: 'Creado por',
    empty_drive: 'No hay archivos ni carpetas',
    empty_folder: 'No hay archivos todavía',
    roles_access: 'Acceso a roles'
  }
}