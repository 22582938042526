
import { createToken } from '@/api_client/Token.js'

import { isString } from '@/helpers/Utils.js'
import axios from 'axios'

const LINK_PREVIEW_SCOPE = 'link_preview'
const requestOptions = {
  headers: {
    'Content-Type': 'application/json'
  }
}
const LINK_PREVIEW_URL = import.meta.env.VITE_APP_LINK_PREVIEW_URL

let currentApiKey = null
let tokenData = {}

export default function useLinkPreview (apiKey) {
  const resetValues = () => {
    tokenData = {}
  }

  if (isString(apiKey) && currentApiKey !== apiKey) {
    resetValues()
    currentApiKey = apiKey
  }

  const tokenValid = () => {
    if (!Number.isInteger(tokenData.expiration_time)) return false
    const nowUnixTime = Math.floor(Date.now() / 1000)
    return tokenData.expiration_time > nowUnixTime
  }

  const refreshToken = async (url) => {
    const requestParams = { scope: LINK_PREVIEW_SCOPE, apiKey: currentApiKey }
    const response = await createToken(requestParams)
    tokenData = response.data.data
  }

  const getLinkData = async (url) => {
    if (!tokenValid()) await refreshToken(url)
    const response = await axios.post(`${LINK_PREVIEW_URL}?token=${tokenData.token}`, { url }, requestOptions)
    return response.data
  }

  return {
    getLinkData
  }
}
