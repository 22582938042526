import Client from '@/api_client/client.js'

const index = (apiKey, { chatGroupId }) => {
  const INDEX_CHAT_GROUP_PARTICIPANT_PATH = `/accounts/${apiKey}/chat_groups/${chatGroupId}/chat_group_participants/`
  return Client.get(INDEX_CHAT_GROUP_PARTICIPANT_PATH)
}

const destroy = (apiKey, { chatGroupId }) => {
  const DELETE_CHAT_GROUP_PARTICIPANT_PATH = `/accounts/${apiKey}/chat_groups/${chatGroupId}/chat_group_participants`
  return Client.delete(DELETE_CHAT_GROUP_PARTICIPANT_PATH)
}

export {
  index,
  destroy
}
