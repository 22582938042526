import Client from '@/api_client/client.js'

function index (apiKey, notifiedModel) {
  const USER_NOTIFICATIONS_INDEX_PATH = `/accounts/${apiKey}/user_notifications`
  return Client.get(USER_NOTIFICATIONS_INDEX_PATH, { params: { notified_model: notifiedModel } })
}

function batchEdit (apiKey, params) {
  const USER_NOTIFICATIONS_BATCH_EDIT_PATH = `/accounts/${apiKey}/user_notifications/batch_edit`
  return Client.post(USER_NOTIFICATIONS_BATCH_EDIT_PATH, { user_notification: params })
}

export {
  index,
  batchEdit
}
