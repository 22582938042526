<template>
  <v-dialog v-model="dialog" :max-width="maxWidth" persistent>
    <v-card>
      <div class="headline text-center pa-3">
        {{ titleRef }}
      </div>
      <v-card-actions class="px-0">
        <v-row align="center" align-content="space-between" no-gutters class="text-center">
          <v-col cols="12" v-if="showContent" class="px-2">
            <v-alert  color="red" class="font-weight-bold">
              {{ contentRef }}
            </v-alert>
          </v-col>
          <v-col>
            <v-btn color="green darken-1" text @click="cancel" :disabled="isLoading">{{ $t("cancel") }}</v-btn>
          </v-col>
          <v-col>
            <v-btn color="red darken-1" text @click="deleteOk" :loading="isLoading" :disabled="isLoading">{{ deleteBtnTextRef }}</v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { onDialogClose, onDialogOpen } from '@/classes/DialogScrollBug.js'

import { isString } from '@/helpers/Utils.js'

import { i18n } from '@/i18n.js'

import { ref, computed } from 'vue'

export default {
  props: {
    maxWidth: {
      type: String,
      required: false,
      default: '200'
    }
  },
  setup () {
    let resolveFunc
    const dialog = ref(false)
    const isLoading = ref(false)
    const titleRef = ref(null)
    const contentRef = ref(null)
    const deleteBtnTextRef = ref(null)

    const closeDialog = () => {
      onDialogClose()
      isLoading.value = false
      dialog.value = false
      titleRef.value = null
      contentRef.value = null
    }
    // Methods

    const close = () => closeDialog()

    const open = ({ title, content, deleteBtnText } = { title: null, content: null }) => {
      onDialogOpen()
      isLoading.value = false
      dialog.value = true
      titleRef.value = isString(title) && title.length > 0 ? title : i18n.t('confirm_delete.title')
      contentRef.value = isString(content) && content.length > 0 ? content : null
      deleteBtnTextRef.value = isString(deleteBtnText) && deleteBtnText.length > 0 ? deleteBtnText : i18n.t('delete')
      return new Promise((resolve, reject) => {
        resolveFunc = resolve
      })
    }
    const cancel = () => {
      closeDialog()
      resolveFunc(false)
    }

    const deleteOk = () => {
      isLoading.value = true
      resolveFunc(true)
    }

    // Computed

    const showContent = computed(() => isString(contentRef.value) && contentRef.value.length > 0)
    return {
      dialog,
      isLoading,
      titleRef,
      contentRef,
      deleteBtnTextRef,
      showContent,
      open,
      cancel,
      deleteOk,
      close
    }
  },
  data () {
    return {
      resolve: null
    }
  }
}
</script>
