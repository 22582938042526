<template>
  <v-row align="start" no-gutters justify="start"
    class="mb-4 mx-2 mt-0 single-item px-4 py-6 pointer" @click="onItemClick">
    <v-col :cols="showOptions ? 10 : 12" class="t-700-16 color10">
      {{ itemNameAndDepartment }}
    </v-col>
    <v-col cols="2" v-if="showOptions" class="texts-end">
      <v-menu min-width="100">
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" small icon>
            <v-icon color="black">more_vert</v-icon>
          </v-btn>
        </template>
        <v-list :class="{ 'rtl': isRtl, 'ltr': !isRtl }">
          <v-list-item v-if="editEnabled" @click="onEditClick" class="px-2">
            <v-list-item-content>
              {{ $t('edit') }}
            </v-list-item-content>
            <v-btn icon class="px-1" small>
              <v-icon>edit</v-icon>
            </v-btn>
          </v-list-item>
          <v-list-item @click="onDeleteClick" v-if="deleteEnabled" class="px-2">
            <v-list-item-content>
              {{ $t('delete') }}
            </v-list-item-content>
            <v-btn icon class="px-1" small>
              <v-icon>delete</v-icon>
            </v-btn>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-col>
    <v-col cols="12" class="stats py-4 mt-4 row no-gutters align-start justify-space-between text-center white--text"
      :class="{ 'all-closed': allClosed }">
      <v-col cols="4" class="t-500-13">
        {{ $t('items_page.total_calls') }}
      </v-col>
      <v-col cols="4" class="t-500-13 ">
        {{ $t('items_page.off_time') }}
      </v-col>
      <v-col cols="4" class="t-500-13">
        {{ $t('items_page.total_cost') }}
      </v-col>
      <v-col cols="12" class="my-2">
      </v-col>
      <v-col cols="4" class="t-700-14">
        {{ item.broken_item_stats.total }}
      </v-col>
      <v-col cols="4" class="t-700-14">
        {{ item.broken_item_stats.total_days_open }}
      </v-col>
      <v-col cols="4" class="t-700-14">
        {{ item.broken_item_stats.total_price }}
      </v-col>
    </v-col>
  </v-row>
</template>
<style lang="scss">
.single-item {
  border-radius: 8px;
  box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.04), 0px 2px 6px 0px rgba(0, 0, 0, 0.04), 0px 16px 24px 0px rgba(0, 0, 0, 0.06);
  background-color: white;

  .stats {
    background-color: #FF1744;
    border-radius: 8px;

    &.all-closed {

      background-color: #0040FF;
    }
  }

}
</style>
<script>
import { isString } from '@/helpers/Utils.js'

import { i18n, isRtl } from '@/i18n.js'
import { computed } from 'vue'
export default {
  props: {
    item: {
      type: Object,
      required: true
    },
    deleteEnabled: {
      type: Boolean,
      required: true
    },
    editEnabled: {
      type: Boolean,
      required: true
    }
  },
  setup(props, { emit }) {

    // Methods

    const onEditClick = () => emit('edit-item', props.item)
    const onDeleteClick = () => emit('delete-item', props.item)
    const onItemClick = () => emit('item-click', props.item)

    // Computed    
    const isGeneral = computed(() => props.item.general === true)
    const showOptions = computed(() => isGeneral.value !== true && (props.deleteEnabled || props.editEnabled))
    const allClosed = computed(() => props.item.broken_item_stats.close_count === props.item.broken_item_stats.total)
    const itemNameAndDepartment = computed(() => {
      if (isGeneral.value === true) return i18n.t('items_page.general')
      const departmentName = props.item.department?.name
      return isString(departmentName) ? `${props.item.name} - ${departmentName}` : props.item.name
    })
    return {
      isGeneral,
      showOptions,
      itemNameAndDepartment,
      allClosed,
      isRtl,
      onEditClick,
      onDeleteClick,
      onItemClick
    }
  }
}
</script>