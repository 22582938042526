<template>
  <v-autocomplete
    :value="value"
    :items="operationsList"
    :search-input.sync="search"
    :disabled="disabled"
    autocomplete="off"
    :loading="isLoading"
    clearable
    @click:clear="resetOperation"
    @input="updateOperation"
    @change="emitChange"
    hide-selected
    item-value="operation_id"
    item-text="operation_description"
    :placeholder="$t('operation_history.search_placeholder')"
    return-object>
  </v-autocomplete>
</template>
<script>
import { search as operationsHistoryClientSearch } from '@/api_client/DailyOperationsHistory.js'

import { isString } from '@/helpers/Utils.js'

import { handler as errHandler } from '@/classes/ErrorHandler.js'

import { ref, watch } from 'vue'

import { i18n } from '@/i18n.js'
export default {
  props: {
    apiKey: {
      type: String,
      required: true
    },
    value: {
      type: Object,
      required: false,
      default: () => {
        return { }
      }
    },
    operationType: {
      type: String,
      required: true
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  setup (props, { emit }) {
    const isLoading = ref(false)
    const search = ref(null)
    const operationsList = ref([])
    const initialResult = { cross_account: [], staff: [], account: [] }

    const resetOperationList = () => {
      const operationsListVal = operationsList.value
      operationsListVal.splice(0, operationsListVal.length)
      initialResult[props.operationType].forEach(operation => operationsListVal.push(operation))
    }

    // Methods
    const updateOperation = (newOperation) => emit('input', newOperation)
    const emitChange = (newOperation) => emit('change', newOperation)

    const resetOperation = () => updateOperation({})
    const loadOperations = ({ query = null, targetArray }) => {
      isLoading.value = true
      return operationsHistoryClientSearch(props.apiKey, { filters: { query }, operationType: props.operationType })
        .then(res => {
          targetArray.splice(0, targetArray.length)
          res.data.data.daily_operations.forEach((operation) => {
            const description = operation.operation_description
            if (description === null || (isString(description) && description.trim().length === 0)) {
              operation.operation_description = i18n.t('operation_history.no_description')
            }
            targetArray.push(operation)
          })
        })
        .catch(errHandler)
        .finally(() => (isLoading.value = false))
    }

    const loadInitialResult = () => loadOperations({ targetArray: initialResult[props.operationType] })

    watch(() => search.value, (query) => {
      if (isLoading.value === true) return
      if (isString(query) && operationsList.value.some((user) => user.operation_description === query)) return

      if (!query || (query || '').trim().length === 0) {
        resetOperationList()
        return
      }
      loadOperations({ query, targetArray: operationsList.value })
    })
    watch(() => props.operationType, () => loadInitialResult().then(resetOperationList))

    loadInitialResult().then(resetOperationList)
    return {
      operationsList,
      isLoading,
      search,
      updateOperation,
      emitChange,
      resetOperation
    }
  }
}
</script>
