
import {
  index as accountDepartmentIndex, destroy as accountDepartmentDelete,
  departmentWithJobExists as accountDepartmentWithJobExists,
  updateOrder as accountDepartmentUpdateOrder

} from '@/api_client/AccountDepartment.js'
import { isString } from '@/helpers/Utils.js'
import { handler as errHandler } from '@/classes/ErrorHandler.js'

import { ref } from 'vue'

const EMPTY_DEPARTMENT_ID = 'empty_department'
const allDepartments = ref([])
const departmentWithJobsExists = ref(null)

const isLoading = ref(false)

let currentApiKey = null
let shouldReload = true
export default function useAccountDepartmentSearch(apiKey) {
  const resetValues = () => {
    isLoading.value = false
    allDepartments.value.splice(0, allDepartments.value.length)
    shouldReload = true
  }

  if (isString(apiKey) && currentApiKey !== apiKey) {
    resetValues()
    currentApiKey = apiKey
  }

  // Methods
  const loadAllDeparmtnets = (forceLoad = false) => {
    if (shouldReload === false && forceLoad !== true) return
    isLoading.value = true

    return accountDepartmentIndex({ apiKey: currentApiKey })
      .then((response) => {
        const allDepartmentsVal = allDepartments.value
        allDepartmentsVal.splice(0, allDepartmentsVal.length)
        response.data.forEach((department) => allDepartmentsVal.push(department))
        shouldReload = false
      })
      .finally(() => (isLoading.value = false))
  }

  const deleteDepartment = (id) => {
    isLoading.value = true
    return accountDepartmentDelete({ apiKey: currentApiKey, id })
      .then(() => loadAllDeparmtnets(true))
      .catch(errHandler)
      .finally(() => (isLoading.value = false))
  }

  const updateOrder = (newOrder) => {
    isLoading.value = true
    const params = { department_ids: newOrder }
    return accountDepartmentUpdateOrder({ apiKey: currentApiKey, params })
      .then(() => loadAllDeparmtnets(true))
      .catch(errHandler)
      .finally(() => (isLoading.value = false))
  }

  const isAccountDepartmentWithJobExists = async () => {
    return accountDepartmentWithJobExists(currentApiKey)
      .then((response) => (departmentWithJobsExists.value = response.data.department_with_jobs_exists))
      .catch(errHandler)
      .finally(() => (isLoading.value = false))
  }

  const changeApiKey = (apiKey) => {
    resetValues()
    currentApiKey = apiKey
  }
  return {
    EMPTY_DEPARTMENT_ID,
    isLoading,
    allDepartments,
    updateOrder,
    loadAllDeparmtnets,
    deleteDepartment,
    isAccountDepartmentWithJobExists,
    changeApiKey
  }
}
