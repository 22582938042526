<template>
  <v-row align="start" justify="start" class="company-broken-items" :class="{ 'rtl': isRtl, 'ltr': !isRtl }">
    <new-broken-item-dialog
      :show-account-selection="isMultipleMemberships"
      :show-close-option="showCloseTicket"
      ref="newBrokenItemDialog">
    </new-broken-item-dialog>
    <v-btn color="black" class="add-broken-item" v-if="showCreateButton"
      @click="createBrokenItem" dark small fab :disabled="isListLoading || isLoading">
      <v-icon>add</v-icon>
    </v-btn>
    <v-col cols="12" class="top-part-image d-flex align-end">
      <broken-item-top-stats :api-key="apiKey">
      </broken-item-top-stats>
    </v-col>
    <v-col cols="12" class="d-flex row py-0 align-center my-2">
      <broken-items-filter :api-key="apiKey">
      </broken-items-filter>
      <v-col cols="12" lg="3" sm="6" class="pb-0 mt-0" v-if="isMultipleMemberships">
        <account-select
          v-model="accountstFilter"
          @blur="onSelectAccountClose"
          :api-key="apiKey"
          :district-ids="companyDistrictFilter"
          outlined
          dense
          add-fault-device-count
          multiple>
        </account-select>
      </v-col>
      <v-col cols="12" lg="3" sm="6" class="pb-0" v-if="$vuetify.breakpoint.mdAndUp">
        <v-btn-toggle class="ltr"
          v-model="uiMode" rounded mandatory>
          <v-btn small value="grid">
            <v-icon>grid_view</v-icon>
          </v-btn>
          <v-btn small value="list">
            <v-icon>list</v-icon>
          </v-btn>
        </v-btn-toggle>
      </v-col>
    </v-col>
    <v-col cols="12" v-if="$vuetify.breakpoint.mdAndUp">
      <broken-item-bottom-stats :api-key="apiKey" class="mb-3">
      </broken-item-bottom-stats>
    </v-col>
    <v-col cols="12" v-if="isListLoading">
      <v-row>
        <v-col v-for="index in 3" :key="index" cols="12" md="6" lg="4">
          <v-skeleton-loader type="card-avatar" elevation="2"></v-skeleton-loader>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12" v-else-if="isGridModeView" class="mt-2 mt-sm-0 mb-10 mb-sm-0">
      <broken-items-grid
        :show-delete="showDeleteTicket"
        :show-close="showCloseTicket"
        :show-edit="showEditTicket"
        @edit-broken-item="editBrokenItem">
      </broken-items-grid>
      <v-col v-if="showLoadMorePages" v-intersect.once="onIntersect"></v-col>
      <v-row v-if="isListLoadingNextPage">
        <v-col v-for="index in 3" :key="index" cols="12" md="6" lg="4">
          <v-skeleton-loader type="card-avatar" elevation="2"></v-skeleton-loader>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12" v-else class="mt-2 mt-sm-0 mb-10">
      <broken-items-table
        :show-delete="showDeleteTicket"
        :show-close="showCloseTicket"
        :show-edit="showEditTicket"
        :show-account-data="isMultipleMemberships"
        @edit-broken-item="editBrokenItem">
      </broken-items-table>
      <v-col v-if="showLoadMorePages" v-intersect.once="onIntersect"></v-col>
      <v-col v-if="isListLoadingNextPage">
        <v-skeleton-loader type="table-tbody" elevation="2"></v-skeleton-loader>
      </v-col>
    </v-col>
  </v-row>
</template>

<style lang="scss">
.company-broken-items {
  .add-broken-item {
    position: fixed;
    z-index: 4;
    bottom: 86px;
    right: 10px;

    @media only screen and (max-width: 600px) {
      bottom: 80px;
    }
  }

  &.rtl {
    .add-broken-item {
      right: unset;
      left: 10px;
    }
  }

  @media only screen and (max-width: 600px) {
    margin-top: 50px;
  }
}
</style>

<script>
import BrokenItemTopStats from '@/components/broken_items/stats/BrokenItemTopStats.vue'
import BrokenItemsTable from '@/components/broken_items/table_view/BrokenItemsTable.vue'
import AccountSelect from '@/components/shared/AccountSelect.vue'
import BrokenItemBottomStats from '@/components/broken_items/stats/BrokenItemBottomStats.vue'
import BrokenItemFilter from '@/components/broken_items/BrokenItemFilter.vue'
import BrokenItemsGrid from '@/components/broken_items/grid_view/BrokenItemsGrid.vue'
import NewBrokenItemDialog from '@/components/broken_item_dialog/NewBrokenItemDialog.vue'

import useCompanyBrokenItem from '@/composables/useCompanyBrokenItem.js'
import useAuth from '@/composables/useAuth.js'
import useCompany from '@/composables/useCompany.js'
import useCurrentUserPermissions from '@/composables/useCurrentUserPermissions.js'

import { handler as errHandler } from '@/classes/ErrorHandler.js'

import { isRtl } from '@/i18n'

import { useRoute } from 'vue-router/composables'

import { ref, computed, onMounted, onUnmounted } from 'vue'

import Vuetify from '@/plugins/vuetify'

export default {
  components: {
    'new-broken-item-dialog': NewBrokenItemDialog,
    'broken-items-grid': BrokenItemsGrid,
    'broken-items-filter': BrokenItemFilter,
    'broken-item-bottom-stats': BrokenItemBottomStats,
    'account-select': AccountSelect,
    'broken-items-table': BrokenItemsTable,
    'broken-item-top-stats': BrokenItemTopStats
  },
  setup() {
    const { apiKey, isAccountAdminOrAbove } = useAuth()
    const { isMultipleMemberships } = useCompany(apiKey.value)
    const {
      canCloseBrokenItem, canDeleteBrokenItem, canUpdateBrokenItem, canCreateBrokenItem,
      loadPermissions
    } = useCurrentUserPermissions(apiKey.value)

    const route = useRoute()
    const newBrokenItemDialog = ref(null)

    const { isLoading, isListLoading, pagination, isListLoadingNextPage, accountstFilter,
      companyDistrictFilter, uiMode, uiModes, isGridModeView,
      initPageData, applyFilters, loadNextPage } =
      useCompanyBrokenItem(apiKey.value)

    const apiKeyFromRoute = route.params.apiKey
    // Methods
    const createBrokenItem = () => {
      newBrokenItemDialog.value.openForCreateWithDeviceSearch()
        .then(({ updatedOrCreated }) => {
          if (!updatedOrCreated) return
          applyFilters()
        })
        .catch(errHandler)
    }

    const editBrokenItem = (brokenItem) => {
      newBrokenItemDialog.value.openForUpdate(brokenItem)
        .then(({ updatedOrCreated }) => {
          if (!updatedOrCreated) return
          applyFilters()
        })
        .catch(errHandler)
    }
    const onIntersect = loadNextPage
    const onSelectAccountClose = applyFilters

    const onResize = () => {
      if (uiMode.value === uiModes.grid) return
      if (Vuetify.framework.breakpoint.mdAndUp) return

      uiMode.value = uiModes.grid
    }
    // Computed
    const showCloseTicket = computed(() => isAccountAdminOrAbove.value || canCloseBrokenItem.value)
    const showDeleteTicket = computed(() => isAccountAdminOrAbove.value || canDeleteBrokenItem.value)
    const showEditTicket = computed(() => isAccountAdminOrAbove.value || canUpdateBrokenItem.value)
    const showCreateButton = computed(() => isAccountAdminOrAbove.value || canCreateBrokenItem.value)
    const showLoadMorePages = computed(() => {
      if (isListLoading.value === true || isListLoadingNextPage.value) return false

      const { page, totalPages } = pagination.value
      return page < totalPages
    })

    loadPermissions()

    const apiKeyFromParams = route.params.apiKey
    initPageData({ apiKey: apiKeyFromParams }).catch(errHandler)
    onMounted(() => window.addEventListener('resize', onResize))
    onUnmounted(() => window.removeEventListener('resize', onResize))
    return {
      newBrokenItemDialog,
      isLoading,
      isListLoading,
      isListLoadingNextPage,
      showLoadMorePages,
      apiKey,
      isMultipleMemberships,
      accountstFilter,
      companyDistrictFilter,
      uiMode,
      showCreateButton,
      isGridModeView,
      showCloseTicket,
      showDeleteTicket,
      showEditTicket,
      isRtl,
      onIntersect,
      createBrokenItem,
      editBrokenItem,
      onSelectAccountClose
    }
  }
}
</script>
