<template>
  <v-dialog v-model="dialog" max-width="550"
    persistent :fullscreen="$vuetify.breakpoint.xsOnly">
    <v-card class="pa-6 item-dialog-layout" v-if="dialog"
      :class="{ 'rtl': isRtl, 'ltr': !isRtl }">
      <v-row align="start" justify="start">
        <v-col cols="12">
          <item-dialog-top-part :item="item">
          </item-dialog-top-part>
        </v-col>
        <v-col cols="12" class="scrollable">
          <item-dialog-list
            :item="item"
            :api-key="apiKey"
            :filters="filters"
            :close-enabled="closeEnabled"
            :delete-enabled="deleteEnabled"
            :edit-enabled="editEnabled">
          </item-dialog-list>
        </v-col>
        <v-col cols="12" class="pb-0 pt-4 texts-end align-self-end">
          <v-btn color="blue darken-1" depressed tile @click="close">
            {{ $t('close') }}
          </v-btn>
        </v-col>
      </v-row>
    </v-card>
  </v-dialog>
</template>
<style scoped lang="scss">
.item-dialog-layout {
  .scrollable {
    overflow: auto;
    height: 55vh;
    max-height: 55vh;
  }
}
</style>
<script>
import ItemDialogTopPart from '@/components/items/item_dialog/ItemDialogTopPart.vue'
import ItemDialogList from '@/components/items/item_dialog/ItemDialogList.vue'

import { isRtl } from '@/i18n.js'

import { ref } from 'vue'
export default {
  components: {
    'item-dialog-top-part': ItemDialogTopPart,
    'item-dialog-list': ItemDialogList
  },
  setup() {
    const isLoading = ref(false)
    const dialog = ref(false)
    const item = ref({})
    const apiKey = ref(null)
    const closeEnabled = ref(false)
    const deleteEnabled = ref(false)
    const editEnabled = ref(false)
    const filters = ref({})



    const openDialog = (itemVal, itemsApiKey, { showClose, showDelete, showEdit }, filtersVal = {}) => {
      dialog.value = true
      item.value = itemVal
      apiKey.value = itemsApiKey
      closeEnabled.value = showClose
      deleteEnabled.value = showDelete
      editEnabled.value = showEdit
      filters.value = filtersVal
    }

    const close = () => {
      dialog.value = false
      isLoading.value = false
      item.value = {}
      apiKey.value = null
      closeEnabled.value = false
      deleteEnabled.value = false
      editEnabled.value = false
    }

    return {
      isLoading,
      dialog,
      apiKey,
      item,
      isRtl,
      closeEnabled,
      deleteEnabled,
      editEnabled,
      filters,
      openDialog,
      close
    }
  }
}
</script>
