<template>
  <v-progress-linear v-if="isLoading"
    class="loading-progress"
    height="23"
    indeterminate
    background-color="#2B2B2B"
    color="white">
  </v-progress-linear>
  <v-row align="center" justify="start" no-gutters v-else
    class="task-progress-wrapper"
    :class="{ 'with-bg': showWrapper }">
    <v-col class="task-progress flex-grow-1 flex-shrink-0">
      <img :src="imageLogoPath" class="image-logo"
        :style="{ 'left': imageLocation }" />
      <span class="progress-inner" :class="{ 'with-border': withBorder && doneStatNumber > 0 }"
        :style="{ 'width': `${doneStatNumber}%` }">
      </span>
    </v-col>
    <v-col class="t-500-16 stats ltr d-inline-block flex-grow-0 flex-shrink-1 pps-2"
      v-if="isValidStats && !hideNumbers">
      {{ finishedTasksCount }} / {{ tasksCount }}
    </v-col>
  </v-row>
</template>
<style lang="scss">
.task-progress-wrapper {
  &.with-bg {
    background-color: #FF947A;
    padding: 8px 4px;
  }

  .stats {
    white-space: nowrap;
    color: white;
  }

  .task-progress {
    position: relative;
    background: linear-gradient(270deg, #2B2B2B 4.02%, #494A4D 96.3%);
    border-radius: 100px;
    height: 23px;
    width: 100%;

    .progress-inner {
      position: absolute;
      height: 23px;
      border-radius: 100px;
      left: 0;
      background-color: white;

      &.with-border {
        border: 1px solid #979797;
      }
    }

    .image-logo {
      top: 50%;
      transform: translateY(-50%);
      position: absolute;
      z-index: 3;
    }
  }
}

.loading-progress {
  border-radius: 100px;
}
</style>
<script>
import { computed } from 'vue'
export default {
  props: {
    showWrapper: {
      type: Boolean,
      default: false
    },
    hideNumbers: {
      type: Boolean,
      default: false
    },
    withBorder: {
      type: Boolean,
      default: false
    },
    tasksCount: {
      type: Number,
      default: 0
    },
    finishedTasksCount: {
      type: Number,
      default: 0
    },
    isLoading: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const imageLogoPath = new URL('@/assets/dex_logo_black.svg', import.meta.url).href

    // Computed
    const isValidStats = computed(() => Number.isInteger(props.tasksCount) && Number.isInteger(props.finishedTasksCount))
    const doneStatNumber = computed(() => {
      if (!isValidStats.value || props.tasksCount === 0) return 0
      return (props.finishedTasksCount / props.tasksCount) * 100
    })

    const imageLocation = computed(() => {
      if (doneStatNumber.value < 5) return '2%'
      return `calc(${doneStatNumber.value}% - 18px)`
    })
    return {
      doneStatNumber,
      imageLogoPath,
      imageLocation,
      isValidStats
    }
  }
}
</script>