<template>
  <v-card class="single-post">
    <v-card-title>
      <single-blog-post-header
        :post="post"
        @delete-post="deletePost"
        @edit-post="editPost">
      </single-blog-post-header>
    </v-card-title>
    <v-card-text>
      <single-blog-post-text :content="post.content"></single-blog-post-text>
      <single-blog-post-images class="mt-5" v-if="showImages"
        :image-urls="imageUrls"
        @image-click="imageClick">
      </single-blog-post-images>
      <video-player v-else-if="showVideo" :video-src="post.video_url"></video-player>
      <v-row align="start" justify="start" no-gutters class="mt-4" v-if="showLinkPreview">
        <v-col cols="12" md="8">
          <link-preview :link-data="post.link"></link-preview>
        </v-col>
      </v-row>
      <single-blog-post-footer
        :post="post"
        class="mt-4"
        @reply-click="replyClick"
        @share-click="shareClick"
        @show-comments-click="loadComments"
        @open-likes-dialog="openLikesDialogClick">
      </single-blog-post-footer>
      <blog-post-comments v-if="showCommentsFeed"
        :post="post"
        @delete-post="deletePostComment"
        @edit-post="editPostComment">
      </blog-post-comments>
    </v-card-text>
  </v-card>
</template>
<style lang="scss">
.single-post {
  border-radius: 8px !important;
}
</style>
<script>
import { isObject, isString } from '@/helpers/Utils.js'

import { ref, computed } from 'vue'
export default {
  components: {
    'single-blog-post-header': () => import('@/components/blog/shared/SingleBlogPostHeader.vue'),
    'single-blog-post-footer': () => import('@/components/blog/single_blog_post/SingleBlogPostFooter.vue'),
    'single-blog-post-images': () => import('@/components/blog/single_blog_post/SingleBlogPostImages.vue'),
    'blog-post-comments': () => import('@/components/blog/BlogPostComments.vue'),
    'single-blog-post-text': () => import('@/components/blog/shared/SingleBlogPostText.vue'),
    'video-player': () => import('@/components/shared/VideoPlayer.vue'),
    'link-preview': () => import('@/components/shared/link_preview/LinkPreview.vue')
  },
  props: {
    post: {
      type: Object,
      required: true
    }
  },
  setup(props, { emit }) {
    const showCommentsFeed = ref(false)
    // Methods

    const replyClick = () => emit('reply-click', props.post)
    const shareClick = () => emit('share-click', props.post)
    const imageClick = (index) => emit('image-click', { imageUrls: imageUrls.value, index })

    const deletePost = () => emit('delete-post', { post: props.post })
    const editPost = () => emit('edit-post', { post: props.post })
    const deletePostComment = (post) => emit('delete-post', { post, parentId: props.post.id })
    const editPostComment = (post) => emit('edit-post', { post, parentId: props.post.id })
    const openLikesDialogClick = () => emit('open-likes-dialog', props.post)
    const loadComments = () => (showCommentsFeed.value = true)

    // Computed
    const showImages = computed(() => Array.isArray(props.post.image_urls) && props.post.image_urls.length > 0)
    const imageUrls = computed(() => props.post.image_urls.map((image) => image.url))
    const showVideo = computed(() => isString(props.post.video_url))
    const showLinkPreview = computed(() => isObject(props.post.link) && isString(props.post.link.url))
    return {
      showImages,
      showVideo,
      showCommentsFeed,
      imageUrls,
      showLinkPreview,
      replyClick,
      shareClick,
      imageClick,
      deletePost,
      editPost,
      deletePostComment,
      editPostComment,
      openLikesDialogClick,
      loadComments
    }
  }
}
</script>
