import Client from '@/api_client/client.js'

export function create (apiKey, operationId, status, reportDate, shiftId) {
  const OPERATION_CREATE_REPORT = `/accounts/${apiKey}/operation_schedules/${operationId}/reported_operation_schedule`
  return Client.post(OPERATION_CREATE_REPORT, { status, report_date: reportDate, shift_id: shiftId })
}

export function destroy (apiKey, operationId, operationReportId) {
  const OPERATION_DELETE_REPORT = `/accounts/${apiKey}/operation_schedules/${operationId}/reported_operation_schedule/${operationReportId}`
  return Client.delete(OPERATION_DELETE_REPORT)
}
