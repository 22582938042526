<template>
  <div class="d-flex flex-column full-width">
    <v-col class="flex-grow-1 flex-shrink-0 mb-15 overflow-y-auto ppe-2 pps-0 pt-0">
      <div v-if="!departmentSelectedUiMode">
        <tasks-progress-with-avatar v-if="showProgressWithAvatar"
          :api-key="apiKey">
        </tasks-progress-with-avatar>
        <tasks-progress v-else
          show-wrapper
          :is-loading="isLoading"
          :tasks-count="stats.tasksCount"
          :finished-tasks-count="stats.finishedTasksCount">
        </tasks-progress>
      </div>
      <single-department v-if="departmentSelectedUiMode"
        class="mt-4 mb-2 mx-2"
        :department="activeDepartment">
      </single-department>
      <jobs-list-layout-big-devices
        :api-key="apiKey"
        :staff-account="staffAccount">
      </jobs-list-layout-big-devices>
    </v-col>
  </div>
</template>
<script>
import JobsListLayoutBigDevices from '@/components/tasks/jobs_list/JobsListLayoutBigDevices.vue'
import SingleDepartment from '@/components/tasks/departments_list/SingleDepartment.vue'
import TasksProgress from '@/components/tasks/TasksProgress.vue'
import TasksProgressWithAvatar from '@/components/tasks/TasksProgressWithAvatar.vue'

import useDailyJobs from '@/composables/useDailyJobs.js'
import useAuth from '@/composables/useAuth.js'

import { computed } from 'vue'
export default {
  components: {
    'jobs-list-layout-big-devices': JobsListLayoutBigDevices,
    'single-department': SingleDepartment,
    'tasks-progress': TasksProgress,
    'tasks-progress-with-avatar': TasksProgressWithAvatar
  },
  props: {
    apiKey: {
      type: String,
      required: true
    },
    staffAccount: {
      type: Boolean,
      default: false,
    }
  },
  setup(props) {
    const { departmentSelectedUiMode, isLoading, stats, activeDepartmentComputed: activeDepartment } = useDailyJobs(props.apiKey)
    const { isUserAccountEmployee, isUserAccountManager, isUserAccountAdmin } = useAuth()

    // Computed
    const showProgressWithAvatar = computed(() => props.staffAccount || isUserAccountEmployee.value ||
      isUserAccountManager.value || isUserAccountAdmin.value)

    return {
      departmentSelectedUiMode,
      showProgressWithAvatar,
      activeDepartment,
      isLoading,
      stats
    }
  }
}
</script>