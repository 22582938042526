<template>
  <v-menu min-width="100" v-if="showComponent">
    <template v-slot:activator="{ on }">
      <v-btn v-on="on" outlined small color="#212121">
        <v-icon color="#212121" class="mr-1">more_vert</v-icon>
        {{ $t('blog_post.actions') }}
      </v-btn>
    </template>
    <v-list>
      <v-list-item v-if="showEdit"
        @click="editClick"
        :disabled="post.editInProgress">
        <v-btn icon small
          :loading="post.editInProgress">
          <v-icon>edit</v-icon>
        </v-btn>
        <v-list-item-content class="px-1">
          {{ $t('edit') }}
        </v-list-item-content>
      </v-list-item>
      <v-list-item v-if="showDelete"
        @click="deleteClick"
        :disabled="post.deleteInProgress">
        <v-btn icon small
          :loading="post.deleteInProgress">
          <v-icon>delete</v-icon>
        </v-btn>
        <v-list-item-content class="px-1">
          {{ $t('delete') }}
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-menu>
</template>
<script>
import useAuth from '@/composables/useAuth.js'
import { computed } from 'vue'
export default {
  props: {
    post: {
      type: Object,
      required: true
    }
  },
  setup(props, { emit }) {
    const { isAccountAdminOrAbove } = useAuth()

    // Methods
    const deleteClick = () => emit('delete-post')
    const editClick = () => emit('edit-post')

    // Computed
    const showDelete = computed(() => props.post.current_user_post || isAccountAdminOrAbove.value)
    const showEdit = computed(() => props.post.current_user_post)
    const showComponent = computed(() => showDelete.value || showEdit.value)
    return {
      showDelete,
      showEdit,
      showComponent,
      deleteClick,
      editClick
    }
  }
}
</script>
