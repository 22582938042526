<template>
  <page-filters
    :any-filter-selected="supplierSelected"
    @apply-filters="applyFilters"
    @reset-filters="clearSearch">
    <supplier-search
      v-model="curentSupplierSearch"
      :api-key="apiKey"
      :menu-props="{ contentClass: 'inside-drawer' }">
    </supplier-search>
  </page-filters>
</template>
<script>

import SupplierSearch from '@/components/shared/supplier_search/SupplierSearch.vue'
import PageFilters from '@/components/shared/PageFilters.vue'

import useAccountSupplier from '@/composables/useAccountSupplier.js'
import { isObject } from '@/helpers/Utils.js'
import { handler as errHandler } from '@/classes/ErrorHandler.js'

import { ref, computed } from 'vue'
export default {
  components: {
    'supplier-search': SupplierSearch,
    'page-filters': PageFilters
  },
  props: {
    apiKey: {
      type: String,
      required: true
    }
  },
  setup (props) {
    const { showSingleSupplier, loadAllSuppliers } = useAccountSupplier(props.apiKey)
    const curentSupplierSearch = ref({})

    // Methods
    const applyFilters = () => {
      const promise = supplierSelected.value === true
        ? showSingleSupplier(curentSupplierSearch.value.id)
        : loadAllSuppliers()
      promise
        .catch(errHandler)
    }

    const clearSearch = () => {
      curentSupplierSearch.value = {}
      applyFilters()
    }

    // Computed
    const supplierSelected = computed(() => isObject(curentSupplierSearch.value) && Number.isInteger(curentSupplierSearch.value.id))
    return {
      curentSupplierSearch,
      supplierSelected,
      applyFilters,
      clearSearch
    }
  }
}
</script>
