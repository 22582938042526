<template>
  <v-row align="start" justify="start" class="notifications-history-filters">
    <v-col cols="12" class="row justify-start align-center">
      <v-btn
        rounded
        @click="clickAll"
        :color="allActive ? '#A1B3E8' : '#CBD5F2'"
        :input-value="allActive"
        :disabled="isLoading"
        dark>
        {{ $t('all') }}
      </v-btn>
      <v-btn
        rounded
        class="mms-2"
        @click="togglePushType('typeTasks')"
        :color="tasksActive ? '#A1B3E8' : '#CBD5F2'"
        :input-value="tasksActive"
        :disabled="isLoading">
        {{ $t('tasks') }}
      </v-btn>
      <v-btn
        rounded
        class="mms-2"
        @click="togglePushType('typeTickets')"
        :color="ticketsActive ? '#A1B3E8' : '#CBD5F2'"
        :input-value="ticketsActive"
        :disabled="isLoading">
        {{ $t('faults') }}
      </v-btn>
      <v-btn
        rounded
        class="mms-2"
        @click="togglePushType('typeBlog')"
        :color="communityActive ? '#A1B3E8' : '#CBD5F2'"
        :input-value="communityActive"
        :disabled="isLoading">
        {{ $t('community') }}
      </v-btn>
      <v-btn
        rounded
        class="mms-2"
        @click="togglePushType('typeChat')"
        :color="chatActive ? '#A1B3E8' : '#CBD5F2'"
        :input-value="chatActive"
        :disabled="isLoading">
        {{ $t('chat') }}
      </v-btn>
      <div class="divider mx-6" v-if="!$vuetify.breakpoint.xsOnly">
      </div>
      <v-col cols="4" lg="2" sm="3" class="pps-0">
        <v-select v-model="pushFilter.viewed" clearable :items="viewOptions" class="filter-select"
          height="24"
          :menu-props="{ offsetY: true }" @change="onFilterChanged" :disabled="isLoading"
          :label="$t('push_history.is_viewed')">
          <template v-slot:item="{ item }">
            <v-row align="center" justify="start" no-gutters :class="{ 'rtl': isRtl }">
              {{ item.text }}
            </v-row>
          </template>
        </v-select>
      </v-col>
      <v-col cols="4" lg="2" sm="3" class="pps-2">
        <v-select v-model="pushFilter.timeFilter" clearable :items="timeOptions" class="filter-select" height="24"
          :menu-props="{ offsetY: true }" @change="onFilterChanged" :disabled="isLoading" :label="$t('time')">
          <template v-slot:item="{ item }">
            <v-row align="center" justify="start" no-gutters :class="{ 'rtl': isRtl }">
              {{ item.text }}
            </v-row>
          </template>
        </v-select>
      </v-col>
    </v-col>
  </v-row>
</template>
<style lang="scss">
.notifications-history-filters {
  .divider {
    width: 1px;
    background-color: #8993AF;
    height: 40px;
    display: inline-block;
  }
}
</style>
<script>
import usePushNotificationsHistory from '@/composables/usePushNotificationsHistory.js'

import { i18n, isRtl } from '@/i18n.js'

import { computed } from 'vue'
export default {
  props: {
    apiKey: {
      type: String,
      required: true
    }
  },
  setup(props) {
    const { isLoading, pushFilter, applyFilters } = usePushNotificationsHistory(props.apiKey)

    const viewOptions = [
      { text: i18n.t('push_history.viewed'), value: true },
      { text: i18n.t('push_history.not_viewed'), value: false }]

    const timeOptions = [
      { text: i18n.t('today'), value: 'today' },
      { text: i18n.t('this_week'), value: 'this_week' },
      { text: i18n.t('older'), value: 'older' }]

    // Methods
    const togglePushType = (type) => {
      pushFilter.value[type] = !pushFilter.value[type]
      onFilterChanged()
    }

    const clickAll = () => {
      if (allActive.value) return

      pushFilter.value.typeTasks = false
      pushFilter.value.typeTickets = false
      pushFilter.value.typeBlog = false
      pushFilter.value.typeChat = false
      onFilterChanged()
    }
    const onFilterChanged = applyFilters
    // Computed
    const tasksActive = computed(() => pushFilter.value.typeTasks === true)
    const ticketsActive = computed(() => pushFilter.value.typeTickets === true)
    const communityActive = computed(() => pushFilter.value.typeBlog === true)
    const chatActive = computed(() => pushFilter.value.typeChat === true)
    const allActive = computed(() => !tasksActive.value && !ticketsActive.value && !communityActive.value && !chatActive.value)

    return {
      allActive,
      tasksActive,
      ticketsActive,
      communityActive,
      chatActive,
      pushFilter,
      isLoading,
      viewOptions,
      timeOptions,
      isRtl,
      clickAll,
      togglePushType,
      onFilterChanged,
    }
  },
};
</script>