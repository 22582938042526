import { index as OperationTypeClientIndex } from '@/api_client/OperationType.js'

import { i18n } from '@/i18n.js'

import { ref, computed } from 'vue'

const STOCK_RELATED_OPERATIONS = {
  stock_order: {},
  stock_receiving: {},
  stock_encoding_and_arrangement: {},
  stock_refund: {},
  stock_receiving_encoding_and_arrangement: { operationNames: ['stock_encoding_and_arrangement', 'stock_receiving'] },
  stock_order_receiving_and_arrangement: { operationNames: ['stock_receiving', 'stock_order', 'stock_encoding_and_arrangement'] },
  stock_order_and_receiving: { operationNames: ['stock_order', 'stock_receiving'] },
  stock_encoding_and_arrangement_and_order: { operationNames: ['stock_encoding_and_arrangement', 'stock_order'] },
  stock_order_and_refund: { operationNames: ['stock_order', 'stock_refund'] },
  stock_encoding_and_arrangement_and_receiving: { operationNames: ['stock_encoding_and_arrangement', 'stock_receiving'] },
  stock_receiving_and_refund: { operationNames: ['stock_receiving', 'stock_refund'] },
  stock_encoding_and_arrangement_and_refund: { operationNames: ['stock_encoding_and_arrangement', 'stock_refund'] },
  stock_encoding_and_arrangement_and_order_and_receiving: { operationNames: ['stock_encoding_and_arrangement', 'stock_order', 'stock_receiving'] },
  stock_order_and_receiving_and_refund: { operationNames: ['stock_order', 'stock_receiving', 'stock_refund'] },
  stock_encoding_and_arrangement_and_receiving_and_refund: { operationNames: ['stock_encoding_and_arrangement', 'stock_receiving', 'stock_refund'] },
  stock_encoding_and_arrangement_and_order_and_refund: { operationNames: ['stock_encoding_and_arrangement', 'stock_order', 'stock_refund'] },
  stock_encoding_and_arrangement_and_order_and_receiving_and_refund: { operationNames: ['stock_encoding_and_arrangement', 'stock_order', 'stock_receiving', 'stock_refund'] }
}

const ROUTINE_OPERATION = 'routine'
const OperationTypeOrder = {
  stock_order: 1,
  stock_receiving: 2,
  stock_encoding_and_arrangement: 3,
  stock_receiving_encoding_and_arrangement: 4,
  stock_order_receiving_and_arrangement: 5,
  stock_order_and_receiving: 6,
  stock_refund: 7
}

OperationTypeOrder[ROUTINE_OPERATION] = -1
let isLoaded = false
const operationTypesInternal = ref([])

// Methods

const translatedOperation = (operationName) => {
  const translatedNameKey = `devices_new_operation.op_type_${operationName}`
  return i18n.t(translatedNameKey)
}

const loadAllOperationTypes = () => {
  if (isLoaded) return
  return OperationTypeClientIndex()
    .then(resp => {
      isLoaded = true
      const operationTypesVal = operationTypesInternal.value
      resp.data
        .map((opType) => {
          const isStockRelatedOp = opType.name in STOCK_RELATED_OPERATIONS
          return { name: opType.name, translatedName: translatedOperation(opType.name), stockOperation: isStockRelatedOp }
        })
        .sort((op1, op2) => {
          const opr1Order = OperationTypeOrder[op1.name] || 0
          const opr2Order = OperationTypeOrder[op2.name] || 0
          if (opr1Order === opr2Order) return 0
          return opr1Order > opr2Order ? 1 : -1
        })
        .forEach((opType) => operationTypesVal.push(opType))
    })
}

const getCompoundOperationType = (operationTypesArg = []) => {
  const opType = operationTypesArg.map((operation) => operation.replace('stock_', '')).sort().join('_and_')
  return `stock_${opType}`
}

const getOperationTypesFromCompoundType = (operationTypeName) => {
  const operationExist = operationTypeName in STOCK_RELATED_OPERATIONS
  if (!operationExist) return null

  const operationType = STOCK_RELATED_OPERATIONS[operationTypeName]
  return operationType.operationNames || [operationTypeName]
}

// Computed

const operationTypes = computed(() => operationTypesInternal.value)
export {
  ROUTINE_OPERATION,
  OperationTypeOrder,
  operationTypes,
  translatedOperation,
  loadAllOperationTypes,
  getCompoundOperationType,
  getOperationTypesFromCompoundType
}
