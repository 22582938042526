<template>
  <v-dialog v-model="dialog" max-width="650" persistent>
  <v-dialog
    v-model="datesDialogOpen"
    persistent
    width="290px">
    <v-date-picker v-model="datePickerValue"
      type="date" scrollable show-current :min="datesDialogMinDate">
      <v-spacer></v-spacer>
      <v-btn text color="primary" @click="cancelDatePicker">{{$t('cancel')}}</v-btn>
      <v-btn depressed tile color="primary" @click="applyDatePicker">{{$t('ok')}}</v-btn>
    </v-date-picker>
  </v-dialog>
    <v-card>
      <v-card-text>
        <v-form ref="newOperationForm">
          <v-container class="pb-0">
            <v-row align="center" justify="start" no-gutters>
              <v-col cols="12" sm="8">
                <job-selection-search v-model="operation.job"
                                      @input="onJobSelect"
                                      :clearable="false"
                                      :api-key="apiKey"
                                      :disabled="isLoadingDaysOfWork"
                                      :label="$t('job_op_schedule.choose_job')">
                </job-selection-search>
              </v-col>
              <v-col cols="3" offset-sm="1">
                <v-select
                  v-model="operation.day"
                  :items="daysOfWork"
                  item-text="translatedName"
                  :disabled="isExectDate || isEveryDay || isEveryYear"
                  item-value="name"
                  single-line
                  :loading="isLoadingDaysOfWork">
                </v-select>
              </v-col>
              <v-col cols="8" sm="5" offset="1" offset-sm="0">
                <v-select
                  v-model="operation.shiftPart"
                  :items="shiftParts"
                  :disabled="isExectDate || isEveryYear"
                  item-text="translateName"
                  item-value="name"
                  single-line>
                </v-select>
              </v-col>
              <v-col cols="12" sm="6" offset-sm="1">
                <v-row align="center" justify="start" no-gutters>
                  <v-col class="flex-grow-1 flex-shrink-0 pr-4">
                    <operation-frequency-select
                      @change="onFrequencyChange"
                      :allowed-frequencies="allowedFrequencies"
                      v-model="operation.frequencyParams"
                      :exect-date="operation.frequencyExectDate"
                      :every-year-dates="[operation.frequencyEveryYear]">
                    </operation-frequency-select>
                  </v-col>
                  <v-col class="flex-grow-0 flex-shrink-1 pt-2">
                    <v-btn icon color="black" @click="openDatesDialog" :disabled="datePickerDisabled">
                      <v-icon>event</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12">
                <v-card-actions class="pt-0 pr-0">
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="close(false)" :disabled="isLoading">
                    {{$t('cancel')}}
                  </v-btn>
                  <v-btn color="blue darken-1" depressed tile @click="save" :loading="isLoading"
                                               :disabled="saveDisabled">
                    {{$t('save')}}
                  </v-btn>
                </v-card-actions>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import OperationFrequencySelect from '@/components/shared/OperationFrequencySelect.vue'

import { operationFrequencyOptionsForSelect, FrequencyTypes } from '@/store/modules/OperationFrequency.js'
import { shiftPartsForSelect as shiftParts } from '@/store/modules/OperationSchedule.js'
import JobSelectionSearch from '@/components/job_selection_search/JobSelectionSearch.vue'

import { getJobWorkingDays } from '@/store/modules/AccountJobWorkDays.js'

import { update as jobOpScheduleUpdate } from '@/api_client/JobOperationSchedule.js'

import { daysOfTheWeek, dateToISO8601String, isValidISO8601Date, translateDay } from '@/helpers/DateTime.js'
import { isObject, isString } from '@/helpers/Utils.js'

import { onDialogClose, onDialogOpen } from '@/classes/DialogScrollBug.js'

function resetDayOfWork () {
  this.daysOfWork.splice(0, this.daysOfWork.length)
}

function assignDaysOfWork (jobDaysOfWork = []) {
  resetDayOfWork.call(this)
  jobDaysOfWork.forEach((dayOfWork) => {
    const day = Object.assign({}, this.dayOfTheWeekObj[dayOfWork])
    this.daysOfWork.push(day)
  })
}

function setOpertionFromParams (dayName, shiftPartName, job, operation) {
  setOperationJob.call(this, job)
  setOperationFrequency.call(this, operation)
  setOperationDay.call(this, dayName)
  this.operation.shiftPart = shiftPartName
}

function setOperationJob ({ id: jobId, name: jobName, shift: jobShift }) {
  this.operation.job = { id: jobId, name: jobName, shiftName: jobShift.name }
}

function setOperationFrequency ({ frequency, frequency_type: frequencyType, operation_next_date: operationNextDate, operation_next_date_raw: operationNextDateRaw }) {
  this.operation.frequencyParams = this.frequencyOptions.find((frequencyOption) => frequency === frequencyOption.frequency &&
                                                frequencyType === frequencyOption.frequencyType)

  if (!isObject(this.operation.frequencyParams)) {
    this.operation.frequencyParams = { frequency: null, frequencyType: null }
  }
  const opFrequencyType = this.operation.frequencyParams.frequencyType
  if (opFrequencyType !== FrequencyTypes.EXECT_DATE && opFrequencyType !== FrequencyTypes.EVERY_YEAR) return

  this.operation.frequencyExectDate = isString(operationNextDateRaw) ? operationNextDateRaw : operationNextDate
  this.operation.frequencyEveryYear = this.operation.frequencyExectDate
}

function setOperationDay (dayName) {
  const day = Object.assign({}, this.dayOfTheWeekObj[dayName])
  this.operation.day = day.name
}

function defaultOperation () {
  const frequencyOptions = this.frequencyOptions || operationFrequencyOptionsForSelect()
  return {
    job: null,
    frequencyParams: frequencyOptions.find((frequency) => frequency.default),
    shiftPart: null,
    frequencyExectDate: null,
    frequencyEveryYear: null,
    day: null
  }
}

function loadJobWorkingDays (jobId) {
  this.isLoadingDaysOfWork = true
  return getJobWorkingDays(this.apiKey, jobId)
    .then((jobDaysOfWork) => assignDaysOfWork.call(this, jobDaysOfWork))
    .catch((err) => this.errorCapture(err))
    .finally(() => (this.isLoadingDaysOfWork = false))
}

function getRequestParams () {
  const requestParams = {
    frequency: this.operation.frequencyParams.frequency,
    frequency_type: this.operation.frequencyParams.frequencyType,
    executor_id: this.operation.job.id,
    shift_part: this.operation.shiftPart,
    day: this.operation.day
  }
  const frequencyType = this.operation.frequencyParams.frequencyType
  if (frequencyType === FrequencyTypes.ExectDate) {
    requestParams.frequency = this.operation.frequencyExectDate
    delete requestParams.day
  } else if (frequencyType === FrequencyTypes.EveryYear) {
    requestParams.frequency = this.operation.frequencyEveryYear
    delete requestParams.day
  } else if (this.operation.frequencyParams.frequencyType === FrequencyTypes.EveryDay) {
    delete requestParams.day
  }
  return requestParams
}

function setDayForNewJob () {
  const currentOperationDay = this.operation.day
  if (!isString(currentOperationDay)) return
  const jobHaveDay = this.daysOfWork.some((dayOfWork) => dayOfWork.name === currentOperationDay)

  if (!jobHaveDay) this.operation.day = null
}

function isChanged (requestParams) {
  return ['frequency', 'frequency_type', 'executor_id', 'shift_part_name', 'day']
    .some((requestField) => requestParams[requestField] !== this.orginalOperation[requestField])
}

export default {
  created () {
    this.dayOfTheWeekObj = {}
    daysOfTheWeek().forEach((dayOfWeek) => (this.dayOfTheWeekObj[dayOfWeek.name] = dayOfWeek))
  },
  props: {
    apiKey: {
      type: String,
      required: true
    }
  },
  components: {
    'job-selection-search': JobSelectionSearch,
    'operation-frequency-select': OperationFrequencySelect
  },
  computed: {
    isJobSelected () {
      const jobId = (this.operation.job || {}).id
      return Number.isInteger(jobId)
    },
    saveDisabled () {
      if (this.isLoading) return true
      if (!this.isJobSelected) return true
      if (!isString(this.operation.day) && !(this.isExectDate || this.isEveryDay || this.isEveryYear)) return true
      if (this.isExectDate && !isValidISO8601Date(this.operation.frequencyExectDate)) return true
      if (this.isEveryYear && !isValidISO8601Date(this.operation.frequencyEveryYear)) return true
      return false
    },
    isExectDate () {
      if (!isObject(this.operation.frequencyParams)) return false
      return this.operation.frequencyParams.frequencyType === FrequencyTypes.ExectDate
    },
    isEveryYear () {
      if (!isObject(this.operation.frequencyParams)) return false
      return this.operation.frequencyParams.frequencyType === FrequencyTypes.EveryYear
    },
    datePickerDisabled () {
      return !this.isExectDate && !this.isEveryYear
    },
    isEveryDay () {
      if (!isObject(this.operation.frequencyParams)) return false
      return this.operation.frequencyParams.frequencyType === FrequencyTypes.EveryDay
    },
    operationJobName () {
      const operation = this.operation || {}
      const job = operation.job || {}
      return job.name
    },
    allowedFrequencies () {
      const allowedFrequencies = Object.values(FrequencyTypes)
      if (!this.isSupplierOperation) return allowedFrequencies
      return allowedFrequencies.filter((frequency) => frequency !== FrequencyTypes.ExectDate &&
      frequency !== FrequencyTypes.EveryDay && frequency !== FrequencyTypes.EveryYear)
    }
  },
  methods: {
    openDialog (dayName, shiftPartName, job, operation) {
      onDialogOpen()
      loadJobWorkingDays.call(this, job.id)
      setOpertionFromParams.call(this, dayName, shiftPartName, job, operation)
      this.operationId = Number.isInteger(operation.id) ? operation.id : operation.operation_id
      this.originalJobId = job.id
      this.orginalOperation = getRequestParams.call(this)
      this.dialog = true
      this.isSupplierOperation = operation.item_type === 'AccountSupplier'
      return new Promise((resolve, reject) => {
        this.resolve = resolve
      })
    },
    onJobSelect () {
      if (!this.isJobSelected) {
        resetDayOfWork.call(this)
        return
      }
      const currentJobId = this.operation.job.id
      loadJobWorkingDays.call(this, currentJobId).then(() => setDayForNewJob.call(this))
    },
    cancelDatePicker () {
      this.datesDialogOpen = false
    },
    applyDatePicker () {
      this.datesDialogOpen = false
      if (this.isExectDate) {
        this.operation.frequencyExectDate = this.datePickerValue
      } else {
        this.operation.frequencyEveryYear = this.datePickerValue
      }
    },
    openDatesDialog () {
      this.datesDialogOpen = true
      this.datePickerValue = this.isExectDate ? this.operation.frequencyExectDate : this.operation.frequencyEveryYear
    },
    onFrequencyChange (newFrequency) {
      if (!this.isExectDate && !this.isEveryYear) return
      this.openDatesDialog()
    },
    close (isUpdated) {
      onDialogClose()
      this.dialog = false
      this.operationId = null
      this.originalJobId = null
      this.operation = defaultOperation.call(this)
      this.$refs.newOperationForm.reset()
      this.resolve(isUpdated)
    },
    save () {
      const requestParams = getRequestParams.call(this)
      if (!isChanged.call(this, requestParams)) {
        this.close(false)
        return
      }
      this.isLoading = true
      jobOpScheduleUpdate(this.apiKey, this.originalJobId, this.operationId, requestParams)
        .then(() => this.close(true))
        .catch((err) => this.errorCapture(err))
        .finally(() => (this.isLoading = false))
    },
    translateDay
  },
  data () {
    const datesDialogMinDate = dateToISO8601String(new Date())
    return {
      frequencyOptions: operationFrequencyOptionsForSelect(),
      shiftParts: shiftParts(),
      daysOfWork: [],
      operation: defaultOperation.call(this),
      dialog: false,
      isLoadingDaysOfWork: false,
      datesDialogOpen: false,
      datesDialogMinDate,
      isLoading: false,
      isSupplierOperation: false,
      datePickerValue: null
    }
  }
}
</script>
