<template>
  <v-menu close-on-content-click v-if="showMoreSelect">
    <template v-slot:activator="{ on }">
      <v-icon v-on="on" color="black">more_vert</v-icon>
    </template>
    <v-list :class="{ 'rtl': isRtl, 'ltr': !isRtl }">
      <v-list-item ripple v-if="showEditItem" @click="onEdit">
        <v-list-item-content class="px-1">
          {{ $t('edit') }}
        </v-list-item-content>
        <v-btn icon small>
          <v-icon small>edit</v-icon>
        </v-btn>
      </v-list-item>
      <v-list-item ripple v-if="showDeleteItem" @click="onDelete">
        <v-list-item-content class="px-1">
          {{ $t('delete') }}
        </v-list-item-content>
        <v-btn icon small>
          <v-icon small>delete</v-icon>
        </v-btn>
      </v-list-item>
      <v-list-item ripple v-if="showCopyItem" @click="onCopy">
        <v-list-item-content class="px-1">
          {{ $t('copy') }}
        </v-list-item-content>
        <v-btn icon small>
          <v-icon small>content_copy</v-icon>
        </v-btn>
      </v-list-item>
      <v-list-item ripple v-if="taskEnabledOptions.share" @click="onShare">
        <v-list-item-content class="px-1">
          {{ $t('share') }}
        </v-list-item-content>
        <v-btn icon small>
          <v-icon small>share</v-icon>
        </v-btn>
      </v-list-item>
      <v-list-item ripple v-if="taskEnabledOptions.notification" @click="onNotify">
        <v-list-item-content class="px-1">
          {{ $t('notifications') }}
        </v-list-item-content>
        <v-btn icon small>
          <v-icon small>notifications</v-icon>
        </v-btn>
      </v-list-item>
    </v-list>
  </v-menu>
</template>
<style lang="scss" scoped>
.edit-operation {
  .v-list-item__icon {
    margin-right: 12px !important;
  }
}
</style>
<script>
import { FrequencyTypes } from '@/store/modules/OperationFrequency.js'
import { ISO8601StringToDate, isDateInThePast } from '@/helpers/DateTime.js'

import { computed } from 'vue'
import { isRtl } from '@/i18n'
export default {
  props: {
    task: {
      type: Object,
      required: true
    },
    taskEnabledOptions: {
      type: Object,
      required: true
    }
  },
  setup(props, { emit }) {
    // Methods
    const onEdit = () => emit('edit-click')
    const onDelete = () => emit('delete-click')
    const onCopy = () => emit('copy-click')
    const onShare = () => emit('share-click')
    const onNotify = () => emit('notify-click')

    // Computed
    const showDeleteItem = computed(() => props.taskEnabledOptions.delete === true && !props.task.operationRef.staff_operation)
    const showEditItem = computed(() => props.taskEnabledOptions.edit === true && !props.task.operationRef.staff_operation)
    const showCopyItem = computed(() => {
      const task = props.task.operationRef
      if (props.taskEnabledOptions.copy !== true || task.item_type !== 'Item' || task.staff_operation) return false
      if (task.frequency_type !== FrequencyTypes.ExectDate && task.frequency_type !== FrequencyTypes.EveryYear) return true

      const dateIso = task.operation_next_date_raw || task.first_scheduled_date
      const date = ISO8601StringToDate(dateIso)
      return !isDateInThePast(date)
    })

    const showMoreSelect = computed(() => showCopyItem.value || showDeleteItem.value || showEditItem.value || props.taskEnabledOptions.share || props.taskEnabledOptions.notification)
    return {
      isRtl,
      showCopyItem,
      showDeleteItem,
      showEditItem,
      showMoreSelect,
      onEdit,
      onDelete,
      onCopy,
      onShare,
      onNotify
    }
  }
}
</script>
