import { createToken } from '@/api_client/Token.js'
import axios from 'axios'
const REPORT_CREATE_SCOPE = 'single_operation_report'
const create = async ({ data, apiKey }) => {
  const url = import.meta.env.VITE_APP_CREATE_REPORT_URL
  const requestParams = { scope: REPORT_CREATE_SCOPE, apiKey, data: { id: data.id } }
  const response = await createToken(requestParams)
  const token = response.data.data.token
  return axios.post(`${url}?token=${token}`, data,
    {
      headers: {
        'Content-Type': 'application/json'
      }
    })
}

export {
  create
}
