<template>
  <v-dialog v-model="dialog"
    :max-width="$vuetify.breakpoint.xsOnly ? '350' : '600'">
    <task-actions v-if="dialog"
      :api-key="apiKey">
    </task-actions>
    <v-card class="job-operations"
      :class="{ 'rtl': isRtl, 'ltr': !isRtl }">
      <v-card-text class="pa-0 scrollable">
        <v-row align="start" justify="start">
          <v-col cols="12" v-if="showLoading">
            <v-skeleton-loader v-for="i in 2" :key="i" class="mt-3"
              type="list-item-three-line">
            </v-skeleton-loader>
          </v-col>
          <v-col cols="12" v-else>
            <v-row align="start" justify="start" class="mt-4">
              <v-col cols="12">
                <single-job
                  :job="jobRef"
                  :staff-account="staffAccount"
                  @toggle-shift-complete="onToggleJobShiftComplete">
                </single-job>
              </v-col>
              <v-col cols="12">
                <tasks-list-layout
                  :api-key="apiKey"
                  :staff-account="staffAccount">
                </tasks-list-layout>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>

      <v-row justify="center" class="pt-2 pb-4">
        <v-btn color="#0040FF" depressed tile @click="close">
          <span class="white--text">
            {{ $t('close') }}
          </span>
        </v-btn>
      </v-row>
    </v-card>
  </v-dialog>
</template>
<style lang="scss">
.job-operations {
  .scrollable {
    overflow: auto;
    height: 100%;
    max-height: 65vh;

    @media only screen and (max-width: 600px) {
      max-height: 75vh;
    }
  }
}
</style>
<script>
import TasksListLayout from '@/components/tasks/tasks_list/TasksListLayout.vue'
import SingleJob from '@/components/tasks/jobs_list/SingleJob.vue'
import TaskActions from '@/components/tasks/TaskActions.vue'

import useDailyJobs from '@/composables/useDailyJobs.js'
import { isRtl } from '@/i18n'
import { ref, computed } from 'vue'
export default {
  props: {
    apiKey: {
      type: String,
      required: true
    },
    staffAccount: {
      type: Boolean,
      default: false
    }
  },
  components: {
    'single-job': SingleJob,
    'tasks-list-layout': TasksListLayout,
    'task-actions': TaskActions
  },
  setup(props) {
    let jobId = null
    let shouldReloadOnClose = false
    let resolveFunc = null
    let lastUiMode = null
    const isLoading = ref(false)
    const jobRef = ref({})

    const jobOperations = ref(null)
    const dialog = ref(false)
    const eventDate = ref(null)

    const loadjobOperations = async () => {
      isLoading.value = true
      const { jobsList, loadJobForCalendar, uiMode, uiModes } = useDailyJobs(props.apiKey)
      lastUiMode = uiMode.value
      uiMode.value = uiModes.jobs
      await loadJobForCalendar({ jobId, date: eventDate.value })
      isLoading.value = false
      const newJob = JSON.parse(JSON.stringify(jobsList.value[0]))
      jobRef.value = { ...newJob }
    }

    // Methods
    const close = () => {
      dialog.value = false
      eventDate.value = null
      jobId = null

      isLoading.value = false
      jobRef.value = {}
      const { uiMode } = useDailyJobs(props.apiKey)
      uiMode.value = lastUiMode
      resolveFunc({ shouldReload: shouldReloadOnClose })
    }

    const openDialog = ({ jobId: selectedJobId, date }) => {
      jobId = selectedJobId
      shouldReloadOnClose = false

      isLoading.value = true
      eventDate.value = date
      dialog.value = true
      loadjobOperations()
      return new Promise((resolve, reject) => {
        resolveFunc = resolve
      })
    }

    const onToggleJobShiftComplete = () => { }
    // Computed

    const showLoading = computed(() => isLoading.value && Number.isInteger(jobRef.value.id))
    return {
      dialog,
      jobOperations,
      showLoading,
      jobRef,
      isRtl,
      openDialog,
      close,
      onToggleJobShiftComplete
    }
  }

}
</script>
