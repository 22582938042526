import useItemCategorySearchStore from '@/components/shared/item_category_search/useItemCategorySearchStore.js'
import { isString } from '@/helpers/Utils.js'
import Client from '@/api_client/client.js'

async function create (apiKey, params) {
  const ITEM_CATEGORY_CREATE_PATH = `/accounts/${apiKey}/item_categories`
  const response = await Client.post(ITEM_CATEGORY_CREATE_PATH, params)
  invalidItemSearchCache(apiKey)
  return response
}

async function update (apiKey, id, params) {
  const ITEM_CATEGORY_UPDATE_PATH = `/accounts/${apiKey}/item_categories/${id}/`
  const response = await Client.put(ITEM_CATEGORY_UPDATE_PATH, params)
  invalidItemSearchCache()
  return response
}

async function destroy (apiKey, id) {
  const ITEM_CATEGORY_DELETE_PATH = `/accounts/${apiKey}/item_categories/${id}/`
  const response = await Client.delete(ITEM_CATEGORY_DELETE_PATH)
  invalidItemSearchCache()
  return response
}

function search (apiKey, { term = null } = {}) {
  const ITEM_CATEGORY_SEARCH_PATH = `/accounts/${apiKey}/item_categories/search/`
  const requestParams = {}
  if (isString(term)) {
    requestParams.term = term
  }
  return Client.get(ITEM_CATEGORY_SEARCH_PATH, { params: requestParams })
}

function invalidItemSearchCache (apiKey) {
  const { invalidateCache } = useItemCategorySearchStore(apiKey)
  invalidateCache()
}

export {
  search,
  create,
  update,
  destroy
}
