import Client from '@/api_client/client.js'

function show (apiKey) {
  const BROKEN_ITEM_SUBDOMAIN_SHOW_PATH = `/accounts/${apiKey}/broken_item_anonymous_create_sub_domains`
  return Client.get(BROKEN_ITEM_SUBDOMAIN_SHOW_PATH)
}

function upsert (apiKey, name) {
  const BROKEN_ITEM_SUBDOMAIN_UPSERT_PATH = `/accounts/${apiKey}/broken_item_anonymous_create_sub_domains/upsert`
  return Client.put(BROKEN_ITEM_SUBDOMAIN_UPSERT_PATH, { sub_domain: name })
}

function destroy (apiKey) {
  const BROKEN_ITEM_SUBDOMAIN_DESTROY_PATH = `/accounts/${apiKey}/broken_item_anonymous_create_sub_domains`
  return Client.delete(BROKEN_ITEM_SUBDOMAIN_DESTROY_PATH)
}

export {
  show,
  upsert,
  destroy
}
