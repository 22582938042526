import Client from '@/api_client/client.js'

const createToken = (apiKey) => {
  const USER_LIVE_CONNECTION_CREATE_PATH = `/accounts/${apiKey}/user_live_connections/create_token`
  return Client.post(USER_LIVE_CONNECTION_CREATE_PATH)
}

export {
  createToken
}
