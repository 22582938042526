import { isObject } from '@/helpers/Utils.js'
import router from '@/router.js'

const urlRegEx = /(https?:\/\/)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi
const objectToUrlParams = (obj) => {
  if (!isObject(obj)) return ''

  return Object.entries(obj).map(([key, val]) => `${key}=${encodeURIComponent(val)}`).join('&')
}

const getPageQueryParamsObject = () => {
  const search = location.search.substring(1)
  if (search.length === 0) return {}

  return JSON.parse('{"' + search.replace(/&/g, '","')
    .replace(/=/g, '":"') + '"}', function (key, value) { return key === '' ? value : decodeURIComponent(value) })
}

const deleteQueryParamsFromUrl = () => window.history.replaceState({}, document.title, router.history.current.path)

const findAllLinks = (text) => {
  const links = text.match(urlRegEx)
  return links !== null ? [...links] : null
}

const hasAnyLinks = (text) => urlRegEx.test(text)

const domainNameFromUrl = (url) => {
  try {
    const urlObj = new URL(url)
    return urlObj.hostname
  } catch (error) {
    return url
  }
}

export {
  urlRegEx,
  objectToUrlParams,
  getPageQueryParamsObject,
  deleteQueryParamsFromUrl,
  findAllLinks,
  hasAnyLinks,
  domainNameFromUrl
}
