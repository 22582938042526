<template>
  <v-dialog v-model="dialog" max-width="600" persistent>
    <date-picker-dialog ref="datePickerDialog" @change="applyDatePicker" @clear="deleteDate" v-model="userBirthDateObj"
      :max-date="maxDate" :show-current-date-button="false" :show-delete-date-button="true"
      :disabled="isLoading"></date-picker-dialog>
    <v-card class="employee-dialog">
      <v-card-title class="text-center pb-0">
        <span class="headline full-width">
          {{ dialogTitle }}
        </span>
      </v-card-title>
      <v-card-text class="scrollable">
        <v-form ref="newEmployeeForm">
          <v-container>
            <v-row align="start" justify="space-around" no-gutters>
              <v-col cols="12" sm="5">
                <v-textarea :rules="[requiredField]" counter="80" class="required" v-model="newEmployee.first_name"
                  :label="$t('employees_dialog.first_name')" auto-grow no-resize rows="1">
                </v-textarea>
              </v-col>
              <v-col cols="12" sm="5">
                <v-textarea :rules="[requiredField]" counter="80" class="required" v-model="newEmployee.last_name"
                  :label="$t('employees_dialog.last_name')" auto-grow no-resize rows="1">
                </v-textarea>
              </v-col>
              <v-col cols="12" sm="5">
                <v-text-field single-line counter="320" v-model="newEmployee.email" :label="$t('email')">
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="5">
                <v-text-field single-line :rules="[requiredField]" counter="20" class="required"
                  v-model="newEmployee.phone" v-mask="'####################'" :label="$t('employees_dialog.phone')">
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="5">
                <v-select v-model="newEmployee.role" :items="availableRoles" item-text="translatedName"
                  item-value="name"
                  class="required" single-line :rules="[requiredField]"
                  :label="$t('employees_dialog.permission')"></v-select>
              </v-col>
              <v-col cols="12" sm="5" v-if="showAvailableJobSelect">
                <job-selection-search multiple :api-key="apiKey" :hide-shift="staffOnlyRoute"
                  :search-for-operation="(isUserAccountManager || staffOnlyRoute)" v-model="allowedJobs"
                  :label="$t('employees_dialog.choose_jobs_placeholder')">
                </job-selection-search>
              </v-col>
              <v-col cols="12" sm="5" v-if="showCompanyDistrictSelect" class="mt-4">
                <company-district-select
                  v-model="allowedCompanyDistricts"
                  :show-empty-area="false"
                  :outlined="false"
                  :api-key="apiKey">
                </company-district-select>
              </v-col>
              <v-col cols="12" sm="5" v-if="showDepartmentSelect">
                <account-department-select v-model="accountDepartments" :api-key="apiKey"
                  :is-required="showDepartmentSelect" multiple>
                </account-department-select>
              </v-col>
              <v-col cols="12" sm="5" v-if="!staffOnlyRoute">
                <v-text-field v-model="userBirthDateClientDateFormat" :label="$t('employees_dialog.birth_date')"
                  @click="openDatePicker" readonly>
                  <v-icon slot="prepend" color="pink">
                    cake
                  </v-icon>
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="5" v-if="showFeatureSelect">
                <employee-feature-select v-model="userFeatures">
                </employee-feature-select>
              </v-col>
              <v-col sm="5" v-if="(showAvailableJobSelect || showDepartmentSelect) && $vuetify.breakpoint.smAndUp">
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
      <v-card-actions class="pb-3 pr-8 white">
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="close" :disabled="isLoading">
          {{ $t('cancel') }}
        </v-btn>
        <v-btn color="blue darken-1" depressed tile @click="save" :loading="isLoading" :disabled="isSaveDisabled">
          {{ $t('save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-snackbar v-model="errorSnackBar" :timeout="3000">
      <div class="text-center">
        {{ $t('employees_dialog.duplicate_phone') }}
      </div>
    </v-snackbar>
  </v-dialog>
</template>
<style lang="scss">
.employee-dialog {
  @media only screen and (max-width: 600px) {
    .scrollable {
      overflow: auto;
      height: 100%;
      max-height: 400px;
    }
  }
}
</style>
<script>

import { newEmployeeModel } from '@/store/modules/Employee.js'
import { accountAdminAndBelow, accountManagerAndBelow, regionAdminAndAbove } from '@/store/modules/EmployeeRole.js'

import CompanyDistrictSelect from '@/components/shared/CompanyDistrictSelect.vue'
import useEmployeeStore from '@/components/organization_structure/employee/useEmployeeStore.js'
import EmployeeFeatureSelect from '@/components/organization_structure/employee/EmployeeFeatureSelect.vue'

import JobSelectionSearch from '@/components/job_selection_search/JobSelectionSearch.vue'
import AccountDepartmentSelect from '@/components/shared/account_department/AccountDepartmentSelect.vue'
import DatePickerDialog from '@/components/shared/DatePickerDialog.vue'

import useAuth from '@/composables/useAuth.js'
import useAccount from '@/composables/useAccount.js'

import { dateToISO8601String, isValidISO8601Date, getClientDateFormatted, ISO8601StringToDate } from '@/helpers/DateTime.js'
import { requiredField } from '@/classes/InputValidators.js'
import { onDialogClose, onDialogOpen } from '@/classes/DialogScrollBug.js'

import { isString } from '@/helpers/Utils.js'

import { handler as errHandler } from '@/classes/ErrorHandler.js'

import { i18n } from '@/i18n.js'

import { mask } from 'vue-the-mask'

import { useRoute } from 'vue-router/composables'

import { ref, computed, nextTick } from 'vue'

const DialogMode = { create: 0, update: 1 }

export default {
  components: {
    'job-selection-search': JobSelectionSearch,
    'account-department-select': AccountDepartmentSelect,
    'date-picker-dialog': DatePickerDialog,
    'employee-feature-select': EmployeeFeatureSelect,
    'company-district-select': CompanyDistrictSelect
  },
  props: {
    apiKey: {
      type: String,
      required: true
    },
    allowSetFeatures: {
      type: Boolean,
      required: true
    }
  },
  directives: {
    mask
  },
  setup(props) {
    const maxDateObj = new Date()
    maxDateObj.setDate(maxDateObj.getDate() - 1)
    let dialogMode = null
    let oldFeatures = []
    const dialog = ref(false)
    const newEmployee = ref(newEmployeeModel({}))
    const isLoading = ref(false)
    const allowedJobs = ref([])
    const allowedCompanyDistricts = ref([])
    const accountDepartments = ref([])
    const errorSnackBar = ref(false)
    const maxDate = ref(dateToISO8601String(maxDateObj))
    const userBirthDateClientDateFormat = ref(null)
    const userBirthDateObj = ref(null)
    const newEmployeeForm = ref(null)
    const datePickerDialog = ref(null)
    const userFeatures = ref([])
    const { isUserAccountManager } = useAuth()
    const { createUser, updateUser, featureForUser, updateUserFeatures } = useEmployeeStore(props.apiKey)
    const { accountCountryCode } = useAccount()
    const router = useRoute()

    const setBirthDate = () => {
      const birthDate = newEmployee.value.birthdate
      if (!isValidISO8601Date(birthDate)) {
        userBirthDateObj.value = null
        userBirthDateClientDateFormat.value = null
        return
      }
      userBirthDateObj.value = ISO8601StringToDate(birthDate)
      const countryCode = accountCountryCode.value
      userBirthDateClientDateFormat.value = getClientDateFormatted({ countryCode, fromDate: userBirthDateObj.value })
    }

    const featuresChanged = () => {
      const newFeatures = userFeatures.value
      const isFeatureRemainTheSame = oldFeatures.length === newFeatures.length && oldFeatures.every((value, index) => value === newFeatures[index])
      return !isFeatureRemainTheSame
    }

    const openDialog = (employee, dialogModeVal) => {
      onDialogOpen()
      dialog.value = true
      dialogMode = dialogModeVal
      newEmployee.value = newEmployeeModel(employee)

      allowedJobs.value = (newEmployee.value.user_allowed_jobs || []).map((allowedJob) => allowedJob.id)
      allowedCompanyDistricts.value = (newEmployee.value.user_allowed_company_districts || [])
      accountDepartments.value = newEmployee.value.account_department_ids
      setBirthDate()
    }

    const userRequestParams = () => {
      const requestParams = newEmployee.value.serialize()
      if (showAvailableJobSelect.value) {
        requestParams.allowed_job_ids = allowedJobs.value
      }
      if (showCompanyDistrictSelect.value) {
        requestParams.allowed_company_district = allowedCompanyDistricts.value
      }
      if (showDepartmentSelect.value) {
        requestParams.account_department_ids = accountDepartments.value
          .filter((department) => Number.isInteger(department) && department > 0)
      }
      return requestParams
    }

    const requestUpdateParams = () => {
      const creationParams = userRequestParams()
      if (!staffOnlyRoute.value) {
        creationParams.role = newEmployee.value.role
      }
      return { apiKey: props.apiKey, user: creationParams, userId: newEmployee.value.id }
    }

    const showErrors = (errors) => {
      if (!Array.isArray(errors)) return
      if (!errors.some((err) => err === 'phone_taken')) return
      errorSnackBar.value = true
    }

    const updateEmployee = () => {
      const requestParams = requestUpdateParams()
      return updateUser(requestParams)
        .catch((err) => {
          showErrors(err?.response?.data?.errors)
          errHandler(err)
          return { error: true }
        })
        .finally(() => (isLoading.value = false))
    }

    const requestCreateParams = () => {
      const creationParams = userRequestParams()
      creationParams.role = newEmployee.value.role
      return { apiKey: props.apiKey, user: creationParams }
    }

    const createNewEmployee = () => {
      const requestParams = requestCreateParams()
      return createUser(requestParams)
        .catch((err) => {
          showErrors(err?.response?.data?.errors)
          errHandler(err)
          return { error: true }
        })
        .finally(() => (isLoading.value = false))
    }
    // Methods

    const openForUpdate = (employee) => {
      openDialog(employee, DialogMode.update)
      if (showFeatureSelect.value) {
        oldFeatures = featureForUser(employee.id)
        userFeatures.value = oldFeatures.slice()
      }
    }

    const openDatePicker = () => datePickerDialog.value.openDialog()
    const openForCreate = (isStaff) => {
      const employee = {}
      if (!isStaff) {
        userFeatures.value = ['broken_item_close', 'broken_item_create', 'broken_item_update']
      }
      return openDialog(employee, DialogMode.create)
    }
    const close = () => {
      onDialogClose()
      dialogMode = null
      dialog.value = false
      allowedJobs.value = []
      accountDepartments.value = []
      newEmployeeForm.value.reset()
      userFeatures.value = []
      oldFeatures = []
    }

    const applyDatePicker = (birthdate) => {
      newEmployee.value.birthdate = dateToISO8601String(birthdate)
      setBirthDate()
    }

    const deleteDate = () => {
      newEmployee.value.birthdate = null
      setBirthDate()
    }

    const updateUserImp = async () => {
      let userId = newEmployee.value.id
      if (showFeatureSelect.value === true && featuresChanged() && Number.isInteger(userId)) {
        await updateUserFeatures(userId, userFeatures.value)
      }
      return updateEmployee()
    }

    const createUserImp = async () => {
      const newUser = await createNewEmployee()
      const userId = newUser?.id
      if (showFeatureSelect.value === true && userFeatures.value.length > 0 && Number.isInteger(userId)) {
        await updateUserFeatures(userId, userFeatures.value)
      }
      return newUser
    }

    const save = async () => {
      if (!newEmployeeForm.value.validate()) return

      isLoading.value = true
      let isError = false
      const response = dialogMode === DialogMode.update ? await updateUserImp() : await createUserImp()
      isLoading.value = false
      isError = response?.error

      if (isError) return
      close()
    }

    // Computed

    const dialogTitle = computed(() => dialogMode === DialogMode.update ? i18n.t('employees_dialog.update_title') : i18n.t('employees_dialog.create_title'))
    const showCompanyDistrictSelect = computed(() => newEmployee.value.isCompanyRegionAdmin())
    const staffOnlyRoute = computed(() => router.meta.staffOnly)
    const showAvailableJobSelect = computed(() => newEmployee.value.isAccountEmployee() || staffOnlyRoute.value)
    const showDepartmentSelect = computed(() => newEmployee.value.isAccountManager())
    const availableRoles = computed(() => {
      if (staffOnlyRoute.value) return regionAdminAndAbove()
      return isUserAccountManager.value ? accountManagerAndBelow() : accountAdminAndBelow()
    })
    const showFeatureSelect = computed(() => props.allowSetFeatures && (newEmployee.value.isAccountManager() || newEmployee.value.isAccountEmployee()))
    const isSaveDisabled = computed(() => {
      if (!newEmployee.value.valid()) return true
      if (isLoading.value) return true
      if (!newEmployee.value.hasPhoneNumber()) return true
      if (newEmployee.value.isAccountManager() && !accountDepartments.value.length > 0) return true
      if (newEmployee.value.isCompanyRegionAdmin() && !allowedCompanyDistricts.value.length > 0) return true
      return false
    })
    return {
      dialog,
      newEmployee,
      isLoading,
      allowedJobs,
      allowedCompanyDistricts,
      accountDepartments,
      errorSnackBar,
      maxDate,
      userBirthDateClientDateFormat,
      userBirthDateObj,
      newEmployeeForm,
      datePickerDialog,
      dialogTitle,
      isSaveDisabled,
      staffOnlyRoute,
      showDepartmentSelect,
      availableRoles,
      showAvailableJobSelect,
      showCompanyDistrictSelect,
      isUserAccountManager,
      showFeatureSelect,
      userFeatures,
      openForUpdate,
      openForCreate,
      close,
      applyDatePicker,
      deleteDate,
      openDatePicker,
      save,
      requiredField
    }
  }
}
</script>
