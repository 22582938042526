<template>
  <span class="d-relative media-preview-container">
    <span class="counter" v-if="showCounter">
      {{ mediaUrls.length }}
    </span>
    <v-img v-if="showImagePreview"
      :src="imagePreviewUrl" contain height="65" width="65" class="pointer grey darken-4 image-preview">
      <template v-slot:placeholder>
        <v-row align="center" justify="center" class="fill-height" no-gutters>
          <v-progress-circular indeterminate color="primary"></v-progress-circular>
        </v-row>
      </template>
    </v-img>
    <div class="video-preview pointer" v-else>
      <video>
        <source :src="videoPreviewUrl">
        Your browser does not support the video tag.
      </video>
    </div>
  </span>
</template>
<style lang="scss">
.media-preview-container {
  position: relative;

  .image-preview {
    border-radius: 12px;
  }

  .video-preview {
    border-radius: 12px;
    width: 65px;
    height: 65px;
    overflow: hidden;

    video {
      width: 65px;
      height: 65px;
    }
  }

  .counter {
    z-index: 6;
    position: absolute;
    bottom: 6px;
    right: 6px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: white;
    color: black;
    font-size: 12px;
    text-align: center;
    line-height: 20px;
  }
}
</style>
<script>
import { isString } from '@/helpers/Utils.js'
import { computed } from 'vue'
export default {
  props: {
    mediaUrls: {
      type: Array,
      required: true
    }
  },
  setup(props) {
    const imagePreviewUrl = computed(() => props.mediaUrls.find((media) => media.type === 'image')?.url)
    const videoPreviewUrl = computed(() => props.mediaUrls.find((media) => media.type === 'video')?.url)
    const showImagePreview = computed(() => isString(imagePreviewUrl.value))
    const showCounter = computed(() => props.mediaUrls.length > 1)
    return {
      imagePreviewUrl,
      videoPreviewUrl,
      showImagePreview,
      showCounter
    }
  }
}
</script>