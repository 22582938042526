<template>
  <v-menu offset-y>
    <template v-slot:activator="{ on, attrs }">
      <v-btn color="black" outlined
        v-bind="attrs"
        v-on="on">
        <v-icon color="white">expand_more</v-icon>
        <span class="t-700-18" :class="color">
          {{ value }}
        </span>
      </v-btn>
    </template>
    <v-list>
      <v-list-item v-for="account in accountList" :key="account.api_key"
        class="pointer" @click="onClick(account)">
        <v-list-item-title>{{ account.name }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>
<script>
import { indexForEmployees } from '@/api_client/AccountClient.js'

import { handler as errHandler } from '@/classes/ErrorHandler.js'

import { ref } from 'vue'
export default {
  props: {
    value: {
      type: String,
      required: true
    },
    apiKey: {
      type: String,
      required: true
    },
    color: {
      type: String,
      default: 'white--text'
    }
  },
  setup(props, { emit }) {
    const accountList = ref([])

    // Methods
    const onClick = (account) => emit('change', account)

    indexForEmployees(props.apiKey)
      .then((response) => accountList.value = response.data.data)
      .catch(errHandler)
    return {
      accountList,
      onClick
    }
  }
}
</script>