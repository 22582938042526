<template>
  <v-list-item :key="chatGroup.id" class="pointer single-chat-group" @click="clickChatGroup">
    <v-list-item-avatar class="mms-4 mme-2" rounded="lg">
      <group-or-user-avatar :chat-group="chatGroup" tile></group-or-user-avatar>
    </v-list-item-avatar>
    <v-list-item-content>
      <v-list-item-title class="d-flex align-center justify-space-between">
        <span class="t-500-16">{{ chatGroup.name }}</span>
        <span class="t-400-14 color3">{{ chatGroup.dateFormatted }}</span>
      </v-list-item-title>
      <v-list-item-subtitle>
        <v-row no-gutters align="center" justify="start">
          <v-col cols="10">
            <span class="t-400-14 color3" v-if="lastChatMessageTypeText">
              {{ chatGroup.last_message }}
            </span>
            <v-icon small v-else-if="lastChatMessageTypeImage" color="#3E8BFE">image</v-icon>
            <v-icon small v-else-if="lastChatMessageTypeAudio" color="#DC1072">mic</v-icon>
            <v-icon small v-else-if="lastChatMessageTypeFile" color="#45D141">attach_file</v-icon>
          </v-col>
          <v-col cols="2" class="texts-end" v-if="showUnreadBadge">
            <div class="unread-mark t-500-13 black white--text">
              {{ unreadMessageCount }}
            </div>
          </v-col>
        </v-row>
      </v-list-item-subtitle>
    </v-list-item-content>
    <v-list-item-action class="mx-2">
      <v-menu left
        origin="center center"
        v-if="isMultipleParticipantsGroup"
        transition="scale-transition">
        <template v-slot:activator="{ on, attrs }">
          <v-btn small color="black darken-1" icon v-on="on" v-bind="attrs">
            <v-icon>more_vert</v-icon>
          </v-btn>
        </template>
        <v-list :class="{ 'rtl': isRtl, 'ltr': !isRtl }">
          <v-list-item class="px-2" @click.stop="onGroupOptionsClick">
            <v-list-item-content>
              {{ $t('app_chat.group_details') }}
            </v-list-item-content>
            <v-btn icon class="px-1" small
              :disabled="actionDisabled">
              <v-icon>info</v-icon>
            </v-btn>
          </v-list-item>
          <v-list-item v-if="isCurrentUserGroupAdmin" class="px-2" @click.stop="onDeleteGroupClick">
            <v-list-item-content>
              {{ $t('app_chat.delete_group') }}
            </v-list-item-content>
            <v-btn icon class="px-1" small
              :disabled="actionDisabled" :loading="deleteIsLoading">
              <v-icon>delete</v-icon>
            </v-btn>
          </v-list-item>
          <v-list-item v-else class="px-2">
            <v-list-item-content>
              {{ $t('app_chat.exit_group') }}
            </v-list-item-content>
            <v-btn icon class="px-1" small @click.stop="onExitGroupClick"
              :disabled="actionDisabled" :loading="exitIsLoading">
              <v-icon>logout</v-icon>
            </v-btn>
          </v-list-item>
        </v-list>
      </v-menu>
      <span v-else class="empty-span"></span>
    </v-list-item-action>
  </v-list-item>
</template>

<style lang="scss">
.single-chat-group {
  background-color: #F4F6F8;
  padding: 0;

  .unread-mark {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    display: inline-flex;
  }

  .empty-span {
    width: 28px;
  }
}
</style>

<script>
import GroupOrUserAvatar from '@/components/app_chat/GroupOrUserAvatar.vue'

import { isRtl } from '@/i18n.js'

import { computed, ref } from 'vue'

export default {
  components: {
    'group-or-user-avatar': GroupOrUserAvatar
  },
  props: {
    chatGroup: {
      type: Object,
      required: true
    }
  },
  setup(props, { emit }) {
    const exitIsLoading = ref(false)
    const deleteIsLoading = ref(false)

    // Methods
    const clickChatGroup = () => emit('click-group', props.chatGroup)

    const cancelCallBackExitGroup = () => (exitIsLoading.value = false)
    const cancelCallBackDeleteGroup = () => (deleteIsLoading.value = false)
    const onGroupOptionsClick = () => emit('group-options', props.chatGroup)

    const onExitGroupClick = () => {
      exitIsLoading.value = true
      emit('exit-group', {
        chatGroupId: props.chatGroup.id,
        groupName: props.chatGroup.name,
        callback: cancelCallBackExitGroup
      })
    }

    const onDeleteGroupClick = () => {
      deleteIsLoading.value = true
      emit('delete-group', {
        chatGroupId: props.chatGroup.id,
        groupName: props.chatGroup.name,
        callback: cancelCallBackDeleteGroup
      })
    }

    // Computed
    const isMultipleParticipantsGroup = computed(() => props.chatGroup.multiple_participants === true)
    const isCurrentUserGroupAdmin = computed(() => props.chatGroup.is_current_user_group_admin === true)
    const lastChatMessageTypeText = computed(() => props.chatGroup.last_message_type === 'text')
    const lastChatMessageTypeImage = computed(() => props.chatGroup.last_message_type === 'image')
    const lastChatMessageTypeAudio = computed(() => props.chatGroup.last_message_type === 'audio')
    const lastChatMessageTypeFile = computed(() => props.chatGroup.last_message_type === 'file')
    const actionDisabled = computed(() => exitIsLoading.value || deleteIsLoading.value)
    const showUnreadBadge = computed(() => Number.isInteger(props.chatGroup.unread_count) && props.chatGroup.unread_count > 0)
    const unreadMessageCount = computed(() => props.chatGroup.unread_count)
    return {
      exitIsLoading,
      deleteIsLoading,
      isMultipleParticipantsGroup,
      actionDisabled,
      showUnreadBadge,
      unreadMessageCount,
      isCurrentUserGroupAdmin,
      lastChatMessageTypeText,
      lastChatMessageTypeImage,
      lastChatMessageTypeAudio,
      lastChatMessageTypeFile,
      isRtl,
      clickChatGroup,
      onGroupOptionsClick,
      onExitGroupClick,
      onDeleteGroupClick
    }
  }
}
</script>
