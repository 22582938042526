import Client from '@/api_client/client.js'

function index (apiKey, recordType, recordId, params) {
  const RECORD_MESSAGE_INDEX_PATH = `/accounts/${apiKey}/${recordType}/${recordId}/record_messages`
  return Client.get(RECORD_MESSAGE_INDEX_PATH, { params })
}

function create (apiKey, recordType, recordId, params) {
  const RECORD_MESSAGE_CREATE_PATH = `/accounts/${apiKey}/${recordType}/${recordId}/record_messages`
  return Client.post(RECORD_MESSAGE_CREATE_PATH, params)
}

function destroy (apiKey, recordType, recordId, id) {
  const RECORD_MESSAGE_DESTROY_PATH = `/accounts/${apiKey}/${recordType}/${recordId}/record_messages/${id}`
  return Client.delete(RECORD_MESSAGE_DESTROY_PATH)
}

export {
  create,
  index,
  destroy
}
