<template>
  <span>
    <new-device-dialog ref="newDeviceDialog"
      :itemPlaceHolder="newItemPlaceHolder"
      :api-key="apiKey"
      v-if="userCanCreateItem">
    </new-device-dialog>
    <confirm-delete ref="deleteDialog"></confirm-delete>
    <v-autocomplete
      :value="value"
      :items="selectEntries"
      :loading="selectIsLoading"
      :search-input.sync="search"
      clearable
      hide-selected
      append-icon="search"
      class="item-search"
      item-text="nameAndDepartment"
      color="black"
      @change="emitChange"
      @click:clear="clear"
      :no-data-text="$t('no_result')"
      @input="update"
      item-value="id"
      :disabled="disabled"
      return-object
      :menu-props="menuPropsWithClass"
      :placeholder="placeHolder">
      <template v-slot:prepend-item v-if="userCanCreateItem">
        <v-list-item
          @click="addNewItem">
          <v-list-item-content>
            <v-list-item-title>
              <v-icon color="#0E4AFD" class="mx-2">add_circle_outline</v-icon>
              {{ $t('item_search.add_item') }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider class="mt-2"></v-divider>
        <v-row align="center" justify="center" no-gutters>
          <v-col cols="11" class="mt-3">
            <item-category-search
              v-model="itemCategory"
              dense outlined
              :api-key="apiKey">
            </item-category-search>
          </v-col>
        </v-row>
        <v-divider class="mt-2"></v-divider>
      </template>

      <template v-slot:item="{ item }">
        <v-row v-bind:class="{ rtl: isRtl }" align="center" justify="space-between">
          <span>
            <span class="font-weight-bold"> {{ item.name }}</span> <span class="mx-1">{{ departmentText(item) }}</span>
            <span class="t-700-13" v-if="searchContext && item.category_name">
              ({{ item.category_name }})
            </span>
          </span>
          <span>
            <v-btn icon color="black" @click.stop="editItem(item)"
              v-if="!item.isGeneral && canEditItem && showEditButton">
              <v-icon>edit</v-icon>
            </v-btn>
            <v-btn icon color="red" @click.stop="deleteItem(item.id)"
              v-if="!item.isGeneral && canDeleteItem && showDeleteButton">
              <v-icon>delete</v-icon>
            </v-btn>
          </span>
        </v-row>
      </template>

      <template v-slot:selection="{ item }">
        <div v-bind:class="{ rtl: isRtl }">
          <span class="font-weight-bold"> {{ item.name }}</span> <span class="ml-1">{{ departmentText(item) }}</span>
        </div>
      </template>
    </v-autocomplete>
  </span>
</template>

<style lang="scss">
.item-search-menu {
  div.v-subheader {
    background-color: #E8E8E8;
    font-weight: bold;
    font-size: 16px;
    height: 36px;
  }
}

.item-search.v-select.v-select--is-menu-active .v-input__icon--append .v-icon {
  transform: rotate(0);
}
</style>

<script>
import NewDeviceDialog from '@/components/shared/DevicesNewDevice.vue'
import ItemCategorySearch from '@/components/shared/item_category_search/ItemCategorySearch.vue'

import { search as itemsClientSearch, destroy as itemsClientDestroy } from '@/api_client/Device.js'

import { isString } from '@/helpers/Utils.js'

import useItemSearchStore from '@/components/shared/item_search/useItemSearchStore.js'
import useCurrentUserPermissions from '@/composables/useCurrentUserPermissions.js'

import { handler as errHandler } from '@/classes/ErrorHandler.js'

import { ref, watch, computed } from 'vue'

import { i18n, isRtl } from '@/i18n.js'
export default {
  props: {
    value: {
      type: Object,
      default: null
    },
    placeHolder: {
      type: String,
      default: function () {
        return i18n.t('item_search.placeholder')
      },
      required: false
    },
    loadAll: {
      type: Boolean,
      default: false,
      required: false
    },
    menuProps: {
      type: Object,
      required: false,
      default: () => { }
    },
    showAddItem: {
      type: Boolean,
      default: false,
      required: false
    },
    apiKey: {
      type: String,
      required: true
    },
    newItemPlaceHolder: {
      type: String,
      default: null,
      required: false
    },
    showDeleteButton: {
      type: Boolean,
      default: false,
      required: false
    },
    showEditButton: {
      type: Boolean,
      default: false,
      required: false
    },
    disabled: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  components: {
    'new-device-dialog': NewDeviceDialog,
    'confirm-delete': () => import('@/components/shared/ConfirmDelete.vue'),
    'item-category-search': ItemCategorySearch
  },
  setup(props, { emit }) {
    const entries = ref([])
    const isSearchLoading = ref(false)
    const search = ref(null)
    const newDeviceDialog = ref(null)
    const deleteDialog = ref(null)
    const searchContext = ref(false)
    const itemCategory = ref(null)

    const { allItems, invalidateCache, loadAllItems, changeApiKey, isLoading } = useItemSearchStore(props.apiKey)
    const { canCreateItem, canDeleteItem, canEditItem, loadPermissions } = useCurrentUserPermissions(props.apiKey)

    const reLoadAllItems = () => loadAllItems().catch(errHandler)


    const setGeneralItemInResults = (result = []) => {
      const generalItem = result.find((item) => item.general === true)
      if (!generalItem) return

      generalItem.name = i18n.t('devices.general_name')
      generalItem.department_name = null
      generalItem.isGeneral = true
    }

    // Methods
    const update = (newValue) => emit('input', newValue)
    const emitChange = (newValue) => emit('change', newValue)
    const clear = () => emit('click:clear')
    const departmentText = (item) => item.department_name || ''

    const refreshItems = () => {
      invalidateCache()
      reLoadAllItems()
    }

    const addNewItem = () => {
      const preDefinaedCategory = isCategorySelected.value ? itemCategory.value.id : null
      newDeviceDialog.value.openForCreate(preDefinaedCategory).then(({ updatedOrCreated, itemId }) => {
        if (updatedOrCreated !== true) return

        reLoadAllItems().then(() => {
          const item = selectEntries.value.find((item) => item.id === itemId)
          if (!item) return
          update(item)
        })
      })
    }

    const deleteItem = (itemId) => {
      const title = i18n.t('item_search.delete_title')
      const content = i18n.t('item_search.delete_content')
      deleteDialog.value.open({ title, content })
        .then((shouldDelete) => {
          if (!shouldDelete) return
          itemsClientDestroy(props.apiKey, itemId)
            .then(() => {
              emit('item-deleted')
              deleteDialog.value.close()
              refreshItems()
            })
        })
    }

    const editItem = (item) => {
      newDeviceDialog.value.openForUpdate(item).then(({ updatedOrCreated, itemId }) => {
        if (updatedOrCreated !== true) return

        reLoadAllItems().then(() => {
          const item = selectEntries.value.find((item) => item.id === itemId)
          if (!item) return
          update(item)
        })
      })
    }
    // Computed

    const selectIsLoading = computed(() => isSearchLoading.value || isLoading.value)
    const isCategorySelected = computed(() => Number.isInteger(itemCategory.value?.id))
    const menuPropsWithClass = computed(() => {
      const menuProps = props.menuProps || {}
      if (isString(menuProps.contentClass)) {
        menuProps.contentClass += ' item-search-menu'
      } else {
        menuProps.contentClass = 'item-search-menu'
      }
      return menuProps
    })
    const selectEntries = computed(() => {
      let selectedRef = []
      searchContext.value = false
      if (isString(search.value) && search.value.length > 0) {
        selectedRef = entries.value
        searchContext.value = true
      } else {
        selectedRef = allItems.value
      }
      const result = []
      let lastCategoryId = null
      const categoryIdsSet = new Set()
      if (isCategorySelected.value) categoryIdsSet.add(itemCategory.value.id)
      selectedRef.forEach((item) => {
        if (isCategorySelected.value && !categoryIdsSet.has(item.category_id)) return

        const itemClone = Object.assign({}, item)
        itemClone.nameAndDepartment = `${item.name} ${departmentText(item)}`
        if (Number.isInteger(item.category_id) && item.category_id !== lastCategoryId) {
          result.push({
            id: itemClone.category_id,
            header: itemClone.category_name
          })
          lastCategoryId = itemClone.category_id
        }
        result.push(itemClone)
      })
      setGeneralItemInResults(result)
      return result
    })
    const userCanCreateItem = computed(() => props.showAddItem && canCreateItem.value)
    reLoadAllItems()

    const itemId = (props.value || {}).id
    if (Number.isInteger(itemId)) {
      entries.value.push(props.value)
    }
    loadPermissions()
    watch(() => search.value, (query) => {
      if (isSearchLoading.value || query === null) return
      if (!query || (query || '').trim().length < 1) {
        entries.value.splice(0, entries.value.length)
        return
      }

      isSearchLoading.value = true
      const reuqestParams = {
        term: query
      }
      if (isCategorySelected.value) {
        reuqestParams.categoryId = itemCategory.value.id
      }
      itemsClientSearch(props.apiKey, reuqestParams)
        .then(res => {
          const results = res.data.data
          setGeneralItemInResults(results)
          const entriesVal = entries.value
          let lastCategoryId = null
          results.forEach(item => entriesVal.push(item))
        })
        .catch(errHandler)
        .finally(() => (isSearchLoading.value = false))
    })

    watch(() => props.apiKey, (newValue) => {
      if (!isString(newValue)) return
      changeApiKey(newValue)
      refreshItems()
    })
    return {
      entries,
      isSearchLoading,
      search,
      selectIsLoading,
      selectEntries,
      searchContext,
      newDeviceDialog,
      userCanCreateItem,
      canDeleteItem,
      canEditItem,
      deleteDialog,
      menuPropsWithClass,
      isRtl,
      itemCategory,
      update,
      emitChange,
      clear,
      departmentText,
      refreshItems,
      addNewItem,
      deleteItem,
      editItem,
    }
  }
}
</script>
