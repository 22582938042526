
import { resetUnreadMessages, unreadMessages } from '@/api_client/SystemGuidanceTrack.js'

import { i18n } from '@/i18n.js'

import { computed, ref } from 'vue'

const haveUnreadMessages = ref(null)
let isLoaded = false
export default function useSystemGuidanceTrack (apiKey) {
  const lang = i18n.locale

  const loadHaveUnreadMessages = () => {
    if (isLoaded === true) return
    isLoaded = true
    unreadMessages({ lang, apiKey })
      .then((response) => {
        isLoaded = true
        haveUnreadMessages.value = response.data.have_unread_messages
      })
      .catch(() => (isLoaded = false))
  }

  // Methods

  const resetCounter = () => {
    if (haveUnreadMessages.value === false) return

    resetUnreadMessages({ lang, apiKey })
      .then(() => {
        isLoaded = true
        haveUnreadMessages.value = false
      })
  }

  // Computed

  const getHaveUnreadMessages = computed(() => haveUnreadMessages.value)

  if (!isLoaded) loadHaveUnreadMessages()

  return {
    getHaveUnreadMessages,
    resetCounter
  }
}
