<template>
  <v-dialog v-model="dialog" persistent width="250">
    <v-card>
      <v-card-title class="text-center">
        <span class="headline full-width">
          {{dialogTitle}}
        </span>
      </v-card-title>
      <v-card-text class="cross-account-new-op">
        <v-form ref="newJobForm" class="text-center">
          <v-text-field
            :rules="[requiredField]"
            single-line
            v-model="jobName"
            :counter="maxNameLength"
            :label="$t('org_job_new_dialog.job_name')">
          </v-text-field>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="close(false)" :disabled="isLoading">
          {{$t('cancel')}}
        </v-btn>
        <v-btn color="blue darken-1" depressed tile @click="save" :loading="isLoading"
                                      :disabled="saveDisabled">
          {{$t('save')}}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>

import { create as createAccountJobClient, update as updateAccountJobClient } from '@/api_client/AccountJob.js'
import useCompany from '@/composables/useCompany.js'
import useAuth from '@/composables/useAuth.js'

import { handler } from '@/classes/ErrorHandler.js'
import { isString } from '@/helpers/Utils.js'

import { requiredField } from '@/classes/InputValidators.js'

import { ref, computed } from 'vue'
import { i18n } from '@/i18n.js'
const MAX_NAME_LENGTH = 120
const DialogModes = { create: 1, update: 2 }
let jobIdForUpdate = null

export default {
  setup (props, { emit }) {
    const dialog = ref(false)
    const isLoading = ref(false)
    const jobName = ref(null)
    const dialogMode = ref(null)
    const maxNameLength = ref(MAX_NAME_LENGTH)
    const newJobForm = ref(null)
    const { apiKey } = useAuth()

    const createAccountJob = (jobName) => {
      const { staffAccountApiKey } = useCompany(apiKey.value)
      return createAccountJobClient(staffAccountApiKey.value, {
        account_job: { name: jobName }
      })
    }

    const updateAccountJob = (jobId, jobName) => {
      const { staffAccountApiKey } = useCompany(apiKey.value)
      return updateAccountJobClient(staffAccountApiKey.value, jobId, {
        account_job: { name: jobName }
      })
    }
    // Methods
    const openDialog = () => {
      dialog.value = true
      dialogMode.value = DialogModes.create
    }
    const openForUpdate = ({ id, name } = {}) => {
      dialog.value = true
      dialogMode.value = DialogModes.update
      jobName.value = name
      jobIdForUpdate = id
    }

    const close = (isJobAdded) => {
      dialog.value = false
      dialogMode.value = null
      newJobForm.value.reset()
      jobName.value = null
      jobIdForUpdate = null
      isLoading.value = false
      if (isJobAdded !== true) return
      emit('staff-job-upsert')
    }

    const save = () => {
      isLoading.value = true
      const promise = dialogMode.value === DialogModes.create
        ? createAccountJob(jobName.value)
        : updateAccountJob(jobIdForUpdate, jobName.value)
      promise
        .then(() => close(true))
        .catch(handler)
        .finally(() => (isLoading.value = false))
    }

    // Computed
    const dialogTitle = computed(() =>
      dialogMode.value === DialogModes.create
        ? i18n.t('staff_account_job_dialog.title')
        : i18n.t('staff_account_job_dialog.title_for_update')
    )
    const saveDisabled = computed(() => {
      if (isLoading.value === true) return true
      const jobNameValue = jobName.value
      if (!isString(jobNameValue)) return true
      const nameLength = jobNameValue.trim().length
      return nameLength <= 0 || nameLength > MAX_NAME_LENGTH
    })

    return {
      dialog,
      isLoading,
      dialogTitle,
      jobName,
      maxNameLength,
      saveDisabled,
      newJobForm,
      openDialog,
      openForUpdate,
      save,
      close,
      requiredField
    }
  }
}
</script>
