<template>
  <tr v-bind:id="supplier.id" class="supplier-row">
    <v-row align="center" justify="space-between"
      class="font-weight-bold ma-0 blue-grey lighten-4" v-bind:class="{ 'no-ops': !supplierHaveOperations }">
      <v-col cols="12" md="4" class="text-left py-1 d-flex align-center">
        <v-avatar size="28" rounded-circle>
          <img :src="supplier.avatar_image">
        </v-avatar>
        <span class="ml-3 d-md-none">{{ supplier.name }}</span>
      </v-col>
      <v-col md="4" class="text-left text-sm-center py-1 d-none d-md-block">
        {{ supplier.name }}
      </v-col>
      <v-col cols="12" md="4" class="text-left text-sm-right py-1">
        <v-btn icon class="mx-1" @click="deleteSupplier">
          <v-icon color="black" medium>delete</v-icon>
        </v-btn>
        <v-btn icon class="mx-1" @click="editSupplier">
          <v-icon color="black" medium>edit</v-icon>
        </v-btn>
        <v-menu
          transition="slide-y-transition"
          bottom left>
          <template v-slot:activator="{ on }">
            <v-btn
              class="mx-1"
              icon
              :disabled="menuDisabled"
              v-on="on">
              <v-icon>menu</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item v-if="supplier.email">
              <v-btn outlined block :href="`mailto:${supplier.email}`">
                <span class="mr-2 font-weight-bold">
                  <v-icon color="black">email</v-icon>
                </span>
                <span class="blue--text mr-2 text-left">
                  {{ supplier.email }}
                </span>
              </v-btn>
            </v-list-item>
            <v-list-item v-if="supplier.phone">
              <v-btn outlined block :href="`tel:${supplier.phone}`">
                <span class="mr-2 font-weight-bold">
                  <v-icon color="black">phone</v-icon>
                </span>
                <span class="blue--text mr-2 text-left">
                  {{ supplier.phone }}
                </span>
              </v-btn>
            </v-list-item>
            <v-list-item v-if="supplier.order_phone">
              <v-btn outlined block :href="`tel:${supplier.order_phone}`">
                <span class="mr-2 font-weight-bold">
                  {{ $t("org_structure_suppliers.order_phone") }}
                </span>
                <span class="blue--text mr-2 text-left">
                  {{ supplier.order_phone }}
                </span>
              </v-btn>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-col>
      <v-col v-for="day in dayNames" :key="day.name">
        <v-btn outlined block @click="createOperation(day)">
          {{ day.translatedName }}
        </v-btn>
      </v-col>
    </v-row>
    <v-row align="start" justify="space-between" no-gutters>
      <span v-for="day in dayNames" class="day-span text-center" :key="day.name">
        <div v-for="operation in operationsSchedule(day.name)" class="single-schedule" :key="operation.id">
          <v-btn color="primary" text icon small class="my-0 mx-2" @click="editOperation(day, operation)">
            <v-icon color="black">edit</v-icon>
          </v-btn>
          <v-btn @click="deleteOperationScheduleClick(operation)" color="primary" text icon small class="my-0 mx-2">
            <v-icon color="black">delete</v-icon>
          </v-btn>
          <div v-if="operation.creatorFirstName" class="font-weight-medium caption">
            {{ operation.creatorFirstName }} {{ operation.creatorLastName }}
          </div>
          <div class="blue--text font-weight-regular">
            {{ operationTypeText(operation) }}
            <div v-show="operation.frequencyText" class="font-italic red--text">
              {{ operation.frequencyText }}
            </div>
          </div>
          <div class="font-weight-bold">
            {{ operation.executor_name }}<span> - {{ operation.shiftNameText }}</span>
          </div>
        </div>
      </span>
    </v-row>
  </tr>
</template>
<style lang="scss">
tr.supplier-row {
  &:not(:last-child) {
    .no-ops {
      border-bottom: 2px solid rgba(0, 0, 0, 0.22) !important;

      .main {
        padding-bottom: 4px !important;
        padding-top: 4px !important;
      }
    }
  }

  .single-schedule {
    margin: 1px;
    border: 1px solid black;
  }

  .day-span {
    width: calc(100%/7);
    display: inline-block;
    vertical-align: top;
  }
}
</style>

<script>
import useAccountSupplier from '@/composables/useAccountSupplier.js'
import useAuth from '@/composables/useAuth.js'
import useAccount from '@/composables/useAccount.js'

import { daysOfTheWeek } from '@/helpers/DateTime.js'

import { computed } from 'vue'

const DefaultShiftColor = 'black'
export default {
  props: {
    supplier: {
      type: Object,
      required: true
    }
  },
  setup(props, { emit }) {
    const { apiKey } = useAuth()
    const { deleteOperationSchedule } = useAccountSupplier(apiKey.value)
    const { weekStartDay } = useAccount()
    const dayNames = daysOfTheWeek(weekStartDay.value)

    // Methods
    const editSupplier = () => emit('edit-supplier', { supplier: props.supplier })
    const deleteSupplier = () => emit('delete-supplier', { supplier: props.supplier })
    const operationsSchedule = (day) => props.supplier.operationSchedule[day] || []
    const createOperation = (day) => emit('create-operation', { day, supplier: props.supplier })
    const editOperation = (day, operation) => emit('edit-operation', { day, supplier: props.supplier, operation })

    const operationTypeText = (scheduledOperation) => {
      const operation = (props.supplier.operations || []).find((operation) => operation.id === scheduledOperation.item_operation_id)
      if (!operation) return ''

      return operation.operationTypeText
    }

    const deleteOperationScheduleClick = (operationsSchedule) => deleteOperationSchedule({
      executor_id: operationsSchedule.executor_id,
      operation_schedule_id: operationsSchedule.id,
      supplierId: props.supplier.id
    })

    // Computed

    const supplierHaveOperations = computed(() => dayNames.map((day) => day.name).some((dayName) => operationsSchedule(dayName).length > 0))
    const menuDisabled = computed(() => !props.supplier.email && !props.supplier.phone && !props.supplier.order_phone)
    return {
      dayNames,
      supplierHaveOperations,
      menuDisabled,
      editSupplier,
      deleteSupplier,
      operationsSchedule,
      createOperation,
      editOperation,
      operationTypeText,
      deleteOperationScheduleClick
    }
  }
}
</script>
