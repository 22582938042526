<template>
  <v-row align="start" justify="start" no-gutters class="px-2 py-4 single-job pointer"
    :class="{ 'selected': selected }"
    @click="onJobClick">
    <v-col class="flex-grow-0 flex-shrink-1">
      <v-icon size="24" class="factory-icon mme-4">factory</v-icon>
    </v-col>
    <v-col class="flex-grow-1 flex-shrink-0 texts-start">
      <span class="t-700-18">{{ nameAndShift }}</span>
      <span color="blue" class="t-700-16 color1 mms-2 text-no-wrap ltr d-inline-block">
        {{ job.total_tasks_reported }} / {{ job.total_tasks }}
      </span>
      <div v-if="showDepartment" class="t-700-14">
        {{ job.department_name }}
      </div>
      <div v-if="!staffAccount" class="t-500-13 mt-1 color2">
        {{ job.shiftStartTimeUserFormat }} - {{ job.shiftEndTimeUserFormat }}
      </div>
    </v-col>
    <v-col class="flex-grow-0 flex-shrink-1 align-self-center d-flex align-center">
      <v-btn icon :input-value="job.shift_mark_complete" :disabled="toggleShiftDisabled"
        @click.stop="toggleShiftComplete" :class="job.shift_mark_complete === true ? 'color4' : 'color3'">
        <v-icon class="font-weight-black" :class="job.shift_mark_complete === true ? 'color4' : 'color3'">
          {{ job.shift_mark_complete === true ? 'lock' : 'lock_open' }}
        </v-icon>
      </v-btn>
      <v-icon v-if="$vuetify.breakpoint.xsOnly">
        keyboard_arrow_down
      </v-icon>
    </v-col>
  </v-row>
</template>
<style lang="scss">
.single-job {
  box-shadow: 0px 16px 24px 0px rgba(0, 0, 0, 0.06), 0px 2px 6px 0px rgba(0, 0, 0, 0.04), 0px 0px 1px 0px rgba(0, 0, 0, 0.04);
  border-radius: 8px;
  border: 1px solid #111;
  background-color: white;

  &.selected {
    border: 2px solid #0040FF;
  }

  .factory-icon {
    color: white;
    background-color: black;
    border-radius: 8px;
    padding: 8px;
  }
}
</style>
<script>
import { translateShift } from '@/store/modules/OrganizationJob.js'
import { isString } from '@/helpers/Utils.js'

import { computed } from 'vue'
export default {
  props: {
    job: {
      type: Object,
      required: true
    },
    selected: {
      type: Boolean,
      required: false,
      default: false
    },
    staffAccount: {
      type: Boolean,
      default: false
    },
    operationMarkDisabled: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  setup(props, { emit }) {

    // Methods

    const onJobClick = () => emit('job-click', props.job)
    const toggleShiftComplete = () => emit('toggle-shift-complete', props.job)

    // Computed
    const showDepartment = computed(() => isString(props.job.department_name) && props.job.department_name.length > 0)
    const toggleShiftDisabled = computed(() => props.operationMarkDisabled || props.job.toggleShiftLoading)
    const nameAndShift = computed(() => {
      if (props.staffAccount === true) return props.job.name
      return `${props.job.name} - ${translateShift(props.job.shift)}`
    })
    return {
      nameAndShift,
      showDepartment,
      toggleShiftDisabled,
      toggleShiftComplete,
      onJobClick
    }
  }
}
</script>