<template>
  <v-chip
    class="pa-4 mb-2 text-left chip-message">
    <v-row no-gutters class="full-width">
      <v-col cols="12" v-if="hasImage">
        <chat-message-images :images="message.image_urls"
          @image-click="imageClick">
        </chat-message-images>
      </v-col>
      <v-col cols="12" v-if="hasFile">
        <v-btn text small icon class="black--text"
          @click="onFileAttachClick">
          <v-icon>attach_file</v-icon>
        </v-btn>
      </v-col>
      <v-col cols="12" v-if="hasVideoFile">
        <video controls class="chat-video">
          <source :src="message.video_file_url">
        </video>
      </v-col>
      <v-col cols="12" v-else-if="hasAudioFile" class="text-center">
        <audio-player :audio-file-url="message.audio_file_url">
        </audio-player>
      </v-col>
      <v-col cols="12" class="show-line-break t-500-16 texts-start">
        {{ messageContent }}
      </v-col>
      <v-col cols="12" class="d-flex align-center justify-space-between">
        <span>
          <sub class="mt-4">
            {{ message.created_at_date }} {{ message.created_at_time }}
          </sub>
          <sub class="mt-4 pl-3 t-500-12">
            {{ displayName }}
          </sub>
        </span>
        <span>
          <v-btn
            v-bind:class="{ 'original-text': !showTranslatedContent }"
            v-if="hasTranslation"
            @click="onTranslationClick"
            small icon>
            <v-icon color="#0E4AFD">
              translate
            </v-icon>
          </v-btn>
          <single-chat-message-options v-if="showOptions"
            :show-delete="isMessageFromCurrentUser"
            :show-info="isMessageFromCurrentUser"
            :show-copy="showCopyMessage"
            :delete-in-progress="deleteInProgress"
            @delete-message="onDeleteMessage"
            @info-click="onInfoClick"
            @copy-click="onCopyClick">
          </single-chat-message-options>
        </span>
      </v-col>
    </v-row>
  </v-chip>
</template>

<style lang="scss">
.chip-message.v-chip {
  height: auto;
  white-space: normal;

  sub {
    font-size: 10px;
  }

  .original-text {
    opacity: 0.3;
  }

  .chat-video {
    width: 80%;
    height: auto;
    max-height: 150px;
    max-width: 150px;
    object-fit: contain;
  }
}
</style>

<script>

import { isString } from '@/helpers/Utils.js'

import { computed, ref } from 'vue'

export default {
  props: {
    message: {
      type: Object,
      required: true
    },
    showOptions: {
      type: Boolean,
      default: true,
      required: false
    },
    deleteInProgress: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  components: {
    'chat-message-images': () => import('@/components/app_chat/ChatMessageImages.vue'),
    'audio-player': () => import('@/components/app_chat/message_imput/AudioPlayer.vue'),
    'single-chat-message-options': () => import('@/components/app_chat/SingleChatMessageOptions.vue')
  },
  setup(props, { emit }) {
    const showTranslatedContent = ref(isString(props.message.content_translated))
    // Methods

    const imageClick = (index) => emit('image-click', { images: props.message.image_urls, index })
    const onInfoClick = () => emit('info-click', props.message)
    const onDeleteMessage = () => emit('delete-click', props.message)
    const onCopyClick = () => emit('copy-click', props.message)
    const onFileAttachClick = () => window.open(props.message.file_url, '_blank').focus()
    const onTranslationClick = () => (showTranslatedContent.value = !showTranslatedContent.value)

    // Computed
    const isMessageFromCurrentUser = computed(() => props.message.sent_by_current_user === true)
    const displayName = computed(() => `${props.message.first_name} ${props.message.last_name}`)
    const hasImage = computed(() => Array.isArray(props.message.image_urls) && props.message.image_urls.length > 0)
    const hasFile = computed(() => isString(props.message.file_url) && props.message.file_url.length > 0)
    const showCopyMessage = computed(() => isString(props.message.content) && props.message.content.length > 0)
    const hasAudioFile = computed(() => isString(props.message.audio_file_url) && props.message.audio_file_url.length > 0)
    const hasVideoFile = computed(() => isString(props.message.video_file_url) && props.message.video_file_url.length > 0)
    const hasTranslation = computed(() => isString(props.message.content_translated))
    const messageContent = computed(() => {
      if (hasTranslation.value !== true) return props.message.content
      return showTranslatedContent.value === true ? props.message.content_translated : props.message.content
    })

    return {
      isMessageFromCurrentUser,
      displayName,
      hasImage,
      hasFile,
      messageContent,
      hasTranslation,
      showTranslatedContent,
      hasAudioFile,
      hasVideoFile,
      showCopyMessage,
      onInfoClick,
      onDeleteMessage,
      imageClick,
      onFileAttachClick,
      onTranslationClick,
      onCopyClick
    }
  }
}
</script>
