import { show as SiteCustomStyleShow } from '@/api_client/SiteCustomStyle.js'
import { isString } from '@/helpers/Utils.js'

const customStyleId = 'site_custom_style'
const customStyleLoadedKey = 'site_custom_style_loaded'

function loadStyleFromApiKey (apiKey) {
  if (isCustomStyleLoaded() === true) {
    loadCustomStyleFromStorage()
  } else {
    loadCustomStyleFromApi(apiKey)
  }
}

function addCustomStyle (link) {
  if (isValidLink(link) === false || customStyleExists() === true) return

  const customStyleLink = document.createElement('link')
  customStyleLink.setAttribute('id', customStyleId)
  customStyleLink.rel = 'stylesheet'
  customStyleLink.href = link
  document.head.appendChild(customStyleLink)
}

function isValidLink (link) {
  return isString(link) && link.startsWith('http')
}
function customStyleExists () {
  return document.getElementById(customStyleId) !== null
}

function saveCustomStyle (link) {
  if (isValidLink(link) === false) return
  sessionStorage.setItem(customStyleId, link)
}

function setCustomStyleLoaded () {
  sessionStorage.setItem(customStyleLoadedKey, 'true')
}

function isCustomStyleLoaded () {
  return sessionStorage.getItem(customStyleLoadedKey) === 'true'
}

function getCustomStyleFromStorage () {
  return sessionStorage.getItem(customStyleId)
}

function loadCustomStyleFromStorage () {
  const link = getCustomStyleFromStorage()
  addCustomStyle(link)
}

function loadCustomStyleFromApi (apiKey) {
  SiteCustomStyleShow(apiKey)
    .then((response) => {
      const link = response.data.data.link
      addCustomStyle(link)
      saveCustomStyle(link)
    })
    .catch(() => {})
    .finally(() => setCustomStyleLoaded())
}
export { loadStyleFromApiKey }
