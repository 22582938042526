
import {
  create as groupChatClientCreate, index as groupChatClientIndex,
  update as groupChatClientUpdate, destroy as groupChatClientDestroy,
  searchPrivateChatGroup as groupChatClientSearchPrivateChatGroup,
  findOrCreatePrivateChatGroup as groupChatClientFindOrCreatePrivateChatGroup,
  addImage as groupChatClientAddGroupImage,
  removeImage as groupChatClientRemoveGroupImage
} from '@/api_client/ChatGroup.js'
import {
  create as chatMessageClientCreate, index as chatMessageClientIndex,
  destroy as chatMessageClientDestroy
} from '@/api_client/ChatMessage.js'

import {
  upsert as chatGroupMessageTrackClientUpsert,
  unreadGroupCount as chatGroupMessageTrackClientUnreadGroupCount
} from '@/api_client/ChatGroupMessageTrack.js'

import {
  index as ChatGroupParticipantClientIndexGroupUsers,
  destroy as ChatGroupParticipantClientexitGroup
} from '@/api_client/ChatGroupParticipant.js'

import { uploadImage as uploadImageToRemoteServer } from '@/general_client/CloudinaryImageUploader.js'
import { uploadFile as uploadFileToRemoteServer } from '@/general_client/FileUploader.js'

import { isString } from '@/helpers/Utils.js'

import { EventBus, ACTIVE_APP_CHAT_GROUP_CHANGED } from '@/EventBus.js'

import Vue, { ref, computed } from 'vue'

const DEFAULT_PAGE = 1
const DEFAULT_PER_PAGE = 100
const DEFAULT_PAGE_CONVERSATION = 1
const DEFAULT_PER_PAGE_CONVERSATION = 100

const defaultPagination = () => ({ page: DEFAULT_PAGE, perPage: DEFAULT_PER_PAGE, total: null, totalPages: null })
const defaultConversationPagination = () => ({ page: DEFAULT_PAGE_CONVERSATION, perPage: DEFAULT_PER_PAGE_CONVERSATION, total: null, totalPages: null })
const defaultActiveConversation = () => ({
  isLoading: false,
  isSendingMessage: false,
  messages: [],
  group: { id: null, name: null, isPrivate: null, privateGroupOtherUserId: null, unreadMessagesCount: null, targetLanguage: null },
  pagination: { page: DEFAULT_PAGE_CONVERSATION, perPage: DEFAULT_PER_PAGE_CONVERSATION, total: null, totalPages: null }
})

const isLoading = ref(false)
const pagination = ref(defaultPagination())
const chatGroupsList = ref([])
const activeConversation = ref(defaultActiveConversation())
const unreadGroupCount = ref(null)
let currentApiKey = null

const getTotalPages = ({ total, perPage }) => {
  const totalItems = total || 0
  if (totalItems === 0 || totalItems <= perPage) {
    return 0
  }
  return Math.ceil(totalItems / perPage)
}

export default function useAppChat(apiKey) {
  const resetValues = () => {
    isLoading.value = false
    pagination.value = defaultPagination()
    chatGroupsList.value.splice(0, chatGroupsList.value.length)
    unreadGroupCount.value = null
  }

  if (isString(apiKey) && currentApiKey !== apiKey) {
    resetValues()
    currentApiKey = apiKey
  }

  const notifyGroupChanged = () => EventBus.emit(ACTIVE_APP_CHAT_GROUP_CHANGED)
  const shouldCreateGroupForPrivateChat = () => {
    const { isPrivate, id, privateGroupOtherUserId } = activeConversation.value.group
    return isPrivate && id === -1 && Number.isInteger(privateGroupOtherUserId)
  }

  const setGroupData = ({ id, name, unreadMessagesCount = null, isPrivate = false,
    privateGroupOtherUserId = null, targetLanguage = null, multiple_participants = null,
    image_url = null, other_user_data = {} } = {}) => {
    const group = activeConversation.value.group
    group.id = id
    group.name = name
    group.isPrivate = isPrivate
    group.privateGroupOtherUserId = privateGroupOtherUserId
    group.unreadMessagesCount = unreadMessagesCount
    group.targetLanguage = targetLanguage
    group.multiple_participants = multiple_participants
    group.image_url = image_url
    group.other_user_data = other_user_data
  }

  const changeUnreadCountToZero = () => {
    const { group } = activeConversation.value
    group.unreadMessagesCount = 0
    const groupId = group.id
    const chatGroupsListVal = chatGroupsList.value
    const grouIndex = chatGroupsListVal.findIndex(chatGroup => chatGroup.id === groupId)
    if (grouIndex === -1) return
    chatGroupsListVal[grouIndex].unread_count = 0
  }

  const resetUnreadMessagesFromApi = async () => {
    const { group: { id, unreadMessagesCount } = {}, messages } = activeConversation.value
    const shouldUpdateUnreadMessagesCount = id > 0 && unreadMessagesCount > 0 && messages.length > 0
    if (shouldUpdateUnreadMessagesCount !== true) return

    const messageId = messages[messages.length - 1].id
    return chatGroupMessageTrackClientUpsert(apiKey, { chatGroupId: id, messageId })
      .then(changeUnreadCountToZero)
      .then(() => loadUnreadGroupCount(true))
  }

  const setGroupImage = ({ chatGroupId, imageUrl }) => {
    const chatGroupsListVal = chatGroupsList.value
    const index = chatGroupsListVal.findIndex((group) => chatGroupId === group.id)
    if (index === -1) return
    const chatGroup = chatGroupsListVal[index]
    if ('image_url' in chatGroup) {
      chatGroup.image_url = imageUrl
    } else {
      Vue.set(chatGroup, 'image_url', imageUrl)
    }
  }
  // Methods

  const createNewGroup = async ({ name, groupUsers, withImage }) => {
    isLoading.value = true
    const resopnse = await groupChatClientCreate(apiKey, { name, groupUsers, withImage })
    await listGroups()
    isLoading.value = false
    return resopnse.data
  }

  const updateGroup = async ({ id, name, groupUsers, withImage }) => {
    isLoading.value = true
    const resopnse = await groupChatClientUpdate(apiKey, { id, name, groupUsers, withImage })
    await listGroups()
    isLoading.value = false
    return resopnse.data
  }

  const changeChatGroupsPage = (page) => {
    pagination.value.page = page
    listGroups()
  }

  const listGroups = () => {
    isLoading.value = true
    return groupChatClientIndex(apiKey, { page: pagination.value.page, perPage: pagination.value.perPage })
      .then(({ data: { data, meta } }) => {
        const chatGroupsListVal = chatGroupsList.value
        const paginationVal = pagination.value
        chatGroupsListVal.splice(0, chatGroupsList.value.length)
        data.forEach(chatGroup => chatGroupsListVal.push(chatGroup))
        paginationVal.total = meta.total
        paginationVal.page = meta.page
        paginationVal.perPage = meta.per_page
        paginationVal.totalPages = getTotalPages(paginationVal)
      })
      .finally(() => (isLoading.value = false))
  }

  const uploadMultipleImages = async ({ imageFiles, signatures }) => {
    const promiseArray = []
    imageFiles.forEach((imageFile, index) => {
      const imageUploadData = signatures[index]
      const uploadRequest = uploadImageToRemoteServer(imageFile, imageUploadData)
      promiseArray.push(uploadRequest)
    })
    return Promise.all(promiseArray)
  }
  const createNewMessage = async ({ content, imageCount, imageFiles, withFile, fileContent, withAudio, audioContent, withVideo, videoContent }) => {
    const activeConversationVal = activeConversation.value
    activeConversationVal.isSendingMessage = true
    if (shouldCreateGroupForPrivateChat() === true) {
      const userId = activeConversationVal.group.privateGroupOtherUserId
      const { data: { data: { id } } } = await groupChatClientFindOrCreatePrivateChatGroup(apiKey, { userId })
      activeConversationVal.group.id = id
    }

    const chatGroupId = activeConversationVal.group.id
    return chatMessageClientCreate(apiKey, { content, chatGroupId, withImages: imageCount, withFile, withAudio, withVideo })
      .then(async ({ data: { data } }) => {
        const haveImages = Number.isInteger(imageCount) && imageCount > 0
        if (haveImages === true && imageFiles.length > 0 && data.image_upload_data !== null) {
          await uploadMultipleImages({ imageFiles, signatures: data.image_upload_data })
        } else if (isString(withFile) === true && fileContent !== null && data.file_upload_data !== null) {
          await uploadFileToRemoteServer(fileContent, data.file_upload_data.file_url)
        } else if (withAudio === true && audioContent !== null && data.file_upload_data !== null) {
          await uploadFileToRemoteServer(audioContent, data.file_upload_data.file_url)
        } else if (isString(withVideo) === true && videoContent !== null && data.file_upload_data !== null) {
          await uploadFileToRemoteServer(videoContent, data.file_upload_data.file_url)
        }
        if (activeConversationVal.group.id !== chatGroupId) return

        activeConversationVal.messages.push(data)
      }).then(() => {
        if (pagination.value.page !== DEFAULT_PAGE) return
        const chatGroupsListVal = chatGroupsList.value
        if (chatGroupsListVal[0]?.id === chatGroupId) return

        listGroups()
      })
      .finally(() => (activeConversationVal.isSendingMessage = false))
  }

  const changeActiveGroup = (chatGroup) => {
    const activeConversationVal = activeConversation.value
    if (chatGroup.id === activeConversationVal.group.id) return
    setGroupData({
      id: chatGroup.id, name: chatGroup.name, unreadMessagesCount: chatGroup.unread_count,
      multiple_participants: chatGroup.multiple_participants, image_url: chatGroup.image_url,
      other_user_data: chatGroup.other_user_data
    })
    activeConversationVal.isSendingMessage = false
    activeConversationVal.messages.splice(0, activeConversationVal.messages.length)
    activeConversationVal.pagination = defaultConversationPagination()
    return loadActiveGroupMessages()
      .then(notifyGroupChanged)
      .then(resetUnreadMessagesFromApi)
  }

  const loadActiveGroupMessages = () => {
    const activeConversationVal = activeConversation.value
    activeConversationVal.isLoading = true
    const { page, perPage } = activeConversationVal.pagination
    const targetLanguage = activeConversationVal.group.targetLanguage
    return chatMessageClientIndex(apiKey, { chatGroupId: activeConversationVal.group.id, page, perPage, targetLanguage })
      .then(({ data: { data, meta } }) => {
        const messages = activeConversationVal.messages
        data.forEach((chatMessage) => messages.unshift(chatMessage))

        const paginationVal = activeConversationVal.pagination
        paginationVal.page = meta.page
        paginationVal.perPage = meta.per_page
        paginationVal.total = meta.total
        paginationVal.totalPages = getTotalPages(paginationVal)
      })
      .finally(() => (activeConversationVal.isLoading = false))
  }

  const deleteMessage = ({ chatMessageId }) => {
    const activeConversationVal = activeConversation.value
    const chatGroupId = activeConversationVal.group.id

    return chatMessageClientDestroy(apiKey, { chatGroupId, chatMessageId })
      .then(() => {
        if (activeConversationVal.group.id !== chatGroupId) return
        const messages = activeConversationVal.messages
        const chatMessageIndex = messages.findIndex((chatMessage) => chatMessage.id === chatMessageId)
        if (chatMessageIndex === -1) return
        messages.splice(chatMessageIndex, 1)
      })
      .finally(() => (activeConversationVal.isLoading = false))
  }

  const startPrivateChat = ({ id: userId, first_name: firstName, last_name: lastName }) => {
    const activeConversationVal = activeConversation.value
    const groupName = `${firstName} ${lastName}`
    activeConversationVal.isLoading = true
    setGroupData({ id: -1, name: groupName, isPrivate: true, privateGroupOtherUserId: userId })
    activeConversationVal.isSendingMessage = false
    activeConversationVal.messages.splice(0, activeConversationVal.messages.length)
    activeConversationVal.pagination = defaultConversationPagination()
    return groupChatClientSearchPrivateChatGroup(apiKey, { userId })
      .then(({ data, status }) => {
        if (status === 204) return
        const { id } = data.data
        activeConversationVal.group.id = id
        return loadActiveGroupMessages()
          .then(resetUnreadMessagesFromApi)
          .then(notifyGroupChanged)
      })
      .finally(() => (activeConversationVal.isLoading = false))
  }

  const exitGroup = ({ chatGroupId }) => {
    return ChatGroupParticipantClientexitGroup(apiKey, { chatGroupId })
      .then(listGroups)
  }

  const deleteGroup = ({ chatGroupId }) => {
    return groupChatClientDestroy(apiKey, { chatGroupId })
      .then(listGroups)
  }

  const addGroupImage = async ({ chatGroupId, imageUploadData, imageFile }) => {
    isLoading.value = true
    const imageUrl = await uploadImageToRemoteServer(imageFile, imageUploadData)
    return groupChatClientAddGroupImage(apiKey, { id: chatGroupId, image: imageUploadData.public_id })
      .then(() => setGroupImage({ chatGroupId, imageUrl }))
      .finally(() => (isLoading.value = false))
  }

  const removeGroupImage = ({ chatGroupId }) => {
    isLoading.value = true
    return groupChatClientRemoveGroupImage(apiKey, { id: chatGroupId })
      .then(() => setGroupImage({ chatGroupId, imageUrl: null }))
      .finally(() => (isLoading.value = false))
  }

  const chatGroupParticipants = ({ chatGroupId }) => {
    return ChatGroupParticipantClientIndexGroupUsers(apiKey, { chatGroupId })
      .then(({ data: { data } }) => data)
  }

  const loadUnreadGroupCount = (forceLoad = false) => {
    if (unreadGroupCount.value !== null && forceLoad === false) return

    return chatGroupMessageTrackClientUnreadGroupCount(apiKey)
      .then(({ data: { data: { unread_group_count: unreadGroupCountInt } } }) =>
        (unreadGroupCount.value = unreadGroupCountInt))
  }

  const appendReceivedMessage = async ({ groupId, message }) => {
    const activeConversationVal = activeConversation.value
    if (activeConversationVal.group.id !== groupId) return

    activeConversationVal.messages.push(message)
    const currentUnread = activeConversationVal.group.unreadMessagesCount
    const newUnread = Number.isInteger(currentUnread) ? currentUnread + 1 : 1
    activeConversationVal.group.unreadMessagesCount = newUnread
    return resetUnreadMessagesFromApi()
  }

  const loadNextMessagePageForActiveGroup = async () => {
    const activeConversationVal = activeConversation.value
    if (activeConversationVal.isLoading === true) return

    const pagination = activeConversationVal.pagination
    if (pagination.page >= pagination.totalPages) return

    activeConversationVal.isLoading = true
    pagination.page += 1
    return loadActiveGroupMessages()
  }

  const changeActiveGroupTranslation = (targetLanguage) => {
    const activeConversationVal = activeConversation.value
    if (activeConversationVal.isLoading === true) return

    if (activeConversationVal.group.targetLanguage === targetLanguage) return
    activeConversationVal.group.targetLanguage = targetLanguage
    activeConversationVal.isLoading = true
    activeConversationVal.pagination.page = 1
    activeConversationVal.messages.splice(0, activeConversationVal.messages.length)
    return loadActiveGroupMessages()
  }
  // Computed

  const isAnyConversationSelected = computed(() => Number.isInteger(activeConversation.value.group.id))
  const activeGroupIndex = computed(() => {
    const chatGroupsListVal = chatGroupsList.value
    const currentGroupId = activeConversation.value.group.id
    if (!Number.isInteger(currentGroupId)) return null
    const index = chatGroupsListVal.findIndex((group) => currentGroupId === group.id)
    return index === -1 ? null : index
  })
  return {
    isLoading,
    chatGroupsList,
    pagination,
    activeConversation,
    isAnyConversationSelected,
    activeGroupIndex,
    unreadGroupCount,
    createNewGroup,
    listGroups,
    changeChatGroupsPage,
    changeActiveGroup,
    createNewMessage,
    deleteMessage,
    startPrivateChat,
    updateGroup,
    exitGroup,
    deleteGroup,
    addGroupImage,
    removeGroupImage,
    chatGroupParticipants,
    loadUnreadGroupCount,
    appendReceivedMessage,
    loadNextMessagePageForActiveGroup,
    changeActiveGroupTranslation
  }
}
