<template>
<v-bottom-sheet :value="shouldshowPrompt"
  :width="$vuetify.breakpoint.xsOnly ? '100%' : '65%'">
  <v-sheet :height="$vuetify.breakpoint.xsOnly ? '210px' : '160px'" class="d-flex py-4">
    <v-row align="center" justify="center" class="align-stretch">
      <v-col cols="12" class="text-center text-h6">
        {{$t('install_app_prompt.title')}}
      </v-col>
      <v-col cols="12" md="6">
        <v-row align="start" justify="space-between">
          <v-img src="google-play-badge.svg" class="pointer"
          max-height="160"
          @click="googleAppClick"
          max-width="160">
          </v-img>
          <v-img src="app_store_logo.svg" class="pointer"
            max-height="140"
            @click="iOSAppClick"
            max-width="140">
          </v-img>
        </v-row>
      </v-col>
      <v-col class="text-center" cols="12">
        <v-btn color="primary" depressed @click="close">
          {{$t('install_app_prompt.close')}}
        </v-btn>
      </v-col>
    </v-row>
  </v-sheet>
</v-bottom-sheet>
</template>

<script>
import { isAuthenticated } from '@/composables/useAuth.js'

import { ref, computed } from 'vue'
const HIDE_INSTALL_PROMPT = 'HIDE_INSTALL_PROMPT'
export default {
  setup () {
    const alreadyClosedPrompt = ref(localStorage.getItem(HIDE_INSTALL_PROMPT) !== null)

    // Methods

    const close = () => {
      localStorage.setItem(HIDE_INSTALL_PROMPT, true)
      alreadyClosedPrompt.value = true
    }

    const googleAppClick = () => window.open('https://play.google.com/store/apps/details?id=com.dex.world.app', '_blank')
    const iOSAppClick = () => window.open('https://apps.apple.com/us/app/dex-world/id1610117890', '_blank')

    // Computed
    const shouldshowPrompt = computed(() => isAuthenticated.value && alreadyClosedPrompt.value !== true)

    return {
      shouldshowPrompt,
      close,
      googleAppClick,
      iOSAppClick
    }
  }
}
</script>
