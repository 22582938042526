<template>
  <v-row align="center" justify="space-between" class="operation-guidence pb-5">
    <operation-image-overlay
      v-bind:showImageOverlay="showImageOverlay"
      v-bind:imageSrc="imageOverlayUrl"
      v-on:cancel-report="removeImage"
      v-on:hide-image="hideImageOverlay">
    </operation-image-overlay>
    <v-col v-for="(image, index) in images"
      :key="index" cols="4">
      <v-col class="pointer pb-0">
        <v-icon v-if="images[index].hasImage" class="font-weight-black" @click="previewImageClick(index)"
                color="green">add_photo_alternate
        </v-icon>
        <label :for="`image_${randSeed}_${index}`" v-else>
          <v-icon class="font-weight-black" color="black"> add_photo_alternate </v-icon>
          <input type="file" :id="`image_${randSeed}_${index}`" accept="image/*"
            @change="event => addImage(index, event)">
        </label>
      </v-col>
    </v-col>
  </v-row>
</template>
<style lang="scss">
.operation-guidence {
  input[type="file"] {
      display: none;
  }
}
</style>
<script>
import OperationImageOverlay from '@/components/shared/OperationImageOverlay.vue'

import { uploadImage as uploadImageToRemoteServer } from '@/general_client/CloudinaryImageUploader.js'

import {
  addImages as CrossAccountOperationAddImages,
  removeImages as CrossAccountOperationRemoveImages
} from '@/api_client/CrossAccountOperation.js'

import {
  addImages as ItemOperationAddImages,
  removeImages as ItemOperationRemoveImages
} from '@/api_client/DeviceOperation.js'

import { isFile } from '@/helpers/Utils.js'

import { ref, computed } from 'vue'

const IMAGE_COUNT = 6
function releaseImageMemory (image) {
  if (!image.url) return
  URL.revokeObjectURL(image.url)
  image.url = null
}

function defaultImage () {
  return { isLoading: false, url: null, originalUploadedFile: null, hasImage: false, imageId: null }
}

async function createNewImages ({ apiKey, itemId, itemOperationId, operationId, imageUploadSignatures, imagesToAdd, recordType }) {
  const uploadImageToRemoteServerPromises = []
  imagesToAdd.forEach((image, index) => {
    const imageSignature = imageUploadSignatures[index]
    uploadImageToRemoteServerPromises.push(uploadImageToRemoteServer(image.originalUploadedFile, imageSignature))
  })
  await Promise.all(uploadImageToRemoteServerPromises)

  const imagePublicIds = imageUploadSignatures.map((signature) => signature.public_id)
  const params = { public_ids: imagePublicIds }
  if (recordType === recordTypes.itemOperation) {
    params.id = itemOperationId
    return ItemOperationAddImages({ apiKey, itemId, itemOperationId, params })
  } else {
    return CrossAccountOperationAddImages({ apiKey, operationId, params })
  }
}

function deleteImages ({ apiKey, itemId, itemOperationId, operationId, imagesToRemove, recordType }) {
  const imageIds = imagesToRemove.map((image) => image.imageId)
  const params = { image_ids: imageIds }
  if (recordType === recordTypes.itemOperation) {
    params.id = itemOperationId
    return ItemOperationRemoveImages({ apiKey, itemId, itemOperationId, params })
  } else {
    return CrossAccountOperationRemoveImages({ apiKey, operationId, params })
  }
}

const recordTypes = { itemOperation: 'ItemOperation', crossAccountOpperation: 'CrossAccountOperation' }
export default {
  components: {
    'operation-image-overlay': OperationImageOverlay
  },
  props: {
    recordType: {
      type: String,
      required: true,
      validator: function (recordType) {
        return Object.values(recordTypes).indexOf(recordType) !== -1
      }
    }
  },
  setup (props) {
    const randSeed = ref(Math.floor(Math.random() * 100000))
    const images = ref([...Array(IMAGE_COUNT)].map(() => defaultImage()))
    const imagePreviewIndex = ref(null)

    const getImage = (index) => (images.value[index])

    const addImage = (index, event) => {
      const file = event.target.files[0]
      if (!file) return
      const image = getImage(index)
      releaseImageMemory(image)

      image.isLoading = true
      image.url = URL.createObjectURL(file)
      image.originalUploadedFile = file
      image.hasImage = true
    }

    const previewImageClick = (index) => {
      const image = getImage(index)
      if (image.hasImage !== true) return

      imagePreviewIndex.value = index
    }

    const hideImageOverlay = () => {
      imagePreviewIndex.value = null
    }

    const removeImage = () => {
      const imageIndex = imagePreviewIndex.value
      const image = getImage(imageIndex)
      releaseImageMemory(image)
      image.isLoading = false
      image.originalUploadedFile = null
      image.hasImage = false
      hideImageOverlay()
    }

    const imagesToUpload = () => images.value.filter((image) => image.hasImage && isFile(image.originalUploadedFile))

    const imagesToDelete = () => images.value.filter((image) => Number.isInteger(image.imageId) && (isFile(image.originalUploadedFile) || image.url === null))

    const uploadImageCount = () => imagesToUpload().length

    const saveImages = async ({ apiKey, itemId, itemOperationId, operationId, imageUploadSignatures } = {}) => {
      const imagesToAdd = imagesToUpload()
      const imagesToRemove = imagesToDelete()
      if (imagesToRemove.length > 0) {
        await deleteImages({ apiKey, itemId, itemOperationId, operationId, imagesToRemove, recordType: props.recordType })
      }
      if (imagesToAdd.length > 0) {
        await createNewImages({ apiKey, itemId, itemOperationId, operationId, imageUploadSignatures, imagesToAdd, recordType: props.recordType })
      }
    }

    const showImageOverlay = computed(() => Number.isInteger(imagePreviewIndex.value))
    const imageOverlayUrl = computed(() => {
      if (showImageOverlay.value !== true) return ''
      const image = getImage(imagePreviewIndex.value)
      return image.url
    })

    const reset = () => images.value.forEach((image) => {
      releaseImageMemory(image)
      const newImage = defaultImage()
      for (const attr in newImage) {
        image[attr] = newImage[attr]
      }
    })

    const hasChanges = () => {
      return imagesToUpload().length > 0 || imagesToDelete().length > 0
    }
    const setImages = (imagesDate) => {
      reset()
      if (Array.isArray(imagesDate) !== true) return

      imagesDate.forEach((imageDate, index) => {
        const image = getImage(index)
        image.url = imageDate.url
        image.hasImage = true
        image.imageId = imageDate.id
      })
    }

    return {
      randSeed,
      images,
      addImage,
      previewImageClick,
      showImageOverlay,
      imageOverlayUrl,
      hideImageOverlay,
      removeImage,
      saveImages,
      uploadImageCount,
      reset,
      setImages,
      hasChanges
    }
  }
}
</script>
