<template>
  <v-row align="start" justify="start" class="account-grid-item elevation-4 pointer my-0"
    @click="accountClick">
    <v-col cols="12" class="row align-start px-0 justify-start align-self-start">
      <v-col cols="12" class="pa-0">
        <v-img class="grey darken-4 account-image"
          height="120"
          :src="storeImage">
        </v-img>
      </v-col>
      <v-col cols="9" class="t-700-16">
        <v-btn icon small @click.stop="openAccountEditDialog">
          <v-icon color="white">edit</v-icon>
        </v-btn>
        <span class="t-700-18">
          {{ account.name }}
        </span>
        <span v-if="districtNameExist">
          <v-divider vertical class="area-divider"></v-divider>
          {{ account.district_name }}
        </span>
      </v-col>
      <v-col cols="3" class="text-end t-700-16">
        <v-badge overlap
          color="red"
          :value="account.faultyDevicesTotals > 0"
          :content="account.faultyDevicesTotals">
          <v-btn icon small color="white" @click.stop="buildClick">
            <v-icon>assignment_late</v-icon>
          </v-btn>
        </v-badge>
      </v-col>
    </v-col>
    <v-col cols="12" class="account-data row no-gutters align-start justify-start pt-3 align-self-end">
      <v-col cols="12" class="t-500-16 mt-1 mb-2 px-2">
        {{ $t('account_selection.daily_progress') }}
      </v-col>
      <v-col cols="12" class="t-500-14 align-center justify-space-between row no-gutters px-2">
        <span>
          <v-icon color="white">
            apartment
          </v-icon>
          <span>
            {{ $t('account_selection.staff_tasks') }}
          </span>
        </span>
        <span class="t-500-13 ltr">
          {{ account.totalFinishedCrossAccountTasks }} &nbsp;/&nbsp; {{ account.totalCrossAccountTasks }}
        </span>
        <v-col cols="12" class="mb-2">
          <v-progress-linear rounded height="8"
            :color="hqTaskProgress === 0 ? '#DADADA' : '#C48BFD'"
            :value="hqTaskProgress">
          </v-progress-linear>
        </v-col>
        <span>
          <v-icon class="material-icons-outlined" color="white">
            home
          </v-icon>
          <span>
            {{ $t('account_selection.store_tasks') }}
          </span>
        </span>
        <span class="t-500-13 ltr">
          {{ account.totalFinishedTasks }} &nbsp;/&nbsp; {{ account.totalTasks }}
        </span>
        <v-col cols="12" class="mb-3">
          <v-progress-linear rounded height="8"
            :color="accountTaskProgress === 0 ? '#DADADA' : '#3E8BFE'"
            :value="accountTaskProgress">
          </v-progress-linear>
        </v-col>
      </v-col>
    </v-col>
  </v-row>
</template>

<style lang="scss">
.account-grid-item {
  max-width: 95vw;
  border-radius: 16px;
  height: 100%;
  background-color: #0F0E13;
  color: white;

  .account-image {
    border-top-right-radius: 16px;
    border-top-left-radius: 16px;
  }

  .area-divider {
    width: 1px;
    height: 20px;
    margin: 0 2px;
    background-color: #C9C9C9;
  }

  .account-data {
    padding: 16px 16px 4px 16px;
  }
}
</style>

<script>

import { isString } from '@/helpers/Utils.js'

import { computed } from 'vue'

export default {
  props: {
    account: {
      type: Object,
      required: true
    }
  },
  setup(props, { emit }) {
    // Methods

    const openAccountEditDialog = () => emit('edit-account', props.account)

    const buildClick = () => emit('broken-item-click', props.account)
    const accountClick = () => emit('account-click', props.account)

    // Computed
    const storeImage = computed(() => {
      const customStoreImage = props.account.custom_store_image
      if (isString(customStoreImage) && customStoreImage.trim().length > 0) {
        return customStoreImage.replace('image/upload/f_auto,q_auto,dpr_auto,c_limit,w_400,h_400', 'image/upload/f_auto,q_auto,dpr_auto')
      } else {
        return props.account.store_image
      }
    })
    const districtNameExist = computed(() => {
      const districtName = props.account.district_name
      return isString(districtName) && districtName.trim().length > 0
    })
    const hqTaskProgress = computed(() => {
      if (props.account.totalCrossAccountTasks === 0) return 0

      return (props.account.totalFinishedCrossAccountTasks / props.account.totalCrossAccountTasks) * 100
    })

    const accountTaskProgress = computed(() => {
      if (props.account.totalTasks === 0) return 0

      return (props.account.totalFinishedTasks / props.account.totalTasks) * 100
    })

    return {
      districtNameExist,
      storeImage,
      hqTaskProgress,
      accountTaskProgress,
      openAccountEditDialog,
      accountClick,
      buildClick
    }
  }
}
</script>
