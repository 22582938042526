<template>
  <v-dialog v-model="dialog" max-width="400" persistent>
    <date-picker-dialog
      :disabled="isLoading"
      :value="deadlineDate"
      :minDate="minDeallineDate"
      ref="datePickerDialog"
      @change="setDeadlineDate">
    </date-picker-dialog>
    <v-card class="broken-item-dialog"
      :class="{ 'rtl': isRtl, 'ltr': !isRtl }">
      <v-card-title class="text-center pb-0">
        <span class="headline top-title">
          {{ dialogTitle }}
        </span>
      </v-card-title>
      <v-card-text class="scrollable">
        <v-form ref="brokenItemForm">
          <v-container>
            <v-row align="start" justify="space-between" no-gutters>
              <v-col cols="12" v-if="showAccountSelection">
                <account-select ref="accountSelectRef"
                  :api-key="apiKey"
                  :multiple="false"
                  :disabled="isDialogModeUpdate"
                  @change="onAccountChange"
                  v-model="selectedAccount">
                </account-select>
              </v-col>
              <v-col cols="12" v-if="dialog">
                <item-search v-model="deviceFromSearch"
                  :place-holder="$t('broken_item_dialog_dialog.what_broke')"
                  :api-key="selectedApiKey"
                  show-add-item
                  show-delete-button
                  show-edit-button
                  load-all
                  :disabled="isLoading || isNoAccountSelected">
                </item-search>
              </v-col>
              <v-col cols="12">
                <v-textarea
                  auto-grow
                  rows="1"
                  single-line
                  v-model="newBrokenItem.description"
                  :counter="MAX_DESCRIPTION_LENGTH"
                  :label="$t('broken_item_dialog_dialog.description')">
                </v-textarea>
              </v-col>
              <v-col cols="12" class="mt-2">
                <user-search v-model="assignedUsers"
                  :api-key="selectedApiKey"
                  with-hq-users
                  multiple
                  :disabled="isLoading || isNoAccountSelected"
                  :placeholder="$t('broken_item_dialog_dialog.assigned_user')">
                </user-search>
              </v-col>
              <v-col cols="12">
                <company-provider-search
                  v-model="newBrokenItem.related_providers"
                  :api-key="selectedApiKey"
                  multiple
                  :disabled="isLoading">
                </company-provider-search>
              </v-col>
              <v-col cols="5">
                <v-text-field
                  clearable
                  @click:clear="clearDeadline"
                  :label="$t('broken_item_dialog_dialog.deadline_date')"
                  :value="deadlineString"
                  @click="openDatePicker"
                  readonly>
                </v-text-field>
              </v-col>
              <v-col cols="5">
                <time-picker-dialog :value="deadlineTimeString"
                  @input="onTimePickerChange"
                  :disabled="deadlineTimeDisabled"
                  :placeholder="$t('broken_item_dialog_dialog.deadline_time')">
                </time-picker-dialog>
              </v-col>
              <v-col cols="12" class="mt-2">
                <broken-item-images ref="brokenItemImages" imageTypes="broken_item_images"></broken-item-images>
              </v-col>
              <v-col cols="12">
                <v-btn @click="toggleMoreDetails" text color="#0040FF" class="my-2 pl-0">
                  <v-icon>{{ moreDetailsOpen ? 'expand_less' : 'expand_more' }}</v-icon>
                  {{ $t('show_more_details') }}
                </v-btn>
                <v-expand-transition>
                  <v-row align="start" v-show="moreDetailsOpen" justify="space-between" no-gutters>
                    <v-col cols="6" v-if="!isDialogModeCreate" class="pr-4">
                      <v-select
                        v-model="newBrokenItem.status"
                        :items="availableStatuses"
                        item-text="translatedName"
                        item-value="name"
                        single-line
                        :rules="[requiredField]"
                        :label="$t('broken_item_dialog_dialog.status')"></v-select>
                    </v-col>
                    <v-col :cols="isDialogModeCreate ? '7' : '6'">
                      <v-select
                        v-model="newBrokenItem.priority"
                        :items="availablePriorities"
                        @change="onPriorityChange"
                        item-text="translatedName"
                        item-value="name"
                        single-line
                        :rules="[requiredField]"
                        :label="$t('broken_item_dialog_dialog.priority')"></v-select>
                    </v-col>
                    <v-col cols="12" class="my-2">
                      <file-uploader ref="brokenItemVideo"
                        :placeholder="$t('broken_item_dialog_dialog.add_video')"
                        record-type="BrokenItemVideo">
                      </file-uploader>
                    </v-col>
                    <v-col cols="12" class="mt-4">
                      <tag-chips-select :max-tags="1"
                        v-model="newBrokenItem.tags"
                        :disabled="isLoading || isNoAccountSelected"
                        :api-key="selectedApiKey"
                        context="broken_item_tags">
                      </tag-chips-select>
                    </v-col>
                    <v-col cols="12">
                      <v-textarea
                        v-model="newBrokenItem.comment"
                        auto-grow
                        :counter="MAX_DESCRIPTION_LENGTH"
                        rows="1"
                        single-line
                        :label="$t('broken_item_dialog_dialog.comment')">
                      </v-textarea>
                    </v-col>
                  </v-row>
                </v-expand-transition>
              </v-col>
              <v-col cols="12" v-if="!isDialogModeCreate" class="t-700-16 mt-2">
                <v-icon color="black">construction</v-icon>{{ $t('broken_item_dialog_dialog.repair') }}
              </v-col>
              <v-col cols="12" v-if="!isDialogModeCreate">
                <v-textarea
                  v-model="newBrokenItem.progress_description"
                  auto-grow
                  :counter="MAX_DESCRIPTION_LENGTH"
                  rows="1"
                  single-line
                  :label="$t('broken_item_dialog_dialog.progress_description')">
                </v-textarea>
              </v-col>
              <v-col cols="12" class="mt-2" v-if="!isDialogModeCreate">
                <broken-item-images ref="brokenItemFixImages"
                  imageTypes="broken_item_fix_images"></broken-item-images>
              </v-col>
              <v-col cols="12" v-if="!isDialogModeCreate">
                <v-text-field
                  v-model="newBrokenItem.price"
                  single-line
                  v-mask="'#########'"
                  :label="$t('broken_item_dialog_dialog.price')">
                </v-text-field>
              </v-col>
              <v-col cols="12" v-if="!isDialogModeCreate">
                <file-uploader ref="brokenItemFile"
                  :placeholder="$t('broken_item_dialog_dialog.add_invoice')"
                  record-type="BrokenItem">
                </file-uploader>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
      <v-row no-gutters align="end" justify="end" class="pb-5 mme-4 mt-3 text-right">
        <v-btn color="blue darken-1" text @click="close(false)" :disabled="isLoading">
          {{ $t('cancel') }}
        </v-btn>
        <v-btn color="blue darken-1" depressed tile @click="save" :loading="isLoading" :disabled="isSaveDisabled">
          {{ $t('save') }}
        </v-btn>
      </v-row>
    </v-card>
  </v-dialog>
</template>

<style lang="scss">
.broken-item-dialog {
  .top-title {
    width: 100%;
  }

  .scrollable {
    overflow: auto;
    height: 100%;
    max-height: 400px;
  }

  input[type="file"] {
    display: none;
  }
}
</style>

<script>

import BrokenItemImages from '@/components/broken_item_dialog/BrokenItemImages.vue'
import ItemSearch from '@/components/shared/item_search/ItemSearch.vue'
import UserSearch from '@/components/shared/user_search/UserSearch.vue'
import FileUploader from '@/components/shared/FileUploader.vue'
import CompanyProviderSearch from '@/components/shared/company_provider_search/CompanyProviderSearch.vue'

import TimePickerDialog from '@/components/shared/TimePickerDialog.vue'

import useBrokenItemDeadline from '@/composables/useBrokenItemDeadline.js'

import { newBrokenItemModel, getAllStatuses, statuses, getAllPriorities } from '@/store/modules/BrokenItem.js'
import {
  dateToISO8601String, getClientDateFormatted, isValidISO8601Date, ISO8601StringToDate, ISO8601TimeFromDate
} from '@/helpers/DateTime.js'
import useAuth from '@/composables/useAuth.js'
import useAccount from '@/composables/useAccount.js'

import { create as BrokenItemClientCreate, update as BrokenItemClientUpdate, show as BrokenItemClientShow } from '@/api_client/BrokenItem.js'

import { onDialogClose, onDialogOpen } from '@/classes/DialogScrollBug.js'
import { requiredField } from '@/classes/InputValidators.js'

import { isString, isDate, isObject } from '@/helpers/Utils.js'

import { handler as errHandler } from '@/classes/ErrorHandler.js'

import { EventBus, UPDATE_TAGS_IF_NEEDED } from '@/EventBus.js'

import { mask } from 'vue-the-mask'

import Vue, { ref, computed, nextTick } from 'vue'

import { i18n, isRtl } from '@/i18n.js'

const DialogModeEnum = { update: 1, create: 2 }

const MAX_DESCRIPTION_LENGTH = 400
export default {
  components: {
    'broken-item-images': BrokenItemImages,
    'item-search': ItemSearch,
    'user-search': UserSearch,
    'file-uploader': FileUploader,
    'date-picker-dialog': () => import('@/components/shared/DatePickerDialog.vue'),
    'tag-chips-select': () => import('@/components/shared/tag_chips_select/TagChipsSelect.vue'),
    'account-select': () => import('@/components/shared/AccountSelect.vue'),
    'company-provider-search': CompanyProviderSearch,
    'time-picker-dialog': TimePickerDialog
  },
  props: {
    showAccountSelection: {
      type: Boolean,
      default: false
    },
    showCloseOption: {
      type: Boolean,
      default: false
    }
  },
  directives: {
    mask
  },
  setup(props) {
    const { accountCountryCode, accountLocaleCode } = useAccount()
    const { apiKey } = useAuth()
    const { brokenItemsDeadline, loadBrokenItemDeadlines } = useBrokenItemDeadline(apiKey.value)

    const countryCode = accountCountryCode.value
    const locale = accountLocaleCode.value
    let deviceId = null
    let originalStatus = null
    let resolveFunc = null
    let isItemIdChanged = null
    let userChangedDeadlineDateOrTime = false

    const dialog = ref(false)
    const isLoading = ref(false)
    const dialogMode = ref(null)
    const newBrokenItem = ref(newBrokenItemModel({ locale }))
    const deviceFromSearch = ref({})
    const assignedUsers = ref([])
    const closeTranslation = i18n.t('broken_item.close_ticket')
    const availableStatuses = ref(getAllStatuses({ addClosedStatus: props.showCloseOption, closeTranslation }))
    const availablePriorities = ref(getAllPriorities())
    const brokenItemImages = ref(null)
    const brokenItemFixImages = ref(null)
    const brokenItemFile = ref(null)
    const brokenItemVideo = ref(null)
    const brokenItemForm = ref(null)
    const datePickerDialog = ref(null)
    const selectedAccount = ref(null)
    const accountSelectRef = ref(null)
    const minDeallineDate = dateToISO8601String(new Date())
    const moreDetailsOpen = ref(false)

    const setDefaultDeadlineForPriority = () => {
      if (dialogMode.value !== DialogModeEnum.create) return
      const deadlines = brokenItemsDeadline.value
      const priority = newBrokenItem.value.priority
      const foundDefaultDeadline = deadlines.find((deadline) => deadline.priority === priority &&
        deadline.deadline_type === 'on_time')

      if (isObject(foundDefaultDeadline) !== true) return
      const hours = foundDefaultDeadline.minutes / 60
      const designatedDeadline = new Date();
      designatedDeadline.setHours(designatedDeadline.getHours() + hours);
      newBrokenItem.value.deadline_date = designatedDeadline
      newBrokenItem.value.deadline_time = ISO8601TimeFromDate(designatedDeadline)
    }

    const openDialog = (deviceIdArg, brokenItem, dialogModeVal) => {
      onDialogOpen()

      dialog.value = true
      deviceId = deviceIdArg
      dialogMode.value = dialogModeVal
      newBrokenItem.value = newBrokenItemModel({ src: brokenItem, locale })
      originalStatus = newBrokenItem.value.status
      setDefaultDeadlineForPriority()
      return new Promise((resolve) => {
        resolveFunc = resolve
      })
    }

    const setDeviceForSearch = (brokenItem) => {
      const deviceFromSearchVal = deviceFromSearch.value
      Vue.set(deviceFromSearchVal, 'id', brokenItem.item_id)
      Vue.set(deviceFromSearchVal, 'name', brokenItem.item_name)
      Vue.set(deviceFromSearchVal, 'department_name', brokenItem.item_department)
    }

    const setAssignedUsers = (brokenItem) => {
      const shouldAddAssignedUsers = Array.isArray(brokenItem.assigned_users) && brokenItem.assigned_users.length > 0

      if (shouldAddAssignedUsers !== true) return
      assignedUsers.value = []
      const assignedUsersVal = assignedUsers.value
      brokenItem.assigned_users.forEach(user => assignedUsersVal.push({ id: user.user_id }));
    }

    const updateBrokenItem = ({ creationParams, newDeviceId, uploadImageCount, uploadImageCountForFix }) => {
      if (originalStatus === statuses.close && newBrokenItem.value.status !== statuses.close) {
        creationParams.close_date = ''
      }
      if (newDeviceId !== deviceId) {
        isItemIdChanged = true
        creationParams.item_id = newDeviceId
      }
      return BrokenItemClientUpdate(selectedApiKey.value, deviceId,
        newBrokenItem.value.id, { broken_item: creationParams, with_image: uploadImageCount, with_fix_image: uploadImageCountForFix })
    }

    const createBrokenItem = ({ creationParams, deviceId, uploadImageCount }) => {
      return BrokenItemClientCreate(selectedApiKey.value, deviceId, { broken_item: creationParams, with_image: uploadImageCount })
    }

    const updateOrCreateBrokenItem = async () => {
      if (isDialogModeUpdate.value && originalStatus !== statuses.close &&
        newBrokenItem.value.status === statuses.close) {
        newBrokenItem.value.setClosingTime()
      }
      const creationParams = newBrokenItem.value.serialize()
      creationParams.assigned_users = Array.isArray(assignedUsers.value) && assignedUsers.value.length > 0
        ? assignedUsers.value.map((user) => user.id)
        : null

      const deviceId = deviceFromSearch.value.id
      const uploadImageCount = brokenItemImages.value.uploadImageCount()

      const attachedVideoFileName = brokenItemVideo.value.attachedFileName()
      if (isString(attachedVideoFileName) === true) {
        creationParams.with_video = attachedVideoFileName
      }

      if (isDialogModeUpdate.value !== true) return createBrokenItem({ creationParams, deviceId, uploadImageCount })

      const attachedFileName = brokenItemFile.value.attachedFileName()
      if (isString(attachedFileName) === true) {
        creationParams.with_file = attachedFileName
      }
      const uploadImageCountForFix = brokenItemFixImages.value.uploadImageCount()
      return updateBrokenItem({ creationParams, newDeviceId: deviceId, uploadImageCount, uploadImageCountForFix })
    }

    const saveAdditionalFiles = async (updateOrCreateResponse) => {
      const responseData = updateOrCreateResponse.data.data
      const apiCalls = []
      const deviceId = deviceFromSearch.value.id
      const brokenItemId = responseData.id
      if (brokenItemImages.value.hasChanges() === true) {
        const imageUploadSignatures = responseData.image_upload_data
        apiCalls.push(brokenItemImages.value.saveImages({ apiKey: selectedApiKey.value, deviceId, brokenItemId, imageUploadSignatures }))
      }
      if (isDialogModeUpdate.value === true && brokenItemFixImages.value.hasChanges() === true) {
        const imageUploadSignatures = responseData.image_upload_data_fix
        apiCalls.push(brokenItemFixImages.value.saveImages({ apiKey: selectedApiKey.value, deviceId, brokenItemId, imageUploadSignatures }))
      }

      if (isDialogModeUpdate.value === true && brokenItemFile.value.hasChanges() === true) {
        const fileUploadSignature = responseData.file_upload_signature
        apiCalls.push(brokenItemFile.value.saveFile({ apiKey: selectedApiKey.value, itemId: deviceId, fileUploadSignature, brokenItemId }))
      }

      if (brokenItemVideo.value.hasChanges() === true) {
        const fileUploadSignature = responseData.video_upload_signature
        apiCalls.push(brokenItemVideo.value.saveFile({ apiKey: selectedApiKey.value, itemId: deviceId, fileUploadSignature, brokenItemId }))
      }

      return Promise.all(apiCalls).then(() => {
        if (apiCalls.length === 0) return updateOrCreateResponse
        return BrokenItemClientShow(selectedApiKey.value, deviceId, brokenItemId)
      })
    }

    const updateTags = () => {
      const tags = newBrokenItem.value.tags
      if (Array.isArray(tags) && tags.length > 0) EventBus.emit(UPDATE_TAGS_IF_NEEDED, { tags: tags[0], context: 'broken_item_tags', apiKey: selectedApiKey.value })
    }

    // Methods
    const openDatePicker = () => datePickerDialog.value.openDialog()
    const onPriorityChange = () => {
      if (userChangedDeadlineDateOrTime) return
      setDefaultDeadlineForPriority()
    }

    const onTimePickerChange = (time) => {
      newBrokenItem.value.deadline_time = time
      userChangedDeadlineDateOrTime = true
    }

    const clearDeadline = () => {
      newBrokenItem.value.deadline_date = null
      newBrokenItem.value.deadline_time = null
      userChangedDeadlineDateOrTime = true
    }
    const setDeadlineDate = (date) => {
      newBrokenItem.value.deadline_date = date
      userChangedDeadlineDateOrTime = true
    }
    const toggleMoreDetails = () => (moreDetailsOpen.value = !moreDetailsOpen.value)
    const onAccountChange = (newValue) => {
      selectedAccount.value = newValue
      deviceFromSearch.value = {}
      assignedUsers.value = []
      newBrokenItem.tags = []
    }
    const openForUpdate = (brokenItem) => {
      if (props.showAccountSelection === true) {
        selectedAccount.value = brokenItem.account_api_key
      }
      setDeviceForSearch(brokenItem)
      setAssignedUsers(brokenItem)
      if (brokenItemImages.value && brokenItemFixImages.value && brokenItemFile.value && brokenItemVideo.value) {
        brokenItemImages.value.setImages(brokenItem.images)
        brokenItemFixImages.value.setImages(brokenItem.fixed_images)
        brokenItemFile.value.setFile(brokenItem.attached_file_url)
        brokenItemVideo.value.setFile(brokenItem.attached_video_url)
      } else {
        nextTick(() => {
          brokenItemImages.value.setImages(brokenItem.images)
          brokenItemFixImages.value.setImages(brokenItem.fixed_images)
          brokenItemFile.value.setFile(brokenItem.attached_file_url)
          brokenItemVideo.value.setFile(brokenItem.attached_video_url)
        })
      }
      return openDialog(brokenItem.item_id, brokenItem, DialogModeEnum.update)
    }

    const openForCreateWithDeviceSearch = () => {
      return openDialog(null, null, DialogModeEnum.create)
    }

    const close = (resolveResult, response) => {
      onDialogClose()
      const isItemIdChangedVal = isItemIdChanged
      userChangedDeadlineDateOrTime = false
      dialogMode.value = null
      dialog.value = false
      isItemIdChanged = false
      deviceId = null
      deviceFromSearch.value = {}
      assignedUsers.value = []
      selectedAccount.value = null
      moreDetailsOpen.value = false
      resolveFunc({ updatedOrCreated: resolveResult, response, isItemIdChanged: isItemIdChangedVal })
    }


    const save = () => {
      if (!brokenItemForm.value.validate()) return
      isLoading.value = true
      updateOrCreateBrokenItem()
        .then(saveAdditionalFiles)
        .then((response) => {
          updateTags()
          close(true, (response || { data: { data: {} } }).data.data)
        })
        .catch(errHandler)
        .finally(() => (isLoading.value = false))
    }

    // Computed
    const isDialogModeUpdate = computed(() => dialogMode.value === DialogModeEnum.update)
    const isDialogModeCreate = computed(() => dialogMode.value === DialogModeEnum.create)
    const isAccountSelected = computed(() =>
      props.showAccountSelection && isString(selectedAccount.value) && selectedAccount.value.length > 0
    )
    const isNoAccountSelected = computed(() => props.showAccountSelection === true && !isAccountSelected.value)

    const selectedApiKey = computed(() => {
      if (isAccountSelected.value === true) return selectedAccount.value
      return apiKey.value
    })


    const dialogTitle = computed(() => {
      return isDialogModeUpdate.value === true
        ? i18n.t('broken_item_dialog_dialog.update_title')
        : i18n.t('broken_item_dialog_dialog.create_title')
    })
    const isSaveDisabled = computed(() => {
      if (isNoAccountSelected.value === true) return true
      if (!Number.isInteger((deviceFromSearch.value || {}).id)) return true
      const newBrokenItemVal = newBrokenItem.value

      if (isString(newBrokenItemVal.description) && newBrokenItemVal.description.length > MAX_DESCRIPTION_LENGTH) return true
      if (isString(newBrokenItemVal.comment) && newBrokenItemVal.comment.length > MAX_DESCRIPTION_LENGTH) return true
      if (isString(newBrokenItemVal.progress_description) && newBrokenItemVal.progress_description.length > MAX_DESCRIPTION_LENGTH) return true

      return isLoading.value
    })

    const deadlineDate = computed(() => {
      const deadline = newBrokenItem.value.deadline_date
      if (isValidISO8601Date(deadline) === true) return ISO8601StringToDate(deadline)
      if (isDate(deadline)) return deadline

      return null
    })
    const deadlineString = computed(() => {
      const deadline = deadlineDate.value
      if (!isDate(deadline)) return ''

      return getClientDateFormatted({ fromDate: deadline, countryCode })
    })

    const deadlineTimeDisabled = computed(() => deadlineDate.value === null)
    const deadlineTimeString = computed(() => deadlineTimeDisabled.value ? '' : newBrokenItem.value.deadline_time)

    loadBrokenItemDeadlines()
    return {
      MAX_DESCRIPTION_LENGTH,
      dialog,
      isLoading,
      apiKey: apiKey.value,
      deviceFromSearch,
      assignedUsers,
      availableStatuses,
      availablePriorities,
      dialogTitle,
      isDialogModeCreate,
      newBrokenItem,
      brokenItemImages,
      brokenItemFixImages,
      brokenItemFile,
      brokenItemVideo,
      brokenItemForm,
      datePickerDialog,
      isSaveDisabled,
      deadlineString,
      deadlineTimeString,
      deadlineTimeDisabled,
      minDeallineDate,
      deadlineDate,
      selectedAccount,
      isNoAccountSelected,
      selectedApiKey,
      isDialogModeUpdate,
      accountSelectRef,
      moreDetailsOpen,
      isRtl,
      openForUpdate,
      openDatePicker,
      openForCreateWithDeviceSearch,
      clearDeadline,
      setDeadlineDate,
      toggleMoreDetails,
      close,
      save,
      requiredField,
      onAccountChange,
      onPriorityChange,
      onTimePickerChange
    }
  }
}
</script>
