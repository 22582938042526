import axios from 'axios'
import qs from 'qs'
const API_URL = import.meta.env.VITE_APP_API_URL
const API_VERSION_PATH = '/api/v1/'
const apiClient = axios.create({
  baseURL: API_URL + API_VERSION_PATH,
  withCredentials: true,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }
})

apiClient.interceptors.request.use(config => {
  config.paramsSerializer = params => {
    return qs.stringify(params, {
      arrayFormat: 'brackets',
      encode: false
    })
  }

  return config
})
export default apiClient
