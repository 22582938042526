import Client from '@/api_client/client.js'

import router from '@/router.js'

import useBrokenItemCount from '@/composables/useBrokenItemCount.js'

import useItemSearchStore from '@/components/shared/item_search/useItemSearchStore.js'

function show (apiKey, deviceId, brokenItemId) {
  const FAULTY_DEVICE_SHOW_PATH = `/accounts/${apiKey}/items/${deviceId}/broken_items/${brokenItemId}`
  return Client.get(FAULTY_DEVICE_SHOW_PATH)
}

function index (apiKey, deviceId, params) {
  const FAULTY_DEVICE_INDEX_PATH = `/accounts/${apiKey}/items/${deviceId}/broken_items`
  return Client.get(FAULTY_DEVICE_INDEX_PATH, { params })
}

async function create (apiKey, deviceId, params) {
  const FAULTY_DEVICE_CREATE_PATH = `/accounts/${apiKey}/items/${deviceId}/broken_items`
  const response = await Client.post(FAULTY_DEVICE_CREATE_PATH, params)
  reloadAccountFaultyDevice(apiKey)
  return response
}

async function update (apiKey, deviceId, brokenItemId, params) {
  const FAULTY_DEVICE_UPDATE_PATH = `/accounts/${apiKey}/items/${deviceId}/broken_items/${brokenItemId}`
  const response = await Client.put(FAULTY_DEVICE_UPDATE_PATH, params)
  reloadAccountFaultyDevice(apiKey)
  return response
}

async function destroy (apiKey, deviceId, brokenItemId) {
  const FAULTY_DEVICE_DESTROY_PATH = `/accounts/${apiKey}/items/${deviceId}/broken_items/${brokenItemId}`
  const response = await Client.delete(FAULTY_DEVICE_DESTROY_PATH)
  reloadAccountFaultyDevice(apiKey)
  return response
}

function addImages ({ apiKey, deviceId, brokenItemId, publicIds, imageType }) {
  const FAULTY_DEVICE_ADD_IMAGES_PATH = `/accounts/${apiKey}/items/${deviceId}/broken_items/${brokenItemId}/add_images`
  return Client.post(FAULTY_DEVICE_ADD_IMAGES_PATH, { broken_item: { image_names: publicIds, image_type: imageType } })
}

function deleteImages ({ apiKey, deviceId, brokenItemId, imageIds, imageType }) {
  const FAULTY_DEVICE_REMOVE_IMAGES_PATH = `/accounts/${apiKey}/items/${deviceId}/broken_items/${brokenItemId}/delete_images`
  return Client.delete(FAULTY_DEVICE_REMOVE_IMAGES_PATH, { params: { image_ids: imageIds, image_type: imageType } })
}

function addFile ({ apiKey, deviceId, brokenItemId, params }) {
  const FAULTY_DEVICE_ADD_FILE_PATH = `/accounts/${apiKey}/items/${deviceId}/broken_items/${brokenItemId}/add_file`
  return Client.post(FAULTY_DEVICE_ADD_FILE_PATH, params)
}

function removeFile ({ apiKey, deviceId, brokenItemId, fileType }) {
  const FAULTY_DEVICE_REMOVE_FILE_PATH = `/accounts/${apiKey}/items/${deviceId}/broken_items/${brokenItemId}/remove_file`
  return Client.delete(FAULTY_DEVICE_REMOVE_FILE_PATH, { params: { file_type: fileType } })
}

function reloadAccountFaultyDevice (apiKey) {
  const { loadBrokenItemCount, loadBrokenItemCompanyCount } = useBrokenItemCount(apiKey)

  loadBrokenItemCount(true)
  if (router.history.current.meta.staffOnly === true) {
    loadBrokenItemCompanyCount(true)
  }
  invalidItemSearchCache(apiKey)
}

function invalidItemSearchCache (apiKey) {
  const { invalidateCache } = useItemSearchStore(apiKey)
  invalidateCache()
}

export {
  show,
  index,
  create,
  update,
  destroy,
  addImages,
  deleteImages,
  addFile,
  removeFile
}
