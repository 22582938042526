<template>
  <v-dialog v-model="dialog" max-width="450" persistent>
    <v-card class="item-op-list-upsert"
      :class="{ 'rtl': isRtl, 'ltr': !isRtl }">
      <v-card-title class="text-center pb-0">
        <div class="full-width">{{ $t('item_op_list_dialog.title') }}</div>
      </v-card-title>
      <v-card-text>
        <div class="text-center mt-5" v-if="listLoading">
          <v-progress-circular
            indeterminate
            color="primary">
          </v-progress-circular>
        </div>
        <v-container class="pb-0 scrollable px-0" v-else>
          <draggable v-model="itemOperationList"
            handle=".drag-handle"
            :disabled="itemsChangeOrderDisabled"
            animation="300"
            @change="onListOrderChanged">
            <v-row align="center" justify="center" no-gutters
              v-for="(itemOperationListItem, index) in itemOperationList"
              :key="`${itemOperationListItem.id}-${index}`"
              class="mb-4 single-row">
              <v-col cols="12" class="d-flex justify-space-between align-center v-row">
                <v-col cols="8">
                  <item-type-select
                    v-model="itemOperationListItem.item_type"
                    @change="onTypeChange(itemOperationListItem)">
                  </item-type-select>
                </v-col>
                <v-col cols="3" class="texts-end px-0">
                  <v-btn text small icon class="black--text drag-handle" v-if="allowChangeItemOrder">
                    <v-icon>drag_indicator</v-icon>
                  </v-btn>
                </v-col>
              </v-col>
              <v-col cols="10">
                <v-textarea
                  class="mt-0 pt-0"
                  v-model="itemOperationListItem.description"
                  counter="500"
                  auto-grow
                  no-resize
                  rows="1">
                </v-textarea>
              </v-col>
              <v-col cols="2" class="texts-end mb-5">
                <v-btn text small icon class="black--text" @click="deleteItem(itemOperationListItem, index)">
                  <v-icon>delete</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </draggable>
          <v-row align="center" justify="start" no-gutters ref="addNewItemRow">
            <v-col cols="2" class="texts-start">
              <v-btn text small icon class="black--text mx-1" @click="addEmptyItem" :disabled="haveInvalidItems">
                <v-icon>add</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
        <v-card-actions class="pt-0 pr-0 mt-5">
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="close">
            {{ $t('close') }}
          </v-btn>
          <v-btn color="blue darken-1" depressed tile @click="save" :disabled="saveDisabled" :loading="saveLoading">
            {{ $t('save') }}
          </v-btn>
        </v-card-actions>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<style lang="scss">
.item-op-list-upsert {
  .scrollable {
    overflow: auto;
    height: 100%;
    max-height: 400px;
  }

  .single-row {
    border: 2px solid #e0e0e0;
    border-radius: 10px;
    padding: 8px 12px;
  }
}
</style>
<script>
import {
  index as itemOperationListItemClientIndex,
  batchUpsert as itemOperationListItemClientBatchUpsert,
  updateItemsOrder as itemOperationListItemClientUpdateItemsOrder
} from '@/api_client/ItemOperationListItem.js'

import { itemTypes } from '@/models/ItemOperationListItemTypes.js'
import { onDialogClose, onDialogOpen } from '@/classes/DialogScrollBug.js'
import { isString } from '@/helpers/Utils.js'

import { handler as errHandler } from '@/classes/ErrorHandler.js'

import { i18n, isRtl } from '@/i18n.js'

import { ref, computed, nextTick } from 'vue'
const DialogMode = { regular: 1, preExistingList: 2, withoutPersist: 3 }

export default {
  props: {
    apiKey: {
      type: String,
      required: true
    },
    allowChangeItemOrder: {
      type: Boolean,
      default: true
    }
  },
  components: {
    'item-type-select': () => import('@/components/operation_schedule_dialog/item_operation_list/ItemTypeSelect.vue'),
    draggable: () => import('vuedraggable')
  },
  setup(props) {
    let dialogMode = null
    let itemId = null
    let operationItemId = null
    let itemOperationListItemsToDelete = []
    let hadItemOperationList = false
    let resolveFunc = null
    let orderChanged = false
    let isChanged = false

    const dialog = ref(false)
    const saveLoading = ref(false)
    const listLoading = ref(false)
    const addNewItemRow = ref(null)
    const itemOperationList = ref([])

    const isNewUnSavedItem = (itemOperationList) => !Number.isInteger(itemOperationList.id)
    const isSavedItem = (itemOperationList) => Number.isInteger(itemOperationList.id)
    const isDescriptionChanged = (itemOperationList) => itemOperationList.description !== itemOperationList.oldDescription
    const isItemTypeChanged = (itemOperationList) => itemOperationList.item_type !== itemOperationList.oldItemType
    const itemOperationListValid = (itemOperationList) => (itemOperationList.description || '').trim().length > 0
    const onListOrderChanged = () => (orderChanged = true)

    const singleItemOperationList = (itemOperationList) => Object.assign(itemOperationList, {
      oldDescription: itemOperationList.description,
      oldItemType: itemOperationList.item_type
    })

    const loadItemOperationList = () => {
      listLoading.value = true
      itemOperationListItemClientIndex(props.apiKey, itemId, operationItemId)
        .then((response) => {
          itemOperationList.value = []
          const itemOperationListVal = itemOperationList.value
          response.data.forEach((item) => itemOperationListVal.push(singleItemOperationList(item)))
          hadItemOperationList = itemOperationList.value.length > 0
        })
        .catch(errHandler)
        .finally(() => (listLoading.value = false))
    }

    const openDialogForMode = (dialogModeArg, operationItemIdArg, itemList) => {
      onDialogOpen()
      dialogMode = dialogModeArg
      dialog.value = true
      const itemListArr = (itemList || []).map(singleItemOperationList)
      itemOperationList.value = []
      const itemOperationListVal = itemOperationList.value
      itemListArr.forEach((item) => itemOperationListVal.push(item))
      hadItemOperationList = itemOperationListVal.length > 0
      operationItemId = operationItemIdArg
      return new Promise((resolve) => {
        resolveFunc = resolve
      })
    }

    const closeDialogForPreExistingListMode = (isSaved) => {
      const resolveResult = { isSaved }
      if (isSaved === true) {
        resolveResult.itemList = itemOperationList.value.map((item) => ({
          item_type: item.item_type,
          description: item.description
        }))
      }
      return resolveFunc(resolveResult)
    }

    const closeDialogForWithoutPresistMode = (isSaved) => {
      const resolveResult = { isSaved }
      resolveResult.itemList = itemOperationList.value.map((item) => ({
        id: item.id,
        item_type: item.item_type,
        description: item.description
      }))
      return resolveFunc(resolveResult)
    }

    const getCreateParams = () => itemOperationList.value.filter(isNewUnSavedItem).map((itemOperationListItem) => ({
      description: itemOperationListItem.description,
      item_type: itemOperationListItem.item_type
    }))

    const getUpdateParams = () => itemOperationList.value.filter(isSavedItem)
      .filter((itemOpList) => isItemTypeChanged(itemOpList) || isDescriptionChanged(itemOpList))
      .map((itemOperationListItem) => ({
        id: itemOperationListItem.id,
        description: itemOperationListItem.description,
        item_type: itemOperationListItem.item_type
      }))

    const closeDialogForRegularMode = (isSaved) => {
      let resolveResult = { changed: false }
      if (hadItemOperationList) {
        if (itemOperationList.value.length === 0 || (itemOperationList.value.every(isNewUnSavedItem) && !isSaved)) {
          resolveResult = { changed: true, hasListItems: false }
        }
      }
      if (isSaved && !hadItemOperationList && itemOperationList.value.length > 0) {
        resolveResult = { changed: true, hasListItems: true }
      }

      itemOperationList.value = []
      return resolveFunc(resolveResult)
    }

    // Methods
    const openDialogWithExistingList = (itemList = []) => openDialogForMode(DialogMode.preExistingList, null, itemList)
    const openDialogWithoutPersist = (operationItemId = null, itemList = []) => openDialogForMode(DialogMode.withoutPersist, operationItemId, itemList)
    const onTypeChange = (item) => {
      if (!isNewUnSavedItem(item) || item.item_type !== itemTypes.feedback) return
      if (isString(item.description) && item.description.trim().length > 0) return
      item.description = i18n.t('item_op_list_dialog.feedback_description')
    }
    const changeItemType = (item, newType) => {
      item.item_type = item.item_type === newType ? itemTypes.default : newType
    }

    const addEmptyItem = () => {
      const newItem = singleItemOperationList({ description: '', item_type: itemTypes.default })
      itemOperationList.value.push(newItem)
      const el = addNewItemRow.value
      nextTick(() => el.scrollIntoView({ behavior: 'smooth', block: 'end' }))
    }

    const openDialog = (itemIdArg, operationItemIdArg) => {
      onDialogOpen()
      dialogMode = DialogMode.regular
      itemId = itemIdArg
      operationItemId = operationItemIdArg
      dialog.value = true
      saveLoading.value = false
      loadItemOperationList()
      return new Promise((resolve) => {
        resolveFunc = resolve
      })
    }

    const close = (saveClicked = false) => {
      onDialogClose()
      const dialogModeValueWas = dialogMode
      dialogMode = null
      itemId = null
      operationItemId = null
      itemOperationListItemsToDelete = []
      const isSaved = isChanged || orderChanged
      isChanged = false
      orderChanged = false
      dialog.value = false
      if (dialogModeValueWas === DialogMode.preExistingList) return closeDialogForPreExistingListMode(saveClicked === true)

      if (dialogModeValueWas === DialogMode.withoutPersist) return closeDialogForWithoutPresistMode(saveClicked === true)
      return closeDialogForRegularMode(isSaved)
    }

    const deleteItem = (itemOperationListItem, index) => {
      itemOperationList.value.splice(index, 1)
      if (isNewUnSavedItem(itemOperationListItem) || dialogMode === DialogMode.withoutPersist) return

      itemOperationListItemsToDelete.push(itemOperationListItem.id)
    }
    const addIdsToNewItems = (newItemListIds) => {
      itemOperationList.value.filter(isNewUnSavedItem)
        .forEach((itemOperationListItem, index) => (itemOperationListItem.id = newItemListIds[index]))
    }

    const updateOrder = async (newItemListIds) => {
      if (orderChanged !== true) return
      if (Array.isArray(newItemListIds) && newItemListIds.length > 0) addIdsToNewItems(newItemListIds)
      const updateOrderParams = { ids_order: itemOperationList.value.map((item) => item.id) }
      return itemOperationListItemClientUpdateItemsOrder(props.apiKey, itemId, operationItemId, updateOrderParams)
    }

    const upsertItems = async () => {
      const createParams = getCreateParams()
      const updateParams = getUpdateParams()
      const deleteParams = itemOperationListItemsToDelete
      if (createParams.length === 0 && updateParams.length === 0 && deleteParams.length === 0) return

      isChanged = true
      return itemOperationListItemClientBatchUpsert(props.apiKey, itemId, operationItemId, {
        create_params: createParams,
        update_params: updateParams,
        delete_params: deleteParams
      })
    }

    const save = () => {
      if (dialogMode === DialogMode.preExistingList || dialogMode === DialogMode.withoutPersist) {
        return close(true)
      }
      saveLoading.value = true
      upsertItems()
        .then((response) => updateOrder(response?.data?.created_items))
        .then(close)
        .catch(errHandler)
        .finally(() => (saveLoading.value = false))
    }

    // Computed
    const haveInvalidItems = computed(() => itemOperationList.value.some((itemOperationlistItem) => !itemOperationListValid(itemOperationlistItem)))
    const itemsChangeOrderDisabled = computed(() => saveLoading.value || props.allowChangeItemOrder !== true)
    const saveDisabled = computed(() => {
      const allItemsAreTitles = itemOperationList.value.every((item) => item.item_type === itemTypes.title || item.item_type === itemTypes.feedback)
      if (allItemsAreTitles === true && itemOperationList.value.length > 0) return true

      return haveInvalidItems.value
    })

    return {
      dialog,
      itemOperationList,
      listLoading,
      saveLoading,
      addNewItemRow,
      saveDisabled,
      haveInvalidItems,
      itemsChangeOrderDisabled,
      itemTypes,
      isRtl,
      changeItemType,
      openDialog,
      openDialogWithExistingList,
      openDialogWithoutPersist,
      onListOrderChanged,
      close,
      onTypeChange,
      deleteItem,
      addEmptyItem,
      save
    }
  }
}
</script>
