import {
  index as SuppliersClientIndex, show as SuppliersClientShow,
  destroy as SuppliersClientDestroy,
  create as SuppliersClientCreate,
  update as SuppliersClientUpdate
} from '@/api_client/OrganizationStructureSupplier.js'

import {
  create as jobOpScheduleCreate,
  destroy as jobOpScheduleDestroy,
  update as jobOpScheduleUpdate
} from '@/api_client/JobOperationSchedule.js'

import { destroy as supplierItemOperationDestroy } from '@/api_client/OrganizationSupplierOperation.js'

import AccountSupplierSearchStore from '@/components/shared/supplier_search/AccountSupplierSearchStore.js'
import { newSupplierModel } from '@/store/modules/OrganizationSupplier.js'

import { isString } from '@/helpers/Utils.js'

import { ref } from 'vue'

const DEFAULT_PER_PAGE = 30
const DEFAULT_PAGE = 1

let currentApiKey = null

const isLoading = ref(false)
const pagination = ref({ page: DEFAULT_PAGE, per_page: DEFAULT_PER_PAGE })
const suppliersList = ref([])
const totalSuppliers = ref(0)
const totalPages = ref(1)

export default function useAccountSupplier (apiKey) {
  const resetValues = () => {
    isLoading.value = false
    pagination.value = { page: DEFAULT_PAGE, per_page: DEFAULT_PER_PAGE }
    suppliersList.value = []
    totalSuppliers.value = 0
    totalPages.value = 1
  }

  if (isString(apiKey) && currentApiKey !== apiKey) {
    resetValues()
    currentApiKey = apiKey
  }

  const setTablePage = ({ data, meta }) => {
    suppliersList.value = []
    const suppliersVal = suppliersList.value
    data.forEach((supplier) => suppliersVal.push(newSupplierModel(supplier)))

    totalSuppliers.value = meta.total
    pagination.value.page = meta.page
    const total = totalSuppliers.value || 0
    if (total === 0 || total <= pagination.value.per_page) {
      totalPages.value = 0
    } else {
      totalPages.value = Math.ceil(total / pagination.value.per_page)
    }
  }

  const loadAllSuppliersForSearch = () => {
    const { loadAccountSupplier } = AccountSupplierSearchStore(currentApiKey)
    loadAccountSupplier()
  }
  // Methods

  const loadAllSuppliers = async (page) => {
    isLoading.value = true
    if (Number.isInteger(page)) {
      pagination.value.page = page
    }
    const response = await SuppliersClientIndex(currentApiKey, pagination)
    setTablePage({ data: response.data.data, meta: response.data.meta })
    isLoading.value = false
  }

  const showSingleSupplier = async (supplierId) => {
    isLoading.value = true
    const response = await SuppliersClientShow(currentApiKey, supplierId)
    setTablePage({ data: [response.data.data], meta: { total: 1, page: DEFAULT_PAGE } })
    isLoading.value = false
  }

  const loadSupplier = async (supplierId) => {
    isLoading.value = true
    const response = await SuppliersClientShow(currentApiKey, supplierId)

    const supplierIndex = suppliersList.value.findIndex((supplier) => supplier.id === supplierId)
    if (supplierIndex > -1) suppliersList.value[supplierIndex].update(response.data.data)

    isLoading.value = false
  }

  const deleteSupplier = async (supplierId) => {
    isLoading.value = true
    await SuppliersClientDestroy(currentApiKey, supplierId)
    let page = pagination.value.page
    if (page > 1 && suppliersList.value.length === 0) {
      page = page - 1
    }
    await loadAllSuppliers(page)
    loadAllSuppliersForSearch()
    isLoading.value = false
  }

  const createSupplier = async (params) => {
    await SuppliersClientCreate(currentApiKey, { account_supplier: params })
    loadAllSuppliersForSearch()
    await loadAllSuppliers()
  }

  const updateSupplier = async (params) => {
    await SuppliersClientUpdate(currentApiKey, params.id, { account_supplier: params })
    loadAllSuppliersForSearch()
    await loadAllSuppliers()
  }

  const createOperationSchedule = async (params) => {
    await jobOpScheduleCreate(currentApiKey, params.executor_id, params.operationParams)
    await loadSupplier(params.supplierId)
  }

  const deleteOperationSchedule = async (params) => {
    await jobOpScheduleDestroy(currentApiKey, params.executor_id, params.operation_schedule_id)
    await loadSupplier(params.supplierId)
  }

  const updateOperationSchedule = async (params) => {
    isLoading.value = true
    await jobOpScheduleUpdate(currentApiKey, params.executor_id, params.operation_schedule_id, params.operationParams)
    await loadSupplier(params.supplierId)
    isLoading.value = false
  }

  const deleteSupplierOperation = async (params) => {
    await supplierItemOperationDestroy(currentApiKey, params.supplierId, params.operationId)
    await loadSupplier(params.supplierId)
  }

  return {
    DEFAULT_PER_PAGE,
    isLoading,
    pagination,
    suppliersList,
    totalSuppliers,
    totalPages,
    loadAllSuppliers,
    showSingleSupplier,
    loadSupplier,
    deleteSupplier,
    createSupplier,
    updateSupplier,
    createOperationSchedule,
    deleteOperationSchedule,
    updateOperationSchedule,
    deleteSupplierOperation
  }
}
