<template>
  <v-row align="center" justify="space-between" no-gutters>
    <v-col cols="12" lg="4" md="6">
      <account-select class="mb-4" v-if="showAccountSelect"
        :api-key="apiKey"
        v-model="apiKeysFilter"
        multiple
        @blur="onChangeAccount">
      </account-select>
    </v-col>
    <v-col cols="12"></v-col>
    <v-col cols="12" lg="4" md="6">
      <v-text-field
        v-model="filters.textSearch"
        prepend-icon="search"
        height="24"
        class="text-search filter-select"
        clearable
        :label="$t('task_bank.serach_placeholder')"
        @click:clear="clearSearchField">
      </v-text-field>
    </v-col>
    <v-col cols="12"></v-col>
    <v-col cols="12" class="row no-gutters align-center justify-start mt-4">
      <v-col cols="4" lg="2" sm="3" class="pps-2" v-if="showJobSearch">
        <job-selection-search
          v-model="filters.accountJobs"
          class="filter-select"
          :disabled="isLoading"
          :api-key="apiKey"
          :hide-shift="staffAccount"
          multiple
          return-object
          :menu-props="{ offsetY: true }"
          @change="onFilterChanged"
          :label="$t('job_op_schedule.choose_job')">
        </job-selection-search>
      </v-col>
      <v-col cols="4" lg="2" sm="3" class="pps-2" v-else-if="showHqJobSearch">
        <hq-task-job-search :api-key="apiKey"
          v-model="filters.accountJobs"
          height="40"
          class="filter-select">
        </hq-task-job-search>
      </v-col>
      <v-col cols="4" lg="2" sm="3" class="pps-2">
        <v-select v-model="filters.frequency"
          clearable
          :items="frequencyOptions"
          class="filter-select"
          height="40"
          item-text="name"
          item-value="value"
          multiple
          :menu-props="{ offsetY: true }"
          @change="onFilterChanged"
          :disabled="disabled" :label="$t('op_schedule_frequency_dialog.placeholder')">
          <template v-slot:selection="{ item, index }">
            <div v-if="index === 0" class="v-select__selection v-select__selection--comma">
              {{ $t('x_selected', { count: filters.frequency.length }) }}
            </div>
          </template>
        </v-select>
      </v-col>
      <v-col cols="4" lg="2" sm="3" v-if="!staffAccount && !hqContext" class="pps-2">
        <account-job-shift-select class="filter-select" height="40" :menu-props="{ offsetY: true }"
          v-model="filters.shifts"
          :multiple="true" @change="onFilterChanged" :disabled="disabled">
        </account-job-shift-select>
      </v-col>
      <v-col cols="4" lg="2" sm="3" class="pps-2">
        <v-select v-model="filters.image" clearable :items="imageOptions" class="filter-select" height="40"
          :menu-props="{ offsetY: true }" @change="onFilterChanged" :disabled="disabled"
          :label="$t('account_operations.image')">
          <template v-slot:item="{ item }">
            <v-row align="center" justify="start" no-gutters :class="{ 'rtl': isRtl }">
              {{ item.text }}
            </v-row>
          </template>
        </v-select>
      </v-col>
      <v-col cols="4" lg="2" sm="3" class="pps-2">
        <operation-report-tag v-model="filters.reportTags" multiple class="filter-select" height="40" show-select-count
          :menu-props="{ offsetY: true }" @change="onFilterChanged">
        </operation-report-tag>
      </v-col>
      <v-col cols="8" lg="4" sm="6">
        <v-switch v-if="!staffAccount && !hqContext" :disabled="disabled" v-model="filters.crossAccountOperations"
          class="d-inline-block ltr pps-2" :label="$t('account_operations.cross_account')" @change="onFilterChanged">
        </v-switch>
        <v-switch :disabled="disabled" v-model="filters.checkList" class="d-inline-block ltr pps-2"
          :label="$t('account_operations.check_list')" @change="onFilterChanged">
        </v-switch>
      </v-col>
    </v-col>
    <v-col cols=12 class="mt-5">
      <v-chip class="color5 t-500-14 mme-2" color="#CBD5F2" outlined v-for="activeChip in activeChips"
        :key="activeChip.name">
        {{ activeChip.text }}
        <v-icon size="25" class="pointer" @click="clearTag(activeChip)">
          close
        </v-icon>
      </v-chip>
      <v-chip v-if="showResetAll" class="color5 t-500-14 mme-2 pointer" color="#CBD5F2" outlined
        @click="onResetFilters">
        {{ $t('reset_all') }}
      </v-chip>
    </v-col>
  </v-row>
</template>
<script>
import AccountSelect from '@/components/shared/AccountSelect.vue'
import JobSelectionSearch from '@/components/job_selection_search/JobSelectionSearch.vue'
import HqTaskJobSearch from '@//components/shared/HqTaskJobSearch.vue'
import AccountJobShiftSelect from '@/components/shared/AccountJobShiftSelect.vue'
import OperationReportTagField from '@/components/operation_schedule_dialog/OperationReportTagField.vue'
import {
  frequencyOptionsForSelectUsingSingleType as frequencySelectOptions,
  translateFrequencyUsingOneType as translateFrequncy
} from '@/store/modules/OperationFrequency.js'

import useAuth from '@/composables/useAuth.js'
import { translateShift } from '@/store/modules/OrganizationJob.js'
import { translateReportTag } from '@/store/modules/ReportTag.js'

import useTaskBank from '@/composables/useTaskBank.js'

import { isObject, isString } from '@/helpers/Utils.js'

import { i18n, isRtl } from '@/i18n.js'

import { ref, onDeactivated, watch, computed } from 'vue'

const SESSION_FILTER_KEY_TASK_BANK = 'task-bank-filters-session'

const sessionFilterKey = (apiKey) => `${SESSION_FILTER_KEY_TASK_BANK}-${apiKey}`

const getSavedFilterFromSession = (apiKey) => {
  const filter = localStorage.getItem(sessionFilterKey(apiKey))
  if (isString(filter) !== true) return
  try {
    return JSON.parse(filter)
  } catch (e) {
  }
}

export default {
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    apiKey: {
      type: String,
      required: true
    },
    showAccountSelect: {
      type: Boolean,
      default: true
    },
    staffAccount: {
      type: Boolean,
      default: false
    },
    hqContext: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  components: {
    'account-select': AccountSelect,
    'account-job-shift-select': AccountJobShiftSelect,
    'operation-report-tag': OperationReportTagField,
    'job-selection-search': JobSelectionSearch,
    'hq-task-job-search': HqTaskJobSearch
  },
  setup(props) {
    const { filters, apiKeysFilter, applyFilterChanges, resetFilters } = useTaskBank(props.apiKey, props.hqContext)
    const { isUserCompanyAdmin } = useAuth()

    const imageOptions = ref([
      { text: i18n.t('account_operations.with_image'), value: true },
      { text: i18n.t('account_operations.without_image'), value: false }])
    const checklistOptions = ref([{ text: i18n.t('account_operations.with_checklist'), value: true }])
    const debounceTimeout = ref(null);

    const debounce = (func, delay) => {
      if (debounceTimeout.value) {
        clearTimeout(debounceTimeout.value);
      }
      debounceTimeout.value = setTimeout(func, delay);
    }


    const saveFilter = () => {
      const currentFilter = JSON.parse(JSON.stringify(filters.value))
      currentFilter.api_keys = JSON.parse(JSON.stringify(apiKeysFilter.value))
      localStorage.setItem(sessionFilterKey(props.apiKey), JSON.stringify(currentFilter))
    }

    const setFilterFromSession = () => {
      const filtersVal = filters.value
      apiKeysFilter.value = [props.apiKey]
      const filtersFromSession = getSavedFilterFromSession(props.apiKey)
      if (isObject(filtersFromSession) !== true) return

      if ('shifts' in filtersFromSession && !props.staffAccount === true) {
        filtersVal.shifts = filtersFromSession.shifts
      }
      if ('image' in filtersFromSession) {
        filtersVal.image = filtersFromSession.image
      }
      if ('checkList' in filtersFromSession) {
        filtersVal.checkList = filtersFromSession.checkList
      }
      if ('crossAccountOperations' in filtersFromSession && !props.staffAccount === true) {
        filtersVal.crossAccountOperations = filtersFromSession.crossAccountOperations
      }
      if ('reportTags' in filtersFromSession) {
        filtersVal.reportTags = filtersFromSession.reportTags
      }
      if ('textSearch' in filtersFromSession) {
        filtersVal.textSearch = filtersFromSession.textSearch
      }
      if (('frequency' in filtersFromSession)) {
        filtersVal.frequency = filtersFromSession.frequency
      }
      if (('accountJobs' in filtersFromSession)) {
        filtersVal.accountJobs = filtersFromSession.accountJobs
      }
      if (('api_keys' in filtersFromSession) && props.showAccountSelect === true) {
        apiKeysFilter.value = filtersFromSession.api_keys
      }
    }
    // Methods
    const clearSearchField = () => { filters.value.textSearch = '' }

    const onChangeAccount = () => {
      saveFilter()
      applyFilterChanges()
    }

    const onFilterChanged = () => {
      saveFilter()
      applyFilterChanges()
    }

    const clearTag = (tag) => {
      const filtersVal = filters.value
      if (tag.type === 'image') {
        filtersVal.image = null
      } else if (tag.type === 'accountJobs') {
        filtersVal.accountJobs = filtersVal.accountJobs.filter((item) => item.id !== tag.name)
      }
      else {
        filtersVal[tag.type] = filtersVal[tag.type].filter((item) => item !== tag.name)
      }
      onFilterChanged()
    }
    const onResetFilters = () => {
      resetFilters()
      onFilterChanged()
    }

    // Computed
    const showJobSearch = computed(() => {
      if (props.staffAccount) return true
      return !props.hqContext && !isUserCompanyAdmin.value
    })

    // Computed
    const showHqJobSearch = computed(() => !props.staffAccount && !props.hqContext && isUserCompanyAdmin.value)

    const activeChips = computed(() => {
      const result = []
      const filtersVal = filters.value
      if (filtersVal.shifts.length > 0) {
        filtersVal.shifts.forEach((shift) => result.push({
          name: shift, text:
            translateShift(shift), type: 'shifts'
        }))
      }
      if (filtersVal.reportTags.length > 0) {
        filtersVal.reportTags.forEach((reportTag) => result.push({
          name: reportTag,
          text: translateReportTag(reportTag),
          type: 'reportTags'
        }))
      }
      if (filtersVal.frequency.length > 0) {
        filtersVal.frequency.forEach((frequency) =>
          result.push({
            name: frequency,
            text: translateFrequncy(frequency),
            type: 'frequency'
          }))
      }
      if (filtersVal.image === true || filtersVal.image === false) {
        const translationKey = filtersVal.image === true ? 'with_image' : 'without_image'
        result.push({
          name: 'image', text: i18n.t(`account_operations.${translationKey}`),
          type: 'image'
        })
      }
      if (Array.isArray(filtersVal.accountJobs) && filtersVal.accountJobs.length > 0) {
        filtersVal.accountJobs.forEach((job) =>
          result.push({
            name: job.id,
            text: job.name,
            type: 'accountJobs'
          }))
      }
      return result
    })

    const showResetAll = computed(() => activeChips.value.length > 1)

    setFilterFromSession()
    applyFilterChanges()
    onDeactivated(saveFilter)

    watch(() => filters.value.textSearch, () => debounce(onFilterChanged, 400))
    return {
      filters,
      imageOptions,
      checklistOptions,
      isRtl,
      activeChips,
      showJobSearch,
      showHqJobSearch,
      showResetAll,
      frequencyOptions: frequencySelectOptions(),
      apiKeysFilter,
      onFilterChanged,
      clearSearchField,
      onChangeAccount,
      clearTag,
      onResetFilters
    }
  }
}
</script>