<template>
  <v-row align="center" justify="center" no-gutters class="single-reported-item my-4">
    <v-col cols="3" v-if="!isTypeTitle"
      class="row no-gutters align-center justify-center align-self-center">
      <v-progress-circular
        v-if="showImageLodaing"
        indeterminate
        color="primary">
      </v-progress-circular>
      <v-col cols="12" class="row align-center no-gutters justif-space-between media-preview-container"
        v-else-if="isTypeImage">
        <v-col cols="10" v-if="hasImages">
          <v-img
            max-width="100px" max-height="100px"
            contain
            class="d-relative rounded-lg grey darken-4 pointer mme-0 mb-2"
            @click="showImage"
            :src="listItem.images[0].url">
            <span class="image-counter" v-if="showImageCounter">
              {{ listItem.images.length }}
            </span>
            <template v-slot:placeholder>
              <v-row align="center" justify="center" class="fill-height" no-gutters>
                <v-progress-circular indeterminate color="primary"></v-progress-circular>
              </v-row>
            </template>
          </v-img>
        </v-col>
        <v-col :cols="hasImages ? 2 : 12" class="text-center" v-if="!readOnly">
          <label :for="index" class="pointer">
            <v-icon class="font-weight-black" :color="uploadImageDisabled ? 'gray' : 'black'"> add_a_photo
            </v-icon>
            <input type="file" :id="index" accept="image/*" :disabled="uploadImageDisabled" capture="capture"
              @change="uploadImage">
          </label>
        </v-col>
      </v-col>
      <v-checkbox v-if="isTypeDefault" :input-value="listItem.bool_value" @change="changeCheckBox"
        :disabled="isDisabled"
        class="report-check-box">
      </v-checkbox>
    </v-col>
    <v-col cols="9" v-if="!isTypeTitle" class="item-desc">
      <span class="t-700-16 show-line-break">
        {{ listItem.description }}
        <div v-if="isTypeFeedback" class="color3">
          {{ $t('optional') }}
        </div>
      </span>
      <div v-if="showCreationDate" class="t-500-13 color5">
        <v-icon>
          today
        </v-icon>
        {{ listItem.reportDate }} {{ listItem.reportTime }}
      </div>
      <div v-if="isTypeRating || isTypeYesNo" class="d-flex justify-space-between align-center">
        <v-rating v-if="isTypeRating" :value="listItem.int_value" hover :readonly="isDisabled" @input="changeRating"
          length="5" color="indigo">
        </v-rating>
        <v-radio-group v-else :value="listItem.int_value" @change="changeRating" row>
          <v-radio :label="$t('yes')" :value="1">
          </v-radio>
          <v-radio :label="$t('no')" :value="0">
          </v-radio>
        </v-radio-group>
        <v-btn icon @click="cancelRating" :disabled="isCancelRatingDisabled" color="pink">
          <v-icon>cancel</v-icon>
        </v-btn>
      </div>
      <div>
        <v-textarea class="ma-0 pt-0 comment" :counter="stringValueMaxLength" @change="changeComment"
          :value="listItem.string_value" rows="1" auto-grow :disabled="isDisabled">
        </v-textarea>
      </div>
    </v-col>
    <v-col cols="12" v-if="isTypeTitle" class="text-center mt-3 mb-5 font-weight-bold">
      <v-chip color="black white--text title-chip">
        {{ listItem.description }}
      </v-chip>
    </v-col>
  </v-row>
</template>
<style lang="scss">
.single-reported-item {
  input[type="file"] {
    display: none;
  }

  .v-chip.title-chip {
    height: auto;

    .v-chip__content {
      min-height: 32px;
      white-space: pre-wrap;
    }
  }

  .image-counter {
    z-index: 6;
    position: absolute;
    bottom: 6px;
    right: 6px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: white;
    color: black;
    font-size: 12px;
    text-align: center;
    line-height: 20px;
  }

  .report-check-box {
    margin-top: 0;

    .v-input__control {
      .v-messages {
        display: none;
      }

      .v-input__slot {
        margin-bottom: 0;

        .v-input--selection-controls__input {
          margin-right: 0;

          .v-icon {
            font-size: 28px;
          }
        }
      }
    }
  }

  .comment {
    .v-input--is-disabled {
      color: rgba(0, 0, 0, 1);

      .v-text-field__slot {
        textarea:disabled {
          color: rgba(0, 0, 0, 1);
        }
      }
    }

    .v-text-field__slot {
      textarea {
        max-height: 8rem;
        overflow-y: auto;
      }
    }
  }
}
</style>
<script>
import { itemTypes, stringValueMaxLength } from '@/models/ItemOperationListItemTypes.js'
import { isString } from '@/helpers/Utils.js'
import { i18n } from '@/i18n.js'
import { computed } from 'vue'

const MaxImages = 6

export default {
  props: {
    listItem: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    isDisabled: {
      type: Boolean,
      required: true,
    },
    readOnly: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  setup(props, { emit }) {

    // Methods
    const uploadImage = (event) => emit('upload-image', { event, item: props.listItem })
    const showImage = () => emit('image-click', props.listItem)
    const changeRating = (value) => emit('change-field', { value, item: props.listItem, field: 'int_value' })
    const cancelRating = () => changeRating(null)
    const changeCheckBox = (value) => emit('change-field', { value, item: props.listItem, field: 'bool_value' })
    const changeComment = (value) => emit('change-field', { value: value, item: props.listItem, field: 'string_value' })

    // Computed
    const isTypeTitle = computed(() => props.listItem.item_type === itemTypes.title)
    const isTypeImage = computed(() => props.listItem.item_type === itemTypes.image)
    const isTypeDefault = computed(() => props.listItem.item_type === itemTypes.default)
    const isTypeRating = computed(() => props.listItem.item_type === itemTypes.rating)
    const isTypeYesNo = computed(() => props.listItem.item_type === itemTypes.yes_no)
    const isTypeFeedback = computed(() => props.listItem.item_type === itemTypes.feedback)
    const hasImages = computed(() => Array.isArray(props.listItem.images) && props.listItem.images.length > 0)
    const showImageCounter = computed(() => hasImages.value && props.listItem.images.length > 1)
    const showCreationDate = computed(() => isString(props.listItem.reportTime) && isString(props.listItem.reportDate))
    const showImageLodaing = computed(() => isTypeImage.value && props.listItem.imageIsUploading)
    const isCancelRatingDisabled = computed(() => props.listItem.int_value === null ||
      !Object.prototype.hasOwnProperty.call(props.listItem, 'int_value'))

    const uploadImageDisabled = computed(() => props.listItem.imageIsUploading === true || (props.listItem.images || []).length >= MaxImages)

    return {
      isTypeTitle,
      isTypeImage,
      isTypeDefault,
      isTypeRating,
      isTypeYesNo,
      isTypeFeedback,
      isCancelRatingDisabled,
      showImageLodaing,
      showCreationDate,
      uploadImageDisabled,
      hasImages,
      showImageCounter,
      changeRating,
      uploadImage,
      showImage,
      cancelRating,
      changeCheckBox,
      changeComment,
      stringValueMaxLength
    }
  }
}
</script>