<template>
  <v-dialog v-model="isDialogOpen" max-width="400" persistent>
    <v-card class="device-object-new" :class="{ 'rtl': isRtl, 'ltr': !isRtl }">
      <v-card-title class="text-center pb-0">
        <span class="headline" style="width: 100%">
          {{ dialogTitle }}
        </span>
      </v-card-title>
      <v-card-text class="py-0">
        <v-form ref="newObjectForm">
          <v-container grid-list-md class="pb-0">
            <v-row align="center" justify="center" no-gutters>
              <v-col cols="12">
                <item-category-search
                  v-model="itemCategory"
                  :api-key="apiKey"
                  show-add-new-button
                  show-delete-button
                  show-edit-button>
                </item-category-search>
              </v-col>
              <v-col cols="12">
                <v-textarea
                  auto-grow
                  rows="1"
                  :rules="[requiredField]"
                  single-line
                  v-model="newObject.name"
                  counter="80"
                  :label="itemPlaceHolderVal">
                </v-textarea>
              </v-col>
              <v-col cols="12">
                <v-textarea
                  auto-grow
                  rows="1"
                  single-line
                  v-model="newObject.department.name"
                  counter="120"
                  :label="$t('device_device_new_dialog.device_department')">
                </v-textarea>
              </v-col>
              <v-col cols="12" class="mt-3">
                <v-card-actions class="pt-0 ppe-0">
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="close" :disabled="isLoading">
                    {{ $t('cancel') }}
                  </v-btn>
                  <v-btn color="blue darken-1" depressed tile @click="save" :loading="isLoading"
                    :disabled="isSaveDisabled">
                    {{ $t('save') }}
                  </v-btn>
                </v-card-actions>
              </v-col>
            </v-row>
            <v-snackbar
              v-model="errorSnackBar"
              :timeout="3000">
              <div class="text-center">
                {{ $t('device_device_new_dialog.name_taken_err') }}
              </div>
            </v-snackbar>
          </v-container>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>

import { requiredField } from '@/classes/InputValidators.js'
import objectModel from '@/store/modules/DeviceObject.js'

import { onDialogClose, onDialogOpen } from '@/classes/DialogScrollBug.js'
import { create as devicesClientCreate, update as devicesClientUpdate } from '@/api_client/Device.js'

import { isString } from '@/helpers/Utils.js'

import { mask } from 'vue-the-mask'

import { computed, ref, nextTick } from 'vue'

import { i18n, isRtl } from '@/i18n.js'
const DialogMode = { create: 0, update: 1 }

export default {
  directives: {
    mask
  },
  props: {
    itemPlaceHolder: {
      type: String,
      required: null
    },
    apiKey: {
      type: String,
      required: true
    }
  },
  components: {
    'item-category-search': import('@/components/shared/item_category_search/ItemCategorySearch.vue')
  },
  setup(props) {
    const isLoading = ref(false)
    const newObject = ref(objectModel.newObjectModel())
    const errorSnackBar = ref(false)
    const dialogMode = ref(null)
    const isDialogOpen = ref(false)
    const newObjectForm = ref(null)
    const itemCategory = ref({})
    let resolveFunc = null

    const closeDialogAndReset = (updatedOrCreated, itemId) => {
      onDialogClose()
      newObjectForm.value.reset()
      isDialogOpen.value = false
      itemCategory.value = {}
      errorSnackBar.value = false
      resolveFunc({ updatedOrCreated, itemId })
    }

    const createAndUpdateParams = () => {
      const item = newObject.value.serialize()
      item.item_category_id = itemCategory.value?.id || ''
      return { item }
    }

    const createNewObject = () => {
      devicesClientCreate(props.apiKey, createAndUpdateParams())
        .then((resp) => closeDialogAndReset(true, resp.data.data.id))
        .catch((err) => {
          const errorsArray = err?.response?.data?.errors
          if (Array.isArray(errorsArray) && errorsArray.some((err) => err === 'name_taken')) {
            errorSnackBar.value = true
          }
          throw err
        })
        .finally(() => { isLoading.value = false })
    }

    const updateObject = () => {
      devicesClientUpdate(props.apiKey, newObject.value.id, createAndUpdateParams())
        .then(() => closeDialogAndReset(true))
        .finally(() => { isLoading.value = false })
    }

    const openDialog = (device) => {
      onDialogOpen()
      isDialogOpen.value = true
      newObject.value = objectModel.newObjectModel(device)
      return new Promise((resolve, reject) => {
        resolveFunc = resolve
      })
    }

    // Methods
    const close = () => closeDialogAndReset(false)

    const openForCreate = (preDefinedCategory) => {
      dialogMode.value = DialogMode.create
      if (Number.isInteger(preDefinedCategory)) {
        itemCategory.value = { id: preDefinedCategory }
      }
      return openDialog()
    }

    const openForUpdate = (device) => {
      dialogMode.value = DialogMode.update
      if (Number.isInteger(device.category_id)) {
        itemCategory.value = { id: device.category_id, name: device.category_name }
      }
      return openDialog(device)
    }

    const save = () => {
      if (!newObjectForm.value.validate()) return
      nextTick(() => {
        isLoading.value = true
        dialogMode.value === DialogMode.create ? createNewObject() : updateObject()
      })
    }

    // Computed
    const dialogTitle = computed(() => {
      if (dialogMode.value === DialogMode.create) {
        return i18n.t('device_device_new_dialog.create_title')
      }
      return i18n.t('device_device_new_dialog.update_title')
    })

    const isSaveDisabled = computed(() => {
      if (isLoading.value) return true
      if (!newObject.value.name) return true
      return false
    })

    const itemPlaceHolderVal = computed(() => {
      if (isString(props.itemPlaceHolder) && props.itemPlaceHolder.length > 0) {
        return props.itemPlaceHolder
      }

      return i18n.t('device_device_new_dialog.device_name')
    })

    return {
      isLoading,
      isSaveDisabled,
      newObject,
      isDialogOpen,
      errorSnackBar,
      newObjectForm,
      dialogTitle,
      itemPlaceHolderVal,
      itemCategory,
      isRtl,
      openForCreate,
      openForUpdate,
      close,
      save,
      requiredField
    }
  }
}
</script>
