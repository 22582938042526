import { searchAll as searchAllDevices } from '@/api_client/Device.js'

import { isString } from '@/helpers/Utils.js'

import { ref } from 'vue'

const allItems = ref([])
const isLoading = ref(false)

let currentApiKey = null

let shouldReload = true
let shouldReloadForBrokenItems = true
let shouldReloadForBrokenItemsWithOpenStatus = true

export default function useItemSearchStore(apiKey) {
  const invalidateCache = () => {
    shouldReload = true
    shouldReloadForBrokenItems = true
    shouldReloadForBrokenItemsWithOpenStatus = true
  }

  const resetValues = () => {
    isLoading.value = false
    allItems.value.splice(0, allItems.value.length)
    invalidateCache()
  }

  if (isString(apiKey) && currentApiKey !== apiKey) {
    resetValues()
    currentApiKey = apiKey
  }

  // Methods

  const loadAllItems = async () => {
    if (shouldReload === false) return
    isLoading.value = true
    return searchAllDevices(currentApiKey)
      .then((response) => {
        const allItemsVal = allItems.value
        allItemsVal.splice(0, allItemsVal.length)
        response.data.data.forEach((item) => allItemsVal.push(item))
        shouldReload = false
      })
      .finally(() => (isLoading.value = false))
  }


  const changeApiKey = (newApiKey) => {
    if (isString(newApiKey) && currentApiKey !== newApiKey) {
      currentApiKey = newApiKey
      resetValues()
    }
  }

  return {
    allItems,
    isLoading,
    loadAllItems,
    invalidateCache,
    changeApiKey
  }
}
