import Client from '@/api_client/client.js'

const index = (apiKey, blogPostId, params) => {
  const INDEX_BLOG_POST_LIKE_PATH = `/accounts/${apiKey}/blog_posts/${blogPostId}/blog_post_likes`
  return Client.get(INDEX_BLOG_POST_LIKE_PATH, { params })
}

const create = (apiKey, blogPostId) => {
  const CREATE_BLOG_POST_LIKE_PATH = `/accounts/${apiKey}/blog_posts/${blogPostId}/blog_post_likes`
  return Client.post(CREATE_BLOG_POST_LIKE_PATH)
}

const destroy = (apiKey, blogPostId) => {
  const DELETE_BLOG_POST_LIKE_PATH = `/accounts/${apiKey}/blog_posts/${blogPostId}/blog_post_likes`
  return Client.delete(DELETE_BLOG_POST_LIKE_PATH)
}

export {
  index,
  create,
  destroy
}
