<template>
  <span class="faulty-device-count">
    <slot v-if="slotPassed" v-bind:faultyDeviceCount="faultyDeviceCount"></slot>
    <v-badge
      v-else-if="uiMode === 'badge'"
      :overlap="overlap"
      v-bind:class="{ 'red-c': faultyDeviceCount > 0, 'none': faultyDeviceCount === 0 }"
      color="white">
      <template v-slot:badge> <span class="font-weight-bold">{{ faultyDeviceCount }}</span></template>
      <v-icon medium color="white" @click="navigationClick" class="pointer">assignment_late</v-icon>
    </v-badge>
    <v-btn v-else-if="uiMode === 'button'"
      text color="white" block tile icon
      class="font-weight-bold no-badge"
      height="44"
      @click="navigationClick">
      <v-row justify="space-between" align="center" no-gutters class="px-2">
        <v-icon color="white" left class="mr-0">assignment_late</v-icon>
        {{ $t("faults") }}
        <v-chip color="red--text">
          {{ faultyDeviceCount }}
        </v-chip>
      </v-row>
    </v-btn>
    <v-chip color="red white--text" v-else
      @click="navigationClick">
      {{ faultyDeviceCount }}
    </v-chip>
  </span>
</template>
<style lang="scss">
.faulty-device-count {
  .v-badge {
    cursor: pointer;

    .v-badge__badge {
      margin-right: 10px;
      margin-top: 2px;
    }

    @media only screen and (max-width: 960px) {
      .v-badge__badge {
        margin-right: 15px;
        margin-top: 10px;
      }
    }

    &.none {
      .v-badge__badge {
        display: none;
      }
    }

    &.red-c {
      .v-badge__badge {
        background-color: red !important;
      }
    }
  }

  .v-btn.no-badge {
    background-color: #FC4A4A;

    &.v-btn--active {
      background-color: #263238;
    }
  }
}
</style>
<script>
import useBrokenItemCount from '@/composables/useBrokenItemCount.js'
import useAuth from '@/composables/useAuth.js'

import { useRouter, useRoute } from 'vue-router/composables'

import { computed, onUnmounted } from 'vue'

const DATA_PULLING_INTERVAL = 1000 * 60 * 5

export default {
  props: {
    overlap: {
      required: false,
      type: Boolean,
      default: false
    },
    uiMode: {
      required: false,
      type: String,
      default: 'badge',
      validator: function (value) {
        return ['badge', 'button', 'chip'].indexOf(value) !== -1
      }
    },
    countMode: {
      required: false,
      type: String,
      default: 'account',
      validator: function (value) {
        return ['account', 'company'].indexOf(value) !== -1
      }
    }
  },

  setup(props, { slots }) {
    let dataPullingIntervalId = null
    const router = useRouter()
    const route = useRoute()
    const { apiKey } = useAuth()
    const {
      accountFaultyDeviceCompanyCount, accountFaultyDeviceCount,
      loadBrokenItemCompanyCount, loadBrokenItemCount
    } = useBrokenItemCount(apiKey.value)
    const loadFaultyDeviceCount = (forceLoad = false) => {
      props.countMode === 'company'
        ? loadBrokenItemCompanyCount(forceLoad)
        : loadBrokenItemCount(forceLoad)
    }

    const startPullingNewData = () => {
      dataPullingIntervalId = setInterval(loadFaultyDeviceCount, DATA_PULLING_INTERVAL)
    }
    const stopPullingNewData = () => window.clearInterval(dataPullingIntervalId)

    // Methods
    const navigationClick = () => {
      if (props.countMode === 'account') {
        if (route.name === 'account_faulty_devices') return

        router.push({ name: 'account_faulty_devices' })
      }
      if (props.countMode === 'company') {
        if (route.name === 'staff/company_faulty_devices') return

        router.push({ name: 'staff/company_faulty_devices' })
      }
    }

    // Computed
    const faultyDeviceCount = computed(() => props.countMode === 'company' ? accountFaultyDeviceCompanyCount.value : accountFaultyDeviceCount.value)
    const slotPassed = computed(() => !!slots.default)
    loadFaultyDeviceCount()
    startPullingNewData()
    onUnmounted(stopPullingNewData)
    return {
      faultyDeviceCount,
      slotPassed,
      navigationClick
    }
  }
}
</script>
