import Client from '@/api_client/client.js'
function index (apiKey) {
  const COMPANY_DISTRICT_INDEX_PATH = `/accounts/${apiKey}/company_districts`
  return Client.get(COMPANY_DISTRICT_INDEX_PATH)
}

function create (apiKey, districtName) {
  const COMPANY_DISTRICT_CREATE_PATH = `/accounts/${apiKey}/company_districts`
  return Client.post(COMPANY_DISTRICT_CREATE_PATH, { company_district: { name: districtName } })
}

function destroy (apiKey, districtId) {
  const COMPANY_DISTRICT_DESTROY_PATH = `/accounts/${apiKey}/company_districts/${districtId}`
  return Client.delete(COMPANY_DISTRICT_DESTROY_PATH)
}

export {
  index,
  create,
  destroy
}
