import Client from '@/api_client/client.js'

function resetUnreadMessages ({ lang, apiKey }) {
  const SYSTEM_GUIDANCE_TRACK_RESET_UNREAD_PATH = `accounts/${apiKey}/system_guidances_track/reset_unread_messages`
  return Client.put(SYSTEM_GUIDANCE_TRACK_RESET_UNREAD_PATH, { lang })
}

function unreadMessages ({ lang, apiKey }) {
  const SYSTEM_GUIDANCE_TRACK_UNREAD_PATH = `accounts/${apiKey}/system_guidances_track/unread_messages`
  return Client.get(SYSTEM_GUIDANCE_TRACK_UNREAD_PATH, { params: { lang } })
}
export {
  resetUnreadMessages,
  unreadMessages
}
