
import { i18n } from '@/i18n.js'

import axios from 'axios'

import { ref, computed } from 'vue'

const COUTRY_FROM_IP_API_URL = 'https://get.geojs.io/v1/ip/country.json'
const LANGUAGE_STORE_KEY = 'lang-key'

const countryCodeToLangague = {
  US: 'en',
  GB: 'en',
  UA: 'en',
  IL: 'he',
  ES: 'es',
  AR: 'es',
  CL: 'es',
  MX: 'es',
  PY: 'es',
  PE: 'es',
  UY: 'es'
}

const language = ref(null)

const setLanguage = ({ language: languageArg, reload = false }) => {
  language.value = languageArg
  localStorage.setItem(LANGUAGE_STORE_KEY, languageArg)
  i18n.locale = languageArg
  if (reload === true) {
    location.reload(true)
  }
}

const CountryCodeFromIp = () => {
  return new Promise((resolve, reject) => {
    axios.get(COUTRY_FROM_IP_API_URL)
      .then((response) => {
        resolve(response.data.country)
      })
      // eslint-disable-next-line
      .catch(() => reject(null))
  })
}

// Methods
const initLanguage = async () => {
  const languageFromCache = localStorage.getItem(LANGUAGE_STORE_KEY)
  if (languageFromCache !== null) {
    setLanguage({ language: languageFromCache })
    return
  }
  const countryCode = await CountryCodeFromIp()
  const languageFromIp = countryCodeToLangague[countryCode] || i18n.locale
  setLanguage({ language: languageFromIp })
}

// Computed
const currentLanguage = computed(() => language.value)
export {
  currentLanguage,
  initLanguage,
  setLanguage
}
