<template>
  <v-card class="stat-card"
    :style="{ 'background-color': backgroundColor, color: color, 'border-radius': borderRadius }">
    <v-row align="center" justify="start" no-gutters class="full-height">
      <v-col cols="3" class="align-self-center">
        <span v-if="iconCircleColor"
          class="icon-circle" :style="{ 'background-color': iconCircleColor }">
          <v-icon color="white" size="22">{{ icon }}</v-icon>
        </span>
        <v-icon v-else :color="color" size="22">{{ icon }}</v-icon>
      </v-col>
      <v-col cols="9" class="row no-gutters full-height">
        <v-col cols="12" class="stat-title align-self-start">
          {{ title }}
        </v-col>
        <v-col cols="12" class="stat-value align-self-end">
          {{ statValue }}
        </v-col>
      </v-col>
    </v-row>
  </v-card>
</template>
<style lang="scss">
.stat-card {
  padding: 4px 12px !important;
  height: 100%;

  .icon-circle {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 28px;
    height: 28px;
    border-radius: 50%;
  }

  &.selected {
    cursor: pointer;
    border: 2px solid black;
    box-sizing: border-box;
  }

  .stat-title {
    font-weight: 700;
    font-size: 16px;
    font-style: normal;
  }

  .stat-value {
    font-size: 38px;
    font-weight: 600;
    line-height: 36px;
  }
}
</style>
<script>
export default {
  props: {
    title: {
      type: String,
      required: true
    },
    statValue: {
      type: String | Number,
      required: false,
      default: '0'
    },
    icon: {
      type: String,
      required: true
    },
    backgroundColor: {
      type: String,
      required: false,
      default: 'white'
    },
    color: {
      type: String,
      required: false,
      default: 'black'
    },
    iconCircleColor: {
      type: String,
      required: false,
      default: null
    },
    borderRadius: {
      type: String,
      required: false,
      default: '60px'
    }
  }
}
</script>