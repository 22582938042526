<template>
  <v-menu top min-width="100" v-if="showComponent">
    <template v-slot:activator="{ on }">
      <v-btn v-on="on" small icon>
        <v-icon color="black">more_vert</v-icon>
      </v-btn>
    </template>
    <v-list :class="{ 'rtl': isRtl, 'ltr': !isRtl }">
      <v-list-item v-if="showDelete"
        @click="onDeleteMessage"
        :disabled="deleteInProgress"
        class="px-2">
        <v-list-item-content>
          {{ $t('delete') }}
        </v-list-item-content>
        <v-btn icon class="px-1" small
          :loading="deleteInProgress">
          <v-icon>delete</v-icon>
        </v-btn>
      </v-list-item>
      <v-list-item v-if="showInfo"
        @click="onInfoClick"
        :disabled="deleteInProgress" class="px-2">
        <v-list-item-content>
          {{ $t('info') }}
        </v-list-item-content>
        <v-btn icon class="px-1" small>
          <v-icon>info</v-icon>
        </v-btn>
      </v-list-item>
      <v-list-item v-if="showCopy"
        @click="onCopyClick" class="px-2">
        <v-list-item-content>
          {{ $t('copy') }}
        </v-list-item-content>
        <v-btn icon class="px-1" small>
          <v-icon>content_copy</v-icon>
        </v-btn>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import { isRtl } from '@/i18n'
import { computed } from 'vue'
export default {
  props: {
    deleteInProgress: {
      type: Boolean,
      default: false
    },
    showDelete: {
      type: Boolean,
      default: true
    },
    showInfo: {
      type: Boolean,
      default: true
    },
    showCopy: {
      type: Boolean,
      default: true
    }
  },
  setup(props, { emit }) {
    // Methods

    const onDeleteMessage = () => emit('delete-message')
    const onInfoClick = () => emit('info-click')
    const onCopyClick = () => emit('copy-click')

    // Computed
    const showComponent = computed(() => props.showDelete || props.showInfo || props.showCopy)
    return {
      showComponent,
      isRtl,
      onDeleteMessage,
      onInfoClick,
      onCopyClick
    }
  }
}
</script>
