import { batchCreate as batchCreateShortLinks } from '@/api_client/UrlShortener.js'

import useCompany from '@/composables/useCompany.js'

import { formatWithCurrency } from '@/helpers/Currency.js'

import { isString } from '@/helpers/Utils.js'
import { i18n } from '@/i18n.js'

const createShareContent = async ({ brokenItem, apiKey }) => {
  const shortLinks = await createShortLinks({ brokenItem, apiKey })
  const result = { content: '', shortLinks }
  addStoreName(brokenItem, result, apiKey)
  addOpenedBy(brokenItem, result)
  addDescription(brokenItem, result)
  addAssignees(brokenItem, result)
  addCompanyProviders(brokenItem, result)
  addDeadline(brokenItem, result)
  addCommentPriceAndProgressDescription(brokenItem, result)
  addImages(brokenItem, result)
  addVideo(brokenItem, result)
  addFixImages(brokenItem, result)
  addAttachedFile(brokenItem, result)

  return result.content
}

const createShortLinks = async ({ brokenItem, apiKey }) => {
  const contentLinks = gatherLinks(brokenItem)
  if (contentLinks.length === 0) return contentLinks
  const response = await batchCreateShortLinks(apiKey, contentLinks)
  return response.data.urls
}

const gatherLinks = (brokenItem) => {
  const links = []
  if (Array.isArray(brokenItem.images) && brokenItem.images.length > 0) {
    brokenItem.images.forEach((image) => {
      const imageUrl = betterImageQuality(image.url)
      links.push(imageUrl)
    })
  }
  if (isString(brokenItem.attached_video_url)) {
    links.push(brokenItem.attached_video_url)
  }
  if (Array.isArray(brokenItem.fixed_images) && brokenItem.fixed_images.length > 0) {
    brokenItem.fixed_images.forEach((image) => {
      const imageUrl = betterImageQuality(image.url)
      links.push(imageUrl)
    })
  }
  if (isString(brokenItem.attached_file_url)) {
    links.push(brokenItem.attached_file_url)
  }
  return links
}

const findShortLink = ({ shortLinks, url }) => {
  const link = shortLinks.find((item) => item.url === url)
  return link ? link.short_url : url
}

const betterImageQuality = (url) => url.replace('image/upload/f_auto,q_auto,dpr_auto,c_limit,w_400,h_400', 'image/upload/f_auto,q_auto,dpr_auto')

const addStoreName = (brokenItem, result, apiKey) => {
  const { companyName } = useCompany(apiKey)
  const hasCompanyName = isString(companyName.value)
  const hasAccountName = isString(brokenItem.account_name)
  if (hasCompanyName && hasAccountName) {
    result.content +=
      `${i18n.t('broken_item_share.ticket_from', { companyName: companyName.value, accountName: brokenItem.account_name })}
`
  } else if (hasCompanyName) {
    result.content +=
      `${i18n.t('broken_item_share.ticket_from_no_company', { companyOrAccountName: companyName.value })}
`
  } else if (hasAccountName) {
    result.content +=
      `${i18n.t('broken_item_share.ticket_from_no_company', { companyOrAccountName: brokenItem.account_name })}
`
  }
}
const addOpenedBy = (brokenItem, result) => {
  let itemNameAndDepartment = brokenItem.item_name
  if (brokenItem.item_department) {
    itemNameAndDepartment += ` (${brokenItem.item_department})`
  }
  if (isString(brokenItem.item_category_name)) {
    itemNameAndDepartment += ` | ${brokenItem.item_category_name}`
  }
  const openedBy = `${brokenItem.creator_first_name} ${brokenItem.creator_last_name}`
  result.content +=
    `${i18n.t('broken_item_share.opened_at', { open_date: brokenItem.open_date_user_format })}
${i18n.t('broken_item_share.opened_by', { open_by: openedBy })}
${itemNameAndDepartment}`
}

const addDescription = (brokenItem, result) => {
  if (!isString(brokenItem.description)) return

  result.content += `
${i18n.t('broken_item_share.description', { description: brokenItem.description })}`
}

const addAssignees = (brokenItem, result) => {
  const hasAssignees = Array.isArray(brokenItem.assigned_users) && brokenItem.assigned_users.length > 0
  if (!hasAssignees) return
  result.content += `
${i18n.t('broken_item_share.responsibility')}`
  brokenItem.assigned_users.forEach((assignedUser) => {
    const assignedUserName = assignedUser.first_name + ' ' + assignedUser.last_name
    result.content += `
  \u2022 ${assignedUserName}`
  })
}

const addCompanyProviders = (brokenItem, result) => {
  const relatedProviders = brokenItem.related_providers
  const hasProviders = Array.isArray(relatedProviders) && relatedProviders.length > 0
  if (!hasProviders) return
  if (relatedProviders.length === 1) {
    result.content += `
${i18n.t('broken_item_share.related_provider', { providerName: relatedProviders[0].name })}`
  } else {
    result.content += `
${i18n.t('broken_item_share.related_providers')}`
    relatedProviders.forEach((provider) => {
      result.content += `
  \u2022 ${provider.name}`
    })
  }
}

const addDeadline = (brokenItem, result) => {
  if (!isString(brokenItem.deadlineUserFormatted)) return
  result.content += `
${i18n.t('broken_item_share.fault_fix_until', { fixUntil: brokenItem.deadlineUserFormatted })}`
}

const addCommentPriceAndProgressDescription = (brokenItem, result) => {
  if (isString(brokenItem.comment)) {
    result.content += `
${i18n.t('broken_item_share.comment', { comment: brokenItem.comment })}`
  }
  if (isString(brokenItem.progress_description)) {
    result.content += `
${i18n.t('broken_item_share.fix_description', { fix_description: brokenItem.progress_description })}`
  }
  if (brokenItem.price) {
    result.content += `
${i18n.t('broken_item_share.price', { price: formatWithCurrency(brokenItem.price) })}`
  }
}

const addImages = (brokenItem, result) => {
  const hasImages = Array.isArray(brokenItem.images) && brokenItem.images.length > 0
  if (!hasImages) return

  result.content += `
${i18n.t('broken_item_share.fault_images')}`
  brokenItem.images.forEach((image) => {
    const imageUrl = findShortLink({ url: betterImageQuality(image.url), shortLinks: result.shortLinks })
    result.content += `
  \u2022 ${imageUrl}`
  })
}

const addVideo = (brokenItem, result) => {
  if (!isString(brokenItem.attached_video_url)) return

  const videoUrl = findShortLink({ url: brokenItem.attached_video_url, shortLinks: result.shortLinks })
  result.content += `
${i18n.t('broken_item_share.fault_attached_video')}
  \u2022 ${videoUrl}`
}

const addFixImages = (brokenItem, result) => {
  const hasFixedImage = Array.isArray(brokenItem.fixed_images) && brokenItem.fixed_images.length > 0
  if (!hasFixedImage) return
  result.content += `
${i18n.t('broken_item_share.fault_fix_images')}`
  brokenItem.fixed_images.forEach((image) => {
    const imageUrl = findShortLink({ url: betterImageQuality(image.url), shortLinks: result.shortLinks })
    result.content += `
  \u2022 ${imageUrl}`
  })
}

const addAttachedFile = (brokenItem, result) => {
  if (!isString(brokenItem.attached_file_url)) return

  const fileLink = findShortLink({ url: brokenItem.attached_file_url, shortLinks: result.shortLinks })
  result.content += `
${i18n.t('broken_item_share.fault_attached_invoice')}
  \u2022 ${fileLink}`
}


export { createShareContent }