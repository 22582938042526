<template>
  <v-row align="center" justify="center">
    <v-card class="video-card">
      <v-responsive :aspect-ratio="16/9" :max-height="$vuetify.breakpoint.xsOnly ? '320px' : '400px'">
        <video controls>
          <source :src="videoSrc" type="video/mp4">
          Your browser does not support the video tag.
        </video>
      </v-responsive>
    </v-card>
  </v-row>
</template>
<style>
.video-card {
  max-width: 50vw;
  margin: 16px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
</style>
<script>
export default {
  props: {
    videoSrc: {
      type: String,
      required: true
    }
  }
}
</script>
