
import {
  accountTotal as accountFulatyDeviceTotalClient,
  companyCount as companyFulatyDeviceTotalClient
} from '@/api_client/AccountFaultyDevice.js'
import { isString } from '@/helpers/Utils.js'

import { ref } from 'vue'

const isLoading = ref(false)
const accountFaultyDeviceCount = ref(null)
const accountFaultyDeviceCompanyCount = ref(null)
let currentApiKey = null

export default function useBrokenItemCount (apiKey) {
  const resetValues = () => {
    isLoading.value = false
    accountFaultyDeviceCount.value = null
    accountFaultyDeviceCompanyCount.value = null
  }

  if (isString(apiKey) && currentApiKey !== apiKey) {
    resetValues()
    currentApiKey = apiKey
  }

  const loadBrokenItemCount = async (forceLoad) => {
    if (Number.isInteger(accountFaultyDeviceCount.value) && !forceLoad) return

    const response = await accountFulatyDeviceTotalClient(currentApiKey)
    accountFaultyDeviceCount.value = response.data.data.total || 0
  }

  const loadBrokenItemCompanyCount = async (forceLoad) => {
    if (Number.isInteger(accountFaultyDeviceCompanyCount.value) && !forceLoad) return

    const response = await companyFulatyDeviceTotalClient(currentApiKey)
    accountFaultyDeviceCompanyCount.value = response.data.data.total || 0
  }

  // Methods

  return {
    isLoading,
    accountFaultyDeviceCount,
    accountFaultyDeviceCompanyCount,
    loadBrokenItemCount,
    loadBrokenItemCompanyCount
  }
}
