<template>
  <v-dialog v-model="dialog" width="750" :fullscreen="$vuetify.breakpoint.xsOnly" persistent>
    <v-card class="new-supplier-ops">
      <v-card-title class="headline d-block text-center">
        <v-row no-gutters align="start" justify="start">
          <v-col cols="12" class="text-center">
            {{ dialogTitle }}
          </v-col>
          <v-col cols="4" sm="2" class="day-select">
            <v-select
              v-if="mode == POPUP_MODE.update"
              v-model="day"
              :items="daysSelectOption"
              item-text="translatedName"
              item-value="name"
              single-line
              :rules="[requiredField]">
            </v-select>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-form v-model="formValid">
          <v-container fluid>
            <v-row no-gutters align="start" justify="start">
              <v-col cols="12" sm="3" class="pt-4 pt-sm-2 d-flex justify-space-between d-sm-block">
                <v-checkbox
                  class="op-types"
                  color="primary"
                  v-for="operation in operationSchedule.operations"
                  :key="operation.operation_type"
                  v-model="operation.selected"
                  :label="operation.operationTypeText">
                </v-checkbox>
              </v-col>
              <v-col cols="12" sm="9" class="mt-2 mt-sm-0">
                <v-row no-gutters align="start" justify="start">
                  <v-col cols="12" sm="7">
                    <job-selection-search
                      :day="day"
                      :api-key="apiKey"
                      v-model="operationSchedule.operationExecuter"
                      :label="$t('job_op_schedule.choose_job')">
                    </job-selection-search>
                  </v-col>
                  <v-col cols="12" sm="4" offset-sm="1" class="mt-4 mt-sm-0">
                    <v-select
                      v-model="operationSchedule.shiftPart"
                      :items="shiftParts"
                      item-text="translateName"
                      item-value="name"
                      single-line>
                    </v-select>
                  </v-col>
                  <v-col cols="12" class="mt-3">
                    <v-textarea
                      v-model="operationSchedule.operationDescription"
                      counter="120"
                      :label="$t('supplier_operation_schedule.op_desc')"
                      auto-grow
                      no-resize
                      rows="1">
                    </v-textarea>
                  </v-col>
                  <v-col cols="9" sm="5" class="mt-4 mt-sm-0">
                    <v-select
                      v-model="operationSchedule.frequncyParams"
                      :items="frequencyOptions"
                      item-text="translatedName"
                      single-line
                      clearable
                      :label="$t('op_schedule_frequency_dialog.placeholder')"
                      return-object></v-select>
                  </v-col>
                  <v-col cols="2" offset="1" class="mt-4 mt-sm-0">
                    <v-checkbox
                      color="black"
                      class="mt-1"
                      v-model="operationSchedule.requireImage"
                      off-icon="add_a_photo"
                      on-icon="add_a_photo">
                    </v-checkbox>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row no-gutters align="end" justify="end" class="mt-6 mt-sm-0">
              <v-btn color="blue darken-1" class="mx-0" text @click="close(false)" :disabled="isLoading">
                {{ $t('cancel') }}
              </v-btn>
              <v-btn color="blue darken-1" class="mx-0" depressed tile @click="save" :loading="isLoading"
                :disabled="saveButtonDisabled">
                {{ $t('save') }}
              </v-btn>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<style lang="scss">
.new-supplier-ops {
  .v-text-field {
    margin-top: 0;
    padding-top: 0;
  }

  .op-types {
    padding: 0;
    margin: 0;

    .v-input__slot {
      margin: 0;
    }

    @media only screen and (max-width: 600px) {
      display: inline-block
    }
  }

  .day-select {
    padding-left: 12px;
  }
}
</style>
<script>
import JobSelectionSearch from '@/components/job_selection_search/JobSelectionSearch.vue'

import { defaultOperations } from '@/store/modules/OrganizationSupplier.js'
import { getCompoundOperationType, getOperationTypesFromCompoundType } from '@/composables/useOperationType.js'
import { shiftPartsForSelect as OpModuleShiftParts } from '@/store/modules/OperationSchedule.js'
import { operationFrequencyOptionsForSelect, FrequencyTypes } from '@/store/modules/OperationFrequency.js'
import useAccountSupplier from '@/composables/useAccountSupplier.js'
import useAuth from '@/composables/useAuth.js'

import { create as supplierItemOperationCreate, update as supplierItemOperationUpdate } from '@/api_client/OrganizationSupplierOperation.js'

import { daysOfTheWeek } from '@/helpers/DateTime.js'
import { isObject } from '@/helpers/Utils.js'

import { handler as errHandler } from '@/classes/ErrorHandler.js'

import { requiredField } from '@/classes/InputValidators.js'
import { onDialogClose, onDialogOpen } from '@/classes/DialogScrollBug.js'

import { i18n } from '@/i18n.js'

import { ref, computed } from 'vue'

const POPUP_MODE = { create: 0, update: 1 }

export default {
  components: {
    'job-selection-search': JobSelectionSearch
  },
  setup() {
    let resolveFunc = null
    let supplierId = null
    let originalScheduledOperation = null
    let originalOperation = null
    let originalDay = null
    const dialog = ref(false)
    const isLoading = ref(false)
    const mode = ref(null)
    const day = ref(null)
    const formValid = ref(null)
    const { apiKey } = useAuth()

    const { createOperationSchedule, updateOperationSchedule, loadSupplier } = useAccountSupplier(apiKey.value)
    const suppliersFrequencyOptions = () => {
      const frequencyOptions = operationFrequencyOptionsForSelect()
      return frequencyOptions.filter((frequency) => frequency.frequencyType !== FrequencyTypes.ExectDate &&
        frequency.frequencyType !== FrequencyTypes.EveryDay && frequency.frequencyType !== FrequencyTypes.EveryYear)
    }

    const frequencyOptions = ref(suppliersFrequencyOptions())

    const defaultOperationSchedule = () => {
      const frequencyOptionsVal = frequencyOptions.value || operationFrequencyOptionsForSelect()
      const operations = defaultOperations()
      operations.forEach(operation => (operation.selected = false))
      return {
        operations,
        operationDescription: '',
        operationExecuter: null,
        shiftPart: 'start',
        requireImage: false,
        frequncyParams: frequencyOptionsVal.find((frequency) => frequency.default)
      }
    }

    const operationSchedule = ref(defaultOperationSchedule())

    const openDialog = (dayVal, supplierIdVal, modeVal) => {
      onDialogOpen()
      dialog.value = true
      day.value = dayVal
      mode.value = modeVal
      supplierId = supplierIdVal
    }

    const setSelectedOperationTypes = () => {
      const operationTypes = getOperationTypesFromCompoundType(originalOperation.operation_type)
      operationSchedule.value.operations.forEach((operation) => {
        if (operationTypes.includes(operation.operation_type)) {
          operation.selected = true
        }
      })
    }

    const setSelectedExecuter = () => {
      operationSchedule.value.operationExecuter = {
        id: originalScheduledOperation.executor_id,
        name: originalScheduledOperation.executor_name,
        shiftName: originalScheduledOperation.shift_name
      }
    }

    const setOperationFrequencyParams = () => {
      operationSchedule.value.frequncyParams = frequencyOptions.value
        .find((frequencyOption) => originalScheduledOperation.frequency === frequencyOption.frequency &&
          originalScheduledOperation.frequency_type === frequencyOption.frequencyType)
      if (!isObject(operationSchedule.value.frequncyParams)) operationSchedule.value.frequncyParams = { frequency: null, frequencyType: null }
    }

    const setOperationFromParams = () => {
      setSelectedOperationTypes()
      setSelectedExecuter()
      setOperationFrequencyParams()
      operationSchedule.value.operationDescription = originalOperation.description
      operationSchedule.value.shiftPart = originalScheduledOperation.shift_part_name
      operationSchedule.value.requireImage = originalScheduledOperation.require_image
    }

    const getOperationType = () => {
      const selectedOperatoins = operationSchedule.value.operations.filter((operation) => operation.selected).map((operation) => operation.operation_type)
      return getCompoundOperationType(selectedOperatoins)
    }

    const getOperatoinParams = () => {
      return {
        operation_type: getOperationType(),
        description: operationSchedule.value.operationDescription
      }
    }

    const createOperation = () => {
      const operationParams = getOperatoinParams()
      return supplierItemOperationCreate(apiKey.value, supplierId, { item_operation: operationParams })
    }

    const getOperationFrequencyParams = () => {
      const frequencyParams = operationSchedule.value.frequncyParams || {}
      return {
        frequency: frequencyParams.frequency || null,
        frequency_type: frequencyParams.frequencyType || null
      }
    }

    const createOperationScheduleCall = (itemOperationId) => {
      let requestParams = {
        operation_id: itemOperationId,
        shift_part: operationSchedule.value.shiftPart,
        day: day.value,
        require_image: operationSchedule.value.requireImage
      }
      requestParams = Object.assign(requestParams, getOperationFrequencyParams())

      return createOperationSchedule({
        executor_id: operationSchedule.value.operationExecuter.id,
        operationParams: requestParams,
        supplierId
      })
    }

    const saveForCreationMode = async () => {
      const operationResponse = await createOperation()
      const itemOperationId = operationResponse.data.data.id
      return createOperationScheduleCall(itemOperationId)
    }

    const operationChanged = () => {
      if (operationSchedule.value.operationDescription !== originalOperation.description ||
        getOperationType() !== originalOperation.operation_type) return true

      return false
    }

    const operationScheduleChangedParams = () => {
      const changedParams = {}
      if ((operationSchedule.value.operationExecuter || {}).id !== originalScheduledOperation.executor_id) {
        changedParams.executor_id = operationSchedule.value.operationExecuter.id
      }
      if (day.value !== originalDay) {
        changedParams.day = day.value
      }
      if (originalScheduledOperation.shift_part_name !== operationSchedule.value.shiftPart) {
        changedParams.shift_part = operationSchedule.value.shiftPart
      }
      if (originalScheduledOperation.frequency !== operationSchedule.value.frequncyParams.frequency) {
        changedParams.frequency = operationSchedule.value.frequncyParams.frequency
      }
      if (originalScheduledOperation.frequency_type !== operationSchedule.value.frequncyParams.frequencyType) {
        changedParams.frequency_type = operationSchedule.value.frequncyParams.frequencyType
      }
      if (originalScheduledOperation.require_image !== operationSchedule.value.requireImage) {
        changedParams.require_image = operationSchedule.value.requireImage
      }
      return changedParams
    }

    const updateOperationScheduleCall = (updateParams) =>
      updateOperationSchedule({
        apiKey: apiKey.value,
        executor_id: originalScheduledOperation.executor_id,
        operation_schedule_id: originalScheduledOperation.id,
        operationParams: updateParams,
        supplierId
      })

    const reloadSupplier = () => loadSupplier(supplierId)

    const saveForUpdateMode = async () => {
      let shouldReloadSupplier = false
      const promisesArray = []
      if (operationChanged()) {
        shouldReloadSupplier = true
        const operationParams = getOperatoinParams()
        const opPromise = supplierItemOperationUpdate(apiKey.value, supplierId, originalOperation.id, { item_operation: operationParams })
        promisesArray.push(opPromise)
      }
      const changedParams = operationScheduleChangedParams()
      if (Object.keys(changedParams).length > 0) {
        const opSchedulePromise = updateOperationScheduleCall(changedParams)
        promisesArray.push(opSchedulePromise)
      }
      const promiseResult = Promise.all(promisesArray)
      if (shouldReloadSupplier) {
        return promiseResult
          .then(reloadSupplier)
      }
      return promiseResult
    }

    // Methods

    const openForCreate = ({ dayName, supplierId }) => {
      openDialog(dayName, supplierId, POPUP_MODE.create)
      return new Promise((resolve, reject) => {
        resolveFunc = resolve
      })
    }

    const openForUpdate = ({ dayName, supplier, itemOperation = null, scheduledOperation, supplierId }) => {
      openDialog(dayName, supplierId, POPUP_MODE.update)
      const operation = isObject(itemOperation)
        ? itemOperation
        : (supplier.operations || []).find((operation) => operation.id === scheduledOperation.item_operation_id)

      originalScheduledOperation = scheduledOperation
      originalOperation = operation
      originalDay = dayName
      setOperationFromParams()
      return new Promise((resolve, reject) => {
        resolveFunc = resolve
      })
    }

    const save = () => {
      isLoading.value = true
      let promise
      if (mode.value === POPUP_MODE.create) {
        promise = saveForCreationMode()
      } else {
        promise = saveForUpdateMode()
      }
      promise
        .then(() => {
          isLoading.value = false
          close(true)
        })
        .catch(errHandler)
    }

    const close = (isChanged = false) => {
      onDialogClose()
      mode.value = null
      dialog.value = false
      operationSchedule.value = defaultOperationSchedule()
      resolveFunc({ isChanged })
    }

    // Computed

    const dialogTitle = computed(() => mode.value === POPUP_MODE.create ? i18n.t('supplier_operation_schedule.create_title') : i18n.t('edit'))
    const saveButtonDisabled = computed(() => {
      if (isLoading.value || !formValid.value) return true
      const noOpsSlected = operationSchedule.value.operations.every((operation) => !operation.selected)
      if (noOpsSlected) return true

      const executerId = (operationSchedule.value.operationExecuter || {}).id
      if (!Number.isInteger(executerId)) return true
      return false
    })
    return {
      POPUP_MODE,
      dialog,
      isLoading,
      mode,
      day,
      daysSelectOption: daysOfTheWeek(),
      formValid,
      shiftParts: OpModuleShiftParts(),
      frequencyOptions,
      operationSchedule,
      dialogTitle,
      saveButtonDisabled,
      apiKey,
      openForCreate,
      openForUpdate,
      save,
      close,
      requiredField
    }
  }
}
</script>
