import Vuetify from '@/plugins/vuetify.js'

let currentYOffset = null
let currentXOffset = null
// fixes https://github.com/vuetifyjs/vuetify/issues/3875
function onDialogOpen () {
  if (Vuetify.framework.breakpoint.mdAndDown) {
    if (!Number.isInteger(currentYOffset) && !Number.isInteger(currentXOffset)) {
      currentYOffset = window.pageYOffset
      currentXOffset = window.pageXOffset
    }
    document.getElementsByTagName('body')[0].className = 'noscroll'
  }
}

function onDialogClose () {
  document.body.removeAttribute('class', 'noscroll')
  if (Number.isInteger(currentYOffset) && Number.isInteger(currentXOffset)) {
    window.scrollTo(currentXOffset, currentYOffset)
  }
  currentYOffset = null
  currentXOffset = null
}

export {
  onDialogOpen,
  onDialogClose
}
