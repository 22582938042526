<template>
  <v-autocomplete
    :value="value"
    :items="selectEntries"
    :loading="selectIsLoading"
    :search-input.sync="search"
    clearable
    hide-selected
    append-icon="search"
    class="item-search"
    item-text="name"
    color="black"
    @change="emitChange"
    @click:clear="clear"
    :no-data-text="$t('no_result')"
    @input="update"
    item-value="id"
    :menu-props="menuProps"
    return-object
    :placeholder="$t('supplier_search.placeholder')">
    <template v-slot:item="{ item }">
      <span class="font-weight-bold"> {{item.name}}</span>
    </template>
    <template v-slot:selection="{ item }">
      <span class="font-weight-bold"> {{item.name}}</span>
    </template>
  </v-autocomplete>
</template>
<style lang="scss">
.item-search.v-select.v-select--is-menu-active .v-input__icon--append .v-icon {
  transform: rotate(0);
}
</style>
<script>
import AccountSupplierSearchStore from '@/components/shared/supplier_search/AccountSupplierSearchStore.js'
import { search as suppliersSearch } from '@/api_client/OrganizationStructureSupplier.js'

import { isString } from '@/helpers/Utils.js'

import { handler as errHandler } from '@/classes/ErrorHandler.js'

import { ref, computed, watch } from 'vue'

export default {
  props: {
    value: {
      type: Object,
      default: null
    },
    apiKey: {
      type: String,
      required: true
    },
    menuProps: {
      type: Object,
      required: false,
      default: () => {}
    }
  },
  setup (props, { emit }) {
    const { isLoading, accountSuppliersList, loadAccountSupplier } = AccountSupplierSearchStore(props.apiKey)
    const entries = ref([])
    const isSearchLoading = ref(false)
    const search = ref(null)

    // Methods
    const update = (newValue) => emit('input', newValue)
    const emitChange = (newValue) => emit('change', newValue)
    const clear = () => emit('click:clear')

    // Computed

    const selectIsLoading = computed(() => isSearchLoading.value || isLoading.value)
    const validSearchTerm = computed(() => isString(search.value) && search.value.trim().length > 0)
    const selectEntries = computed(() => {
      if (isLoading.value) return []
      if (validSearchTerm.value === true) return entries.value
      return accountSuppliersList.value
    })

    watch(() => search.value, (query) => {
      if (isSearchLoading.value) return
      if (!validSearchTerm.value) {
        entries.value.splice(0, entries.value.length)
        return
      }

      isSearchLoading.value = true
      suppliersSearch(props.apiKey, query)
        .then(res => {
          const entriesValue = entries.value
          entriesValue.splice(0, entriesValue.length)
          res.data.data.forEach(suuplier => entriesValue.push(suuplier))
        })
        .catch(errHandler)
        .finally(() => (isSearchLoading.value = false))
    })

    loadAccountSupplier()

    const supplierId = (props.value || {}).id
    if (Number.isInteger(supplierId)) {
      entries.value.push(props.value)
    }

    return {
      update,
      emitChange,
      clear,
      search,
      selectIsLoading,
      selectEntries
    }
  }
}
</script>
