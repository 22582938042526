<template>
  <v-dialog
    v-model="dialog"
    width="290px"
    persistent>
    <v-time-picker
      class="shift-time-picker"
      :value="value"
      :format="format"
      :allowed-minutes="allowedMinutes"
      ref="timePicker"
      @input="update"
      @click:minute="saveTimeDialogValue(value)">
      <v-spacer></v-spacer>
      <v-btn color="primary" @click="reset">{{$t('reset')}}</v-btn>
    </v-time-picker>
  </v-dialog>
</template>
<style lang="scss">
.shift-time-picker {
  .v-input--selection-controls {
    label {
      color: #1976d2;
    }
  }
}

</style>
<script>
import { isObject } from '@/helpers/Utils.js'

import useAccount from '@/composables/useAccount.js'

import { ref } from 'vue'

export default {
  props: {
    value: {
      type: String
    }
  },
  setup (props, { emit }) {
    const dialog = ref(false)
    const format = ref('24hr')
    const timePicker = ref(null)

    let resolveFunc = null

    const setTimeFormat = (userFormattedTime) => {
      userFormattedTime = (userFormattedTime || '').toLowerCase()
      if (userFormattedTime.endsWith('am') || userFormattedTime.endsWith('pm')) {
        format.value = 'ampm'
        return
      }
      if (userFormattedTime.trim().length === 0) {
        const { accountTimeZone } = useAccount()
        const timeZone = accountTimeZone.value || ''
        format.value = timeZone.startsWith('America') ? 'ampm' : '24hr'
      }
    }
    // Methods

    const update = (newValue) => emit('input', newValue)
    const allowedMinutes = (minute) => minute % 5 === 0

    const reset = () => {
      update('')
      saveTimeDialogValue()
    }
    const saveTimeDialogValue = (value) => {
      dialog.value = false
      resolveFunc()
    }
    const open = (userFormattedTime) => {
      if (isObject(timePicker.value)) {
        timePicker.value.selectingHour = true
      }
      setTimeFormat(userFormattedTime)
      dialog.value = true
      return new Promise((resolve, reject) => {
        resolveFunc = resolve
      })
    }
    return {
      dialog,
      format,
      timePicker,
      reset,
      update,
      saveTimeDialogValue,
      allowedMinutes,
      open
    }
  }
}
</script>
