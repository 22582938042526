import useAuth from '@/composables/useAuth.js'
import { flattenObject, isObject, isString } from '@/helpers/Utils'
import Appsignal from '@appsignal/javascript'
import { errorHandler } from '@appsignal/vue'
import { AxiosError } from 'axios'

import Vue from 'vue'

const appsignal = new Appsignal({
  key: import.meta.env.VITE_APP_APPSIGNAL_APIKEY,
  revision: '{{POST_BUILD_ENTERS_HASH_HERE}}'
})
const appSignalErrorHandler = errorHandler(appsignal, Vue)

const logAxiosError = (err) => {
  const { currentUser, apiKey } = useAuth()
  const errorData = {}
  if (err.response) {
    if (err.response.data) errorData.response_data = err.response.data
    if (err.response.status) errorData.response_status = err.response.status
  }
  if (err.config) {
    const { url, method, params, data } = err.config
    if (url) errorData.request_url = url
    if (method) errorData.request_method = method
    if (params) {
      errorData.request_params = isObject(params) ? flattenObject(params) : params
    }
    if (data) {
      errorData.request_data = isObject(data) ? flattenObject(data) : data
    }
  }
  if (isString(apiKey.value)) {
    errorData.api_key = apiKey.value
  }
  if (Number.isInteger(currentUser.value?.id)) {
    errorData.user_id = currentUser.value.id
  }
  appsignal.sendError(err, (span) => {
    span.setTags(errorData)
  })
}

const reportError = (err, vm, info) => {
  if (err instanceof AxiosError) {
    logAxiosError(err)
  } else if (vm) {
    appSignalErrorHandler(err, vm, info)
  } else {
    appsignal.sendError(err, (span) => {
      const errorData = {}
      const { currentUser, apiKey } = useAuth()
      if (isString(apiKey.value)) {
        errorData.api_key = apiKey.value
      }
      if (Number.isInteger(currentUser.value?.id)) {
        errorData.user_id = currentUser.value.id
      }
      span.setTags(errorData)
    })
  }
}

export {
  reportError
}
