<template>
  <tr @click="editProvider" class="pointer">
    <v-row align="center" justify="start" class="font-weight-bold table-row-border-top pa-3" no-gutters>
      <v-col cols="3">
      </v-col>
      <v-col cols="6" class="text-center">
        <div>{{ provider.name }}</div>
        <div v-if="showContactName" class="t-500-16">
          {{ provider.contact_name }}
        </div>
        <div v-if="showComment" class="t-700-14 color2">
          {{ provider.comment }}
        </div>
      </v-col>
      <v-col cols="12" sm="3"
        :class="$vuetify.breakpoint.smAndUp ? 'texts-end' : 'text-center'">
        <v-btn icon class="mx-1" @click.stop="deleteProvider" v-if="!readOnly">
          <v-icon color="black" medium>delete</v-icon>
        </v-btn>
        <v-btn icon class="mx-1" @click="editProvider" v-if="!readOnly">
          <v-icon color="black" medium>edit</v-icon>
        </v-btn>
        <v-menu
          transition="slide-y-transition"
          bottom left>
          <template v-slot:activator="{ on }">
            <v-btn
              class="mx-1"
              icon
              :disabled="menuDisabled"
              @click.stop
              v-on="on">
              <v-icon>menu</v-icon>
            </v-btn>
          </template>
          <v-list :class="{ 'rtl': isRtl, 'ltr': !isRtl }">
            <v-list-item v-if="provider.email">
              <v-btn outlined block :href="`mailto:${provider.email}`">
                <span class="mme-2 font-weight-bold">
                  <v-icon color="black">email</v-icon>
                </span>
                <span class="blue--text mme-2 texts-start">
                  {{ provider.email }}
                </span>
              </v-btn>
            </v-list-item>
            <v-list-item v-if="provider.phone">
              <v-btn outlined block :href="`tel:${provider.phone}`">
                <span class="mme-2 font-weight-bold">
                  <v-icon color="black">phone</v-icon>
                </span>
                <span class="blue--text mme-2 texts-start">
                  {{ provider.phone }}
                </span>
              </v-btn>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-col>
    </v-row>
  </tr>
</template>
<script>
import { isString } from '@/helpers/Utils.js'
import { isRtl } from '@/i18n.js'
import { computed } from 'vue'
export default {
  props: {
    provider: {
      type: Object,
      required: true
    },
    readOnly: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit }) {
    // Methods

    const editProvider = () => emit('edit-provider', { provider: props.provider })
    const deleteProvider = () => emit('delete-provider', { provider: props.provider })

    // Computed
    const showContactName = computed(() => isString(props.provider.contact_name) && props.provider.contact_name.length > 0)
    const showComment = computed(() => isString(props.provider.comment) && props.provider.comment.length > 0)
    const menuDisabled = computed(() => !props.provider.email && !props.provider.phone)
    return {
      menuDisabled,
      showContactName,
      showComment,
      isRtl,
      editProvider,
      deleteProvider
    }
  }
}
</script>
