import Client from '@/api_client/client.js'

function index(apiKey, params) {
  const FAULTY_DEVICE_INDEX_PATH = `/accounts/${apiKey}/account_broken_items`
  return Client.get(FAULTY_DEVICE_INDEX_PATH, { params })
}

function companyTotals(apiKey, { allStatuses = null, apiKeys = null } = {}) {
  const FAULTY_DEVICE_COMPANY_TOTAL_PATH = `/accounts/${apiKey}/account_broken_items/company_total`
  const requestParams = {}
  if (allStatuses === true) requestParams.all_statuses = true
  if (Array.isArray(apiKeys)) requestParams.api_keys = apiKeys
  return Client.put(FAULTY_DEVICE_COMPANY_TOTAL_PATH, requestParams)
}

function companyCount(apiKey) {
  const FAULTY_DEVICE_COMPANY_COUNT_PATH = `/accounts/${apiKey}/account_broken_items/company_count`
  return Client.get(FAULTY_DEVICE_COMPANY_COUNT_PATH)
}

function accountTotal(apiKey) {
  const FAULTY_DEVICE_ACCOUNT_TOTAL_PATH = `/accounts/${apiKey}/account_broken_items/account_total`
  return Client.get(FAULTY_DEVICE_ACCOUNT_TOTAL_PATH)
}

const companyAccountsStatusCount = (apiKey, filters) => {
  const FAULTY_DEVICE_COMPANY_ACCOUNTS_STATUS_COUNT_PATH = `/accounts/${apiKey}/account_broken_items/company_status_count`
  return Client.put(FAULTY_DEVICE_COMPANY_ACCOUNTS_STATUS_COUNT_PATH, filters)
}

export {
  index,
  companyTotals,
  companyCount,
  accountTotal,
  companyAccountsStatusCount
}
