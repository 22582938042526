import Client from '@/api_client/client.js'

const create = (apiKey, params) => {
  const CREATE_BLOG_POST_PATH = `/accounts/${apiKey}/blog_posts/`
  return Client.post(CREATE_BLOG_POST_PATH, params)
}

const update = (apiKey, blogPostId, params) => {
  const UPDATE_BLOG_POST_PATH = `/accounts/${apiKey}/blog_posts/${blogPostId}`
  return Client.put(UPDATE_BLOG_POST_PATH, params)
}

const index = (apiKey, params) => {
  const BLOG_POST_INDEX_PATH = `/accounts/${apiKey}/blog_posts/`
  return Client.put(BLOG_POST_INDEX_PATH, params)
}

const destroy = (apiKey, blogPostId) => {
  const BLOG_POST_DELETE_PATH = `/accounts/${apiKey}/blog_posts/${blogPostId}`
  return Client.delete(BLOG_POST_DELETE_PATH)
}

export {
  create,
  update,
  index,
  destroy
}
