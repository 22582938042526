import { search as suppliersSearch } from '@/api_client/OrganizationStructureSupplier.js'

import { isString } from '@/helpers/Utils.js'

import { ref } from 'vue'

const SEARCH_LIMIT = 100
const accountSuppliersList = ref([])
const isLoading = ref(false)

let currentApiKey = null
let shouldReload = true

export default function useAccountSupplier (apiKey) {
  const resetValues = () => {
    isLoading.value = false
    accountSuppliersList.value.splice(0, accountSuppliersList.value.length)
  }

  if (isString(apiKey) && currentApiKey !== apiKey) {
    resetValues()
    currentApiKey = apiKey
    shouldReload = true
  }

  // Methods

  const loadAccountSupplier = async () => {
    if (shouldReload === false) return
    isLoading.value = true
    return suppliersSearch(apiKey, null, SEARCH_LIMIT)
      .then((response) => {
        const accountSuppliersListVal = accountSuppliersList.value
        accountSuppliersListVal.splice(0, accountSuppliersListVal.length)
        response.data.data.forEach((supplier) => accountSuppliersListVal.push(supplier))
        shouldReload = false
      })
      .finally(() => (isLoading.value = false))
  }

  const invalidateCache = () => (shouldReload = true)
  return {
    isLoading,
    accountSuppliersList,
    loadAccountSupplier,
    invalidateCache
  }
}
