<template>
  <v-row align="start" justify="start" no-gutters v-if="showIsLoading">
    <v-col cols="4" v-for="index in 3" :key="index" class="px-2">
      <v-skeleton-loader type="card" elevation="2">
      </v-skeleton-loader>
    </v-col>
  </v-row>
  <div v-else class="items-list"
    :class="{
      'flex-column d-flex full-width mb-8': $vuetify.breakpoint.mdAndUp,
      'row align-start no-gutters justify-start': !$vuetify.breakpoint.mdAndUp
    }">
    <new-device-dialog :api-key="apiKey" ref="newDeviceDialog"></new-device-dialog>
    <confirm-delete ref="deleteDialog"></confirm-delete>
    <new-item-category :api-key="apiKey" ref="newItemCategoryDialoge"></new-item-category>
    <item-dialog-layout ref="itemDialog"></item-dialog-layout>
    <v-col class="list-vol flex-grow-1 flex-shrink-0">
      <v-row align="start" justify="start" no-gutters>
        <template v-for="item in itemsWithCategory ">
          <v-col cols="12" v-if="item.isCategory" :key="`category_${item.id}`">
            <category-row
              :item="item"
              :show-options="showCategoryOptions"
              @edit-category="onEditCategory"
              @delete-category="onDeleteCategory">
            </category-row>
          </v-col>
          <v-col cols="12" lg="4" md="6" sm="6" v-else :key="item.id">
            <item-row
              :item="item"
              :edit-enabled="editItemEnabled"
              :delete-enabled="deleteItemEnabled"
              @edit-item="onEditItem"
              @delete-item="onDeleteItem"
              @item-click="onItemClick">
            </item-row>
          </v-col>
        </template>
        <v-col v-if="showLoadMorePages" v-intersect.once="onIntersect"></v-col>
        <v-col cols="12" class="row no-gutters align-start justify-start mb-4" v-if="isListLoadingNextPage">
          <v-col cols="4" v-for=" index in 3 " :key="index" class="px-2">
            <v-skeleton-loader type="card" elevation="2">
            </v-skeleton-loader>
          </v-col>
        </v-col>
      </v-row>
    </v-col>
  </div>
</template>
<style lang="scss">
.items-list {
  @media only screen and (min-width: 960px) {
    .list-vol {
      overflow-y: auto;
    }
  }
}
</style>
<script>

import ItemDialogLayout from '@/components/items/item_dialog/ItemDialogLayout.vue'
import CategoryRow from '@/components/items/item_list/CategoryRow.vue'
import ItemRow from '@/components/items/item_list/ItemRow.vue'
import ConfirmDelete from '@/components/shared/ConfirmDelete.vue'
import NewItemCategory from '@/components/shared/NewItemCategory.vue'
import NewDeviceDialog from '@/components/shared/DevicesNewDevice.vue'

import useAuth from '@/composables/useAuth.js'
import useItem from '@/composables/useItem.js'
import useCurrentUserPermissions from '@/composables/useCurrentUserPermissions.js'

import { isDate } from '@/helpers/Utils.js'
import { handler as errHandler } from '@/classes/ErrorHandler.js'

import { i18n } from '@/i18n.js'

import { ref, computed } from 'vue'
export default {
  props: {
    apiKey: {
      type: String,
      required: true
    }
  },
  components: {
    'category-row': CategoryRow,
    'item-row': ItemRow,
    'confirm-delete': ConfirmDelete,
    'new-item-category': NewItemCategory,
    'new-device-dialog': NewDeviceDialog,
    'item-dialog-layout': ItemDialogLayout
  },
  setup(props) {
    const editItemEnabled = ref(false)
    const deleteItemEnabled = ref(false)
    const deleteDialog = ref(null)
    const newItemCategoryDialoge = ref(null)
    const newDeviceDialog = ref(null)
    const itemDialog = ref(null)

    const { isLoading, itemList, pagination, filters,
      loadNextPage, deleteCategory, resetTableAndReload, deleteItem
    } = useItem(props.apiKey)
    const { isAccountAdminOrAbove } = useAuth()
    const { canEditItem, canDeleteItem, canCloseBrokenItem, canDeleteBrokenItem,
      canUpdateBrokenItem, loadPermissions } = useCurrentUserPermissions(props.apiKey)

    // Methods
    const onIntersect = () => loadNextPage()

    const onEditCategory = (category) => {
      newItemCategoryDialoge.value.openForUpdate(category)
        .then(({ updatedOrCreated }) => {
          if (updatedOrCreated === true) resetTableAndReload()
        })
    }

    const onDeleteCategory = (category) => {
      const title = i18n.t('item_category_search.delete_title')
      const content = i18n.t('item_category_search.delete_content')
      deleteDialog.value.open({ title, content })
        .then((shouldDelete) => {
          if (!shouldDelete) return

          deleteCategory(category.id)
            .then(() => deleteDialog.value.close?.())
            .catch(errHandler)
        })
    }

    const onEditItem = (item) => newDeviceDialog.value.openForUpdate(item)
      .then(({ updatedOrCreated }) => {
        if (updatedOrCreated === true) resetTableAndReload()
      })

    const onDeleteItem = (item) => {
      const title = i18n.t('item_search.delete_title')
      const content = i18n.t('item_search.delete_content')
      deleteDialog.value.open({ title, content })
        .then((shouldDelete) => {
          if (!shouldDelete) return

          deleteItem(item.id)
            .then(() => deleteDialog.value.close?.())
            .catch(errHandler)
        })
    }

    const onItemClick = (item) => {
      const permissions = {
        showClose: showCloseTicket.value,
        showDelete: showDeleteTicket.value,
        showEdit: showEditTicket.value
      }
      const filtersVal = JSON.parse(JSON.stringify(filters.value))
      if (isDate(filters.value.openDateStartFilter)) filtersVal.openDateStartFilter = new Date(filters.value.openDateStartFilter)
      if (isDate(filters.value.openDateEndFilter)) filtersVal.openDateEndFilter = new Date(filters.value.openDateEndFilter)
      if (isDate(filters.value.closeDateStartFilter)) filtersVal.closeDateStartFilter = new Date(filters.value.closeDateStartFilter)
      if (isDate(filters.value.closeDateEndFilter)) filtersVal.closeDateEndFilter = new Date(filters.value.closeDateEndFilter)
      itemDialog.value.openDialog(JSON.parse(JSON.stringify(item)), props.apiKey, permissions, filtersVal)
    }

    // Computed
    const showCategoryOptions = computed(() => isAccountAdminOrAbove.value)
    const showIsLoading = computed(() => isLoading.value && pagination.value.page === 1)
    const isListLoadingNextPage = computed(() => isLoading.value && pagination.value.page > 1)
    const showCloseTicket = computed(() => isAccountAdminOrAbove.value || canCloseBrokenItem.value)
    const showDeleteTicket = computed(() => isAccountAdminOrAbove.value || canDeleteBrokenItem.value)
    const showEditTicket = computed(() => isAccountAdminOrAbove.value || canUpdateBrokenItem.value)

    const showLoadMorePages = computed(() => {
      if (isLoading.value === true) return false

      const { page, totalPages } = pagination.value
      return page < totalPages
    })

    const itemsWithCategory = computed(() => {
      const result = []
      let currentCategoryId = null
      itemList.value.forEach(item => {
        if (Number.isInteger(item.category_id) && item.category_id !== currentCategoryId) {
          currentCategoryId = item.category_id
          result.push({
            isCategory: true,
            id: item.category_id,
            name: item.category_name,
          })
        }
        result.push(item)
      })
      return result
    })

    loadPermissions().then(() => {
      editItemEnabled.value = canEditItem.value
      deleteItemEnabled.value = canDeleteItem.value
    })

    return {
      deleteDialog,
      newItemCategoryDialoge,
      newDeviceDialog,
      itemDialog,
      showIsLoading,
      isListLoadingNextPage,
      itemsWithCategory,
      showCategoryOptions,
      showLoadMorePages,
      editItemEnabled,
      deleteItemEnabled,
      onIntersect,
      onEditCategory,
      onDeleteCategory,
      onEditItem,
      onDeleteItem,
      onItemClick
    }
  }
}
</script>