
import useAccount from '@/composables/useAccount.js'

function formatWithCurrency (price) {
  if (!Number.isInteger(price)) return ''
  if (price === 0) return price
  const { accountCurrencySign } = useAccount()
  const accountCurrencySignVal = accountCurrencySign.value || ''
  return `${price} ${accountCurrencySignVal}`
}

export {
  formatWithCurrency
}
