import { addFile as ItemOperationAddFile, removeFile as ItemOperationRemoveFile } from '@/api_client/DeviceOperation.js'
import { addFile as CrossAccountOperationAddFile, removeFile as CrossAccountOperationRemoveFile } from '@/api_client/CrossAccountOperation.js'
import { addFile as BrokenItemAddFile, removeFile as BrokenItemAddFileRemoveFile } from '@/api_client/BrokenItem.js'
import { addFiles as UserAddFiles } from '@/api_client/User.js'
import { removeFiles as UserRemoveFiles } from '@/api_client/User.js'

import { uploadFile as uploadFileToRemoteServer } from '@/general_client/FileUploader.js'

const recordTypes = {
  itemOperation: 'ItemOperation',
  crossAccountOpperation: 'CrossAccountOperation',
  brokenItem: 'BrokenItem',
  brokenItemVideo: 'BrokenItemVideo',
  user: 'User'
}

const removeFileApiCall = ({ apiKey, itemId, operationId, itemOperationId, brokenItemId, recordType }) => {
  if (recordType === recordTypes.itemOperation) {
    return ItemOperationRemoveFile({ apiKey, itemId, itemOperationId })
  } else if (recordType === recordTypes.crossAccountOpperation) {
    return CrossAccountOperationRemoveFile({ apiKey, operationId })
  } else {
    const fileType = recordType === recordTypes.brokenItemVideo ? 'video' : 'file'
    return BrokenItemAddFileRemoveFile({ apiKey, deviceId: itemId, brokenItemId, fileType })
  }
}

const addFileApiCall = async ({ apiKey, itemId, operationId, itemOperationId, fileUploadSignature, brokenItemId, recordType, attachedFile }) => {
  const params = { file_name: fileUploadSignature.file_name }

  await uploadFileToRemoteServer(attachedFile, fileUploadSignature.file_url)

  if (recordType === recordTypes.itemOperation) {
    return ItemOperationAddFile({ apiKey, itemId, itemOperationId, params })
  } else if (recordType === recordTypes.crossAccountOpperation) {
    return CrossAccountOperationAddFile({ apiKey, operationId, params })
  } else if (recordType === recordTypes.brokenItem) {
    return BrokenItemAddFile({ apiKey, deviceId: itemId, brokenItemId, params })
  }
}

const addMultipleFilesApiCall = async ({ apiKey, userId, recordType, attachedFiles }) => {
  if (recordType === recordTypes.user) {
    const fileNames = []
    for (const attachedFile of attachedFiles) {
      fileNames.push(attachedFile.name)
    }
    const response = await UserAddFiles({ apiKey, userId, fileNames })
    const signatures = response.data.data.file_upload_signatures
    const promises = []
    for (const [index, attachedFile] of attachedFiles.entries()) {
      promises.push(uploadFileToRemoteServer(attachedFile, signatures[index].file_url))
    }
    await Promise.all(promises)
  }
}

const removeMultipleFilesApiCall = ({ apiKey, userId, recordType, fileIds }) => {
  if (recordType === recordTypes.user) {
    return UserRemoveFiles({ apiKey, userId, fileIds })
  }
}

export {
  recordTypes,
  removeFileApiCall,
  addFileApiCall,
  addMultipleFilesApiCall,
  removeMultipleFilesApiCall
}