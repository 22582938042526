<template>
  <div>
    <div class="d-flex justify-space-between color19 t-500-13">
      <span v-if="isDeadlinePassed">
        {{ hoursFromDeadlineTillNow }} {{ $t('company_broken_items.after_deadline') }}
      </span>
      <span v-else>
        {{ hoursFromOpenTillNow }}
      </span>
      <span>
        {{ hoursFromOpenTillDeadline }}
      </span>
    </div>
    <v-progress-linear :value="progressValue" :color="progressColor" height="6"
      rounded :background-color="backgroundColor"
      :class="{ 'rtl-progress': isRtl }">
    </v-progress-linear>
  </div>
</template>
<style lang="scss" scoped>
.rtl-progress {
  transform: scaleX(-1);

  ::v-deep .v-progress-linear__content {
    transform: scaleX(-1);
  }
}
</style>
<script>
import { deadlineColors } from '@/store/modules/BrokenItem.js'

import {
  isDateIsToday, isDateInTheFuture, dateAndTimeStringToDate,
  diffBetweenDatesInHoursAndMinutes, translateHoursAndMinutes
} from '@/helpers/DateTime.js'

import { isRtl } from '@/i18n'
import { computed } from 'vue'
export default {
  props: {
    brokenItem: {
      type: Object,
      required: true
    },
    backgroundColor: {
      type: String,
      default: 'rgba(255, 255, 255, 0.60)'
    }
  },
  setup(props) {
    const openDateObj = computed(() => dateAndTimeStringToDate({ dateString: props.brokenItem.open_date, timeString: props.brokenItem.open_time, defaultTime: '00:00' }))
    const deadlineDateObj = computed(() => dateAndTimeStringToDate({ dateString: props.brokenItem.deadline_date, timeString: props.brokenItem.deadline_time }))

    const progressValue = computed(() => {
      if (isDeadlinePassed.value) return 100
      const openDate = openDateObj.value
      const deadlineDate = deadlineDateObj.value
      const now = new Date()
      const total = deadlineDate - openDate
      const elapsed = now - openDate
      return Math.min(100, (elapsed / total) * 100)
    })
    const progressColor = computed(() => {
      const deadlineDate = deadlineDateObj.value
      if (isDateInTheFuture(deadlineDate)) return deadlineColors.thisWeek
      if (isDateIsToday(deadlineDate)) {
        const now = new Date()
        return now < deadlineDate ? deadlineColors.today : deadlineColors.passed
      }
      return deadlineColors.passed
    })

    const isDeadlinePassed = computed(() => {
      const now = new Date()
      return deadlineDateObj.value < now
    })

    const hoursFromOpenTillDeadline = computed(() => {
      const { hours, minutes } = diffBetweenDatesInHoursAndMinutes(openDateObj.value, deadlineDateObj.value)
      return translateHoursAndMinutes({ hours, minutes })
    })

    const hoursFromOpenTillNow = computed(() => {
      const { hours, minutes } = diffBetweenDatesInHoursAndMinutes(openDateObj.value, Date.now())
      return translateHoursAndMinutes({ hours, minutes })
    })

    const hoursFromDeadlineTillNow = computed(() => {
      const { hours, minutes } = diffBetweenDatesInHoursAndMinutes(deadlineDateObj.value, Date.now())
      return translateHoursAndMinutes({ hours, minutes })
    })

    return {
      progressValue,
      progressColor,
      hoursFromOpenTillDeadline,
      hoursFromOpenTillNow,
      isDeadlinePassed,
      hoursFromDeadlineTillNow,
      isRtl
    }
  }
}
</script>