import Client from '@/api_client/client.js'

function create ({ apiKey, shiftId, clientDate }) {
  const CREATE_ACCOUNT_JOB_COMPLETED_SHIFT = `/accounts/${apiKey}/account_job_completed_shifts`
  return Client.post(CREATE_ACCOUNT_JOB_COMPLETED_SHIFT,
    { account_job_completed_shift: { shift_id: shiftId }, client_date: clientDate })
}
function destroy ({ apiKey, shiftId, clientDate }) {
  const DELETE_ACCOUNT_JOB_COMPLETED_SHIFT = `/accounts/${apiKey}/account_job_completed_shifts`
  return Client.delete(DELETE_ACCOUNT_JOB_COMPLETED_SHIFT,
    { params: { account_job_completed_shift: { shift_id: shiftId }, client_date: clientDate } })
}

export {
  create,
  destroy
}
