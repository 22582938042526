import useAppChat from '@/composables/useAppChat' // 10MB

import { isString, isFile, isBlob } from '@/helpers/Utils'

import { EventBus, APP_CHAT_NEW_MESSAGE_SENT } from '@/EventBus.js'

import { computed, ref } from 'vue'

const MESSAGE_MAX_LENGTH = 5000
const currentMessage = ref(null)
const imageCount = ref(null)
const attachedFile = ref(null)
const attachedAudio = ref(null)
const attahcedVideo = ref(null)
const showAudioControls = ref(false)

export default function MessageInputState(apiKey) {
  const { activeConversation, createNewMessage } = useAppChat(apiKey)

  const newMessageRequestParams = (imageFiles) => {
    const params = { content: currentMessage.value }
    if (hasImages.value) {
      params.imageCount = imageCount.value
      params.imageFiles = imageFiles
    }
    if (isFileAttached.value === true) {
      params.withFile = attachedFile.value.name
      params.fileContent = attachedFile.value
    }
    if (hasAudio.value === true) {
      params.withAudio = true
      params.audioContent = attachedAudio.value
    }
    if (hasVideo.value === true) {
      params.withVideo = attahcedVideo.value.name
      params.videoContent = attahcedVideo.value
    }

    return params
  }

  // Methods
  const sendMessage = (imageFiles) => {
    return createNewMessage(newMessageRequestParams(imageFiles))
      .then(() => {
        currentMessage.value = null
        EventBus.emit(APP_CHAT_NEW_MESSAGE_SENT)
        attachedFile.value = null
        imageCount.value = null
        attahcedVideo.value = null
        closeAudio()
      })
  }

  const onImageUploaded = (newImageCount) => (imageCount.value = newImageCount)
  const imageRemoved = () => (imageCount.value = 0)
  const onShowAudio = () => (showAudioControls.value = true)
  const closeAudio = () => (showAudioControls.value = false)
  const fileClick = (event) => {
    if (isFileAttached.value !== true) return
    event.preventDefault()
    event.stopPropagation()
    attachedFile.value = null
  }
  // Computed

  const isLoading = computed(() => activeConversation.value.isLoading)
  const isSendingMessage = computed(() => activeConversation.value.isSendingMessage)
  const fileInputDisabled = computed(() => hasImages.value === true || hasVideo.value || isLoading.value === true)
  const videoInputDisabled = computed(() => hasImages.value === true || isFileAttached.value || isLoading.value === true)
  const isFileAttached = computed(() => isFile(attachedFile.value))
  const imageUploadDisabled = computed(() => isFileAttached.value === true || hasVideo.value || isLoading.value === true)
  const hasImages = computed(() => Number.isInteger(imageCount.value) && imageCount.value > 0)
  const hasAudio = computed(() => isBlob(attachedAudio.value))
  const hasVideo = computed(() => isFile(attahcedVideo.value))
  const showAudioDisabled = computed(() => isLoading.value === true || isFileAttached.value === true || hasImages.value === true)
  const sendMessageDisabled = computed(() => {
    if (isSendingMessage.value === true || isLoading.value === true) return true

    const currentMessageVal = currentMessage.value

    if (isString(currentMessageVal) && currentMessageVal.trim().length > MESSAGE_MAX_LENGTH) return true

    if (hasImages.value === true || isFileAttached.value || hasAudio.value) return false

    const isValidContent = isString(currentMessageVal) && currentMessageVal.trim().length > 0
    if (isValidContent !== true) return true
    return false
  })

  return {
    isLoading,
    isSendingMessage,
    currentMessage,
    sendMessageDisabled,
    attachedFile,
    fileInputDisabled,
    imageUploadDisabled,
    videoInputDisabled,
    attahcedVideo,
    isFileAttached,
    attachedAudio,
    showAudioControls,
    showAudioDisabled,
    closeAudio,
    onShowAudio,
    sendMessage,
    onImageUploaded,
    imageRemoved,
    fileClick
  }
}
