import Client from '@/api_client/client.js'

function show (apiKey) {
  const SITE_CUSTOM_STYLE_SHOW_PATH = `/accounts/${apiKey}/site_custom_style`
  return Client.get(SITE_CUSTOM_STYLE_SHOW_PATH)
}

export {
  show
}
