import {
  index as indexHqTaskJobGroups,
  create as createHqTaskJobGroup,
  update as updateHqTaskJobGroup,
  destroy as destroyHqTaskJobGroup
} from '@/api_client/HqTaskJobGroup.js'

import { companySearch as companyJobClientSearch } from '@/api_client/AccountJob.js'

import { isString } from '@/helpers/Utils.js'

import { ref } from 'vue'

let currentApiKey = null
let hqJobsGroupLoaded = false

const hqTaskJobGroups = ref([])
const isLoading = ref(false)

export default function useHqTaskJobGroup(apiKey) {
  const resetValues = () => {
    hqTaskJobGroups.value = []
    isLoading.value = false
    hqJobsGroupLoaded = false
  }

  if (isString(apiKey) && currentApiKey !== apiKey) {
    resetValues()
    currentApiKey = apiKey
  }

  // Methods
  const loadGroupJobs = (groupId) => companyJobClientSearch({ apiKey: currentApiKey, hqTaskGroupId: groupId })

  const loadHqTaskJobGroups = async (forceLoad = false) => {
    if (hqJobsGroupLoaded && !forceLoad) return
    isLoading.value = true
    const response = await indexHqTaskJobGroups(currentApiKey)
    hqTaskJobGroups.value = response.data.data
    hqJobsGroupLoaded = true
    isLoading.value = false
  }

  const createHqTaskGroup = ({ name, jobIds }) => {
    isLoading.value = true
    return createHqTaskJobGroup({ apiKey: currentApiKey, name, jobIds })
      .then(() => loadHqTaskJobGroups(true))
      .finally(() => isLoading.value = false)
  }

  const updateHqTaskGroup = ({ name, jobIds, id }) => {
    isLoading.value = true
    return updateHqTaskJobGroup({ apiKey: currentApiKey, name, jobIds, id })
      .then(() => loadHqTaskJobGroups(true))
      .finally(() => isLoading.value = false)
  }

  const deleteGroup = async (groupId) => {
    await destroyHqTaskJobGroup({ apiKey: currentApiKey, id: groupId })
    hqTaskJobGroups.value = hqTaskJobGroups.value.filter(group => group.id !== groupId)
  }

  return {
    hqTaskJobGroups,
    loadHqTaskJobGroups,
    createHqTaskGroup,
    updateHqTaskGroup,
    deleteGroup,
    loadGroupJobs
  }
}
