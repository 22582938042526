import Client from '@/api_client/client.js'
import { isString } from '@/helpers/Utils.js'

const create = (apiKey, { content, chatGroupId, withImages, withFile, withAudio, withVideo }) => {
  const CREATE_GROUP_CHAT_MESSAGE_PATH = `/accounts/${apiKey}/chat_groups/${chatGroupId}/chat_messages`
  const params = { chat_message: { content } }
  if (Number.isInteger(withImages) && withImages > 0) {
    params.chat_message.with_images = withImages
  } else if (isString(withFile) === true && withFile.length > 0) {
    params.chat_message.with_file = withFile
  } else if (withAudio === true) {
    params.chat_message.with_audio = true
  } else if (isString(withVideo) === true && withVideo.length > 0) {
    params.chat_message.with_video = withVideo
  }
  return Client.post(CREATE_GROUP_CHAT_MESSAGE_PATH, params)
}

const index = (apiKey, { chatGroupId, page, perPage, targetLanguage = null }) => {
  const INDEX_GROUP_CHAT_MESSAGE_PATH = `/accounts/${apiKey}/chat_groups/${chatGroupId}/chat_messages`
  const requestParams = { page, per_page: perPage }
  if (targetLanguage !== null) {
    requestParams.target_language = targetLanguage
  }
  return Client.get(INDEX_GROUP_CHAT_MESSAGE_PATH, { params: requestParams })
}

const destroy = (apiKey, { chatGroupId, chatMessageId }) => {
  const DELETE_GROUP_CHAT_MESSAGE_PATH = `/accounts/${apiKey}/chat_groups/${chatGroupId}/chat_messages/${chatMessageId}`
  return Client.delete(DELETE_GROUP_CHAT_MESSAGE_PATH)
}

const readBy = (apiKey, { chatGroupId, chatMessageId }) => {
  const READ_BY_CHAT_MESSAGE_PATH = `/accounts/${apiKey}/chat_groups/${chatGroupId}/chat_messages/${chatMessageId}/read_by`
  return Client.get(READ_BY_CHAT_MESSAGE_PATH)
}

export {
  create,
  index,
  destroy,
  readBy
}
