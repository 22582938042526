import Client from '@/api_client/client.js'

function resetUnreadPosts (apiKey) {
  const BLOG_POST_TRACK_RESET_UNREAD_PATH = `accounts/${apiKey}/blog_post_last_seen/reset`
  return Client.put(BLOG_POST_TRACK_RESET_UNREAD_PATH)
}

function unreadPosts (apiKey) {
  const BLOG_POST_UNREAD_PATH = `accounts/${apiKey}/blog_post_last_seen`
  return Client.get(BLOG_POST_UNREAD_PATH)
}
export {
  resetUnreadPosts,
  unreadPosts
}
