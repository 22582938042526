<template>
  <div class="d-flex flex-column chat-groups-list px-4 px-md-0">
    <new-group-dialog ref="newGroupDialog" :api-key="apiKey"></new-group-dialog>
    <confirm-delete ref="deleteDialog" max-width="300"></confirm-delete>
    <v-col class="flex-grow-0 flex-shrink-1 px-0 pt-0"
      v-if="$vuetify.breakpoint.mdAndUp" ref="chatImage">
      <v-expand-transition>
        <div v-show="showImage" class="back-image">
        </div>
      </v-expand-transition>
    </v-col>
    <v-col class="flex-grow-0 flex-shrink-1">
      <v-row align="start" justify="center">
        <v-col cols="12" class="pb-0 pt-4 pt-md-0">
          <app-chat-search
            :api-key="apiKey"
            @change="searchSelected"
            :disabled="isLoading">
          </app-chat-search>
        </v-col>
        <v-col cols="12" class="px-0 py-2">
          <v-btn color="#0E4AFD" text :disabled="isLoading" @click="createGroup">
            <v-icon>group_add</v-icon>
            {{ $t('app_chat.create_group') }}
          </v-btn>
        </v-col>
      </v-row>
    </v-col>
    <v-col v-if="showChatList" class="flex-grow-1 flex-shrink-0 chat-list py-0" ref="groupList">
      <v-card elevation="0">
        <v-list two-line class="py-0">
          <!-- There is no :value in v-list-item-group beacause when adding new group selected is diverged https://github.com/vuetifyjs/vuetify/issues/11405 -->
          <v-list-item-group
            color="primary">
            <template v-for="(chatGroup, index) in chatGroupsListFormatted">
              <v-divider v-if="index > 0" :key="`divider_${index}`"></v-divider>
              <chat-group-list-item
                :key="chatGroup.id"
                :api-key="apiKey"
                :chat-group="chatGroup"
                :class="{ 'v-list-item--active': chatGroup.id === (chatGroupsListFormatted[activeGroupIndex] || {}).id }"
                @click-group="clickChatGroup"
                @group-options="onGroupOptionsClick"
                @delete-group="onDeleteGroupClick"
                @exit-group="onExitGroupClick">
              </chat-group-list-item>
            </template>
          </v-list-item-group>
        </v-list>
      </v-card>
    </v-col>
    <v-col class="flex-grow-0 flex-shrink-1 pb-0" v-if="showPagination">
      <v-pagination v-model="pagination.page" circle total-visible="10" :length="pagination.totalPages"
        v-on:input="changeChatGroupsPage">
      </v-pagination>
    </v-col>
  </div>
</template>

<style lang="scss">
.chat-groups-list {
  background-color: #F4F6F8;

  .back-image {
    background-image: url(@/assets/chat_header.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 200px;
  }

  .chat-list {
    background-color: #F4F6F8;
    overflow-y: auto;

    @media only screen and (max-width: 600px) {
      .v-list-item {
        min-height: 60px;
      }
    }
  }
}
</style>

<script>
import AppChatSearch from '@/components/app_chat/AppChatSearch.vue'
import NewGroupDialog from '@/components/app_chat/NewGroupDialog.vue'
import ChatGroupListItem from '@/components/app_chat/ChatGroupListItem.vue'
import ConfirmDelete from '@/components/shared/ConfirmDelete.vue'

import useAppChat from '@/composables/useAppChat.js'
import useAccount from '@/composables/useAccount.js'

import { isValidISO8601Date, ISO8601dateToText } from '@/helpers/DateTime.js'

import { handler as errHandler } from '@/classes/ErrorHandler.js'

import { isObject } from '@/helpers/Utils.js'

import { EventBus, APP_CHAT_USER_JOINED_GROUP, NEW_APP_CHET_MESSAGE_RECEIVED } from '@/EventBus.js'

import { i18n, isRtl } from '@/i18n.js'

import { ref, computed, onUnmounted, watch, nextTick } from 'vue'

export default {
  props: {
    apiKey: {
      type: String,
      required: true
    }
  },
  components: {
    'app-chat-search': AppChatSearch,
    'new-group-dialog': NewGroupDialog,
    'chat-group-list-item': ChatGroupListItem,
    'confirm-delete': ConfirmDelete
  },
  setup(props, { emit }) {
    const newGroupDialog = ref(null)
    const deleteDialog = ref(null)
    const groupList = ref(null)
    const chatImage = ref(null)
    const showImage = ref(true)

    const { isLoading, chatGroupsList, pagination, activeGroupIndex, exitGroup, deleteGroup, changeChatGroupsPage, listGroups } = useAppChat(props.apiKey)

    const onUserJoinedGroup = () => {
      if (pagination.value.page !== 1) return

      listGroups().catch(errHandler)
    }

    const onNewMessageReceived = ({ group_id: groupId }) => {
      if (pagination.value.page !== 1) return

      const activeGroupIndexVal = activeGroupIndex.value
      if (Number.isInteger(activeGroupIndexVal) === true && chatGroupsList.value[activeGroupIndexVal].id === groupId) return

      listGroups().catch(errHandler)
    }


    // Methods
    const onScroll = () => showImage.value = groupList.value.scrollTop < 100
    const createGroup = () => newGroupDialog.value.openDialog()
    const clickChatGroup = (chatGroup) => emit('click-chat-group', JSON.parse(JSON.stringify(chatGroup)))
    const searchSelected = (event) => emit('search-selected', event)
    const onGroupOptionsClick = (chatGroup) => {
      const { id, name, image_url: imageUrl } = chatGroup
      const isReadOnly = chatGroup.is_current_user_group_admin !== true
      newGroupDialog.value.openForUpdate({ id, name, isReadOnly, imageUrl })
    }

    const onExitGroupClick = ({ chatGroupId, groupName, callback }) => {
      const title = i18n.t('app_chat.exit_group_confirm', { groupName })
      const content = i18n.t('app_chat.exit_group_confirm_detail')
      const deleteBtnText = i18n.t('app_chat.exit_group')
      deleteDialog.value.open({ title, content, deleteBtnText })
        .then((shouldDelete) => {
          if (!shouldDelete) {
            callback()
            return
          }

          exitGroup({ chatGroupId })
            .then(() => deleteDialog.value.close())
            .catch(errHandler)
        })
    }
    const onDeleteGroupClick = ({ chatGroupId, groupName, callback }) => {
      const title = i18n.t('app_chat.delete_group_confirm', { groupName })
      const content = i18n.t('app_chat.delete_group_confirm_detail')
      const deleteBtnText = i18n.t('app_chat.delete_group')
      deleteDialog.value.open({ title, content, deleteBtnText })
        .then((shouldDelete) => {
          if (!shouldDelete) {
            callback()
            return
          }

          deleteGroup({ chatGroupId })
            .then(() => deleteDialog.value.close())
            .catch(errHandler)
        })
    }
    // Computed

    const showChatList = computed(() => chatGroupsList.value.length > 0)
    const showPagination = computed(() => {
      const totalPages = pagination.value.totalPages
      return Number.isInteger(totalPages) && totalPages > 1
    })

    const chatGroupsListFormatted = computed(() => {
      const { accountCountryCode } = useAccount()
      const countryCode = accountCountryCode.value
      return chatGroupsList.value.map((chatGroup) => {
        const fromDate = chatGroup.updated_at
        if (isValidISO8601Date(fromDate)) {
          chatGroup.dateFormatted = ISO8601dateToText({ fromDate, countryCode })
        }
        if (chatGroup.multiple_participants === false && isObject(chatGroup.other_user_data)) {
          const { first_name: firstName, last_name: lastName } = chatGroup.other_user_data
          chatGroup.name = `${firstName} ${lastName}`
        }
        return chatGroup
      })
    })

    listGroups().catch(errHandler)

    EventBus.off(APP_CHAT_USER_JOINED_GROUP, onUserJoinedGroup)
    EventBus.on(APP_CHAT_USER_JOINED_GROUP, onUserJoinedGroup)

    EventBus.off(NEW_APP_CHET_MESSAGE_RECEIVED, onNewMessageReceived)
    EventBus.on(NEW_APP_CHET_MESSAGE_RECEIVED, onNewMessageReceived)

    onUnmounted(() => {
      EventBus.off(APP_CHAT_USER_JOINED_GROUP, onUserJoinedGroup)
      EventBus.off(NEW_APP_CHET_MESSAGE_RECEIVED, onNewMessageReceived)

      if (groupList.value === null) return

      groupList.value.removeEventListener('scroll', onScroll);
    })
    watch(() => showChatList.value, (isShow) => {
      if (isShow !== true || chatImage.value === null) return

      nextTick(() => {
        if (groupList.value.scrollHeight < 580) return

        groupList.value.addEventListener('scroll', onScroll)
      })
    })
    return {
      isLoading,
      chatGroupsListFormatted,
      showChatList,
      showPagination,
      pagination,
      isRtl,
      activeGroupIndex,
      newGroupDialog,
      deleteDialog,
      groupList,
      chatImage,
      showImage,
      createGroup,
      onGroupOptionsClick,
      changeChatGroupsPage,
      clickChatGroup,
      searchSelected,
      onExitGroupClick,
      onDeleteGroupClick
    }
  }
}
</script>
