import Client from '@/api_client/client.js'

const create = (apiKey, params) => {
  const CREATE_CROSS_ACCOUNT_OPERATION = `/accounts/${apiKey}/cross_account_operations`
  return Client.post(CREATE_CROSS_ACCOUNT_OPERATION, params)
}

const operationAccountJobs = (apiKey, operationId) => {
  const CROSS_ACCOUNT_OPERATION_ACCOUNT_JOBS = `/accounts/${apiKey}/cross_account_operations/${operationId}/operation_account_jobs`
  return Client.get(CROSS_ACCOUNT_OPERATION_ACCOUNT_JOBS)
}

const update = (apiKey, operationId, params) => {
  const CROSS_ACCOUNT_OPERATION_SET = `/accounts/${apiKey}/cross_account_operations/${operationId}`
  return Client.put(CROSS_ACCOUNT_OPERATION_SET, params)
}

const itemListOperationSetter = (apiKey, operationId, params) => {
  const CROSS_ACCOUNT_ITEM_LIST_OPERATION_SET = `/accounts/${apiKey}/cross_account_operations/${operationId}/item_list_setter`
  return Client.post(CROSS_ACCOUNT_ITEM_LIST_OPERATION_SET, params)
}

const addImages = ({ apiKey, operationId, params }) => {
  const CROSS_ACCOUNT_OPERATION_ADD_IMAGES_PATH = `/accounts/${apiKey}/cross_account_operations/${operationId}/add_images`
  return Client.post(CROSS_ACCOUNT_OPERATION_ADD_IMAGES_PATH, params)
}

const removeImages = ({ apiKey, operationId, params }) => {
  const CROSS_ACCOUNT_OPERATION_REMOVE_IMAGES_PATH = `/accounts/${apiKey}/cross_account_operations/${operationId}/remove_images`
  return Client.delete(CROSS_ACCOUNT_OPERATION_REMOVE_IMAGES_PATH, { params })
}

const addFile = ({ apiKey, operationId, params }) => {
  const CROSS_ACCOUNT_OPERATION_ADD_FILE_PATH = `/accounts/${apiKey}/cross_account_operations/${operationId}/add_file`
  return Client.post(CROSS_ACCOUNT_OPERATION_ADD_FILE_PATH, params)
}

const removeFile = ({ apiKey, operationId }) => {
  const CROSS_ACCOUNT_OPERATION_REMOVE_FILE_PATH = `/accounts/${apiKey}/cross_account_operations/${operationId}/remove_file`
  return Client.delete(CROSS_ACCOUNT_OPERATION_REMOVE_FILE_PATH)
}

const destroy = (apiKey, crossAccountOperationId) => {
  const CROSS_ACCOUNT_OPERATION_DESTROY = `/accounts/${apiKey}/cross_account_operations/${crossAccountOperationId}`
  return Client.delete(CROSS_ACCOUNT_OPERATION_DESTROY)
}

const generalStats = async (apiKey, params) => {
  const CROSS_ACCOUNT_GENERAL_STATS = `/accounts/${apiKey}/cross_account_operations/stats`
  return Client.put(CROSS_ACCOUNT_GENERAL_STATS, params)
}

const index = async (apiKey, params) => {
  const CROSS_ACCOUNT_TASKS_INDEX = `/accounts/${apiKey}/cross_account_operations`
  return Client.put(CROSS_ACCOUNT_TASKS_INDEX, params)
}

export {
  create,
  update,
  operationAccountJobs,
  itemListOperationSetter,
  addImages,
  removeImages,
  addFile,
  removeFile,
  destroy,
  generalStats,
  index
}
