<template>
  <v-container fluid class="tasks-layout full-height pa-0">
    <tasks-layout-big-devices v-if="$vuetify.breakpoint.smAndUp"
      :api-key="selectedApiKey"
      :staff-account="staffAccount"
      :date-range-scope="dateRangeScope"
      :client-date="clientDate"
      @change-date="onChangeDate"
      @change-account="onChangeAccount"
      @date-range-scope-changed="onDateRangeScopeChanged">
    </tasks-layout-big-devices>
    <tasks-layout-small-devices v-else
      :api-key="selectedApiKey"
      :staff-account="staffAccount"
      @change-date="onChangeDate">
    </tasks-layout-small-devices>
    <add-task-button v-if="isAccountManagerOrAbove" :api-key="selectedApiKey" :task-mode="addTaskMode">
    </add-task-button>
  </v-container>
</template>
<style lang="scss">
.tasks-layout {
  margin-top: 14px;

  @media screen and (max-width: 600px) {
    margin-top: 0;
  }
}
</style>
<script>
import TasksLayoutBigDevices from '@/components/tasks/layout/TasksLayoutBigDevices.vue'
import TasksLayoutSmallDevices from '@/components/tasks/layout/TasksLayoutSmallDevices.vue'
import AddTaskButton from '@/components/tasks/AddTaskButton.vue'

import { dateToISO8601String } from '@/helpers/DateTime.js'

import useDailyJobs from '@/composables/useDailyJobs.js'
import useAuth from '@/composables/useAuth.js'

import { ref, watch, computed } from 'vue'
export default {
  props: {
    apiKey: {
      type: String,
      required: true
    },
    staffAccount: {
      type: Boolean,
      default: false
    },
    clientDate: {
      type: Date,
      required: true
    },
    dateRangeScope: {
      type: String,
      required: true
    }
  },
  components: {
    'tasks-layout-big-devices': TasksLayoutBigDevices,
    'tasks-layout-small-devices': TasksLayoutSmallDevices,
    'add-task-button': AddTaskButton
  },
  setup(props, { emit }) {
    const selectedApiKey = ref(props.apiKey)

    const { isAccountManagerOrAbove } = useAuth()
    const { initPageData, changeApiKey, changeDate, clientDate } = useDailyJobs(props.apiKey)

    // Methods
    const onDateRangeScopeChanged = (dateRangeScopeVal) => emit('date-range-scope-changed', dateRangeScopeVal)

    const onChangeDate = (newDate) => {
      if (dateToISO8601String(newDate) === dateToISO8601String(clientDate.value)) return

      emit('change-date', newDate)
      changeDate(newDate)
    }

    const onChangeAccount = (newApiKey) => {
      selectedApiKey.value = newApiKey
      if (isDayDateRangeScope.value) {
        changeApiKey(newApiKey)
      }
    }

    // Computed
    const isDayDateRangeScope = computed(() => props.dateRangeScope === 'day')
    const addTaskMode = computed(() => props.staffAccount ? 'staff' : 'account')

    initPageData({ clientDateInitial: props.clientDate, showDepartmentsUiMode: true })

    watch(() => props.clientDate, (newDate) => {
      if (newDate === null) return
      if (dateToISO8601String(newDate) === dateToISO8601String(clientDate.value)) return

      changeDate(newDate)
    })
    return {
      selectedApiKey,
      isAccountManagerOrAbove,
      addTaskMode,
      onChangeAccount,
      onChangeDate,
      onDateRangeScopeChanged
    }
  }
}
</script>