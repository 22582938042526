<template>
  <div>
    <v-dialog
      v-model="dialog"
      width="290px"
      persistent>
      <v-time-picker v-if="dialog"
        :value="value"
        :format="format"
        @input="update"
        @click:minute="closeTimeSelect">
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="reset">{{ $t('reset') }}</v-btn>
      </v-time-picker>
    </v-dialog>
    <v-text-field
      :value="timeInUserFormat"
      :label="placeholder ? placeholder : $t('schedule_new_op_dialog.execution_time')"
      readonly
      single-line
      clearable
      @click:clear="reset"
      :disabled="disabled"
      @click="openTimeSelect">
    </v-text-field>
  </div>
</template>

<script>
import useAccount from '@/composables/useAccount.js'

import { timeStringToUserFormat } from '@/helpers/DateTime.js'
import { isString } from '@/helpers/Utils.js'

import { ref, computed } from 'vue'
export default {
  props: {
    value: {
      type: String,
      required: false,
      default: ''
    },
    placeholder: {
      type: String,
      required: false,
      default: null
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  setup(props, { emit }) {
    const dialog = ref(false)
    const format = ref('24hr')
    const { accountTimeZone, accountLocaleCode } = useAccount()
    const timeZone = accountTimeZone.value || ''
    const localeCode = accountLocaleCode.value || ''
    format.value = timeZone.startsWith('America') ? 'ampm' : '24hr'

    // Methods
    const openTimeSelect = () => {
      if (props.disabled) return
      dialog.value = true
    }
    const closeTimeSelect = () => (dialog.value = false)
    const update = (newValue) => emit('input', newValue)
    const reset = () => {
      update('')
      closeTimeSelect()
    }

    // Computed
    const timeInUserFormat = computed(() => {
      const isValidTime = isString(props.value) && props.value.length === 5
      if (!isValidTime) return props.value
      return timeStringToUserFormat({ timeString: props.value, localeCode })
    })
    return {
      dialog,
      format,
      timeInUserFormat,
      update,
      openTimeSelect,
      closeTimeSelect,
      reset
    }
  }
}
</script>
