<template>
  <v-select
    color="black"
    hide-no-data
    class="account-search"
    item-value="apiKey"
    item-text="name"
    :value="value"
    :items="accountWithDistrictFilter"
    :append-icon="appendIcon"
    :multiple="multiple"
    :isLoading="isLoading"
    :placeholder="$t('account_selection_select.placeholder')"
    :disabled="disabled"
    :outlined="outlined"
    :dense="dense"
    :menu-props="menuPropsWithClass"
    :clearable="clearable"
    :class="{ 'rtl': isRtl, 'ltr': !isRtl }"
    :no-data-text="$t('no_result')"
    @input="update"
    @change="change"
    @blur="emitBlur"
    return-object>
    <template v-slot:prepend-item v-if="multiple && showSelectAll">
      <v-list-item ripple @click="toggleSelectAll">
        <v-list-item-content>
          <v-list-item-title>
            {{ $t('select_all') }}
          </v-list-item-title>
        </v-list-item-content>
        <v-list-item-action class="mx-0">
          <v-icon>
            {{ selectAllIcon }}
          </v-icon>
        </v-list-item-action>
      </v-list-item>
      <v-divider class="mt-2"></v-divider>
    </template>

    <template v-slot:selection="{ index }">
      <div v-if="index === 0" class="v-select__selection v-select__selection--comma">
        <span v-if="multiple">
          {{ $t('account_selection_select.x_accounts_selected', { account_count: value.length }) }}
        </span>
        <span v-else>
          {{ nameForApiKey(value) }}
        </span>
      </div>
    </template>

    <template v-slot:item="{ item: account, attrs }">
      <v-row no-gutters justify="start" align="center" v-if="addFaultDeviceCount || addFaultDeviceCountAllStatuses">
        <v-col :cols="multiple ? 10 : 12" class="font-weight-bold">
          {{ account.name }} ({{ account.faultsCount }})
        </v-col>
        <v-col cols="2" class="texts-end" v-if="multiple">
          <v-icon v-if="attrs.inputValue">check_box</v-icon>
          <v-icon v-else>check_box_outline_blank</v-icon>
        </v-col>
      </v-row>
      <v-row no-gutters justify="start" align="center" v-else-if="addAccountTaskCount">
        <v-col cols="12" class="font-weight-bold">
          {{ account.name }} ({{ account.totalTasks }} / {{ account.finishedTasksCount }})
        </v-col>
      </v-row>
      <v-row no-gutters justify="start" align="center" v-else>
        <v-col :cols="multiple ? 10 : 12" class="font-weight-bold">
          {{ account.name }}
        </v-col>
        <v-col cols="2" class="texts-end" v-if="multiple">
          <v-icon v-if="attrs.inputValue">check_box</v-icon>
          <v-icon v-else>check_box_outline_blank</v-icon>
        </v-col>
      </v-row>
    </template>
  </v-select>
</template>

<style lang="scss">
.account-search {
  div.v-subheader {
    font-weight: 700;
    background-color: #E8E8E8;
  }

  .v-text-field__details {
    display: none;
  }
}

.account-search.v-select.v-select--is-menu-active .v-input__icon--append .v-icon {
  transform: rotate(0);
}
</style>

<script>
import { companyTotals as faultyDeviceCompanyTotals } from '@/api_client/AccountFaultyDevice.js'

import { totalOperationsForAccounts } from '@/api_client/OperationSchedule.js'

import useAccount from '@/composables/useAccount.js'

import { getClientDate, isDateInTheFuture, isDateIsToday } from '@/helpers/DateTime.js'

import { isObject, isString } from '@/helpers/Utils.js'

import { isRtl } from '@/i18n.js'

import { handler as errHandler } from '@/classes/ErrorHandler.js'

import { ref, computed, watch } from 'vue'

const getApiKey = (account) => isObject(account) ? account.apiKey : account
const EMPTY_DISTRICT_ID = -1

export default {
  props: {
    apiKey: {
      type: String,
      required: true
    },
    value: {
      type: [Array, String, Object],
      default() {
        return []
      }
    },
    menuProps: {
      type: Object,
      required: false,
      default: () => { }
    },
    multiple: {
      type: Boolean,
      default: true
    },
    returnObject: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    outlined: {
      type: Boolean,
      default: true
    },
    dense: {
      type: Boolean,
      default: true
    },
    returnObject: {
      type: Boolean,
      default: false
    },
    districtIds: {
      type: Array,
      default: () => []
    },
    addFaultDeviceCount: {
      type: Boolean,
      default: false
    },
    addFaultDeviceCountAllStatuses: {
      type: Boolean,
      default: false
    },
    addAccountTaskCount: {
      type: Boolean,
      required: false,
      default: false
    },
    clientDate: {
      type: Date,
      required: false,
      default: null
    },
    showSelectAll: {
      type: Boolean,
      required: false,
      default: true
    },
    appendIcon: {
      type: String,
      required: false,
      default: 'search'
    },
    clearable: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  setup(props, { emit }) {
    const isLoading = ref(true)
    const allAccounts = ref([])
    const { loadAllAccounts } = useAccount()
    const requests = [loadAllAccounts()]
    if (props.addFaultDeviceCount || props.addFaultDeviceCountAllStatuses) {
      const requestParams = {}
      if (props.addFaultDeviceCountAllStatuses) {
        requestParams.allStatuses = true
      }
      requests.push(faultyDeviceCompanyTotals(props.apiKey, requestParams))
    }
    Promise.all(requests).then(async (responses) => {
      const accounts = responses[0]
      await addTasksCount(accounts)
      const faultsCount = props.addFaultDeviceCount || props.addFaultDeviceCountAllStatuses ? responses[1].data.data : {}
      const allAccountsVal = allAccounts.value
      let currentDistrictId = null
      accounts.forEach((account) => {
        const result = JSON.parse(JSON.stringify(account))
        if (Number.isInteger(result.company_district_id) && result.company_district_id !== currentDistrictId) {
          allAccountsVal.push({ id: result.company_district_id, header: result.district_name })
          currentDistrictId = result.company_district_id
        }
        result.apiKey = account.api_key
        result.faultsCount = faultsCount[account.api_key] || 0
        allAccountsVal.push(result)
      })
    }).catch(errHandler)
      .finally(() => (isLoading.value = false))
    const onChange = (newValue) => {
      if (props.multiple) {
        const nonHeaderValues = newValue.filter((val) => !val.header)
        return props.returnObject ? nonHeaderValues : nonHeaderValues.map(getApiKey)
      }
      return props.returnObject ? newValue : getApiKey(newValue)
    }

    const addTasksCount = (accounts) => {
      const apiKeys = accounts.map((account) => account.api_key)
      if (props.addAccountTaskCount !== true || props.clientDate === null ||
        apiKeys.length === 0) return

      const requestParams = {
        client_date: getClientDate(props.clientDate),
        client_date_is_today: isDateIsToday(props.clientDate),
        client_date_is_future: isDateInTheFuture(props.clientDate),
        api_keys: apiKeys
      }
      return totalOperationsForAccounts(props.apiKey, requestParams)
        .then((response) => {
          const responseData = response.data.data
          accounts.forEach((account) => {
            const totalOperations = responseData[account.api_key] || { tasks_count: 0, finished_tasks_count: 0, cross_account_tasks_count: 0, finished_cross_account_tasks_count: 0 }
            account.totalTasks = totalOperations.tasks_count + totalOperations.cross_account_tasks_count
            account.finishedTasksCount = totalOperations.finished_tasks_count + totalOperations.finished_cross_account_tasks_count
          })
        }).catch(errHandler)

    }

    // Methods
    const update = (newValue) => emit('input', onChange(newValue))
    const change = (newValue) => emit('change', onChange(newValue))
    const emitBlur = () => emit('blur')
    const nameForApiKey = (val) => {
      if (props.returnObject) return val.name

      return allAccounts.value.find((account) => account.apiKey === val).name
    }
    const toggleSelectAll = () => {
      if (isAllAccountsSelected.value === true) {
        update([])
      } else {
        update(accountWithDistrictFilter.value)
      }
    }

    // Computed
    const isAllAccountsSelected = computed(() => {
      if (Array.isArray(props.value) === false) return false
      return accountWithDistrictFilter.value.filter((account) => !account.header).length === props.value.filter((account) => !account.header).length
    })
    const somAccountSelected = computed(() => props.value.length > 0)
    const selectAllIcon = computed(() => {
      if (isAllAccountsSelected.value === true) return 'check_box'
      if (somAccountSelected.value === true) return 'indeterminate_check_box'
      return 'check_box_outline_blank'
    })

    const accountWithDistrictFilter = computed(() => {
      if (props.districtIds.length === 0) return allAccounts.value
      return allAccounts.value.filter((account) => {
        let districtId = null
        if ('header' in account) {
          districtId = account.id
        } else {
          districtId = account.company_district_id === null ? EMPTY_DISTRICT_ID : account.company_district_id
        }
        return props.districtIds.includes(districtId)
      })
    })

    const menuPropsWithClass = computed(() => {
      const result = isObject(props.menuProps) ? Object.assign(props.menuProps, {}) : {}
      result.contentClass = isString(result.contentClass) ? result.contentClass : ''
      const addedClass = isRtl.value ? 'rtl' : 'ltr'
      result.contentClass = `${result.contentClass} ${addedClass} account-search`
      return result
    })

    watch(() => props.districtIds, () => {
      if (!Array.isArray(props.value)) return
      if (props.districtIds.length === 0) return

      const filteredAccounts = props.value.filter((account) => {
        let foundDistrictId = null
        if (props.returnObject) {
          foundDistrictId = account.company_district_id
        } else {
          foundDistrictId = allAccounts.value.find((row) => row.apiKey === account).company_district_id
        }
        const districtId = foundDistrictId === null ? EMPTY_DISTRICT_ID : account.company_district_id
        return props.districtIds.includes(districtId)
      })
      if (filteredAccounts.length === props.value.length) return
      update(filteredAccounts)
    })
    watch(() => props.clientDate, () => addTasksCount(allAccounts.value))

    return {
      isLoading,
      allAccounts,
      isAllAccountsSelected,
      selectAllIcon,
      accountWithDistrictFilter,
      isRtl,
      menuPropsWithClass,
      update,
      change,
      toggleSelectAll,
      nameForApiKey,
      emitBlur
    }
  }
}
</script>
