<template>
  <v-dialog v-model="dialog" max-width="250" persistent>
    <v-card :class="{ 'rtl': isRtl, 'ltr': !isRtl }">
      <v-card-title class="text-center pb-0">
        <span class="headline full-width">
          {{ $t('create_district_dialog.title') }}
        </span>
      </v-card-title>
      <v-card-text class="py-0">
        <v-row align="start" justify="space-around" no-gutters>
          <v-col cols="12">
            <v-text-field
              v-model="districtName"
              single-line
              :rules="[requiredField]"
              counter="80"
              :label="$t('create_district_dialog.placeholder')">
            </v-text-field>
          </v-col>
          <v-col cols="12" class="mt-3">
            <v-card-actions class="pt-0 ppe-0">
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="close" :disabled="isLoading">
                {{ $t('cancel') }}
              </v-btn>
              <v-btn color="blue darken-1" depressed tile @click="save" :loading="isLoading"
                :disabled="isSaveDisabled">
                {{ $t('save') }}
              </v-btn>
            </v-card-actions>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import useAuth from '@/composables/useAuth.js'

import { onDialogClose, onDialogOpen } from '@/classes/DialogScrollBug.js'
import { requiredField } from '@/classes/InputValidators.js'
import { isString } from '@/helpers/Utils.js'
import { handler as errHandler } from '@/classes/ErrorHandler.js'

import { createCompanyDistrict } from '@/models/CompanyDistrict.js'
import { isRtl } from '@/i18n'
import { computed, ref } from 'vue'
export default {
  setup() {
    const dialog = ref(false)
    const isLoading = ref(false)
    const districtName = ref(null)

    // Methods

    const openDialog = () => {
      onDialogOpen()
      dialog.value = true
    }

    const close = () => {
      onDialogClose()
      dialog.value = false
      isLoading.value = false
      districtName.value = null
    }

    const save = () => {
      isLoading.value = true
      const { apiKey } = useAuth()
      createCompanyDistrict({ apiKey: apiKey.value, districtName: districtName.value })
        .catch(errHandler)
        .finally(() => {
          isLoading.value = false
          close()
        })
    }

    // Computed

    const isSaveDisabled = computed(() => {
      if (!isString(districtName.value)) return true

      const length = districtName.value.trim().length
      const isValidName = length > 0 && length <= 80
      return !isValidName
    })
    return {
      dialog,
      isLoading,
      districtName,
      isSaveDisabled,
      isRtl,
      openDialog,
      close,
      save,
      requiredField
    }
  }
}
</script>
