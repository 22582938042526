<template>
  <v-container fluid grid-list-xs class="org-structure-suppliers">
    <new-supplier-dialog ref="supplierDialog"></new-supplier-dialog>
    <new-supplier-operation ref="supplierOperation"></new-supplier-operation>
    <confirm-delete ref="deleteDialog"></confirm-delete>
    <v-row align="start" justify="start" class="supplier-filter">
      <v-col cols="10" sm="5" lg="3" class="mb-2 pt-0 px-0">
        <supplier-filter :api-key="apiKey"></supplier-filter>
      </v-col>
    </v-row>
    <v-row align="center" justify="space-around" no-gutters>
      <v-col cols="12">
        <v-btn fab small color="black white--text" class="ml-2" @click="createNewSupplier">
          <v-icon>add</v-icon>
        </v-btn>
      </v-col>
      <v-col cols="12" class="ml-2 mt-2">
        <span class="font-weight-bold">
          {{ $t("org_structure_suppliers.total_suppliers") }}
        </span>
        <span class="font-weight-bold blue--text">
          {{totalSuppliers}}
        </span>
      </v-col>
      <v-col cols="12" class="mt-2 supplier-table-wrapper">
        <v-icon color="red" class="scroll-hint">navigate_next</v-icon>
        <v-data-table
          :items="suppliersList"
          :loading="isLoading"
          class="elevation-1 org-supplier-table"
          :loading-text="$t('loading')"
          :items-per-page="DEFAULT_PER_PAGE"
          hide-default-header
          hide-default-footer
          item-key="id">
          <template v-slot:item="{ item }">
            <suppliers-tab-row
              :supplier="item"
              @create-operation="createOperation"
              @edit-operation="editOperation"
              @edit-supplier="editSupplier"
              @delete-supplier="deleteSupplierClick">
            </suppliers-tab-row>
          </template>
          <template v-slot:no-data>
            <v-alert :value="true" color="white black--text">
              {{ $t("no_result") }}
            </v-alert>
          </template>
        </v-data-table>
      </v-col>
      <v-col cols="12" class="text-center mb-12 mt-2 mb-sm-0" v-if="totalPages > 0">
        <v-pagination
          :value="pagination.page"
          circle
          total-visible="10"
          :length="totalPages"
          @input="setPage"
          @previous="setPage( pagination.page - 1 )"
          @next="setPage( pagination.page + 1 )">
        </v-pagination>
      </v-col>
    </v-row>
  </v-container>
</template>
<style lang="scss">
.org-structure-suppliers {
  margin-top: 116px;
  @media only screen and (max-width: 600px) {
    margin-top: 140px;
    .supplier-filter {
      .page-filters {
        top: 152px;
      }
    }
  }
  .supplier-table-wrapper {
    .scroll-hint {
      display: none;
    }
    @media only screen and (max-width: 960px) {
      position: relative;
      .scroll-hint {
        display: inline;
        position: absolute;
        top: 6px; right: 0;
      }
    }
    .org-supplier-table {
      .v-data-table__wrapper {
          overflow-x: hidden;
      }

      @media only screen and (max-width: 960px) {
        .v-data-table__wrapper {
          overflow-x: scroll;
        }
        tbody {
          tr {
            width: 1100px !important;
            display: block
          }
        }
      }
    }
  }
}
</style>
<script>
import NewSupplierDialog from '@/components/organization_structure/supplier/SuppliersTabNewSupplierDialog.vue'
import NewSupplierOperation from '@/components/organization_structure/supplier/NewSupplierOperation.vue'
import SuppliersTabRow from '@/components/organization_structure/supplier/SuppliersTabRow.vue'
import SupplierFilter from '@/components/organization_structure/supplier/SupplierFilter.vue'

import ConfirmDelete from '@/components/shared/ConfirmDelete.vue'

import useAccountSupplier from '@/composables/useAccountSupplier.js'
import useAuth from '@/composables/useAuth.js'

import { scrollToTop } from '@/classes/TopScroll.js'
import { handler as errHandler } from '@/classes/ErrorHandler.js'

import { useRoute } from 'vue-router/composables'

import { ref } from 'vue'

export default {
  components: {
    'new-supplier-dialog': NewSupplierDialog,
    'suppliers-tab-row': SuppliersTabRow,
    'new-supplier-operation': NewSupplierOperation,
    'confirm-delete': ConfirmDelete,
    'supplier-filter': SupplierFilter
  },
  setup () {
    const route = useRoute()
    const { apiKey } = useAuth()
    const supplierDialog = ref(null)
    const supplierOperation = ref(null)
    const deleteDialog = ref(null)
    const {
      DEFAULT_PER_PAGE, suppliersList, totalSuppliers, pagination, totalPages, isLoading,
      loadAllSuppliers, deleteSupplier
    } = useAccountSupplier(apiKey.value)
    const supplierId = route.params.supplierId
    const page = route.params.page

    // Methods

    const createNewSupplier = () => supplierDialog.value.openForCreate()
    const createOperation = (data) => supplierOperation.value.openForCreate({ dayName: data.day.name, supplierId: data.supplier.id })
    const editOperation = (data) => supplierOperation.value.openForUpdate({ dayName: data.day.name, supplier: data.supplier, scheduledOperation: data.operation, supplierId: data.supplier.id })
    const editSupplier = (data) => supplierDialog.value.openForUpdate(data.supplier)

    const deleteSupplierClick = (data) => deleteDialog.value.open()
      .then((shouldDelete) => {
        if (!shouldDelete) return
        deleteDialog.value.close()
        deleteSupplier(data.supplier.id)
          .catch(errHandler)
      })

    const setPage = (page) => {
      loadAllSuppliers(page)
      scrollToTop()
    }

    loadAllSuppliers(page)
      .then(() => {
        if (!supplierId) return
        const supplierElement = document.getElementById(supplierId)
        if (supplierElement) {
          supplierElement.scrollIntoView()
        }
      })
      .catch(errHandler)

    return {
      DEFAULT_PER_PAGE,
      apiKey,
      supplierDialog,
      supplierOperation,
      deleteDialog,
      suppliersList,
      totalSuppliers,
      pagination,
      totalPages,
      isLoading,
      createNewSupplier,
      setPage,
      editOperation,
      createOperation,
      editSupplier,
      deleteSupplierClick
    }
  }
}
</script>
