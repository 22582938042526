<template>
  <v-row align="start" justify="start" no-gutters class="single-broken-item"
    :class="{ rtl: isRtl }" :style="{ 'background-color': backgroundColor }">
    <v-col cols="12">
      <broken-item-top-part
        :broken-item="brokenItem"
        @media-click="onMediaClick">
      </broken-item-top-part>
    </v-col>
    <v-col cols="12">
      <broken-item-middle-part :broken-item="brokenItem">
      </broken-item-middle-part>
    </v-col>
    <v-col cols="12" class="align-self-end">
      <broken-item-deadline-progress
        class="my-2"
        v-if="showProgress"
        :broken-item="brokenItem">
      </broken-item-deadline-progress>
      <broken-item-bottom-part
        :broken-item="brokenItem"
        :allow-edit="allowEdit"
        :allow-delete="allowDelete"
        :show-close-option="showCloseOption"
        :show-chat="showChat"
        @open-chat="openChat"
        @edit-broken-item="editBrokenItem"
        @delete-broken-item="deleteBrokenItem"
        @change-status="changeStatus">
      </broken-item-bottom-part>
    </v-col>
  </v-row>
</template>
<style lang="scss">
.single-broken-item {
  padding: 12px;
  height: 100%;
  border-radius: 12px;
  border: 1px solid #DADADA;

  &.open {
    background-color: #F7B3C2;
  }

  &.in_progress {
    background-color: #FFE0B3;
  }

  &.done {
    background-color: #B3C6FF;
  }
}
</style>
<script>
import BrokenItemDeadlineProgress from '@/components/shared/single_broken_item/BrokenItemDeadlineProgress.vue'
import { statusColors, statuses } from '@/store/modules/BrokenItem.js'

import { isRtl } from '@/i18n.js'
import { computed } from 'vue'
export default {
  props: {
    brokenItem: {
      type: Object,
      required: true
    },
    allowDelete: {
      type: Boolean,
      required: true
    },
    allowEdit: {
      type: Boolean,
      required: true
    },
    showCloseOption: {
      type: Boolean,
      default: false
    },
    showChat: {
      type: Boolean,
      default: true
    }
  },
  components: {
    'broken-item-top-part': () => import('@/components/shared/single_broken_item/BrokenItemTopPart.vue'),
    'broken-item-middle-part': () => import('@/components/shared/single_broken_item/BrokenItemMiddlePart.vue'),
    'broken-item-bottom-part': () => import('@/components/shared/single_broken_item/BrokenItemBottomPart.vue'),
    'broken-item-deadline-progress': BrokenItemDeadlineProgress
  },
  setup(props, { emit }) {

    // Methods
    const openChat = () => emit('open-chat', props.brokenItem)
    const editBrokenItem = () => emit('edit-broken-item', props.brokenItem)
    const deleteBrokenItem = () => emit('delete-broken-item', props.brokenItem)
    const changeStatus = (status) => emit('change-status', { brokenItem: props.brokenItem, status })
    const onMediaClick = (mediaUrls) => emit('media-click', mediaUrls)

    // Computed
    const backgroundColor = computed(() => statusColors[props.brokenItem.status])
    const showProgress = computed(() => props.brokenItem.status !== statuses.close && props.brokenItem.deadline_date != null)
    return {
      showProgress,
      backgroundColor,
      isRtl,
      openChat,
      editBrokenItem,
      deleteBrokenItem,
      changeStatus,
      onMediaClick
    }
  }
}
</script>