<template>
  <v-row align="center" justify="center" class="pt-4"
    v-if="tasksInfo.isLoading">
    <v-col v-for="index in 3" :key="index" cols="12" md="6" lg="4">
      <v-skeleton-loader type="card" elevation="2"></v-skeleton-loader>
    </v-col>
  </v-row>
  <v-row align="stretch" justify="start" v-else>
    <v-col cols="12" :md="task.isTitle ? 12 : 4"
      :sm="task.isTitle ? 12 : 6" v-for="task in tasks"
      :key="task.operation_id">
      <div v-if="task.isTitle" class="text-center t-700-18">
        {{ task.accountName }}
      </div>
      <task-bank-single-task v-else
        :hq-context="hqContext"
        :task="task"
        :staff-account="staffAccount"
        @edit-click="onEditClick"
        @delete-click="onDeleteClick"
        @copy-click="onCopyClick">
      </task-bank-single-task>
    </v-col>
    <v-col v-if="showLoadMorePages" v-intersect="onIntersect"></v-col>
    <v-col cols="12" v-if="tasksInfo.isLoadingNextPage">
      <v-row align="start" justify="start">
        <v-col v-for="index in 3" :key="index" cols="12" md="6" lg="4">
          <v-skeleton-loader type="card" elevation="2"></v-skeleton-loader>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>
<script>
import TaskBankSingleTask from '@/components/task_bank/TaskBankSingleTask.vue'

import useAuth from '@/composables/useAuth.js'

import { isObject } from '@/helpers/Utils.js'

import { computed } from 'vue'
export default {
  props: {
    tasksInfo: {
      type: Object,
      required: true
    },
    staffAccount: {
      type: Boolean,
      default: true,
      required: false
    },
    hqContext: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  components: {
    'task-bank-single-task': TaskBankSingleTask
  },
  setup(props, { emit }) {
    const { isCompanyAdminRegionOrAbove } = useAuth()
    // Methods
    const onEditClick = (task) => emit('edit-click', task)
    const onDeleteClick = (task) => emit('delete-click', task)
    const onCopyClick = (task) => emit('copy-click', task)
    const onIntersect = () => {
      if (props.tasksInfo.isLoadingNextPage) return

      emit('load-next-page')
    }

    // Computed
    const showAccountTitles = computed(() => !props.staffAccount && !props.hqContext && isCompanyAdminRegionOrAbove.value)
    const showLoadMorePages = computed(() => {
      if (!isObject(props.tasksInfo.pagination)) return false
      return props.tasksInfo.total > props.tasksInfo.tasks.length
    })

    const tasks = computed(() => {
      const tasksArray = props.tasksInfo.tasks
      if (!showAccountTitles.value) return tasksArray
      let lastApiKey = null
      const result = []
      tasksArray.forEach(task => {
        if (task.account_api_key !== lastApiKey) {
          result.push({ isTitle: true, accountName: task.account_name, operation_id: task.account_api_key })
          lastApiKey = task.account_api_key
        }
        result.push(task)
      })
      return result
    })

    return {
      showLoadMorePages,
      showAccountTitles,
      tasks,
      onEditClick,
      onDeleteClick,
      onCopyClick,
      onIntersect
    }
  }
}
</script>