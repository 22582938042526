<template>
  <v-row align="center"
    justify="space-between"
    class="job-daily-worksheet-list-item my-1" no-gutters
    v-bind:class="{ 'pointer': !requireImage && hasItemOperationList }">
    <v-col cols="9" :sm="smallLayout ? 9 : 10" :lg="smallLayout ? 9 : 10" class="info-wrapper text-left">
      <slot name="info" v-bind:item="listItem" v-bind:frequencyText="frequencyText"></slot>
      <div v-if="listItem.creator_data" class="font-weight-medium caption pl-1">
        {{ $t('account_operations.operation_from', {
      first_name: listItem.creator_data.first_name, last_name:
        listItem.creator_data.last_name
    }) }}
      </div>
      <div v-if="showRelatedItem || showOperationNotification || showReportTag || showAvgStars">
        <v-icon dense v-if="showOperationNotification" class="mr-3">notifications</v-icon>
        <v-chip class="my-1 mr-3 white--text" small
          color="#F58F29"
          v-if="showRelatedItem"
          label>
          <v-icon left small>
            build
          </v-icon>
          {{ relatedItemName }}
        </v-chip>
        <v-chip class="my-1 white--text" small
          color="#0040FF"
          v-if="showReportTag"
          label>
          <v-icon left small>
            label
          </v-icon>
          {{ translatedReportTag }}
        </v-chip>
        <reported-item-list-star-avg :items-list="listItem.item_operation_list" v-if="showAvgStars">
        </reported-item-list-star-avg>
      </div>
      <v-badge
        :color="messageBadgeColor"
        overlap
        v-if="showChatButton"
        v-bind:class="{ 'mt-2': hasChatMessages }"
        :value="hasChatMessages"
        bordered
        :content="listItem.total_messages_count">
        <v-btn text small icon class="black--text"
          @click.stop="openChat">
          <v-icon>chat</v-icon>
        </v-btn>
      </v-badge>
      <edit-options v-if="showMoreSelect"
        :show-copy="showCopyItem"
        :show-delete="showDeleteItem"
        :show-edit="showEditItem"
        :show-share="showShareItem"
        :show-notification="showNotificationItem"
        @edit-task="onEditClick"
        @delete-task="onDeleteClick"
        @copy-task="onCopyClick"
        @share-task="onShareClick"
        @notify-task="onNotifyClick">
      </edit-options>
      <v-btn icon small class="ml-4 ml-sm-6" v-if="showOperationInfo" @click="openOperationInfo">
        <v-icon color="black">info</v-icon>
      </v-btn>
      <v-btn icon small class="ml-4 ml-sm-6" v-if="showOperationAttachedFile" @click="onAttachedFileClick">
        <v-icon color="black">attachment</v-icon>
      </v-btn>
      <span class="font-weight-medium caption pl-4" v-if="listItem.staff_operation">
        <v-icon>high_quality</v-icon>
      </span>
    </v-col>
    <v-col cols="3" :sm="smallLayout ? 3 : 2" :lg="smallLayout ? 3 : 2" class="action-wrapper">
      <div class="d-flex align-center justify-center"
        v-if="operationNotReported && !requireImage">
        <reported-item-list-badge
          v-if="hasItemOperationList"
          @click.native="openItemOperationList"
          :items-list="listItem.item_operation_list"
          :overlap="smallLayout">
        </reported-item-list-badge>
        <v-btn fab small outlined class="ml-0 mr-1 done"
          @click="markComplete"
          v-else
          :disabled="isDisabled">
          <v-icon class="font-weight-black"> done </v-icon>
        </v-btn>
      </div>
      <div class="d-flex align-center actions"
        v-bind:class="{ 'center': !hasItemOperationList, 'pr-4': hasItemOperationList }"
        v-else-if="operationNotReported && requireImage">
        <reported-item-list-badge
          v-if="hasItemOperationList"
          @click.native="openItemOperationList"
          :items-list="listItem.item_operation_list">
        </reported-item-list-badge>
        <label :for="idForImageLabel" class="image-uploader" @click="cameraClick">
          <v-icon class="font-weight-black" color="black"> add_a_photo </v-icon>
          <input type="file" :id="idForImageLabel" accept="image/*"
            :disabled="uploadImageDisabled" capture="capture" @change="uploadImage">
        </label>
      </div>
      <div class="d-flex align-center history"
        v-bind:class="{ 'justify-center': !showImageUploadForDoneOperation, 'actions pr-4': showImageUploadForDoneOperation }"
        v-else>
        <v-progress-circular v-if="uploadingImage && requireImage"
          indeterminate
          color="primary">
        </v-progress-circular>
        <v-img v-else-if="operationDone && hasImage"
          @click="imageClick"
          class="op-image"
          v-on:error="onImageLoadError"
          :src="listItem.image_url">
        </v-img>
        <v-btn rounded small class="ml-0 mr-1 done outlined cancel"
          :disabled="isDisabled"
          max-width="66"
          v-else-if="operationDone && !hasImage && !hasItemOperationList"
          color="green"
          @click="cancelOperationReport()">
          <v-icon class="font-weight-black mr-1" color="white"> cancel </v-icon>
          <v-icon class="done font-weight-black"> done </v-icon>
        </v-btn>
        <reported-item-list-badge
          v-else-if="operationDone && hasItemOperationList"
          @click.native="openItemOperationList"
          :items-list="listItem.item_operation_list"
          :overlap="smallLayout">
        </reported-item-list-badge>
        <label :for="idForImageLabel" class="image-uploader" v-if="showImageUploadForDoneOperation"
          @click="cameraClick">
          <v-icon class="font-weight-black" color="black"> add_a_photo </v-icon>
          <input type="file" :id="idForImageLabel" accept="image/*"
            :disabled="uploadImageDisabled" capture="capture" @change="uploadImage">
        </label>
      </div>
    </v-col>
  </v-row>
</template>
<style lang="scss">
.job-daily-worksheet-list-item {
  input[type="file"] {
    display: none;
  }

  .op-image {
    .v-responsive__content {
      display: none;
    }
  }

  .image-uploader,
  .op-image {
    cursor: pointer;
  }

  min-height: 60px;

  .info-wrapper {
    padding-left: 12px !important;
  }

  .action-wrapper {
    button.v-btn.v-btn--disabled:not(.v-btn--icon):not(.v-btn--flat):not(.v-btn--outline) {
      &.yellow.accent-2 {
        background-color: #FFFF00 !important;
      }

      &.red {
        color: white !important;

        .v-icon {
          color: white !important;
        }

        background-color: #F44336 !important;
      }
    }
  }

  .history {
    .small-spacing {
      letter-spacing: 0;
      padding: 0 5px;
    }

    .v-btn {
      min-height: 35px;

      &.cancel.v-btn--disabled:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined) {
        background-color: #4CAF50 !important;
      }
    }
  }

  .actions {
    -webkit-box-pack: justify !important;
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;

    &.center {
      -webkit-box-pack: center !important;
      -ms-flex-pack: center !important;
      justify-content: center !important;
    }

    .v-btn {
      background-color: white;
    }

    .done {
      color: #2E7D32;
    }
  }

  @media only screen and (max-width: 400px) {
    .actions {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-direction: row;
      flex-direction: row;
      -webkit-box-align: end;
      -ms-flex-align: end;
      align-items: flex-end;
      padding-left: 4px !important;
    }

    .action-wrapper {
      flex-basis: 37%;
      -ms-flex-preferred-size: 37%;
      max-width: 37%;
    }

    .info-wrapper {
      padding-left: 4px !important;
      flex-basis: 63%;
      -ms-flex-preferred-size: 63%;
      max-width: 63%;
    }
  }

  @media only screen and (max-width: 370px) {
    .actions {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;

      .v-badge {
        margin-bottom: 10px;
      }
    }

    .action-wrapper {
      flex-basis: 25%;
      -ms-flex-preferred-size: 25%;
      max-width: 25%;
    }

    .info-wrapper {
      padding-left: 4px !important;
      flex-basis: 75%;
      -ms-flex-preferred-size: 75%;
      max-width: 75%;
    }
  }

  @media only screen and (max-width: 360px) {
    .action-wrapper {
      flex-basis: 32%;
      -ms-flex-preferred-size: 32%;
      max-width: 32%;
    }

    .info-wrapper {
      padding-left: 4px !important;
      flex-basis: 68%;
      -ms-flex-preferred-size: 68%;
      max-width: 68%;
    }
  }
}
</style>
<script>
import EditOptions from '@/components/shared/daily_work_sheet/EditOptions.vue'
import { itemTypes } from '@/models/ItemOperationListItemTypes.js'

import { OperationStatuses, translateReportTag } from '@/store/modules/OperationSchedule.js'
import { getFrequencyTextWithDays, FrequencyTypes } from '@/store/modules/OperationFrequency.js'
import { dayNameFromISO8601String, ISO8601StringToDate, isValidISO8601Date, isDateInThePast } from '@/helpers/DateTime.js'
import { isString } from '@/helpers/Utils.js'

import { computed, ref } from 'vue'

const itemOperationListNoTitle = (itemOpListItem) => itemOpListItem.item_type !== itemTypes.title
export default {
  components: {
    'reported-item-list-badge': () => import('@/components/shared/daily_work_sheet/ReportedItemListBadge.vue'),
    'reported-item-list-star-avg': () => import('@/components/shared/daily_work_sheet/ReportedItemListAvarage.vue'),
    'edit-options': EditOptions
  },
  props: {
    listItem: {
      type: Object,
      required: true
    },
    processing: {
      type: Boolean,
      required: false,
      default: false
    },
    uploadingImage: {
      type: Boolean,
      required: false,
      default: false
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    smallLayout: {
      type: Boolean,
      required: false,
      default: false
    },
    allowAction: {
      type: Function,
      required: false,
      default: null
    },
    showChatButton: {
      type: Boolean,
      default: true,
      required: false
    },
    currentDate: {
      type: String,
      required: false,
      default: null
    },
    moreSelectOptions: {
      type: Object,
      required: false,
      default: () => {
        return {
          edit: true,
          copy: true,
          delete: true,
          share: true,
          notification: true
        }
      }
    }
  },
  setup(props, { emit }) {
    const imageFailedToLoad = ref(false)
    const isActionAllowed = () => props.allowAction === null || props.allowAction(props.listItem) === true

    // Methods
    const imageClick = () => emit('image-click')
    const onEditClick = () => emit('edit-click', props.listItem)
    const onDeleteClick = () => emit('delete-click', props.listItem)
    const onCopyClick = () => emit('copy-click', props.listItem)
    const onShareClick = () => emit('share-click', props.listItem)
    const onNotifyClick = () => emit('notify-click', props.listItem)

    const markComplete = () => isActionAllowed() ? emit('mark-comlete') : emit('on-prevent-action', props.listItem)
    const cancelOperationReport = () => isActionAllowed() ? emit('cancel-report') : emit('on-prevent-action', props.listItem)
    const uploadImage = (event) => isActionAllowed() ? emit('upload-image', event) : emit('on-prevent-action', props.listItem)
    const openItemOperationList = () => isActionAllowed() ? emit('open-item-operation-list') : emit('on-prevent-action', props.listItem)
    const onImageLoadError = () => (imageFailedToLoad.value = true)
    const openChat = () => emit('open-chat', props.listItem)
    const onAttachedFileClick = () => window.open(props.listItem.guidance_file, '_blank').focus()
    const cameraClick = (event) => {
      if (isActionAllowed()) return true
      event.preventDefault()
      emit('on-prevent-action', props.listItem)
    }
    const openOperationInfo = () => {
      const imageUrls = props.listItem.guidance_images.map((image) => image.url)
      emit('open-operation-info', imageUrls)
    }

    // Computed
    const isDisabled = computed(() => props.processing || props.disabled === true || endDatePassed.value)
    const endDatePassed = computed(() => {
      const endDateString = props.listItem.end_date
      if (!endDateString || !isValidISO8601Date(endDateString)) return false
      const endDate = ISO8601StringToDate(endDateString)
      return isDateInThePast(endDate)
    })
    const operationNotReported = computed(() => props.listItem.status === OperationStatuses.notReported)
    const operationDone = computed(() => props.listItem.status === OperationStatuses.done)
    const uploadImageDisabled = computed(() => {
      if (isDisabled.value) return true
      const itemOperationList = props.listItem.item_operation_list || []
      const allMarkedAsDone = itemOperationList.filter(itemOperationListNoTitle).every((item) => Number.isInteger(item.report_id) && item.bool_value === true)
      return !allMarkedAsDone
    })

    const frequencyText = computed(() => {
      const dayName = dayNameFromISO8601String(props.currentDate)
      return getFrequencyTextWithDays({
        frequency: props.listItem.frequency,
        frequencyType: props.listItem.frequency_type,
        dateString: props.listItem.first_scheduled_date,
        dayName
      })
    })

    const hasImage = computed(() => {
      if (Object.prototype.toString.call(props.listItem.image_url) !== '[object String]') return false
      if (imageFailedToLoad.value) return false
      return props.listItem.image_url.length > 0
    })

    const requireImage = computed(() => props.listItem.require_image === true && !imageFailedToLoad.value)
    const hasItemOperationList = computed(() => (props.listItem.item_operation_list || []).length > 0)
    const showImageUploadForDoneOperation = computed(() => operationDone.value && hasItemOperationList.value && requireImage.value && !hasImage.value && !props.uploadingImage)
    const hasChatMessages = computed(() => Number.isInteger(props.listItem.total_messages_count) && props.listItem.total_messages_count > 0)
    const hasUnreadChatMessages = computed(() => Number.isInteger(props.listItem.unread_messages_count) && props.listItem.unread_messages_count > 0)
    const messageBadgeColor = computed(() => hasUnreadChatMessages.value === true ? 'red' : 'green')
    const showCopyItem = computed(() => {
      const operation = props.listItem
      if (props.moreSelectOptions.copy !== true || operation.item_type !== 'Item' || operation.staff_operation) return false
      if (operation.frequency_type !== FrequencyTypes.ExectDate && operation.frequency_type !== FrequencyTypes.EveryYear) return true

      const dateIso = operation.operation_next_date_raw || operation.first_scheduled_date
      const date = ISO8601StringToDate(dateIso)
      return !isDateInThePast(date)
    })
    const showDeleteItem = computed(() => props.moreSelectOptions.delete === true && !props.listItem.staff_operation)
    const showEditItem = computed(() => props.moreSelectOptions.edit === true && !props.listItem.staff_operation)
    const showShareItem = computed(() => props.moreSelectOptions.share === true)
    const showNotificationItem = computed(() => props.moreSelectOptions.notification === true)
    const showMoreSelect = computed(() => showCopyItem.value || showDeleteItem.value || showEditItem.value || showShareItem.value || showNotificationItem.value)
    const showOperationNotification = computed(() => props.listItem.mark_complete_notification === true || props.listItem.mark_uncomplete_notification === true)
    const showReportTag = computed(() => isString(props.listItem.report_tag))
    const translatedReportTag = computed(() => showReportTag.value ? translateReportTag(props.listItem.report_tag) : '')
    const showAvgStars = computed(() => hasItemOperationList.value && props.listItem.item_operation_list.some((item) => item.item_type === itemTypes.rating))
    const showOperationInfo = computed(() => {
      const guidanceImages = props.listItem.guidance_images
      return Array.isArray(guidanceImages) && guidanceImages.length > 0
    })
    const showOperationAttachedFile = computed(() => {
      const guidanceFileUrl = props.listItem.guidance_file
      return isString(guidanceFileUrl) && guidanceFileUrl.length > 0
    })
    const showRelatedItem = computed(() => isString(props.listItem.related_item_name))
    const relatedItemName = computed(() => {
      const name = props.listItem.related_item_name
      const department = props.listItem.related_item_department
      if (!isString(department)) return name
      return `${name} - ${department}`
    })
    return {
      idForImageLabel: Math.floor(Math.random() * 10000),
      isDisabled,
      operationNotReported,
      operationDone,
      uploadImageDisabled,
      frequencyText,
      hasImage,
      requireImage,
      hasItemOperationList,
      showImageUploadForDoneOperation,
      hasChatMessages,
      hasUnreadChatMessages,
      messageBadgeColor,
      showOperationInfo,
      showOperationAttachedFile,
      showRelatedItem,
      relatedItemName,
      showCopyItem,
      showDeleteItem,
      showEditItem,
      showShareItem,
      showMoreSelect,
      showNotificationItem,
      showOperationNotification,
      showReportTag,
      translatedReportTag,
      showAvgStars,
      onEditClick,
      onDeleteClick,
      onCopyClick,
      onShareClick,
      onNotifyClick,
      markComplete,
      cancelOperationReport,
      uploadImage,
      imageClick,
      openItemOperationList,
      onImageLoadError,
      cameraClick,
      openChat,
      openOperationInfo,
      onAttachedFileClick
    }
  }
}
</script>
