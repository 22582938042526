<template>
  <page-filters class="blog-filters"
    :any-filter-selected="anyFilterSelected"
    :right="!isRtl"
    :left="isRtl"
    @apply-filters="applyFilters"
    @reset-filters="clearSearch">
    <v-row align="start" justify="start" no-gutters>
      <v-col cols="12">
        <tag-chips-select :max-tags="1"
          v-model="filters.tags"
          :menu-props="{ contentClass: 'inside-drawer', closeOnContentClick: true }"
          :api-key="apiKey"
          context="blog_post_tags">
        </tag-chips-select>
      </v-col>
      <v-col cols="12">
        <v-checkbox
          v-model="filters.myPosts"
          :label="$t('blog_post.my_posts')">
        </v-checkbox>
      </v-col>
      <v-col cols="12">
        <v-checkbox
          v-model="filters.myLikes"
          :label="$t('blog_post.my_likes')">
        </v-checkbox>
      </v-col>
    </v-row>
  </page-filters>
</template>
<style lang="scss" scoped>
.blog-filters {
  top: 90px;

  @media only screen and (max-width: 960px) {
    top: 74px;
  }

  @media only screen and (max-width: 600px) {
    top: 70px;
  }
}
</style>
<script>

import useBlogPost from '@/composables/useBlogPost.js'
import { isRtl } from '@/i18n'

import { computed } from 'vue'
export default {
  props: {
    apiKey: {
      type: String,
      required: true
    }
  },
  components: {
    'tag-chips-select': () => import('@/components/shared/tag_chips_select/TagChipsSelect.vue'),
    'page-filters': () => import('@/components/shared/PageFilters.vue')
  },
  setup(props) {
    const { filters, resetFilter, applyFilters } = useBlogPost(props.apiKey)
    // Methods

    const clearSearch = () => {
      resetFilter()
      applyFilters()
    }

    // Computed

    const anyFilterSelected = computed(() => {
      const filtersVal = filters.value
      return filtersVal.tags.length > 0 || filtersVal.myPosts || filtersVal.myLikes
    })
    return {
      filters,
      anyFilterSelected,
      isRtl,
      applyFilters,
      clearSearch
    }
  }
}
</script>
