<template>
  <v-select
    :value="selectedAccount"
    :items="allAccounts"
    :loading="isLoading"
    class="page-account-select"
    item-text="name"
    item-value="id"
    outlined
    :menu-props="{ contentClass: isRtl ? 'rtl' : 'ltr' }"
    @change="onAccountChange"
    return-object>
    <template v-slot:item="{ item: account }">
      <v-avatar size="40">
        <v-img :src="account.custom_store_image || account.store_image">
        </v-img>
      </v-avatar>
      <span class="t-500-16 pps-2">
        {{ account.name }}
      </span>
    </template>
    <template v-slot:selection="{ item: account }">
      <div :class="{ 'rtl': isRtl, 'ltr': !isRtl }">
        <v-avatar size="40">
          <v-img :src="account.custom_store_image || account.store_image">
          </v-img>
        </v-avatar>
        <span class="t-500-16 pps-2">
          {{ account.name }}
        </span>
      </div>
    </template>
  </v-select>
</template>
<style lang="scss">
.page-account-select {
  .v-text-field__details {
    display: none;
  }
}
</style>
<script>
import useAccount from '@/composables/useAccount.js'
import useAuth from '@/composables/useAuth.js'

import { isRtl } from '@/i18n'

import { ref } from 'vue'
export default {
  props: {
    reloadOnChange: {
      type: Boolean,
      default: true
    }
  },
  setup(props, { emit }) {
    const isLoading = ref(false)
    const selectedAccount = ref(null)
    const { allAccounts, loadAllAccounts, changeAccount } = useAccount()
    const { apiKey } = useAuth()
    loadAllAccounts().then(() => {
      selectedAccount.value = allAccounts.value.find((account) => account.api_key === apiKey.value)
    })
      .finally(() => (isLoading.value = false))

    // Methods

    const onAccountChange = async (account) => {
      if (props.reloadOnChange) {
        await changeAccount(account)
        location.reload()
      } else {
        selectedAccount.value = account
        emit('change', account)
      }
    }
    return {
      selectedAccount,
      allAccounts,
      isLoading,
      isRtl,
      onAccountChange
    }
  }
}
</script>