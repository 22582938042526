<template>
  <v-row class="page-filters" justify="end" align="start"
    :style="{ 'right': right ? '0%' : 'unset', 'left': left ? '0%' : 'unset' }">
    <v-col cols="12" class="text-right">
      <v-badge
        bordered
        :value="anyFilterSelected ? 1 : 0"
        color="green"
        icon="filter_list"
        overlap>
        <v-btn small fab
          color="black"
          class="inside-drawer"
          @click="openSideDrawer">
          <v-icon color="white" class="inside-drawer">tune</v-icon>
        </v-btn>
      </v-badge>
    </v-col>
    <v-navigation-drawer
      v-model="drawer"
      mobile-breakpoint="0"
      color="#F4F6F8"
      :width="width"
      :right="right"
      :left="left"
      fixed>
      <v-row justify="start" align="start" class="mt-5 main-row" v-click-outside="onClickOutside">
        <v-col cols="12">
          <slot></slot>
        </v-col>
        <v-col cols="12" class="d-flex justify-space-between align-self-end">
          <v-col cols="6">
            <v-btn block outlined color="black" :disabled="disabled" :loading="isLoading"
              @click="resetFilters">
              {{ $t('account_operations.cancel_filter') }}
            </v-btn>
          </v-col>
          <v-col cols="6">
            <v-btn block color="black white--text" @click="applyFilters" :disabled="disabled" :loading="isLoading">
              {{ $t('save') }}
            </v-btn>
          </v-col>
        </v-col>
      </v-row>
    </v-navigation-drawer>
  </v-row>
</template>

<style lang="scss">
.page-filters {
  top: 128px;
  right: 0%;
  z-index: 7;
  position: fixed;
  height: 52px;

  .main-row {
    height: 70%;
  }

  @media only screen and (max-width: 960px) {
    top: 134px;
  }

  @media only screen and (max-width: 600px) {
    top: 148px;

    .v-input {
      margin-top: 0;
    }

    .v-text-field__details {
      display: none;
    }
  }
}
</style>

<script>

import { hasAncestorWithClass } from '@/helpers/Utils.js'

import { ref } from 'vue'

export default {
  props: {
    isLoading: {
      type: Boolean,
      required: false,
      default: false
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    anyFilterSelected: {
      type: Boolean,
      required: false,
      default: false
    },
    right: {
      type: Boolean,
      required: false,
      default: true
    },
    left: {
      type: Boolean,
      required: false,
      default: false
    },
    width: {
      type: String,
      required: false,
      default: '300'
    }
  },
  setup(props, { emit }) {
    const drawer = ref(false)

    const closeDrawer = () => (drawer.value = false)

    // Methods
    const openSideDrawer = () => (drawer.value = true)

    const resetFilters = () => {
      emit('reset-filters')
      closeDrawer()
    }

    const applyFilters = () => {
      emit('apply-filters')
      closeDrawer()
    }

    const onClickOutside = (e) => {
      const hasClass = hasAncestorWithClass(e.target, 'inside-drawer')
      if (hasClass) return

      closeDrawer()
    }

    return {
      drawer,
      onClickOutside,
      resetFilters,
      openSideDrawer,
      applyFilters
    }
  }
}
</script>
