<template>
  <v-app-bar color="black" fixed height="66" class="top-tool-bar"
    :class="{ 'rtl': isRtl, 'ltr': !isRtl }">
    <v-toolbar-title class="full-width">
      <v-row align="center" justify="start" no-gutters class="full-height">
        <v-col>
          <v-menu close-on-content-click offset-y bottom v-model="isPushListVisible" rounded="xxl">
            <template v-slot:activator="{ on, attrs }">
              <v-badge color="#EC2328" v-if="unseenCount && unseenCount > 0"
                :offset-x="isRtl ? 64 : 24"
                offset-y="20"
                overlap>
                <user-avatar :size="38" class="mx-4 mb-3 pointer"
                  @click="onAvatarClick">
                </user-avatar>
                <template v-slot:badge>
                  <span @click="onAvatarClick" class="pointer">
                    {{ unseenCount }}
                  </span>
                </template>
              </v-badge>
              <v-badge v-else color="#12D278" :offset-x="isRtl ? 64 : 24" offset-y="20" overlap>
                <user-avatar :size="38" class="mx-4 mb-3 pointer" @click="onAvatarClick">
                </user-avatar>
                <template v-slot:badge>
                  <v-icon @click="onAvatarClick">
                    notifications_none
                  </v-icon>
                </template>
              </v-badge>
            </template>
            <top-bar-push-list :api-key="apiKey">
            </top-bar-push-list>
          </v-menu>
          <span v-if="showAccountSelection">
            <account-menu-select :value="accountName" :api-key="apiKey"
              :color="isStaging ? 'yellow--text' : 'white--text'"
              @change="onChangeAccount">
            </account-menu-select>
          </span>
          <span class="main-name t-700-22" :class="{ 'yellow--text': isStaging, 'white--text': !isStaging }" v-else>
            {{ showCompanyName ? companyName : accountName }}
          </span>
        </v-col>
        <v-col class="mms-auto flex-grow-0 flex-shrink-1 d-flex align-center mme-2">
          <v-btn icon :to="{ name: menuRoute }" class="white mme-2" small v-if="$vuetify.breakpoint.xsOnly">
            <v-icon small>menu</v-icon>
          </v-btn>
          <img :src="imageLogoPath" class="top-logo mme-8">
        </v-col>
      </v-row>
    </v-toolbar-title>
    <v-spacer></v-spacer>
    <v-btn class="ml-2" text icon color="white" @click="reloadApp" v-if="isInStandaloneMode()">
      <v-icon>refresh</v-icon>
    </v-btn>
  </v-app-bar>
</template>

<style lang="scss">
.top-tool-bar {
  .v-toolbar__content {
    padding: 0;

    .top-logo {
      max-height: 16px;
    }

    .main-name {
      font-size: 22px;
      font-weight: 700;
    }

    @media only screen and (max-width: 600px) {
      .top-logo {
        height: 28px;
      }

      .main-name {
        font-size: 16px;
      }
    }
  }
}
</style>

<script>

import TopBarPushList from '@/components/layout/TopBarPushList.vue'

import usePushNotificationHistoryTopBar from '@/composables/usePushNotificationHistoryTopBar.js'

import { isInStandaloneMode } from '@/helpers/ClientDevice.js'
import { isRtl } from '@/i18n.js'
import useCompany from '@/composables/useCompany.js'
import useAccount from '@/composables/useAccount.js'
import useAuth from '@/composables/useAuth.js'

import { computed, ref } from 'vue'

export default {
  components: {
    'top-bar-push-list': TopBarPushList,
    'user-avatar': () => import('@/components/shared/UserAvatar.vue'),
    'account-menu-select': () => import('@/components/shared/AccountMenuSelect.vue')
  },
  setup() {
    const isPushListVisible = ref(false)
    const { apiKey, isCompanyAdminRegionOrAbove, isUserAccountEmployee } = useAuth()
    const { companyName, isMultipleMemberships } = useCompany(apiKey.value)
    const { accountName, changeAccount } = useAccount(apiKey.value)
    const { unseenCount, loadUnSeenCount } = usePushNotificationHistoryTopBar(apiKey.value)
    const imageLogoPath = new URL('@/assets/dex_logo_and_name_white.svg', import.meta.url).href

    // Methods
    const reloadApp = () => location.reload(true)
    const onAvatarClick = () => (isPushListVisible.value = !isPushListVisible.value)

    const onChangeAccount = async (account) => {
      await changeAccount(account)
      reloadApp()
    }

    // Computed
    const isStaging = computed(() => import.meta.env.MODE === 'staging')
    const showCompanyName = computed(() => isCompanyAdminRegionOrAbove.value)
    const showAccountSelection = computed(() => !isCompanyAdminRegionOrAbove.value && isMultipleMemberships.value)
    const menuRoute = computed(() => isCompanyAdminRegionOrAbove.value === true ? 'staff/menu' : 'menu')

    loadUnSeenCount()

    return {
      imageLogoPath,
      isUserAccountEmployee,
      showCompanyName,
      isStaging,
      companyName,
      accountName,
      apiKey,
      showAccountSelection,
      isRtl,
      menuRoute,
      isPushListVisible,
      unseenCount,
      reloadApp,
      isInStandaloneMode,
      onChangeAccount,
      onAvatarClick
    }
  }
}
</script>
