<template>
  <v-row align="start" justify="center" no-gutters class="tasks-small-devices">
    <task-actions
      :api-key="apiKey"
      :staff-account="staffAccount"
      @change-date="onChangeDate">
    </task-actions>
    <v-col cols="12">
      <tasks-progress
        show-wrapper
        :is-loading="isLoading"
        :tasks-count="stats.tasksCount"
        :finished-tasks-count="stats.finishedTasksCount">
      </tasks-progress>
    </v-col>
    <v-col cols=12>
      <jobs-list-layout-small-devices
        :api-key="apiKey"
        :staff-account="staffAccount">
        <template v-slot:tasks-list>
          <tasks-list-layout
            :api-key="apiKey">
          </tasks-list-layout>
        </template>
      </jobs-list-layout-small-devices>
    </v-col>
  </v-row>

</template>
<style lang="scss">
.tasks-small-devices {
  margin-top: 46px !important;
}
</style>
<script>
import JobsListLayoutSmallDevices from '@/components/tasks/jobs_list/JobsListLayoutSmallDevices.vue'
import TasksProgress from '@/components/tasks/TasksProgress.vue'
import TasksListLayout from '@/components/tasks/tasks_list/TasksListLayout.vue'
import TaskActions from '@/components/tasks/TaskActions.vue'

import useDailyJobs from '@/composables/useDailyJobs.js'

export default {
  components: {
    'jobs-list-layout-small-devices': JobsListLayoutSmallDevices,
    'tasks-list-layout': TasksListLayout,
    'task-actions': TaskActions,
    'tasks-progress': TasksProgress
  },
  props: {
    apiKey: {
      type: String,
      required: true
    },
    staffAccount: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit }) {
    const { isLoading, stats } = useDailyJobs(props.apiKey)

    // Methods
    const onChangeDate = (newDate) => emit('change-date', newDate)

    return {
      onChangeDate,
      isLoading,
      stats
    }
  }
}
</script>