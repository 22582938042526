<template>
  <v-card class="link-preview" v-if="showLoading">
    <v-skeleton-loader type="list-item-avatar-three-line">
    </v-skeleton-loader>
  </v-card>
  <link-preview :link-data="linkData" v-else></link-preview>
</template>
<script>
import useAuth from '@/composables/useAuth.js'
import useLinkPreview from '@/components/shared/link_preview/useLinkPreview.js'

import { debounce, isString } from '@/helpers/Utils.js'
import { handler } from '@/classes/ErrorHandler.js'

import { watch, ref, computed } from 'vue'
export default {
  props: {
    url: {
      type: String,
      required: true
    },
    value: {
      type: Object,
      required: false,
      default: () => ({})
    }
  },
  components: {
    'link-preview': () => import('@/components/shared/link_preview/LinkPreview.vue')
  },
  setup(props, { emit }) {
    const linkData = ref(JSON.parse(JSON.stringify(props.value)))
    const isLoading = ref(false)
    const { apiKey } = useAuth()
    const { getLinkData } = useLinkPreview(apiKey.value)
    const emitData = (newValue) => emit('input', newValue)
    const debouncedGetLinkData = debounce((newUrl, oldUrl) => {
      if (newUrl.trim().length === 0 || oldUrl === newUrl) return
      isLoading.value = true
      getLinkData(newUrl)
        .then((data) => (linkData.value = data))
        .catch((err) => {
          linkData.value = { url: newUrl }
          handler(err)
        })
        .finally(() => {
          isLoading.value = false
          emitData(linkData.value)
        })
    }, 700)

    // Computed

    const showLoading = computed(() => isLoading.value || Object.keys(linkData.value).length === 0)
    if (isString(props.value.url)) {
      // Pre defined link from user, watch only after first change
      watch(() => props.url, debouncedGetLinkData)
    } else {
      watch(() => props.url, debouncedGetLinkData, { immediate: true })
    }
    return {
      showLoading,
      linkData
    }
  }
}
</script>
