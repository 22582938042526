const defaultCountryCode = 'US'
const defaultLocaleCode = 'en-US'
const currencyCodeToSign = {
  ILS: '₪',
  USD: '$'
}
const timeZoneToCountryCode = {
  'Asia/Jerusalem': 'IL',
  'America/New_York': 'US',
  'America/Los_Angeles': 'US',
  'Europe/London': 'UK',
  'Europe/Kiev': 'UA'
}

const timeZoneToBCP47 = {
  'Asia/Jerusalem': 'he',
  'America/New_York': 'en-US',
  'America/Los_Angeles': 'en-US',
  'Europe/London': 'en-GB',
  'Europe/Kiev': 'uk'
}

const BCP47ToCountryCode = {
  he: 'IL',
  'en-US': 'US',
  'en-GB': 'UK',
  uk: 'UA'
}
export {
  currencyCodeToSign,
  timeZoneToCountryCode,
  timeZoneToBCP47,
  defaultCountryCode,
  defaultLocaleCode,
  BCP47ToCountryCode
}
