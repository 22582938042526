import Client from '@/api_client/client.js'

function create (apiKey, recordType, recordId) {
  const RECORD_MESSAGE_CREATE_PATH = `/accounts/${apiKey}/${recordType}/${recordId}/record_messages_track`
  return Client.post(RECORD_MESSAGE_CREATE_PATH)
}

export {
  create
}
