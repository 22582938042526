<template>
  <page-filters
    :any-filter-selected="districtSelected"
    :right="!isRtl"
    :left="isRtl"
    @apply-filters="applyFilters"
    @reset-filters="resetFilters">
    <v-select
      class="select-area"
      v-model="selectedAreas"
      :items="companyDistrictsWithEmpty"
      :loading="isDistrictsLoading"
      multiple
      item-text="name"
      item-value="id"
      outlined
      dense
      clearable
      :no-data-text="$t('account_selection.no_area')"
      :menu-props="{ contentClass: 'inside-drawer' }"
      :label="$t('account_selection.select_area')">
      <template v-slot:item="{ item: area, attrs }">
        <v-row no-gutters justify="space-between" align="center">
          <v-col cols="4" class="d-flex align-center justify-space-between">
            <v-icon v-if="attrs.inputValue">check_box</v-icon>
            <v-icon v-else>check_box_outline_blank</v-icon>
          </v-col>
          <v-col cols="8" class="font-weight-bold"
            v-bind:class="{ 'text-right': isRtl, 'text-left': !isRtl }">
            {{ area.name }} {{ area.accounts_count || 0 }}
          </v-col>
        </v-row>
      </template>
    </v-select>
  </page-filters>
</template>
<style lang="scss">
.select-area {
  fieldset {
    legend {
      display: none;
    }
  }
}
</style>
<script>

import PageFilters from '@/components/shared/PageFilters.vue'

import {
  getAccountsWithoutDistrictsSize, getCompanyDistricts, getIsLoading as isDistrictsLoading
} from '@/models/CompanyDistrict.js'
import useAuth from '@/composables/useAuth.js'
import { loadAllAccounts, setFilters } from '@/components/company_stores/AccountSelectionStore.js'

import { handler as errHandler } from '@/classes/ErrorHandler.js'
import { isObject, isString } from '@/helpers/Utils.js'

import { ref, computed, onBeforeUnmount } from 'vue'

import { i18n, isRtl } from '@/i18n.js'

const EMPTY_AREA_ID = 'empty_district'
const SESSION_FILTER_KEY = 'stores-filters-session'

const loadFilterFromLocalCache = () => {
  const filter = localStorage.getItem(SESSION_FILTER_KEY)
  if (isString(filter) !== true) return
  try {
    return JSON.parse(filter)
  } catch (e) {
  }
}

export default {
  components: {
    'page-filters': PageFilters
  },
  props: {
    apiKey: {
      type: String,
      required: true
    }
  },
  setup(props) {
    const selectedAreas = ref([])
    const { isUserCompanyAdmin } = useAuth()
    const emptyAreaSelectOption = () => {
      const emptyAreaText = i18n.t('account_selection.empty_area')
      return { id: EMPTY_AREA_ID, name: emptyAreaText, accounts_count: getAccountsWithoutDistrictsSize.value }
    }

    const saveFilterToLocalCache = () => {
      const filter = JSON.stringify({ companyDistrictIds: selectedAreas.value })
      localStorage.setItem(SESSION_FILTER_KEY, filter)
    }

    const setFilterFromSession = () => {
      const filtersFromLocalCache = loadFilterFromLocalCache()
      if (isObject(filtersFromLocalCache) !== true) return
      if (Array.isArray(filtersFromLocalCache.companyDistrictIds) !== true) return
      selectedAreas.value = filtersFromLocalCache.companyDistrictIds
    }
    // Methods

    const applyFilters = () => {
      saveFilterToLocalCache()
      setFilters({ companyDistrictIds: selectedAreas.value })
      loadAllAccounts(props.apiKey, true)
        .catch(errHandler)
    }

    const resetFilters = () => {
      selectedAreas.value.splice(0, selectedAreas.value.length)
      saveFilterToLocalCache()
      applyFilters()
    }

    // Computed
    const districtSelected = computed(() => selectedAreas.value.length > 0)

    const companyDistrictsWithEmpty = computed(() => {
      const companyDistricts = getCompanyDistricts.value
      if (companyDistricts.length === 0 || !isUserCompanyAdmin.value) return companyDistricts
      return [emptyAreaSelectOption()].concat(companyDistricts)
    })
    onBeforeUnmount(saveFilterToLocalCache)

    setFilterFromSession()
    applyFilters()
    return {
      EMPTY_AREA_ID,
      districtSelected,
      isDistrictsLoading,
      selectedAreas,
      companyDistrictsWithEmpty,
      isRtl,
      applyFilters,
      resetFilters
    }
  }
}
</script>
