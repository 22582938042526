<template>
  <page-filters class="company-broken-items-filter"
    :any-filter-selected="anyFilterSelected"
    :class="isRtl ? 'rtl' : 'ltr'"
    :right="!isRtl"
    :left="isRtl"
    width="350"
    @apply-filters="applyFilters"
    @reset-filters="resetFilters">
    <v-row align="start" justify="start" no-gutters>
      <date-picker-dialog
        ref="datePickerDialog"
        :value="dateRangeValue"
        :min-date="minDate"
        :show-current-date-button="true"
        content-class="inside-drawer"
        @change="applyDatePicker">
      </date-picker-dialog>
      <v-col cols="12" v-if="isCompanyAdminRegionOrAbove">
        <company-district-select
          :api-key="apiKey"
          v-model="filters.companyDistrictFilter"
          :menu-props="{ contentClass: 'inside-drawer' }"
          :show-empty-area="isUserCompanyAdmin">
        </company-district-select>
      </v-col>
      <v-col cols="12" class="mb-4" v-if="isMultipleMemberships">
        <account-select
          v-model="filters.accountsFilter"
          :api-key="apiKey"
          :district-ids="filters.companyDistrictFilter"
          outlined
          dense
          multiple
          :menu-props="{ contentClass: 'inside-drawer' }">
        </account-select>
      </v-col>
      <v-col cols="12" class="t-500-18 color10">
        {{ $t('account_fault_devices.open_date') }}
      </v-col>
      <v-col cols="12" class="row align-start justify-space-between no-gutters">
        <v-col>
          <v-text-field
            :value="openDateStartString"
            outlined
            dense
            class="mme-2"
            prepend-inner-icon="date_range"
            clearable
            :label="$t('from')"
            @click="openDatePicker('openDateStart')"
            @click:clear="deleteDate('openDateStart')"
            readonly>
          </v-text-field>
        </v-col>
        <v-col>
          <v-text-field
            :value="openDateEndString"
            outlined
            dense
            prepend-inner-icon="date_range"
            clearable
            :label="$t('to')"
            :disabled="openDateEndDisabled"
            @click="openDatePicker('openDateEnd')"
            @click:clear="deleteDate('openDateEnd')"
            readonly>
          </v-text-field>
        </v-col>
      </v-col>
      <v-col cols="12" class="t-500-18 color10">
        {{ $t('account_fault_devices.close_date') }}
      </v-col>
      <v-col cols="12" class="row align-start justify-space-between no-gutters">
        <v-col>
          <v-text-field
            :value="closeDateStartString"
            outlined
            dense
            class="mme-2"
            prepend-inner-icon="date_range"
            clearable
            :label="$t('from')"
            @click="openDatePicker('closeDateStart')"
            @click:clear="deleteDate('closeDateStart')"
            readonly>
          </v-text-field>
        </v-col>
        <v-col>
          <v-text-field
            :value="closeDateEndString"
            outlined
            dense
            prepend-inner-icon="date_range"
            clearable
            :label="$t('to')"
            :disabled="closeDateEndDisabled"
            @click="openDatePicker('closeDateEnd')"
            @click:clear="deleteDate('closeDateEnd')"
            readonly>
          </v-text-field>
        </v-col>
      </v-col>
      <v-col cols="12">
        <v-text-field
          v-model="filters.textSearchFilter"
          outlined
          dense
          prepend-inner-icon="search"
          clearable
          :label="$t('account_fault_devices.general_search')"
          @click:clear="clearSearchField">
        </v-text-field>
      </v-col>
      <v-col cols="12">
        <company-provider-search
          v-model="filters.companyProvidersFilter"
          :menu-props="{ contentClass: 'inside-drawer' }"
          :api-key="apiKey"
          multiple>
        </company-provider-search>
      </v-col>
      <v-col cols="12">
        <broken-item-deadline-filter
          v-model="filters.deadlineDateFilter"
          :menu-props="{ contentClass: filtersClasses }">
        </broken-item-deadline-filter>
      </v-col>
      <v-col cols="12">
        <v-select
          v-model="filters.brokenItemStatusFilter"
          clearable
          :items="availableStatuses"
          item-text="translatedName"
          item-value="name"
          single-line
          multiple
          :label="$t('broken_item_dialog_dialog.status')"
          :menu-props="{ contentClass: filtersClasses }">
          <template v-slot:item="{ item, attrs }">
            <v-row no-gutters justify="start" align="center">
              <v-col cols="10" class="font-weight-bold">
                {{ item.translatedName }}
              </v-col>
              <v-col cols="2" class="texts-end">
                <v-icon v-if="attrs.inputValue">check_box</v-icon>
                <v-icon v-else>check_box_outline_blank</v-icon>
              </v-col>
            </v-row>
          </template>
        </v-select>
      </v-col>
      <v-col cols="12">
        <v-select v-model="filters.brokenItemPriorityFilter" clearable :items="availablePriorities"
          item-text="translatedName"
          item-value="name" single-line :label="$t('broken_item_dialog_dialog.priority')"
          :menu-props="{ contentClass: filtersClasses }">
        </v-select>
      </v-col>
      <v-col cols="12">
        <v-checkbox v-model="filters.onlyBrokenItemAssignedToMe"
          :label="$t('account_fault_devices.broken_items_assigned_to_me')">
        </v-checkbox>
      </v-col>
      <v-col cols="12">
        <v-checkbox v-model="filters.onlyBrokenItemOpenedByMe"
          :label="$t('account_fault_devices.broken_items_opened_by_me')">
        </v-checkbox>
      </v-col>
    </v-row>
  </page-filters>
</template>

<style lang="scss">
.company-broken-items-filter {
  @media only screen and (max-width: 600px) {
    top: 168px;
  }
}
</style>

<script>
import CompanyProviderSearch from '@/components/shared/company_provider_search/CompanyProviderSearch.vue'

import useCompany from '@/composables/useCompany.js'
import useItem from '@/composables/useItem.js'
import useAccount from '@/composables/useAccount.js'
import useAuth from '@/composables/useAuth.js'

import { dateToISO8601String, getClientDateFormatted } from '@/helpers/DateTime.js'

import { isDate, isString } from '@/helpers/Utils.js'
import { isRtl } from '@/i18n.js'

import { computed, nextTick, ref } from 'vue'

export default {
  props: {
    apiKey: {
      type: String,
      required: true
    }
  },
  components: {
    'page-filters': () => import('@/components/shared/PageFilters.vue'),
    'company-district-select': () => import('@/components/shared/CompanyDistrictSelect.vue'),
    'account-select': () => import('@/components/shared/AccountSelect.vue'),
    'broken-item-deadline-filter': () => import('@/components/shared/BrokenItemDeadlineFilter.vue'),
    'company-provider-search': CompanyProviderSearch
  },
  setup(props) {
    const {
      filters, availableStatuses, availablePriorities, applyFilters
    } = useItem(props.apiKey)

    const { isMultipleMemberships } = useCompany(props.apiKey)
    const { isUserCompanyAdmin, isCompanyAdminRegionOrAbove } = useAuth()
    const { accountCountryCode } = useAccount()

    const datePickerDialog = ref(null)
    const dateType = ref(null)

    const dateInUserFormat = (date) => {
      if (!isDate(date)) return ''
      return getClientDateFormatted({ countryCode: accountCountryCode.value, fromDate: date })
    }

    // Methods
    const clearSearchField = () => { filters.value.textSearchFilter = '' }
    const openDatePicker = (dateTypeVal) => {
      dateType.value = dateTypeVal
      nextTick(() => datePickerDialog.value.openDialog())
    }

    const deleteDate = (dateTypeVal) => {
      if (dateTypeVal === 'openDateStart') {
        filters.value.openDateStartFilter = null
        filters.value.openDateEndFilter = null
      } else if (dateTypeVal === 'openDateEnd') {
        filters.value.openDateEndFilter = null
      } else if (dateTypeVal === 'closeDateStart') {
        filters.value.closeDateStartFilter = null
        filters.value.closeDateEndFilter = null
      } else if (dateTypeVal === 'closeDateEnd') {
        filters.value.closeDateEndFilter = null
      }
    }

    const applyDatePicker = (val) => {
      let dateRef = null
      if (dateType.value === 'openDateStart') {
        filters.value.openDateStartFilter = val
      } else if (dateType.value === 'openDateEnd') {
        filters.value.openDateEndFilter = val
      } else if (dateType.value === 'closeDateStart') {
        filters.value.closeDateStartFilter = val
      } else if (dateType.value === 'closeDateEnd') {
        filters.value.closeDateEndFilter = val
      }
    }

    const resetFilters = () => {
      const filtersVal = filters.value
      filtersVal.onlyBrokenItemAssignedToMe = false
      filtersVal.onlyBrokenItemOpenedByMe = false
      filtersVal.brokenItemStatusFilter = []
      filtersVal.brokenItemPriorityFilter = null
      filtersVal.companyDistrictFilter = []
      filtersVal.deadlineDateFilter = null
      filtersVal.openDateStartFilter = null
      filtersVal.openDateEndFilter = null
      filtersVal.closeDateStartFilter = null
      filtersVal.closeDateEndFilter = null
      filtersVal.textSearchFilter = null
      filtersVal.companyProvidersFilter = []
      applyFilters()
    }

    // Computed
    const filtersClasses = computed(() => (`inside-drawer ${isRtl.value ? 'rtl' : 'ltr'}`))

    const openDateStartString = computed(() => dateInUserFormat(filters.value.openDateStartFilter))
    const openDateEndString = computed(() => dateInUserFormat(filters.value.openDateEndFilter))
    const closeDateStartString = computed(() => dateInUserFormat(filters.value.closeDateStartFilter))
    const closeDateEndString = computed(() => dateInUserFormat(filters.value.closeDateEndFilter))
    const openDateEndDisabled = computed(() => isDate(filters.value.openDateStartFilter) === false)
    const closeDateEndDisabled = computed(() => isDate(filters.value.closeDateStartFilter) === false)

    const dateRangeValue = computed(() => {
      if (dateType.value === 'openDateStart') {
        return filters.value.openDateStartFilter
      } else if (dateType.value === 'openDateEnd') {
        return filters.value.openDateEndFilter
      } else if (dateType.value === 'closeDateStart') {
        return filters.value.closeDateStartFilter
      } else if (dateType.value === 'closeDateEnd') {
        return filters.value.closeDateEndFilter
      }
    })

    const minDate = computed(() => {
      if (dateType.value === 'openDateEnd' && isDate(filters.value.openDateStartFilter)) {
        return dateToISO8601String(filters.value.openDateStartFilter)
      }
      if (dateType.value === 'closeDateEnd' && isDate(filters.value.closeDateStartFilter)) {
        return dateToISO8601String(filters.value.closeDateStartFilter)
      }
      return null
    })
    const anyFilterSelected = computed(() =>
      filters.value.onlyBrokenItemAssignedToMe ||
      filters.value.onlyBrokenItemOpenedByMe || filters.value.brokenItemStatusFilter.length > 0 ||
      filters.value.brokenItemPriorityFilter !== null || filters.value.companyDistrictFilter.length > 0 ||
      filters.value.deadlineDateFilter !== null ||
      isDate(filters.value.openDateStartFilter) || isDate(filters.value.openDateEndFilter) ||
      isDate(filters.value.closeDateStartFilter) || isDate(filters.value.closeDateEndFilter) ||
      (isString(filters.value.textSearchFilter) && filters.value.textSearchFilter.trim().length > 0) ||
      filters.value.companyProvidersFilter.length > 0
    )
    return {
      anyFilterSelected,
      availableStatuses,
      availablePriorities,
      filters,
      isUserCompanyAdmin,
      isCompanyAdminRegionOrAbove,
      isMultipleMemberships,
      isRtl,
      filtersClasses,
      openDateStartString,
      openDateEndString,
      closeDateStartString,
      closeDateEndString,
      openDateEndDisabled,
      closeDateEndDisabled,
      minDate,
      datePickerDialog,
      dateRangeValue,
      applyFilters,
      resetFilters,
      openDatePicker,
      applyDatePicker,
      deleteDate,
      clearSearchField
    }
  }
}
</script>
