import Client from '@/api_client/client.js'
function index (apiKey) {
  const INDEX_FEATURES = `/accounts/${apiKey}/features`
  return Client.get(INDEX_FEATURES)
}

function indexForUsers ({ apiKey, userIds }) {
  const INDEX_FOR_USERS = `/accounts/${apiKey}/features/index_for_users`
  return Client.put(INDEX_FOR_USERS, { user_ids: userIds })
}

function setUserFeatures ({ apiKey, userId, features }) {
  const SET_FEATURES = `/accounts/${apiKey}/features/set_features`
  return Client.put(SET_FEATURES, { user_id: userId, features, resource_type: 'user' })
}

function setCompanyFeatures ({ apiKey, features }) {
  const SET_FEATURES = `/accounts/${apiKey}/features/set_features`
  return Client.put(SET_FEATURES, { features, resource_type: 'company' })
}

export {
  index,
  indexForUsers,
  setUserFeatures,
  setCompanyFeatures
}
