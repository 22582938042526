<template>
  <v-row align="start" justify="start" no-gutters>
    <v-col class="flex-grow-1 flex-shrink-0">
      <div v-if="showAccountInfo" class="d-flex align-start justify-start">
        <v-img :src="task.operationRef.account_image"
          contain height="40" width="40"
          class="pointer grey darken-4 account-image-preview flex-grow-0 flex-shrink-1">
          <template v-slot:placeholder>
            <v-row align="center" justify="center" class="fill-height" no-gutters>
              <v-progress-circular indeterminate color="primary"></v-progress-circular>
            </v-row>
          </template>
        </v-img>
        <span class="t-500-16 color5 pps-2">
          {{ task.operationRef.account_name }}
        </span>
      </div>
      <div v-if="showStartTime" class="color1 t-700-13">
        {{ task.operationRef.startTime }}
      </div>
      <div v-if="showDescriptionAndType || showHqIcon" class="t-500-16 d-flex align-center justify-start" :class="{
        'color2 text-decoration-line-through': isReported,
        'color5': !isReported,
        'black--text text-decoration-line-through': pendingStatusChange
      }">
        <span v-if="showDescriptionAndType" class="ppe-2 t-700-16">
          {{ descriptionAndType }}
        </span>
        <v-icon color="black" v-if="showHqIcon">
          home_work
        </v-icon>
      </div>
      <div v-if="showCreator" class="my-1 t-700-13">
        {{ $t('account_operations.created_by', {
          firstName: task.operationRef.creator_data.first_name, lastName:
            task.operationRef.creator_data.last_name
        }) }}
      </div>
      <div v-if="showReporter" class="t-500-13">
        <span class="color11">
          {{ $t('account_operations.done_by', {
            first_name: task.operationRef.reporter_first_name, last_name:
              task.operationRef.reporter_last_name
          }) }}
        </span>
        <div class="color5 mt-1">
          <v-icon>
            today
          </v-icon>
          {{ task.operationRef.reportedDate }} {{ task.operationRef.reportedTime }}
        </div>
      </div>
      <div v-if="showReportTag && !hqView" class="my-1">
        <div class="task-tag bg-color1">
          <v-icon color="white" class="material-icons-outlined">
            sell
          </v-icon>
          <span>
            {{ translatedReportTag }}
          </span>
        </div>
      </div>
      <div v-if="showRelatedItemTag && !hqView" class="my-1">
        <div class="task-tag bg-color15">
          <v-icon color="white" class="material-icons-outlined">
            build
          </v-icon>
          <span>
            {{ relatedItemText }}
          </span>
        </div>
      </div>
      <div v-if="!hqView">
        <v-icon color="#F5409B">
          schedule
        </v-icon>
        <span class="pps-1 color7 t-400-12">
          {{ frequencyText }}
        </span>
      </div>
      <div v-if="!hqView && showEveryXWeeksStartPeriod" class="my-1">
        <v-icon color="#F5409B">
          calendar_today
        </v-icon>
        <span class="pps-1 color7 t-400-12">
          {{ startPeriodForEveryXWeeksFrequnecy }}
        </span>
      </div>
      <div v-if="hqView" class="mt-1">
        <v-icon color="rgba(0, 0, 0, 0.54)">
          person_outline
        </v-icon>
        <span>
          {{ jobNameAndShift }}
        </span>
      </div>
      <div v-if="showImage" class="mt-1">
        <v-img :src="task.operationRef.image_url" contain height="65" width="65"
          class="pointer grey darken-4 image-preview"
          @click="onImageClick">
          <template v-slot:placeholder>
            <v-row align="center" justify="center" class="fill-height" no-gutters>
              <v-progress-circular indeterminate color="primary"></v-progress-circular>
            </v-row>
          </template>
        </v-img>
      </div>
      <div v-else-if="showImageUploading" class="mt-1">
        <v-row align="center" justify="start" no-gutters>
          <v-progress-circular indeterminate color="primary"></v-progress-circular>
        </v-row>
      </div>
    </v-col>
    <v-col class="flex-grow-0 flex-shrink-1">
      <single-task-options :task="task" :task-enabled-options="taskEnabledOptions" @delete-click="onDeleteClick"
        @copy-click="onCopyClick" @share-click="onShareClick" @edit-click="onEditClick" @notify-click="onNotifyClick">
      </single-task-options>
    </v-col>
  </v-row>
</template>
<script>

import SingleTaskOptions from '@/components/tasks/tasks_list/single_task/SingleTaskOptions.vue'
import { translateReportTag } from '@/store/modules/OperationSchedule.js'

import { translateShift } from '@/store/modules/OrganizationJob.js'
import { translatedOperation as OpTypeTranslatedOperation, ROUTINE_OPERATION } from '@/composables/useOperationType.js'
import { frequencyTextFromTask, calculateCurrentStartPeriodForEveryXWeeksFrequency, FrequencyTypes } from '@/store/modules/OperationFrequency.js'
import { dayNameFromISO8601String, ISO8601StringToDate, getClientDateFormatted, translateDayFromDate } from '@/helpers/DateTime.js'
import useAccount from '@/composables/useAccount.js'

import { isObject, isString } from '@/helpers/Utils.js'

import {
  EventBus, TASK_DELETE_CLICK, TASK_COPY_CLICK, TASK_SHARE_CLICK,
  TASK_EDIT_CLICK, TASK_NOTIFICATION_CLICK, TASK_IMAGE_CLICK
} from '@/EventBus.js'

import { i18n } from '@/i18n.js'

import { computed } from 'vue'

export default {
  props: {
    task: {
      type: Object,
      required: true
    },
    clientDate: {
      type: String,
      required: true
    },
    taskEnabledOptions: {
      type: Object,
      required: true
    },
    hqView: {
      type: Boolean,
      default: false
    }
  },
  components: {
    'single-task-options': SingleTaskOptions
  },
  setup(props) {
    const { accountCountryCode } = useAccount()

    // Methods
    const onDeleteClick = () => EventBus.emit(TASK_DELETE_CLICK, props.task)
    const onCopyClick = () => EventBus.emit(TASK_COPY_CLICK, props.task)
    const onShareClick = () => EventBus.emit(TASK_SHARE_CLICK, props.task)
    const onEditClick = () => EventBus.emit(TASK_EDIT_CLICK, props.task)
    const onNotifyClick = () => EventBus.emit(TASK_NOTIFICATION_CLICK, props.task)
    const onImageClick = () => EventBus.emit(TASK_IMAGE_CLICK, props.task)

    // Computed
    const isReported = computed(() => props.task.isReported())
    const pendingStatusChange = computed(() => props.task.operationRef.pendingStatusChange)
    const showReporter = computed(() => isReported.value && isString(props.task.operationRef.reporter_first_name))
    const showReportTag = computed(() => isString(props.task.operationRef.report_tag))
    const translatedReportTag = computed(() => showReportTag.value ? translateReportTag(props.task.operationRef.report_tag) : '')
    const showStartTime = computed(() => !props.hqView && isString(props.task.operationRef.startTime))
    const showImage = computed(() => isReported.value && props.task.requireImage() && isString(props.task.operationRef.image_url))
    const showImageUploading = computed(() => isReported.value && props.task.requireImage() && props.task.uploading_image === true)
    const showAccountInfo = computed(() => props.hqView && isString(props.task.operationRef.account_name))
    const showDescriptionAndType = computed(() => !props.hqView && descriptionAndType.value.length > 0)
    const showHqIcon = computed(() => !props.hqView && props.task.operationRef.staff_operation)
    const showRelatedItemTag = computed(() => isString(props.task.operationRef.related_item_name))
    const showCreator = computed(() => !props.hqView && isObject(props.task.operationRef.creator_data))
    const relatedItemText = computed(() => {
      if (showRelatedItemTag.value === false) return ''
      const taskRef = props.task.operationRef
      const relatedItemName = taskRef.related_item_name
      const relatedItemDepartment = taskRef.related_item_department
      if (!isString(relatedItemDepartment)) return relatedItemName
      return `${relatedItemName} - ${relatedItemDepartment}`
    })

    const jobNameAndShift = computed(() => {
      if (!props.hqView) return ''
      const task = props.task.operationRef
      return `${task.account_job_name} ${translateShift(task.account_job_shift)}`
    })

    const descriptionAndType = computed(() => {
      const description = props.task.operationRef.operation_description || ''
      if (ROUTINE_OPERATION === props.task.operationRef.operation_type) return description

      const translatedOp = OpTypeTranslatedOperation(props.task.operationRef.operation_type)

      if (description.length > 0) return `${translatedOp} - ${description}`

      return translatedOp
    })

    const frequencyText = computed(() => {
      const task = props.task.operationRef
      const dayName = dayNameFromISO8601String(props.clientDate)
      return frequencyTextFromTask({
        task,
        dateString: task.first_scheduled_date,
        dayName
      })
    })

    const showEveryXWeeksStartPeriod = computed(() => {
      const task = props.task.operationRef
      if (task.frequency_type !== FrequencyTypes.EveryXWeek) return false

      const firstScheudled = ISO8601StringToDate(task.first_scheduled_date)
      const clientDate = ISO8601StringToDate(props.clientDate)
      const startPeriod = calculateCurrentStartPeriodForEveryXWeeksFrequency(task.frequency, firstScheudled, clientDate)
      return startPeriod.getTime() !== clientDate.getTime()
    })

    const startPeriodForEveryXWeeksFrequnecy = computed(() => {
      const task = props.task.operationRef
      const firstScheudled = ISO8601StringToDate(task.first_scheduled_date)
      const clientDate = ISO8601StringToDate(props.clientDate)
      const startPeriod = calculateCurrentStartPeriodForEveryXWeeksFrequency(task.frequency, firstScheudled, clientDate)

      return i18n.t('operation_frequency.week_x_frequency_start_period', {
        date: getClientDateFormatted({ fromDate: startPeriod, countryCode: accountCountryCode.value }),
        day: translateDayFromDate(startPeriod)
      })
    })

    return {
      isReported,
      showReporter,
      showReportTag,
      translatedReportTag,
      showStartTime,
      showDescriptionAndType,
      descriptionAndType,
      pendingStatusChange,
      frequencyText,
      showAccountInfo,
      showImageUploading,
      showEveryXWeeksStartPeriod,
      startPeriodForEveryXWeeksFrequnecy,
      jobNameAndShift,
      showImage,
      showHqIcon,
      showRelatedItemTag,
      showCreator,
      relatedItemText,
      onDeleteClick,
      onCopyClick,
      onShareClick,
      onEditClick,
      onNotifyClick,
      onImageClick
    }
  }
}
</script>