<template>
  <v-autocomplete
    :value="value"
    :items="selectEntries"
    :loading="selectIsLoading"
    :search-input.sync="search"
    clearable
    color="black"
    @change="emitChange"
    cache-items
    @click:clear="clear"
    :no-data-text="$t('no_result')"
    @input="update"
    multiple
    item-value="id"
    item-text="name"
    return-object
    :placeholder="placeholderVal">
    <template v-slot:selection="{ item, index }" v-if="showSelectionCount">
      <div v-if="index === 0" class="v-select__selection v-select__selection--comma">
        {{ $t('user_search.x_users_selected', { usersCount: value.length }) }}
      </div>
    </template>
    <template v-slot:selection="{ item }" v-else>
      <v-chip
        :key="item.id">
        {{ item.name }}
        <v-icon @click="removeSelection(item.id)">
          cancel
        </v-icon>
      </v-chip>
    </template>
  </v-autocomplete>
</template>
<style lang="scss">
.item-search.v-select.v-select--is-menu-active .v-input__icon--append .v-icon {
  transform: rotate(0);
}
</style>
<script>
import UserSearchStore from '@/components/shared/user_search/UserSearchStore.js'
import { searchForCompany as usersSearchForCompany } from '@/api_client/User.js'
import { isObject, isString } from '@/helpers/Utils.js'
import useAuth from '@/composables/useAuth.js'

import { handler as errHandler } from '@/classes/ErrorHandler.js'

import { i18n } from '@/i18n.js'
import { ref, computed, watch } from 'vue'

export default {
  props: {
    value: {
      type: Array,
      default: null
    },
    apiKey: {
      type: String,
      required: true
    },
    placeholder: {
      type: String,
      default: null
    },
    menuProps: {
      type: Object,
      required: false,
      default: () => { }
    },
    showSelectionCount: {
      type: Boolean,
      required: false,
      default: false
    },
    addCurrentUser: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  setup(props, { emit }) {
    const entries = ref([])
    const isSearchLoading = ref(false)
    const search = ref(null)


    // Methods
    const update = (newValue) => emit('input', newValue)
    const emitChange = (newValue) => {
      emit('change', newValue)
      search.value = ''
    }
    const clear = () => emit('click:clear')

    const { loadAllCompanyUsers, allCompanyUsers } = UserSearchStore(props.apiKey)
    const { loadCurrentUser, currentUser } = useAuth()
    const userNameFunction = (user) => {
      user.name = `${user.first_name} ${user.last_name}`
      return user
    }
    const removeSelection = (id) => {
      const value = props.value
      const index = value.findIndex((user) => user.id === id)
      if (index > -1) value.splice(index, 1)
      update(value)
    }

    // Computed
    const selectIsLoading = computed(() => isSearchLoading.value)
    const validSearchTerm = computed(() => isString(search.value) && search.value.trim().length > 0)
    const allUsersWithName = computed(() => allCompanyUsers.value.map(userNameFunction))
    const placeholderVal = computed(() => isString(props.placeholder) ? props.placeholder : i18n.t('user_search.placeholder'))
    const selectEntries = computed(() => {
      if (validSearchTerm.value === true) return entries.value
      const shouldAddCurrentUser = props.addCurrentUser && Number.isInteger(currentUser.value?.id)
      if (!shouldAddCurrentUser) return allUsersWithName.value
      const currentUserVal = currentUser.value
      const currentUserObj = { id: currentUserVal.id, first_name: currentUserVal.first_name, last_name: currentUserVal.last_name }
      return [userNameFunction(currentUserObj), ...allUsersWithName.value]
    })

    // Watch
    watch(() => search.value, (query) => {
      if (isSearchLoading.value) return
      if (!validSearchTerm.value) {
        entries.value.splice(0, entries.value.length)
        return
      }

      isSearchLoading.value = true

      usersSearchForCompany({ apiKey: props.apiKey, searchTerm: query })
        .then(response => {
          const entriesVal = entries.value
          entriesVal.splice(0, entriesVal.length)
          response.data.data.forEach((user) => entriesVal.push(userNameFunction(user)))
        })
        .catch(errHandler)
        .finally(() => (isSearchLoading.value = false))
    })
    watch(() => props.addCurrentUser, (shouldLoadCurrentUser) => {
      if (shouldLoadCurrentUser) {
        loadCurrentUser()
      }
    }, { immediate: true })

    const users = props.value || []
    if (users.length > 0) {
      entries.value.splice(0, entries.value.length)
      users.forEach((user) => {
        if (Number.isInteger(user.id)) entries.value.push(user)
      })
    }
    loadAllCompanyUsers()
    return {
      update,
      emitChange,
      clear,
      removeSelection,
      placeholderVal,
      search,
      selectIsLoading,
      selectEntries
    }
  }
}
</script>
