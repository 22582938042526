

import useAccount from '@/composables/useAccount.js'
import { generalStats, index } from '@/api_client/OperationSchedule.js'
import { generalStats as generalStatsForCrossAccount, index as indexForCrossAccount } from '@/api_client/CrossAccountOperation.js'
import { destroy as jobOpScheduleDestroy } from '@/api_client/JobOperationSchedule.js'
import { destroy as CrossAccountOperationDelete } from '@/api_client/CrossAccountOperation.js'

import useAuth from '@/composables/useAuth.js'

import {
  isValidISO8601Date, getClientDateFormatted, ISO8601StringToDate
} from '@/helpers/DateTime.js'
import { isString, isObject } from '@/helpers/Utils.js'

import { i18n } from '@/i18n.js'
import { ref } from 'vue'
import { FrequencyTypes } from '@/store/modules/OperationFrequency'

const DEFAULT_PAGE = 1
const DEFAULT_PER_PAGE = 100

let currentApiKey = null

const isStatsLoading = ref(false)
const stats = ref({})
const apiKeysFilter = ref([])
let countryCode = null
const defaultFilters = () => ({
  reportTags: [],
  shifts: [],
  checkList: null,
  crossAccountOperations: null,
  textSearch: null,
  image: null,
  frequency: [],
  accountJobs: []
})
const filters = ref(defaultFilters())

const defaultPagination = () => ({ page: DEFAULT_PAGE, perPage: DEFAULT_PER_PAGE, totalPages: 0 })
export default function useTaskBank(apiKey, isHq = false) {
  const isHqContext = isHq
  const resetValues = () => {
    isStatsLoading.value = false
    stats.value = {}
    apiKeysFilter.value = []
    filters.value = defaultFilters()
    const { accountCountryCode } = useAccount()
    countryCode = accountCountryCode.value
  }

  if (isString(apiKey) && currentApiKey !== apiKey) {
    resetValues()
    currentApiKey = apiKey
  }


  const getTotalPages = ({ total, perPage }) => {
    const totalItems = total || 0
    if (totalItems === 0 || totalItems <= perPage) {
      return 0
    }
    return Math.ceil(totalItems / perPage)
  }

  const apiFilterParams = () => {
    const filterParams = {}
    const filtersVal = filters.value
    if (Array.isArray(filtersVal.reportTags) && filtersVal.reportTags.length > 0) {
      filterParams.report_tags = filtersVal.reportTags
    }
    if (Array.isArray(filtersVal.shifts) && filtersVal.shifts.length > 0) {
      filterParams.shifts = filtersVal.shifts
    }
    if (filtersVal.image === true || filtersVal.image === false) {
      filterParams.require_image = filtersVal.image
    }
    if (filtersVal.checkList === true) filterParams.with_list = true
    if (filtersVal.crossAccountOperations === true) filterParams.cross_account_operations = true
    if (isString(filtersVal.textSearch) && filtersVal.textSearch.trim().length > 0) {
      filterParams.description = filtersVal.textSearch.trim()
    }
    if (Array.isArray(filtersVal.frequency) && filtersVal.frequency.length > 0) {
      filterParams.frequency_type = filtersVal.frequency
    }
    if (Array.isArray(filtersVal.accountJobs) && filtersVal.accountJobs.length > 0) {
      filterParams.account_job_ids = filtersVal.accountJobs.map(job => job.id)
    }
    return { filters: filterParams, api_keys: apiKeysFilter.value }
  }

  // Methods

  const loadStats = () => {
    isStatsLoading.value = true
    const filters = apiFilterParams()
    const apiCall = isHqContext ? generalStatsForCrossAccount : generalStats
    return apiCall(currentApiKey, filters)
      .then(response => {
        stats.value = response.data.data.map(item => {
          item.translatedName = i18n.t(`task_bank.${item.name}_frequency`)
          item.tasks = []
          item.isLoading = false
          item.pagination = defaultPagination()
          item.isLoadingNextPage = false
          return item
        })
      })
      .finally(() => (isStatsLoading.value = false))
  }

  const addFormatsToTasks = (tasks) => {
    const { isUserCompanyRegionAdmin, currentUser } = useAuth()
    const regionHqTasks = isUserCompanyRegionAdmin.value && isHqContext
    const validUserId = Number.isInteger(currentUser.value?.id)
    tasks.forEach(task => {
      const endDate = task.end_date
      if (endDate && isValidISO8601Date(endDate)) {
        task.endDateUserFormat = getClientDateFormatted({ fromDate: ISO8601StringToDate(endDate), countryCode })
      }
      if (regionHqTasks && (!validUserId || task.creator_data?.user_id !== currentUser.value.id)) {
        task.hideOptions = true
      }
    })
  }

  const loadTasksForFrequency = async ({ frequency, page = DEFAULT_PAGE }) => {
    const frequencyStats = stats.value.find(item => item.name === frequency)
    if (!isObject(frequencyStats)) return

    const loadedAllTasks = frequencyStats.tasks.length === frequencyStats.pagination.total
    if (loadedAllTasks || frequencyStats.isLoading || frequencyStats.isLoadingNextPage) return
    if (page === DEFAULT_PAGE) {
      frequencyStats.isLoading = true
    } else {
      frequencyStats.isLoadingNextPage = true
    }

    const apiParams = apiFilterParams()
    const frequencyFilter = apiParams.filters.frequency_type
    const hasWeeklYFilter = Array.isArray(frequencyFilter) && frequencyFilter
      .some(item => item.startsWith('every_') && item.endsWith('_weeks'))

    if (frequency !== FrequencyTypes.EveryXWeek || !hasWeeklYFilter) {
      apiParams.filters.frequency_type = frequency
    }

    apiParams.page = page
    apiParams.per_page = DEFAULT_PER_PAGE
    const apiCall = isHqContext ? indexForCrossAccount : index
    return apiCall(currentApiKey, apiParams)
      .then(({ data: { data, meta } }) => {
        addFormatsToTasks(data)
        frequencyStats.tasks = frequencyStats.tasks.concat(data)
        frequencyStats.pagination.total = meta.total
        frequencyStats.pagination.page = meta.page
        frequencyStats.pagination.perPage = meta.per_page
        frequencyStats.pagination.totalPages = getTotalPages({ total: meta.total, perPage: meta.per_page })
      })
      .finally(() => {
        frequencyStats.isLoading = false
        frequencyStats.isLoadingNextPage = false
      })
  }

  const loadNextPageForTaskFrequency = (frequency) => {
    const frequencyStats = stats.value.find(item => item.name === frequency)
    if (!isObject(frequencyStats)) return
    return loadTasksForFrequency({ frequency, page: frequencyStats.pagination.page + 1 })
  }

  const resetFilters = () => filters.value = defaultFilters()
  const applyFilterChanges = loadStats

  const deleteTask = async (task) => {
    if (isHqContext) {
      await CrossAccountOperationDelete(currentApiKey, task.cross_account_operation_id)
    } else {
      await jobOpScheduleDestroy(task.account_api_key, task.account_job_id, task.operation_id)
    }
    return loadStats()

  }

  return {
    stats,
    isStatsLoading,
    apiKeysFilter,
    filters,
    loadStats,
    loadTasksForFrequency,
    resetFilters,
    applyFilterChanges,
    deleteTask,
    loadNextPageForTaskFrequency
  }
}