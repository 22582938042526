import { create as createReportClient } from '@/api_serverless_client/CreateReport.js'

import { base64toBlob } from '@/helpers/Utils.js'
const downloadPdf = async ({ data, apiKey }) => {
  return createReportClient({ data, apiKey })
    .then(response => {
      const blob = base64toBlob(response.data, 'application/pdf')
      const blobUrl = URL.createObjectURL(blob)
      // Create a link element
      const downloadLink = document.createElement('a')
      downloadLink.href = blobUrl
      downloadLink.download = 'task-report.pdf'
      downloadLink.click()

      URL.revokeObjectURL(blobUrl)
    })
}

export {
  downloadPdf
}
