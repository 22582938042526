<template>
  <v-dialog v-model="dialog" max-width="290">
    <v-card>
      <v-card-title class="text-center pb-5 blue-grey lighten-5">
        <span class="headline full-width">
            {{$t('account_name')}}
        </span>
      </v-card-title>
      <v-card-text class="pa-0">
        <v-container>
          <v-row align="start" justify="start">
            <v-col cols="12">
              <v-text-field
                v-model="accountName"
                single-line
                :rules="[requiredField]"
                counter="120"
                :label="$t('account_name')">
              </v-text-field>
            </v-col>
          </v-row>
          <v-row no-gutters align="end" justify="end" class="pb-5 text-right">
            <v-btn color="blue darken-1" text @click="close" :disabled="isLoading">
              {{$t('cancel')}}
            </v-btn>
            <v-btn color="blue darken-1" depressed tile @click="save" :loading="isLoading" :disabled="isLoading">
              {{$t('save')}}
            </v-btn>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<style lang="scss" scoped>
.store-settings {
  margin-top: 100px;
}
</style>
<script>
import { requiredField } from '@/classes/InputValidators.js'

import useAuth from '@/composables/useAuth.js'
import useAccount from '@/composables/useAccount.js'

import { handler as errHandler } from '@/classes/ErrorHandler.js'

import { ref } from 'vue'
export default {
  setup () {
    const isLoading = ref(false)
    const accountName = ref(null)
    const dialog = ref(false)
    const { apiKey } = useAuth()
    const { accountName: accountNameRef, updateAccount } = useAccount(apiKey.value)
    const openDialog = () => {
      dialog.value = true
      accountName.value = accountNameRef.value
    }

    const close = () => {
      dialog.value = false
      isLoading.value = false
    }

    const save = () => {
      isLoading.value = true
      updateAccount(accountName.value)
        .then(() => (accountName.value = accountNameRef.value))
        .catch(errHandler)
        .finally(() => (close()))
    }
    return {
      isLoading,
      accountName,
      dialog,
      requiredField,
      openDialog,
      close,
      save
    }
  }
}
</script>
