<template>
  <v-row align="center" justify="start" class="company-stats">
    <v-col cols="4" class="graph-wrapper d-flex align-center px-0" v-if="$vuetify.breakpoint.smAndUp">
      <canvas class="doughnut-chart pb-2" ref="statChart">
      </canvas>
      <span class="stats-total ltr">
        <span class="t-700-13">{{ stats.totalTasksDone + stats.totalHqTasksDone }}</span> /
        <span class="t-500-13">{{ stats.totalTasks + stats.totalHqTasks }}</span>
        <div class="t-700-13 text-center">
          {{ $t('total') }}
        </div>
      </span>
    </v-col>
    <v-col cols="12" sm="8" class="row align-center justify-space-between px-0">
      <v-col cols="7" class="py-1 pointer" @click="hqTasksClick">
        <v-avatar color="#C48BFD" size="32">
          <v-icon color="white">
            apartment
          </v-icon>
        </v-avatar>
        <span class="t-600-18 pps-2">{{ $t('account_selection.staff_tasks') }}</span>
      </v-col>
      <v-col cols="5" class="py-1 t-400-18 ltr pointer" @click="hqTasksClick">
        {{ stats.totalHqTasksDone }} / {{ stats.totalHqTasks }}
      </v-col>
      <v-col cols="7" class="py-1 pointer" @click="accountTasksClick">
        <v-avatar color="#3E8BFE" size="32">
          <v-icon color="white">
            home
          </v-icon>
        </v-avatar>
        <span class="t-600-18 pps-2 pointer">{{ $t('account_selection.store_tasks') }}</span>
      </v-col>
      <v-col cols="5" class="py-1 t-400-18 ltr pointer" @click="accountTasksClick">
        {{ stats.totalTasksDone }} / {{ stats.totalTasks }}
      </v-col>
      <v-col cols="12">
        <v-divider class="area-divider"></v-divider>
      </v-col>
      <v-col cols="8" class="py-1 pointer" @click="brokenItemClick">
        <v-avatar color="#4EBA9A" size="32">
          <v-icon color="white">
            assignment_late
          </v-icon>
        </v-avatar>
        <span class="t-600-18 pps-2">{{ $t('company_stats.faults') }}</span>
      </v-col>
      <v-col cols="4" class="py-1 t-400-20 ltr pointer" @click="brokenItemClick">
        {{ stats.totalBrokenItems }}
      </v-col>
    </v-col>
  </v-row>
</template>
<style lang="scss">
.company-stats {
  border-radius: 16px;
  background-color: #0F0E13;
  color: white;
  min-width: 500px;

  .graph-wrapper {
    position: relative;

    .stats-total {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .area-divider {
    background-color: #F5C829;
  }

  .doughnut-chart {
    width: 100% !important;
    height: 100% !important;
  }

  @media only screen and (max-width: 700px) {
    min-width: unset;
  }
}
</style>
<script>
import useCompanyStat from '@/composables/useCompanyStat.js'
import useAuth from '@/composables/useAuth.js'

import { dateToISO8601String } from '@/helpers/DateTime.js'

import Chart from 'chart.js/auto'
import { ref, onMounted, watch, computed, nextTick } from 'vue'

const chartDefaultOptions = {
  type: 'doughnut',
  data: {
    datasets: [{
      data: [0, 0, 0],
      backgroundColor: [
        '#C48BFD',
        '#3E8BFE',
        '#D4D4D4'
      ],
    }]
  },
  options: { cutout: '80%', responsive: true }
}

export default {
  props: {
    clientDate: {
      type: Date,
      required: true
    }
  },
  setup(props, { emit }) {
    const statChart = ref(null)
    const { apiKey } = useAuth()
    const { stats, loadTasksStats, loadAllStats } = useCompanyStat(apiKey.value)
    let doughnutChart = null

    const setChartResults = () => {
      const unfinsihedTasks = (stats.value.totalHqTasks - stats.value.totalHqTasksDone) + (stats.value.totalTasks - stats.value.totalTasksDone)
      doughnutChart.data.datasets[0].data = [stats.value.totalHqTasksDone, stats.value.totalTasksDone, unfinsihedTasks]
      doughnutChart.update()
    }

    const loadPage = () => {
      var ctx = statChart.value.getContext('2d');
      doughnutChart = new Chart(ctx, chartDefaultOptions)

      loadAllStats(props.clientDate).then(setChartResults)
    }

    // Methods
    const hqTasksClick = () => emit('hq-stat-click')
    const accountTasksClick = () => emit('account-stat-click')
    const brokenItemClick = () => emit('broken-item-stat-click')

    // Computed
    const clientDateString = computed(() => dateToISO8601String(props.clientDate))

    onMounted(() => {
      if (statChart.value === null) return nextTick(loadPage)
      loadPage()
    })

    watch(clientDateString, () => loadTasksStats(props.clientDate).then(setChartResults))
    return {
      stats,
      statChart,
      hqTasksClick,
      accountTasksClick,
      brokenItemClick
    }
  }
}
</script>