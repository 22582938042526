<template>
  <div class="multiple-image-uploader">
    <image-carousel-dialog ref="imageCarouselDialog"
      @reset="onResetClick">
    </image-carousel-dialog>
    <v-icon color="green" v-if="showIconPreview"
      @click="previewImageClick">add_photo_alternate
    </v-icon>
    <label :for="randomId" v-else>
      <v-icon class="font-weight-black pointer" :color="iconColor" :class="{ 'disabled': isDisabled }"> {{ icon }}
      </v-icon>
      <input type="file" :id="randomId" accept="image/*" multiple
        :disabled="isDisabled" @change="uploadImagePreview">
    </label>
    <v-snackbar
      timeout="2000"
      v-model="showMaxImages">
      <div class="text-center">
        {{ $t('image_uploader.max_images', { maxImages }) }}
      </div>
    </v-snackbar>
  </div>
</template>
<style lang="scss">
.multiple-image-uploader {
  input[type="file"] {
    display: none;
  }

  .v-icon.disabled {
    opacity: 0.2;
  }
}
</style>
<script>
import ImageCarouselDialog from '@/components/shared/ImageCarouselDialog.vue'

import { ref, computed } from 'vue'
export default {
  props: {
    maxImages: {
      type: Number,
      required: true
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    icon: {
      type: String,
      required: false,
      default: 'add_a_photo'
    },
    iconColor: {
      type: String,
      required: false,
      default: 'black'
    }
  },
  components: {
    'image-carousel-dialog': ImageCarouselDialog
  },
  setup(props, { emit }) {
    const imageCarouselDialog = ref(null)
    const images = ref([])
    const showMaxImages = ref(false)

    const notifyMaxImageExceeded = () => (showMaxImages.value = true)
    const randomId = (Math.random() + 1).toString(36).substring(2)

    const releaseImagesMemory = () => images.value.forEach(image => {
      if (!image.url) return
      URL.revokeObjectURL(image.url)
      image.url = null
    })

    const defaultImage = () => {
      return {
        isLoading: false,
        url: '',
        hasImage: false,
        showImageOverlay: false,
        hadImage: false,
        originalUploadedFile: null
      }
    }

    // Methods
    const getImageFiles = () => images.value.map(image => image.originalUploadedFile)
    const previewImageClick = () => {
      const imageUrls = images.value.map((image) => image.url)
      imageCarouselDialog.value.openDialog({ imageUrls, showReset: true })
    }
    const resetImages = () => {
      releaseImagesMemory()
      images.value.splice(0, images.value.length)
    }

    const emitChange = () => emit('change', images.value.length)

    const onResetClick = () => {
      resetImages()
      emit('image-removed')
      emitChange()
    }

    const uploadImagePreview = (event) => {
      resetImages()
      const imageFiles = event.target.files

      if (!Number.isInteger(imageFiles.length) || imageFiles.length === 0) return
      if (imageFiles.length > (props.maxImages)) {
        notifyMaxImageExceeded()
        event.preventDefault()
        return
      }

      Array.from(imageFiles).forEach(imageFile => {
        const imageVal = defaultImage()
        imageVal.isLoading = true
        imageVal.originalUploadedFile = imageFile
        imageVal.url = URL.createObjectURL(imageFile)
        imageVal.isLoading = false
        imageVal.hasImage = true
        images.value.push(imageVal)
      })
      emit('image-uploaded', images.value.length)
      emitChange()
    }

    // Computed
    const isDisabled = computed(() => props.disabled === true || images.value.some(image => image.isLoading === true))
    const showIconPreview = computed(() => images.value.some(image => image.isLoading || image.hasImage))
    return {
      randomId,
      images,
      imageCarouselDialog,
      isDisabled,
      showIconPreview,
      showMaxImages,
      resetImages,
      previewImageClick,
      uploadImagePreview,
      onResetClick,
      getImageFiles
    }
  }
}
</script>
