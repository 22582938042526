import Client from '@/api_client/client.js'
import AccountSupplierSearchStore from '@/components/shared/supplier_search/AccountSupplierSearchStore.js'

function show (apiKey, id) {
  const SUPPLIIERS_SHOW_PATH = `/accounts/${apiKey}/account_suppliers/${id}/`
  return Client.get(SUPPLIIERS_SHOW_PATH)
}
function search (apiKey, searchTerm, limit) {
  const SUPPLIIERS_SHOW_PATH = `/accounts/${apiKey}/account_suppliers/search/`
  return Client.get(SUPPLIIERS_SHOW_PATH, { params: { name: searchTerm, per_page: limit } })
}
function index (apiKey, params) {
  const SUPPLIIERS_INDEX_PATH = `/accounts/${apiKey}/account_suppliers`
  return Client.get(SUPPLIIERS_INDEX_PATH, { params })
}

async function create (apiKey, params) {
  const SUPPLIIERS_CREATE_PATH = `/accounts/${apiKey}/account_suppliers`
  const response = await Client.post(SUPPLIIERS_CREATE_PATH, params)
  invalidSupplierSearchCache(apiKey)
  return response
}
async function update (apiKey, id, params) {
  const SUPPLIIERS_UPDATE_PATH = `/accounts/${apiKey}/account_suppliers/${id}/`
  const response = await Client.put(SUPPLIIERS_UPDATE_PATH, params)
  invalidSupplierSearchCache(apiKey)
  return response
}
async function destroy (apiKey, id) {
  const SUPPLIIERS_DELETE_PATH = `/accounts/${apiKey}/account_suppliers/${id}/`
  const response = await Client.delete(SUPPLIIERS_DELETE_PATH)
  invalidSupplierSearchCache(apiKey)
  return response
}

function invalidSupplierSearchCache (apiKey) {
  const { invalidateCache } = AccountSupplierSearchStore(apiKey)
  invalidateCache()
}

export {
  show,
  search,
  index,
  create,
  update,
  destroy
}
