<template>
  <v-avatar color="yellow darken-2" :tile="tile" :rounded="rounded">
    <v-icon v-if="showGroupIcon" color="white">
      groups
    </v-icon>
    <v-img v-else-if="showGroupImage" :src="chatGroup.image_url"></v-img>
    <v-img v-else-if="showOtherUserAvatar" :src="chatGroup.other_user_data.avatar_url"></v-img>
    <span v-else class="white--text text-h5">
      {{ otherUserInitilas }}
    </span>
  </v-avatar>
</template>

<script>
import { isString } from '@/helpers/Utils.js'
import { computed } from 'vue'
export default {
  props: {
    chatGroup: {
      type: Object,
      required: true
    },
    tile: {
      type: Boolean,
      default: false
    },
    rounded: {
      type: String,
      default: null
    }
  },
  setup(props) {
    // Computed
    const isMultipleParticipantsGroup = computed(() => props.chatGroup.multiple_participants === true)
    const hasImageGroup = computed(() => isString(props.chatGroup.image_url) && props.chatGroup.image_url.length > 0)
    const showGroupImage = computed(() => isMultipleParticipantsGroup.value && hasImageGroup.value)
    const showGroupIcon = computed(() => isMultipleParticipantsGroup.value && !hasImageGroup.value)
    const showOtherUserAvatar = computed(() => {
      if (isMultipleParticipantsGroup.value === true) return false
      const userAvatarUrl = props.chatGroup?.other_user_data?.avatar_url
      return isString(userAvatarUrl) && userAvatarUrl.length > 0
    })
    const otherUserInitilas = computed(() => {
      const otherUserData = props.chatGroup.other_user_data || {}
      const { first_name: firstName, last_name: lastName } = otherUserData
      if (isString(firstName) !== true || isString(lastName) !== true) {
        return props.chatGroup.name?.charAt(0)
      }
      return `${firstName?.charAt(0)}${lastName?.charAt(0)}`
    })
    return {
      showGroupIcon,
      showGroupImage,
      showOtherUserAvatar,
      otherUserInitilas
    }
  }
}

</script>