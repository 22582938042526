<template>
  <v-overlay :value="showImageOverlay" class="op-image">
    <v-container class="pa-5">
      <v-row justify="start" align="start" class="px-5">
        <v-btn fab small @click="hideImage" color="cyan">
          <v-icon color="black">close</v-icon>
        </v-btn>
      </v-row>
      <v-row justify="center" align="center">
        <pinch-zoom>
          <v-col class="px-0">
            <v-img :src="betterQualityImageSrc" :max-height="imageMaxHeight" :max-width="imageMaxWidth" contain>
              <template v-slot:placeholder>
                <v-row align="center" justify="center" class="fill-height" no-gutters>
                  <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                </v-row>
              </template>
            </v-img>
          </v-col>
        </pinch-zoom>
      </v-row>
      <v-row justify="center" align="center" v-if="showCancelButton">
        <v-btn rounded small class="outlined cancel"
                :disabled="undoDisabled"
                color="green"
                @click="cancelReport">
          <v-icon class="font-weight-black mr-1" color="white">cancel</v-icon>
          <v-icon class="done font-weight-black">done</v-icon>
        </v-btn>
      </v-row>
    </v-container>
  </v-overlay>
</template>
<style lang="scss">
.op-image {
  .v-btn.cancel {
    min-height: 35px;
      &.cancel.v-btn--disabled:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined) {
        background-color: #4CAF50 !important;
      }
  }
}
</style>
<script>
import PinchZoom from 'vue-pinch-zoom'

export default {
  components: {
    'pinch-zoom': PinchZoom
  },
  props: {
    showImageOverlay: {
      type: Boolean,
      required: true
    },
    imageSrc: {
      type: String,
      required: true
    },
    undoDisabled: {
      type: Boolean,
      default: false
    },
    hideCancelButton: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    imageMaxWidth () {
      return this.$vuetify.breakpoint.xsOnly ? '90vw' : '50vw'
    },
    imageMaxHeight () {
      return this.$vuetify.breakpoint.xsOnly ? '90vh' : '50vh'
    },
    showCancelButton () {
      return this.hideCancelButton !== true
    },
    betterQualityImageSrc () {
      return this.imageSrc.replace('image/upload/f_auto,q_auto,dpr_auto,c_limit,w_400,h_400', 'image/upload/f_auto,q_auto,dpr_auto')
    }
  },
  methods: {
    hideImage () {
      this.$emit('hide-image')
    },
    cancelReport () {
      this.$emit('cancel-report')
    }
  }
}
</script>
