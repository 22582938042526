
import { reportError } from '@/plugins/AppSignal.js'

const handler = (err, vm, info) => {
  if (import.meta.env.MODE === 'development' || import.meta.env.MODE === 'staging') {
    console.error(err) // eslint-disable-line no-console
  }
  if (import.meta.env.MODE === 'development') {
    return
  }
  reportError(err, vm, info)
}

export { handler }
