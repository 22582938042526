<template>
  <v-dialog v-model="dialog" max-width="350" persistent>
    <v-card class="message-info-dialog" :class="{ 'rtl': isRtl, 'ltr': !isRtl }">
      <v-card-title class="text-center pb-5 blue-grey lighten-5">
        <span class="headline full-width">
          {{ $t('app_chat.seen_by_dialog.title') }}
        </span>
      </v-card-title>
      <v-card-text class="pa-2 scrollable">
        <v-row align="center" justify="center" v-if="isLoading" class="pt-4">
          <v-progress-circular indeterminate color="primary"></v-progress-circular>
        </v-row>
        <v-row align="center" justify="start" v-else>
          <v-col cols="12" class="text-center mt-4">
            <v-chip :ripple="false">
              {{ messageDateText }}
            </v-chip>
          </v-col>
          <v-col cols="12" class="text-center">
            <chat-message-content :message="chatMessage" :show-options="false">
            </chat-message-content>
          </v-col>
          <v-col cols="12" v-if="showReadByList">
            <div class="d-flex align-center justify-start mb-2">
              <span class="px-2">{{ $t('app_chat.seen_by_dialog.read_by') }}</span> <v-icon
                color="#0040FF">done_all</v-icon>
            </div>
            <read-by-user-list :users="readByList">
            </read-by-user-list>
          </v-col>
          <v-col cols="12" v-if="showPendingList">
            <div class="d-flex align-center justify-start">
              <span class="px-2">{{ $t('app_chat.seen_by_dialog.pending') }}</span>
            </div>
            <read-by-user-list :users="pendingReadByList">
            </read-by-user-list>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="pt-2 pr-2">
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" depressed tile @click="close">
          {{ $t('close') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<style lang="scss">
.message-info-dialog {
  background-color: #F4F4F4;

  .scrollable {
    overflow: auto;
    height: 50vh;
    max-height: 70vh;
  }
}
</style>
<script>

import useAccount from '@/composables/useAccount.js'

import { readBy as chatMessageClientReadBy } from '@/api_client/ChatMessage.js'

import { ISO8601dateToText, ISO8601dateTimeStringToISODate } from '@/helpers/DateTime.js'

import { isString } from '@/helpers/Utils.js'
import { isRtl } from '@/i18n.js'
import { handler as errHandler } from '@/classes/ErrorHandler.js'

import { ref, computed } from 'vue'

export default {

  props: {
    apiKey: {
      type: String,
      required: true
    }
  },
  components: {
    'chat-message-content': () => import('@/components/app_chat/chat_message/ChatMessageContent.vue'),
    'read-by-user-list': () => import('@/components/app_chat/read_by_dialog/ReadByUserList.vue')
  },
  setup(props) {
    let chatGroupId = null
    const chatMessage = ref({})
    const dialog = ref(false)
    const isLoading = ref(false)
    const userList = ref([])
    const { accountCountryCode } = useAccount()
    const countryCode = accountCountryCode.value

    const resetValues = () => {
      chatGroupId = null
      isLoading.value = false
      chatMessage.value = {}
      userList.value = []
    }

    const loadReadBy = () => {
      const userListVal = userList.value
      return chatMessageClientReadBy(props.apiKey, { chatGroupId, chatMessageId: chatMessage.value.id })
        .then((response) => response.data.data.forEach((user) => userListVal.push(user)))
        .catch(errHandler)
    }
    // Methods
    const openDialog = ({ message, chatGroupId: groupId }) => {
      chatMessage.value = JSON.parse(JSON.stringify(message))
      chatGroupId = groupId
      dialog.value = true
      isLoading.value = true
      loadReadBy()
        .finally(() => (isLoading.value = false))
    }

    const close = () => {
      dialog.value = false
      resetValues()
    }

    // Computed

    const readByList = computed(() => userList.value.filter((user) => user.read === true))
    const pendingReadByList = computed(() => userList.value.filter((user) => user.read !== true))
    const showReadByList = computed(() => readByList.value.length > 0)
    const showPendingList = computed(() => pendingReadByList.value.length > 0)

    const messageDateText = computed(() => {
      const createdAt = chatMessage.value.created_at_date_raw
      if (!isString(createdAt)) return ''
      const isoDate = ISO8601dateTimeStringToISODate(createdAt)
      return ISO8601dateToText({ fromDate: isoDate, countryCode })
    })

    return {
      dialog,
      isLoading,
      userList,
      readByList,
      pendingReadByList,
      messageDateText,
      chatMessage,
      showReadByList,
      isRtl,
      showPendingList,
      openDialog,
      close
    }
  }
}
</script>
