import { ref, computed } from 'vue'
import {
  index as CompanyDistrictClientIndex,
  create as CompanyDistrictClientCreate,
  destroy as CompanyDistrictClientDestroy
} from '@/api_client/CompanyDistrictClient.js'

let isLoaded = false
const companyDistricts = ref([])
const accountsWithoutDistrictsSize = ref(0)
const isLoading = ref(false)

const loadCompanyDistricts = async ({ apiKey, forceLoad = false } = {}) => {
  if (forceLoad !== true && (isLoaded === true || isLoading.value === true)) return
  isLoading.value = true
  const response = await CompanyDistrictClientIndex(apiKey)
  isLoading.value = false
  companyDistricts.value = response.data.data
  accountsWithoutDistrictsSize.value = response.data.meta.account_without_district
  isLoaded = true
}

const createCompanyDistrict = async ({ apiKey, districtName }) => {
  isLoading.value = true
  const createdDistrictResponse = await CompanyDistrictClientCreate(apiKey, districtName)
  const createdDistrict = Object.assign({}, createdDistrictResponse.data.data, { accounts_count: 0 })
  companyDistricts.value.push(createdDistrict)
  isLoading.value = false
}

const destroyCompanyDistrict = async ({ apiKey, districtId }) => {
  isLoading.value = true
  await CompanyDistrictClientDestroy(apiKey, districtId)
  await loadCompanyDistricts({ forceLoad: true, apiKey })
  isLoading.value = false
}

const getCompanyDistricts = computed(() => companyDistricts.value)
const getIsLoading = computed(() => isLoading.value)
const getAccountsWithoutDistrictsSize = computed(() => accountsWithoutDistrictsSize.value)

export {
  getCompanyDistricts,
  getIsLoading,
  loadCompanyDistricts,
  destroyCompanyDistrict,
  createCompanyDistrict,
  getAccountsWithoutDistrictsSize
}
