import Client from '@/api_client/client.js'

const index = (apiKey, { page, perPage, dateRangeStart, dateRangeEnd, operationType, filters = {} }) => {
  const DAILY_OPERATIONS_HISTORY_INDEX_PATH = `/accounts/${apiKey}/daily_operations_history/`
  return Client.get(DAILY_OPERATIONS_HISTORY_INDEX_PATH, {
    params: {
      page,
      per_page: perPage,
      start_date: dateRangeStart,
      end_date: dateRangeEnd,
      filters,
      operation_type: operationType
    }
  })
}

const search = (apiKey, { operationType, filters = {} }) => {
  const DAILY_OPERATIONS_HISTORY_SEARCH_PATH = `/accounts/${apiKey}/daily_operations_history/search`
  return Client.get(DAILY_OPERATIONS_HISTORY_SEARCH_PATH, { params: { filters, operation_type: operationType } })
}

export {
  index,
  search
}
