<template>
  <v-dialog v-model="dialog" persistent :fullscreen="$vuetify.breakpoint.xsOnly" width="500">
    <v-card class="pt-3"
      :class="{ 'rtl': isRtl, 'ltr': !isRtl }">
      <v-card-title class="text-center">
        <span class="headline full-width">
          {{ $t('schedule_new_op_dialog.title') }}
        </span>
      </v-card-title>
      <v-card-text class="cross-account-new-op">
        <cross-account-operation-content v-if="isCrossAccountTab"
          @close="closeDialog"
          ref="crossAccountOperation">
        </cross-account-operation-content>
        <schedule-new-staff-operation-content v-if="isStaffOperationTab"
          :is-dialog-open="dialog"
          @close="closeDialog"
          ref="staffAccountOperation">
        </schedule-new-staff-operation-content>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<style lang="scss">
.cross-account-new-op {
  .description-text.v-text-field.rtl {
    label.v-label.theme--light {
      left: 0 !important;
    }
  }
}
</style>
<script>
import ScheduleNewCrossAccountOperationContent from '@/components/operation_schedule_dialog/cross_account_operation/ScheduleNewCrossAccountOperationContent.vue'
import ScheduleNewStaffOperationContent from '@/components/operation_schedule_dialog/ScheduleNewStaffOperationContent.vue'

import { onDialogClose, onDialogOpen } from '@/classes/DialogScrollBug.js'
import { EventBus, RESET_OPERATION_DIALOG_CONTENT } from '@/EventBus.js'

import { isRtl } from '@/i18n.js'

import { ref, nextTick, computed } from 'vue'
const tabs = { crossAccountOperatiopn: 'cross_account', staffOperation: 'staff_operation' }
export default {
  components: {
    'cross-account-operation-content': ScheduleNewCrossAccountOperationContent,
    'schedule-new-staff-operation-content': ScheduleNewStaffOperationContent
  },
  setup() {
    const dialog = ref(false)
    const selectedTab = ref(null)
    const crossAccountOperation = ref(null)
    const staffAccountOperation = ref(null)

    // Methods
    const closeDialog = () => {
      onDialogClose()
      dialog.value = false
      EventBus.emit(RESET_OPERATION_DIALOG_CONTENT)
    }
    const openAndSelectTab = (tab) => {
      onDialogOpen()
      dialog.value = true
      selectedTab.value = tab
    }

    const openCrossAccountCreate = () => {
      openAndSelectTab(tabs.crossAccountOperatiopn)
      nextTick(() => {
        crossAccountOperation.value.openDialog()
      })
    }

    const openStaffAccountCreate = () => openAndSelectTab(tabs.staffOperation)
    const openCrossAccountUpdate = (operation, apiKey, dayName) => {
      openAndSelectTab(tabs.crossAccountOperatiopn)
      nextTick(() => {
        crossAccountOperation.value.openForUpdate(operation, apiKey, dayName)
      })
    }

    const openCrossAccountCopy = (operation, apiKey, dayName) => {
      openAndSelectTab(tabs.crossAccountOperatiopn)
      nextTick(() => {
        crossAccountOperation.value.openForCopy(operation, apiKey, dayName)
      })
    }

    const openStaffAccountUpdate = ({ operation, jobId, dayName, shiftPartName, hasItemList }) => {
      openAndSelectTab(tabs.staffOperation)
      nextTick(() => {
        staffAccountOperation.value.openForUpdate({ operation, jobId, dayName, shiftPartName, hasItemList })
      })
    }

    const isCrossAccountTab = computed(() => selectedTab.value === tabs.crossAccountOperatiopn)
    const isStaffOperationTab = computed(() => selectedTab.value === tabs.staffOperation)

    return {
      dialog,
      selectedTab,
      crossAccountOperation,
      staffAccountOperation,
      isCrossAccountTab,
      isStaffOperationTab,
      isRtl,
      openCrossAccountCreate,
      openCrossAccountUpdate,
      openCrossAccountCopy,
      openStaffAccountCreate,
      openStaffAccountUpdate,
      closeDialog
    }
  }
}
</script>
