import Vue, { computed } from 'vue'
import VueI18n from 'vue-i18n'
import en from './translations/en.js'
import he from './translations/he.js'
import es from './translations/es.js'
Vue.use(VueI18n)

const messages = {
  en,
  he,
  es
}
const i18n = new VueI18n({
  locale: 'en',
  fallbackLocale: 'en',
  messages
})

const isRtl = computed(() => i18n.locale === 'he')

const AvailableLangugues = [
  { name: 'en', translatedName: i18n.t('languages.en') },
  { name: 'he', translatedName: i18n.t('languages.he') },
  { name: 'es', translatedName: i18n.t('languages.es') }
]

export { i18n, isRtl, AvailableLangugues }
