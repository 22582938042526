<template>
  <v-autocomplete
    :value="value"
    :items="selectEntries"
    :loading="selectIsLoading"
    :search-input.sync="search"
    clearable
    :hide-selecte="!multiple"
    append-icon="search"
    class="item-search"
    item-text="name"
    item-value="id"
    :multiple="multiple"
    color="black"
    @change="emitChange"
    @click:clear="clear"
    :no-data-text="$t('no_result')"
    @input="update"
    :return-object="returnObject"
    :disabled="disabled"
    :menu-props="menuProps"
    :placeholder="$t('provider_search.placeholder')">
    <template v-slot:item="{ item, attrs }">
      <v-row no-gutters justify="start" align="center" :class="{ 'rtl': isRtl, 'ltr': !isRtl }">
        <v-col :cols="multiple ? 10 : 12">
          <span class="t-500-18 color10">{{ item.name }}</span>
          <div v-if="item.contact_name" class="t-500-14 color2">
            {{ item.contact_name }}
          </div>
        </v-col>
        <v-col cols="2" class="texts-end" v-if="multiple">
          <v-icon v-if="attrs.inputValue">check_box</v-icon>
          <v-icon v-else>check_box_outline_blank</v-icon>
        </v-col>
      </v-row>
    </template>
    <template v-slot:selection="{ item }" v-if="multiple">
      <v-chip
        :key="item.id">
        {{ item.name }}
        <v-icon @click="removeSelection(item.id)">
          cancel
        </v-icon>
      </v-chip>
    </template>
  </v-autocomplete>
</template>
<style lang="scss">
.item-search.v-select.v-select--is-menu-active .v-input__icon--append .v-icon {
  transform: rotate(0);
}
</style>
<script>

import CompanyProviderSearchStore from '@/components/shared/company_provider_search/CompanyProviderSearchStore.js'
import { search as providersSearch } from '@/api_client/CompanyProvider.js'

import { isString } from '@/helpers/Utils.js'
import { isRtl } from '@/i18n'

import { handler as errHandler } from '@/classes/ErrorHandler.js'

import { ref, computed, watch } from 'vue'

export default {
  props: {
    value: {
      type: [Object, Array],
      default: null
    },
    apiKey: {
      type: String,
      required: true
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    multiple: {
      type: Boolean,
      required: false,
      default: false
    },
    menuProps: {
      type: Object,
      required: false,
      default: () => { }
    },
    returnObject: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  setup(props, { emit }) {
    const { isLoading, companyProvidersList, loadCompanyProviders } = CompanyProviderSearchStore(props.apiKey)
    const entries = ref([])
    const isSearchLoading = ref(false)
    const search = ref(null)

    // Methods
    const update = (newValue) => emit('input', newValue)
    const emitChange = (newValue) => {
      emit('change', newValue)
      search.value = ''
    }
    const clear = () => emit('click:clear')
    const removeSelection = (providerId) => {
      const newValue = props.returnObject === true ?
        props.value.filter((provider) => provider.id !== providerId) :
        props.value.filter((provider) => provider !== providerId)
      update(newValue)
    }

    // Computed

    const selectIsLoading = computed(() => isSearchLoading.value || isLoading.value)
    const validSearchTerm = computed(() => isString(search.value) && search.value.trim().length > 0)
    const selectEntries = computed(() => {
      if (isLoading.value) return []
      if (validSearchTerm.value === true) return entries.value
      return companyProvidersList.value
    })

    watch(() => search.value, (query) => {
      if (isSearchLoading.value) return
      if (!validSearchTerm.value) {
        entries.value.splice(0, entries.value.length)
        return
      }

      isSearchLoading.value = true
      providersSearch(props.apiKey, query)
        .then(res => {
          const entriesValue = entries.value
          entriesValue.splice(0, entriesValue.length)
          res.data.data.forEach(provider => entriesValue.push(provider))
        })
        .catch(errHandler)
        .finally(() => (isSearchLoading.value = false))
    })

    loadCompanyProviders()

    const providerId = props.returnObject ? (props.value || {}).id : props.value
    if (Number.isInteger(providerId)) {
      entries.value.push(props.value)
    }
    return {
      clear,
      isRtl,
      selectEntries,
      selectIsLoading,
      validSearchTerm,
      search,
      update,
      emitChange,
      removeSelection
    }
  }
}
</script>
