<template>
  <v-row class="task-bank-page" align="start" justify="center"
    no-gutters
    :class="{ 'rtl': isRtl && !$vuetify.breakpoint.xsOnly, 'ltr': !isRtl }">
    <v-tabs
      class="mt-3 sticky elevation-2 mt-sm-15"
      slider-color="black"
      v-model="selectedTab"
      @change="tabChange"
      color="white">
      <v-tab href="#staffTask" v-if="isCompanyAdminRegionOrAbove">
        {{ $t('account_selection.my_tasks_tab') }}
      </v-tab>
      <v-tab href="#hqTasks" v-if="isCompanyAdminRegionOrAbove">
        {{ $t('account_selection.hq_tasks_tab') }}
      </v-tab>
      <v-tab href="#accountsTasks">
        {{ $t('account_selection.accounts_tasks_tab') }}
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="selectedTab" touchless class="tabs-content"
      :class="{ 'rtl': isRtl, 'ltr': !isRtl }">
      <v-tab-item value="staffTask" :transition="false" v-if="isCompanyAdminRegionOrAbove"
        class="full-height">
        <task-bank-layout v-if="showStaffTasksContent"
          :api-key="staffAccountApiKey"
          staff-account>
        </task-bank-layout>
      </v-tab-item>
      <v-tab-item value="hqTasks" :transition="false" v-if="isCompanyAdminRegionOrAbove"
        class="full-height">
        <task-bank-layout v-if="hqTasksSelected"
          hq-context
          :api-key="apiKey">
        </task-bank-layout>
      </v-tab-item>
      <v-tab-item value="accountsTasks" :transition="false" class="full-height">
        <task-bank-layout v-if="accountsTasksSelected"
          :api-key="apiKey">
        </task-bank-layout>
      </v-tab-item>
    </v-tabs-items>
  </v-row>
</template>
<style lang="scss">
.task-bank-page {
  height: 100%;


  .tabs-content {
    width: 100%;
    height: 100%;
  }

  @media only screen and (max-width: 960px) {
    .sticky {
      top: 56px !important;
    }
  }

  .sticky {
    position: sticky;
    top: 60px;
    z-index: 5;
  }
}
</style>
<script>
import TaskBankLayout from '@/components/task_bank/TaskBankLayout.vue'

import { loadTab, saveTab } from '@/helpers/TabPersist.js'

import useAuth from '@/composables/useAuth.js'
import useCompany from '@/composables/useCompany.js'

import { isString } from '@/helpers/Utils.js'
import { isRtl } from '@/i18n.js'

import { ref, computed } from 'vue'

const TABS = {
  staffTask: 'staffTask',
  hqTasks: 'hqTasks',
  accountsTasks: 'accountsTasks'
}

export default {
  components: {
    'task-bank-layout': TaskBankLayout,
  },
  setup() {

    const { isCompanyAdminRegionOrAbove, apiKey, isUserCompanyRegionAdmin, loadCurrentUser } = useAuth()
    const defaultTab = isCompanyAdminRegionOrAbove.value ? TABS.staffTask : TABS.accountsTasks
    const selectedTab = ref(loadTab({ defaultTab }))

    const { staffAccountApiKey } = useCompany(apiKey.value)

    // Methods
    const tabChange = () => saveTab({ currentTab: selectedTab.value })


    // Computed
    const accountsTasksSelected = computed(() => selectedTab.value === TABS.accountsTasks)
    const hqTasksSelected = computed(() => selectedTab.value === TABS.hqTasks)
    const staffApiKeyLoaded = computed(() => isString(staffAccountApiKey.value) === true &&
      staffAccountApiKey.value.length > 0)
    const showStaffTasksContent = computed(() => isCompanyAdminRegionOrAbove.value &&
      staffApiKeyLoaded.value &&
      selectedTab.value === TABS.staffTask)

    if (isUserCompanyRegionAdmin.value) {
      loadCurrentUser()
    }
    return {
      isRtl,
      selectedTab,
      isCompanyAdminRegionOrAbove,
      apiKey,
      staffApiKeyLoaded,
      staffAccountApiKey,
      showStaffTasksContent,
      accountsTasksSelected,
      hqTasksSelected,
      tabChange
    }
  }
}
</script>