
import { resetUnreadPosts, unreadPosts } from '@/api_client/BlogPostLastSeen.js'

import { computed, ref } from 'vue'

const unreadPostsCount = ref(0)
let isLoaded = false

export default function useBlogPostLastSeen(apiKey) {
  const loadHaveUnreadPosts = () => {
    if (isLoaded === true) return
    isLoaded = true
    unreadPosts(apiKey)
      .then((response) => unreadPostsCount.value = response.data.data.unseen_count)
      .catch(() => (isLoaded = false))
  }

  // Methods

  const resetCounter = () => {
    if (unreadPostsCount.value === 0) return

    resetUnreadPosts(apiKey)
      .then(() => {
        isLoaded = true
        unreadPostsCount.value = 0
      })
  }

  // Computed

  const getHaveUnreadPosts = computed(() => Number.isInteger(unreadPostsCount.value) && unreadPostsCount.value > 0)

  if (!isLoaded) loadHaveUnreadPosts()

  return {
    getHaveUnreadPosts,
    unreadPostsCount,
    resetCounter
  }
}
