import Client from '@/api_client/client.js'

const totalOperationsForAccounts = (apiKey, params) => {
  const TOTAL_OPS_FOR_COMPANY = `/accounts/${apiKey}/operation_schedules/total_for_accounts`
  return Client.put(TOTAL_OPS_FOR_COMPANY, params)
}

const generalStats = async (apiKey, params) => {
  const GENERAL_STATS = `/accounts/${apiKey}/operation_schedules/stats`
  return Client.put(GENERAL_STATS, params)
}

const index = async (apiKey, params) => {
  const TASKS_INDEX = `/accounts/${apiKey}/operation_schedules`
  return Client.put(TASKS_INDEX, params)
}

export {
  totalOperationsForAccounts,
  generalStats,
  index
}
