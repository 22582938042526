import Client from '@/api_client/client.js'

function create (apiKey, itemId, params) {
  const DEVICE_OPERATION_CREATE_PATH = `/accounts/${apiKey}/items/${itemId}/item_operations`
  return Client.post(DEVICE_OPERATION_CREATE_PATH, params)
}

function update (apiKey, itemId, itemOperationId, params) {
  const DEVICE_OPERATION_UPDATE_PATH = `/accounts/${apiKey}/items/${itemId}/item_operations/${itemOperationId}`
  return Client.put(DEVICE_OPERATION_UPDATE_PATH, params)
}

function addImages ({ apiKey, itemId, itemOperationId, params }) {
  const DEVICE_OPERATION_ADD_IMAGES_PATH = `/accounts/${apiKey}/items/${itemId}/item_operations/${itemOperationId}/add_images`
  return Client.post(DEVICE_OPERATION_ADD_IMAGES_PATH, params)
}

function removeImages ({ apiKey, itemId, itemOperationId, params }) {
  const DEVICE_OPERATION_REMOVE_IMAGES_PATH = `/accounts/${apiKey}/items/${itemId}/item_operations/${itemOperationId}/remove_images`
  return Client.delete(DEVICE_OPERATION_REMOVE_IMAGES_PATH, { params })
}

function addFile ({ apiKey, itemId, itemOperationId, params }) {
  const DEVICE_OPERATION_ADD_FILE_PATH = `/accounts/${apiKey}/items/${itemId}/item_operations/${itemOperationId}/add_file`
  return Client.post(DEVICE_OPERATION_ADD_FILE_PATH, params)
}

function removeFile ({ apiKey, itemId, itemOperationId }) {
  const DEVICE_OPERATION_REMOVE_FILE_PATH = `/accounts/${apiKey}/items/${itemId}/item_operations/${itemOperationId}/remove_file`
  return Client.delete(DEVICE_OPERATION_REMOVE_FILE_PATH)
}

export {
  create,
  update,
  addImages,
  removeImages,
  addFile,
  removeFile
}
