<template>
  <div v-if="showDay" class="justify-center align-center row py-2 px-1 no-gutters">
    <div v-for="(event, index) in dayEvents" :key="index"
      class="v-event monthly-day white--text text-truncate texts-start pa-2 pointer"
      v-ripple :style="{ 'background-color': event.color }" @click="onClick(event)">
      <div class="t-700-14 black--text">
        {{ event.jobName }} - {{ event.shiftTranslated }}
      </div>
      <div class="t-700-14 black--text" v-if="event.departmentName">{{ event.departmentName }}</div>
    </div>
  </div>
</template>
<style scoped lang="scss">
.v-event.monthly-day {
  width: 95%;
  margin-bottom: 8px;
}
</style>
<script>

import { computed } from 'vue'

export default {
  props: {
    dayEvents: {
      type: Array,
      required: false,
      default: () => []
    }
  },
  setup(props, { emit }) {
    const showDay = computed(() => props.dayEvents.length > 0)

    // Methods
    const onClick = (event) => emit('click', { event })

    // Computed
    return {
      showDay,
      onClick
    }
  }
}
</script>
