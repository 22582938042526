<template>
  <v-row align="start" justify="start" v-if="showLoadingIndicatorFirsrPage">
    <v-col cols="12" lg="3" md="4" sm="6" v-for="n in 8" :key="n">
      <v-skeleton-loader
        type="card-heading, divider, list-item-avatar, list-item-three-line">
      </v-skeleton-loader>
    </v-col>
  </v-row>
  <v-row align="start" justify="start" v-else>
    <confirm-delete ref="deleteDialog" max-width="250"></confirm-delete>
    <v-col cols="12" lg="3" md="4" sm="6" class="align-self-stretch"
      v-for="folder in driveFolders" :key="folder.id">
      <single-drive-folder
        :folder="folder"
        @delete-folder="onDelete"
        @edit-folder="onEdit"
        @click-folder="onFolderClick"
        @share-folder="onFolderShare">
      </single-drive-folder>
    </v-col>
    <v-col v-if="showLoadingIndicatorMorePages" class="row align-start justify-start">
      <v-col cols="12" lg="3" md="4" sm="6" v-for="n in 8" :key="n">
        <v-skeleton-loader
          type="card-heading, divider, list-item-avatar, list-item-three-line">
        </v-skeleton-loader>
      </v-col>
    </v-col>
    <v-col cols="12" v-if="showLoadMorePages" v-intersect.once="loadNextPage"></v-col>
    <v-snackbar
      v-model="showSnackBar" timeout="3000">
      <div class="text-center">
        {{ $t('drive.link_copied') }}
      </div>
    </v-snackbar>
  </v-row>
</template>
<script>

import SingleDriveFolder from '@/components/drive/folders_grid_view/SingleDriveFolder.vue'
import ConfirmDelete from '@/components/shared/ConfirmDelete.vue'

import useCompanyDriveFolder from '@/composables/useCompanyDriveFolder.js'

import { handler as errHandler } from '@/classes/ErrorHandler.js'

import { i18n } from '@/i18n.js'

import { useRouter } from 'vue-router/composables'
import { ref, computed } from 'vue'
export default {
  components: {
    'single-drive-folder': SingleDriveFolder,
    'confirm-delete': ConfirmDelete
  },
  props: {
    apiKey: {
      type: String,
      required: true
    }
  },
  setup(props, { emit }) {
    const router = useRouter()
    const deleteDialog = ref(null)
    const showSnackBar = ref(false)
    const { isLoading, isFoldersLoading, driveFolders, pagination, loadFolders, loadNextPage, deleteFolder } = useCompanyDriveFolder(props.apiKey)

    // Methods
    const onFolderClick = (folder) => router.push({ name: 'files_folder', params: { folderId: folder.id } })
    const onEdit = (folder) => emit('edit-folder', folder)
    const onFolderShare = (folder) => {
      const route = router.resolve({ name: 'files_folder', params: { folderId: folder.id } })
      const absoluteUrl = `${window.location.origin}/${route.href}`;

      navigator.clipboard.writeText(absoluteUrl)
      showSnackBar.value = true
    }
    const onDelete = (folder) => {
      const title = i18n.t('drive.delete_folder', { folderName: folder.name })
      const content = i18n.t('drive.delete_folder_details')
      deleteDialog.value.open({ title, content })
        .then((shouldDelete) => {
          if (shouldDelete !== true) return
          deleteFolder(folder.id)
            .then(() => deleteDialog.value.close())
            .catch(errHandler)
        })
    }

    // Computed

    const showLoadMorePages = computed(() => {
      if (isFoldersLoading.value || isLoading.value) return false

      return driveFolders.value.length < pagination.value.total
    })

    const showLoadingIndicatorMorePages = computed(() => isFoldersLoading.value && pagination.page > 1)
    const showLoadingIndicatorFirsrPage = computed(() => isFoldersLoading.value && pagination.page === 1)
    loadFolders({ reset: true, useLoading: true })
    return {
      isFoldersLoading,
      showLoadingIndicatorMorePages,
      showLoadingIndicatorFirsrPage,
      deleteDialog,
      showLoadMorePages,
      driveFolders,
      showSnackBar,
      onFolderClick,
      onDelete,
      onEdit,
      onFolderShare,
      loadNextPage
    }
  }
}
</script>