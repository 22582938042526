<template>
  <v-row align="start" justify="start" class="full-height">
    <task-actions v-if="isDayDateRangeScope"
      :api-key="apiKey"
      :staff-account="staffAccount"
      @change-date="onChangeDate">
    </task-actions>
    <v-col lg="3" md="4" class="d-flex align-self-stretch px-0 pt-0" v-if="showSideBar">
      <tasks-layout-big-devices-side-content
        :api-key="apiKey"
        :staff-account="staffAccount">
      </tasks-layout-big-devices-side-content>
    </v-col>
    <v-col :lg="showSideBar ? 9 : 12" :md="showSideBar ? 8 : 12" class="d-flex align-self-stretch pt-0">
      <div class="d-flex flex-column full-width mb-8">
        <v-col class="flex-grow-0 flex-shrink-1 px-0">
          <filters-row ref="filtersRowRef"
            :api-key="apiKey"
            :staff-account="staffAccount"
            :date-range-scope="dateRangeScope"
            @change-account="onChangeAccount"
            @date-range-scope-changed="onDateRangeScopeChanged"
            @filters-changed="filterChanged">
          </filters-row>
        </v-col>
        <v-col class="flex-grow-1 flex-shrink-0 px-0">
          <jobs-calander-layout v-if="!isDayDateRangeScope"
            ref="jobsCalanderLayoutRef"
            :api-key="apiKey"
            :date-range-scope="dateRangeScope"
            :client-date="clientDate"
            :staff-account="staffAccount">
          </jobs-calander-layout>
          <tasks-list-layout v-else-if="showTasksList"
            :api-key="apiKey"
            :staff-account="staffAccount"
            show-job-name>
          </tasks-list-layout>
          <department-list-layout :api-key="apiKey" v-else>
          </department-list-layout>
        </v-col>
      </div>
    </v-col>
  </v-row>
</template>
<script>

import TasksLayoutBigDevicesSideContent from '@/components/tasks/layout/TasksLayoutBigDevicesSideContent.vue'
import TasksListLayout from '@/components/tasks/tasks_list/TasksListLayout.vue'
import TaskActions from '@/components/tasks/TaskActions.vue'
import FiltersRow from '@/components/tasks/filters/FiltersRow.vue'
import JobsCalanderLayout from '@/components/jobs_calander/JobsCalanderLayout.vue'
import DepartmentListLayout from '@/components/tasks/departments_list/DepartmentListLayout.vue'

import useDailyJobs from '@/composables/useDailyJobs.js'
import useAuth from '@/composables/useAuth.js'

import { dateToISO8601String } from '@/helpers/DateTime.js'

import { ref, computed, watch, nextTick } from 'vue'

export default {
  components: {
    'tasks-layout-big-devices-side-content': TasksLayoutBigDevicesSideContent,
    'tasks-list-layout': TasksListLayout,
    'task-actions': TaskActions,
    'filters-row': FiltersRow,
    'jobs-calander-layout': JobsCalanderLayout,
    'department-list-layout': DepartmentListLayout
  },
  props: {
    apiKey: {
      type: String,
      required: true
    },
    staffAccount: {
      type: Boolean,
      default: false
    },
    dateRangeScope: {
      type: String,
      required: true
    },
    clientDate: {
      type: Date,
      required: true
    }
  },
  setup(props, { emit }) {
    let lastFilters = {}
    const filtersRowRef = ref(null)

    const jobsCalanderLayoutRef = ref(null)

    const { jobsUiMode, departmentSelectedUiMode, applyFilters: applyJobsFilter } = useDailyJobs(props.apiKey)
    const { isUserAccountEmployee } = useAuth()
    // Methods
    const onChangeAccount = (newApiKey) => emit('change-account', newApiKey)
    const onChangeDate = (newDate) => emit('change-date', newDate)
    const onDateRangeScopeChanged = (dateRangeScope) => emit('date-range-scope-changed', dateRangeScope)

    const filterChanged = (filters) => {
      lastFilters = filters
      if (isDayDateRangeScope.value) {
        applyJobsFilter(filters)
      } else {
        const isoDate = dateToISO8601String(props.clientDate)
        jobsCalanderLayoutRef.value.filterChanged({ filters: filters.filters, selectedDate: isoDate })
      }
    }

    // Computed
    const isDayDateRangeScope = computed(() => props.dateRangeScope === 'day')
    const showSideBar = computed(() => isDayDateRangeScope.value && (jobsUiMode.value || departmentSelectedUiMode.value))
    const showTasksList = computed(() => isDayDateRangeScope.value && (jobsUiMode.value || departmentSelectedUiMode.value))
    watch(() => props.dateRangeScope, (newDateRangeScope, oldDateRangeScope) => {
      const isOldRangeCalander = oldDateRangeScope === 'week' || oldDateRangeScope === 'month'
      const isNewRangeCalander = newDateRangeScope === 'week' || newDateRangeScope === 'month'
      if (isOldRangeCalander && isNewRangeCalander) return
      nextTick(() => {
        if (isUserAccountEmployee.value) {
          filterChanged(lastFilters)
        } else {
          filtersRowRef.value.emitFilterChanged()
        }
      })
    })
    return {
      isDayDateRangeScope,
      jobsCalanderLayoutRef,
      filtersRowRef,
      isUserAccountEmployee,
      jobsUiMode,
      showSideBar,
      showTasksList,
      onChangeAccount,
      onChangeDate,
      onDateRangeScopeChanged,
      filterChanged
    }
  }
}
</script>