
import useAccount from '@/composables/useAccount.js'

import { batchCreate as batchCreateShortLinks } from '@/api_client/UrlShortener.js'

import { getClientDateFormatted, ISO8601StringToDate, ISO8601dateTimeStringToISODate } from '@/helpers/DateTime.js'

import { isString } from '@/helpers/Utils.js'

import { i18n } from '@/i18n.js'

const createShareContent = async ({ blogPost, apiKey, medium }) => {
  const shortLinks = await createShortLinks({ blogPost, apiKey })
  const result = { content: '', shortLinks }
  addPostCreator(blogPost, result)
  addPostCreationDate(blogPost, result)
  addPostTags(blogPost, result)
  addPostContent(blogPost, result)
  addPostImages(blogPost, result)
  addPostVideo(blogPost, result)
  addPostLikesCount(blogPost, result)
  addPostCommentsCount(blogPost, result)
  return result.content
}

const addPostCreator = (blogPost, result) => {
  const creator = blogPost.creator
  result.content += `${i18n.t('blog_post_share.post_by', { firstName: creator.first_name, lastName: creator.last_name })}
`
}

const addPostCreationDate = (blogPost, result) => {
  const { accountCountryCode } = useAccount()
  const countryCode = accountCountryCode.value
  const postDateIso = ISO8601dateTimeStringToISODate(blogPost.created_at)
  const fromDate = ISO8601StringToDate(postDateIso)
  const dateFormmated = getClientDateFormatted({ fromDate, countryCode })
  result.content += `${i18n.t('blog_post_share.created_at', { date: dateFormmated })}
`
}

const addPostTags = (blogPost, result) => {
  const tags = blogPost.tags
  if (!Array.isArray(tags)) return
  const tagsContent = tags.map((tag) => `#${tag}`).join(', ')
  result.content += `${i18n.t('blog_post_share.post_tags', { tags: tagsContent })}
`
}

const addPostContent = (blogPost, result) => {
  result.content += `${blogPost.content}
`
}

const addPostImages = (blogPost, result) => {
  const imageUrls = blogPost.image_urls
  if (!Array.isArray(imageUrls)) return
  result.content += `${i18n.t('blog_post_share.images')}
`
  imageUrls.forEach((image) => {
    const imageUrl = findShortLink({ url: image.url, shortLinks: result.shortLinks })
    result.content += `  \u2022 ${imageUrl}
`
  })
}

const addPostVideo = (blogPost, result) => {
  const videoUrl = blogPost.video_url
  if (!isString(blogPost.video_url)) return
  const videoUrlShort = findShortLink({ url: videoUrl, shortLinks: result.shortLinks })
  result.content += `${i18n.t('blog_post_share.video', { videoUrl: videoUrlShort })}
`
}
const addPostLikesCount = (blogPost, result) => {
  const showLikesCount = blogPost.likes_count > 0
  if (!showLikesCount) return
  result.content += `${i18n.t('blog_post_share.likes_count', { likesCount: blogPost.likes_count })}
`
}

const addPostCommentsCount = (blogPost, result) => {
  const showCommentsCount = blogPost.comments_count > 0
  if (!showCommentsCount) return
  result.content += `${i18n.t('blog_post_share.comments_count', { commentsCount: blogPost.comments_count })}
`
}

const gatherLinks = (blogPost) => {
  const links = []
  const blogImages = blogPost.image_urls
  if (Array.isArray(blogImages)) {
    blogImages.forEach((image) => links.push(image.url))
  }

  if (isString(blogPost.video_url)) {
    links.push(blogPost.video_url)
  }
  return links
}

const createShortLinks = async ({ blogPost, apiKey }) => {
  const contentLinks = gatherLinks(blogPost)
  if (contentLinks.length === 0) return contentLinks
  const response = await batchCreateShortLinks(apiKey, contentLinks)
  return response.data.urls
}

const findShortLink = ({ shortLinks, url }) => {
  const link = shortLinks.find((item) => item.url === url)
  return link ? link.short_url : url
}

export {
  createShareContent
}
