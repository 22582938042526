<template>
  <v-row class="menu-item" align="start" justify="center" @click="clickItem">
    <v-col cols="2" class="text-center align-self-stretch" :style="{ 'background-color': color }">
      <v-badge bordered color="red" dot :value="iconBadge" overlap>
        <v-icon color="white">
          {{ icon }}
        </v-icon>
      </v-badge>
    </v-col>
    <v-col cols="9" v-bind:class="{ 'py-0': subText }">
      <v-row align="start" justify="start" no-gutters>
        <v-col cols="12" class="main-text">
          {{ text }}
        </v-col>
        <v-col cols="12" class="sub-text" v-if="subText">
          {{ subText }}
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="1" class="texts-start pl-0 action-icon">
      <v-icon color="#616365">
        chevron_right
      </v-icon>
    </v-col>
  </v-row>
</template>
<style lang="scss">
.menu-item {
  border-radius: 8px;
  background-color: white;
  border: 1px solid #EDEDED;
  box-sizing: border-box;
  overflow: hidden;
  cursor: pointer;

  .main-text {
    font-size: 16px;
    font-weight: 500;
    padding-left: 6px;
  }

  .sub-text {
    font-size: 12px;
  }
}

.rtl {
  .action-icon {
    rotate: 180deg;
  }
}
</style>
<script>

import { useRouter } from 'vue-router/composables'

export default {
  props: {
    color: {
      type: String,
      required: true
    },
    icon: {
      type: String,
      required: true
    },
    text: {
      type: String,
      required: true
    },
    subText: {
      type: String,
      required: false
    },
    to: {
      type: String,
      required: false
    },
    iconBadge: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  setup(props) {
    const router = useRouter()
    const clickItem = () => {
      if (props.to) {
        router.push({ name: props.to })
      }
    }

    return {
      clickItem
    }
  }
}
</script>
