<template>
  <v-badge
    :color="allItemOperationListDone ? 'green' : 'red'"
    @click="openItemOperationList"
    v-if="hasItemOperationList"
    :overlap="overlap"
    right top>
    <!-- eslint-disable-next-line vue/no-useless-template-attributes -->
    <template v-slot:badge @click="openItemOperationList">
        <v-icon v-if="allItemOperationListDone">
          done
        </v-icon>
        <span v-else class="pointer">{{itemOperationListDoneVsTotal}}</span>
    </template>
    <v-icon @click="openItemOperationList">
      format_list_bulleted
    </v-icon>
  </v-badge>
</template>
<style lang="scss">
span.v-badge__badge {
  padding-left: 2px;
  padding-right: 2px;
}
</style>
<script>
import { itemTypes } from '@/models/ItemOperationListItemTypes.js'

import { computed } from 'vue'

export default {
  props: {
    itemsList: {
      type: Array,
      required: false,
      default: () => []
    },
    overlap: {
      type: Boolean,
      default: false
    }
  },
  setup (props, { emit }) {
    const reportableItem = (itemOpList) => itemOpList.item_type !== itemTypes.title && itemOpList.item_type !== itemTypes.feedback

    // Methods
    const openItemOperationList = () => emit('open-item-operation-list')

    // Computed
    const hasItemOperationList = computed(() => (props.itemsList || []).filter(reportableItem).length > 0)
    const itemOperationListDoneVsTotal = computed(() => {
      const list = (props.itemsList || []).filter(reportableItem)
      const done = list.filter((item) => item.bool_value === true && Number.isInteger(item.report_id))
      return `${done.length}/${list.length}`
    })
    const allItemOperationListDone = computed(() => {
      const list = (props.itemsList || []).filter(reportableItem)
      return list.every((item) => item.bool_value === true && Number.isInteger(item.report_id))
    })

    return {
      openItemOperationList,
      hasItemOperationList,
      itemOperationListDoneVsTotal,
      allItemOperationListDone
    }
  }
}
</script>
