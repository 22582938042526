import { search as usersSearch, searchCrossAccount as usersSearchCrossAccount, searchForCompany as userSearchForCompany } from '@/api_client/User.js'

import { isString } from '@/helpers/Utils.js'

import { ref } from 'vue'

const allUsers = ref([])
const allUsersWithHQ = ref([])
const allUsersCrossAccount = ref([])
const allCompanyUsers = ref([])

const isLoading = ref(false)
const isLoadingUsersWithHQ = ref(false)
const isLoadingCrossAccount = ref(false)
const isLoadingCompanyUsers = ref(false)

let currentApiKey = null
let shouldReload = true
let shouldReloadWithHq = true

const invalidateCache = () => {
  shouldReload = true
  shouldReloadWithHq = true
}

export default function useUserSearch(apiKey) {
  const resetValues = () => {
    isLoading.value = false
    isLoadingUsersWithHQ.value = false
    isLoadingCrossAccount.value = false
    isLoadingCompanyUsers.value = false
    allUsers.value.splice(0, allUsers.value.length)
    allUsersWithHQ.value.splice(0, allUsersWithHQ.value.length)
    allUsersCrossAccount.value.splice(0, allUsersCrossAccount.value.length)
    allCompanyUsers.value.splice(0, allCompanyUsers.value.length)
    shouldReload = true
    shouldReloadWithHq = true
  }

  if (isString(apiKey) && currentApiKey !== apiKey) {
    resetValues()
    currentApiKey = apiKey
  }

  // Methods
  const loadAllUsers = () => {
    if (shouldReload === false) return
    isLoading.value = true
    return usersSearch({ apiKey: currentApiKey, limit: 100 })
      .then((response) => {
        const allUsersVal = allUsers.value
        allUsersVal.splice(0, allUsersVal.length)
        response.data.data.forEach((user) => allUsersVal.push(user))
        shouldReload = false
      })
      .finally(() => (isLoading.value = false))
  }

  const loadAllUsersWithHq = () => {
    if (shouldReloadWithHq === false) return
    isLoadingUsersWithHQ.value = true
    return usersSearch({ apiKey: currentApiKey, limit: 100, withHq: true })
      .then((response) => {
        const allUsersWithHQVal = allUsersWithHQ.value
        allUsersWithHQVal.splice(0, allUsersWithHQVal.length)
        response.data.data.forEach((user) => allUsersWithHQVal.push(user))
        shouldReloadWithHq = false
      })
      .finally(() => (isLoadingUsersWithHQ.value = false))
  }

  const loadAllUsersCrossAccount = () => {
    isLoadingCrossAccount.value = true
    return usersSearchCrossAccount({ apiKey: currentApiKey, limit: 100 })
      .then((response) => {
        const allUsersCrossAccountVal = allUsersCrossAccount.value
        allUsersCrossAccountVal.splice(0, allUsersCrossAccountVal.length)
        response.data.data.forEach((user) => allUsersCrossAccountVal.push(user))
      })
      .finally(() => (isLoadingCrossAccount.value = false))
  }

  const loadAllCompanyUsers = () => {
    isLoadingCompanyUsers.value = true
    return userSearchForCompany({ apiKey: currentApiKey, limit: 100 })
      .then((response) => {
        const allCompanyUsersVal = allCompanyUsers.value
        allCompanyUsersVal.splice(0, allCompanyUsersVal.length)
        response.data.data.forEach((user) => allCompanyUsersVal.push(user))
      })
      .finally(() => (isLoadingCompanyUsers.value = false))
  }

  const changeApiKey = (newApiKey) => {
    if (isString(newApiKey) && currentApiKey !== newApiKey) {
      resetValues()
      currentApiKey = newApiKey
    }
  }

  return {
    isLoading,
    isLoadingUsersWithHQ,
    isLoadingCrossAccount,
    isLoadingCompanyUsers,
    allUsers,
    allUsersWithHQ,
    allUsersCrossAccount,
    allCompanyUsers,
    loadAllUsers,
    loadAllUsersWithHq,
    loadAllUsersCrossAccount,
    loadAllCompanyUsers,
    changeApiKey
  }
}

export {
  invalidateCache
}
