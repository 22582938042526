<template>
  <v-menu close-on-content-click>
    <template v-slot:activator="{ on }">
      <slot name="button" v-if="haveSlots" v-bind:on="on">
      </slot>
      <v-icon v-on="on" color="black" class="pl-4" v-else>more_vert</v-icon>
    </template>
    <v-list class="edit-operation">
      <v-list-item ripple @click="onEdit" v-if="showEdit">
        <v-list-item-icon>
          <v-icon small>edit</v-icon>
        </v-list-item-icon>
        <v-list-item-title>{{ $t('edit') }}</v-list-item-title>
      </v-list-item>
      <v-list-item ripple @click="onDelete" v-if="showDelete">
        <v-list-item-icon>
          <v-icon small>delete</v-icon>
        </v-list-item-icon>
        <v-list-item-title>{{ $t('delete') }}</v-list-item-title>
      </v-list-item>
      <v-list-item ripple @click="onCopy" v-if="showCopy">
        <v-list-item-icon>
          <v-icon small>content_copy</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ $t('copy') }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item ripple @click="onShare" v-if="showShare">
        <v-list-item-icon>
          <v-icon small>share</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ $t('share') }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item ripple @click="onNotify" v-if="showNotification">
        <v-list-item-icon>
          <v-icon small>notifications</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ $t('notifications') }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-menu>
</template>
<style lang="scss" scoped>
.edit-operation {
  .v-list-item__icon {
    margin-right: 12px !important;
  }
}
</style>
<script>
import { computed } from 'vue'
export default {
  props: {
    showCopy: {
      type: Boolean,
      required: true,
      default: true
    },
    showDelete: {
      type: Boolean,
      required: true,
      default: true
    },
    showEdit: {
      type: Boolean,
      required: true,
      default: true
    },
    showShare: {
      type: Boolean,
      required: true,
      default: true
    },
    showNotification: {
      type: Boolean,
      required: true,
      default: true
    }
  },
  setup(_, { emit, slots }) {
    const onEdit = () => emit('edit-task')
    const onDelete = () => emit('delete-task')
    const onCopy = () => emit('copy-task')
    const onShare = () => emit('share-task')
    const onNotify = () => emit('notify-task')
    const haveSlots = computed(() => !!slots.button)
    return {
      onEdit,
      onDelete,
      onCopy,
      onShare,
      onNotify,
      haveSlots
    }
  }
}
</script>
