<template>
  <v-row align="start" justify="space-between" no-gutters class="post-header">
    <v-col cols="8" md="9" class="d-flex align-start justify-start">
      <v-col class="flex-grow-0 flex-shrink-1 pa-0">
        <single-blog-post-avatar :creator="post.creator" v-if="hideAvatar !== true"></single-blog-post-avatar>
      </v-col>
      <v-col class="flex-grow-1 flex-shrink-0 pa-0">
        <div class="user-name t-600-18">
          {{ post.creator.first_name }} {{ post.creator.last_name }}
          <span v-if="showTags" class="color8 t-400-14">
            #{{ post.tags[0] }}
          </span>
        </div>
        <div class="t-400-12 color5 post-time">
          {{ post.creationDateFormmated }}
        </div>
      </v-col>
    </v-col>
    <v-col cols="4" md="3" class="texts-end">
      <single-blog-post-menu-options
        :post="post"
        @delete-post="deletePost"
        @edit-post="editPost">
      </single-blog-post-menu-options>
    </v-col>
  </v-row>
</template>
<style lang="scss">
.post-header {
  .user-name {
    vertical-align: top;
    line-height: 26px;
  }

  .post-time {
    vertical-align: top;
    line-height: 12px;
  }
}
</style>
<script>
import { computed } from 'vue'
export default {
  components: {
    'single-blog-post-menu-options': () => import('@/components/blog/single_blog_post/SingleBlogPostMenuOptions.vue'),
    'single-blog-post-avatar': () => import('@/components/blog/shared/SingleBlogPostAvatar.vue')
  },
  props: {
    post: {
      type: Object,
      required: true
    },
    hideAvatar: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit }) {
    // Computed
    const showTags = computed(() => Array.isArray(props.post.tags) && props.post.tags.length > 0)

    // Methods
    const deletePost = () => emit('delete-post')
    const editPost = () => emit('edit-post')

    return {
      showTags,
      deletePost,
      editPost
    }
  }
}
</script>
