import Client from '@/api_client/client.js'

const indexOperationsByDay = (apiKey, params) => {
  const ACCOUNT_INDEX_OPERATIONS_BY_DAY_PATH = `/accounts/${apiKey}/accounts/operation_schedules/index_operation_by_day`
  return Client.post(ACCOUNT_INDEX_OPERATIONS_BY_DAY_PATH, params)
}

const dailyStats = (apiKey, params) => {
  const ACCOUNT_DAILY_STATS_PATH = `/accounts/${apiKey}/accounts/operation_schedules/daily_stats`
  return Client.get(ACCOUNT_DAILY_STATS_PATH, { params })
}

const dailyJobs = async (apiKey, params) => {
  const DAILY_JOBS = `/accounts/${apiKey}/accounts/operation_schedules/index_daily_jobs`
  return Client.post(DAILY_JOBS, params)
}

export {
  indexOperationsByDay,
  dailyStats,
  dailyJobs
}
