<template>
  <v-row align="start" justify="start" v-if="isLoading">
    <v-col cols="4" v-for="i in 6" :key="i">
      <v-skeleton-loader class="my-4"
        type="card">
      </v-skeleton-loader>
    </v-col>
  </v-row>
  <v-row align="start" justify="start" v-else class="mt-4">
    <v-col cols="6" md="4" lg="3">
      <div class="row justify-space-between px-1 pb-4 t-500-16">
        <span>{{ $t('tasks_page.progress') }}</span>
        <span class="ltr d-inline-block">{{ stats.finishedTasksCount }} / {{ stats.tasksCount }}</span>
      </div>
      <tasks-progress
        hide-numbers with-border
        :is-loading="isLoading"
        :tasks-count="stats.tasksCount"
        :finished-tasks-count="stats.finishedTasksCount">
      </tasks-progress>
    </v-col>
    <v-col cols="12" class="pa-0">
    </v-col>
    <v-col cols="6" md="4" lg="3" v-for="department in departmentsList"
      :key="department.department_id">
      <single-department :department="department" class="pointer"
        @department-click="onDepartmentClick">
      </single-department>
    </v-col>
  </v-row>
</template>
<script>

import JobsListLayoutBigDevices from '@/components/tasks/jobs_list/JobsListLayoutBigDevices.vue'
import SingleDepartment from '@/components/tasks/departments_list/SingleDepartment.vue'
import TasksProgress from '@/components/tasks/TasksProgress.vue'

import useDailyJobs from '@/composables/useDailyJobs.js'

import { ref, computed } from 'vue'
export default {
  props: {
    apiKey: {
      type: String,
      required: true
    }
  },
  components: {
    'jobs-list-layout-big-devices': JobsListLayoutBigDevices,
    'single-department': SingleDepartment,
    'tasks-progress': TasksProgress
  },
  setup(props) {
    const panel = ref(null)
    const { isLoading, stats, departmentsList, activeDepartmentJobs, changeActiveDepartment } = useDailyJobs(props.apiKey)
    const onDepartmentClick = changeActiveDepartment

    return {
      panel,
      isLoading,
      departmentsList,
      activeDepartmentJobs,
      stats,
      onDepartmentClick
    }
  }
}
</script>