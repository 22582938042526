<template>
  <page-filters
    :any-filter-selected="userSelected"
    :right="!isRtl"
    :left="isRtl"
    @apply-filters="applyFilters"
    @reset-filters="clearSearch" class="employees-filter">
    <user-search
      v-model="curentUserSearch"
      :api-key="apiKey"
      :menu-props="{ contentClass: 'inside-drawer' }">
    </user-search>
  </page-filters>
</template>
<style lang="scss">
.employees-filter {
  top: 132px;

  @media only screen and (max-width: 600px) {
    top: 202px;
  }
}
</style>
<script>

import UserSearch from '@/components/shared/user_search/UserSearch.vue'
import PageFilters from '@/components/shared/PageFilters.vue'

import useEmployeeStore from '@/components/organization_structure/employee/useEmployeeStore.js'
import { isRtl } from '@/i18n.js'

import { isObject } from '@/helpers/Utils.js'
import { handler as errHandler } from '@/classes/ErrorHandler.js'

import { ref, computed } from 'vue'
export default {
  components: {
    'user-search': UserSearch,
    'page-filters': PageFilters
  },
  props: {
    apiKey: {
      type: String,
      required: true
    }
  },
  setup(props) {
    const curentUserSearch = ref({})
    const { loadUsers, showSingleUser } = useEmployeeStore(props.apiKey)
    // Methods
    const applyFilters = () => {
      const promise = userSelected.value === true ? showSingleUser(curentUserSearch.value.id) : loadUsers()
      promise.catch(errHandler)
    }

    const clearSearch = () => {
      curentUserSearch.value = {}
      applyFilters()
    }

    // Computed
    const userSelected = computed(() => isObject(curentUserSearch.value) && Number.isInteger(curentUserSearch.value.id))
    return {
      curentUserSearch,
      userSelected,
      isRtl,
      applyFilters,
      clearSearch
    }
  }
}
</script>
