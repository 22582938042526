<template>
  <v-row align="start" justify="start" class="full-height" no-gutters>
    <v-col cols="4" lg="4" md="5" class="d-flex align-self-stretch">
      <chat-groups-list :api-key="apiKey"
        @click-chat-group="onClickChatGroup"
        @search-selected="onSearchSelected">
      </chat-groups-list>
    </v-col>
    <v-col cols="8" lg="8" md="7" class="d-flex align-self-stretch">
      <group-conversation-layout :api-key="apiKey" v-if="isAnyConversationSelected"></group-conversation-layout>
    </v-col>
  </v-row>
</template>

<script>

import ChatGroupsList from '@/components/app_chat/ChatGroupsList.vue'
import GroupConversationLayout from '@/components/app_chat/group_conversation/GroupConversationLayout.vue'

import useAppChat from '@/composables/useAppChat.js'

export default {
  props: {
    apiKey: {
      type: String,
      required: true
    }
  },
  components: {
    'chat-groups-list': ChatGroupsList,
    'group-conversation-layout': GroupConversationLayout
  },
  setup(props) {
    const { changeActiveGroup, startPrivateChat, isAnyConversationSelected } = useAppChat(props.apiKey)
    // Methods

    const onClickChatGroup = changeActiveGroup
    const onSearchSelected = ({ isGroup, eventData }) => {
      if (isGroup === true) {
        onClickChatGroup(eventData)
        return
      }
      startPrivateChat(eventData)
    }
    return {
      isAnyConversationSelected,
      onClickChatGroup,
      onSearchSelected
    }
  }
}
</script>
