import Client from '@/api_client/client.js'
import { setIsFirstXJobsLoaded } from '@/components/job_selection_search/JobSelectionStore.js'
import { removeJobWorkingDays } from '@/store/modules/AccountJobWorkDays.js'

import { EventBus, ACCOUNT_JOB_CREATE, ACCOUNT_JOB_UPDATE, ACCOUNT_JOB_DELETE } from '@/EventBus.js'
function show(apiKey, id) {
  const JOBS_SHOW_PATH = `/accounts/${apiKey}/account_jobs/${id}/`
  return Client.get(JOBS_SHOW_PATH)
}

function index(apiKey, params) {
  const JOBS_INDEX_PATH = `/accounts/${apiKey}/account_jobs`
  return Client.put(JOBS_INDEX_PATH, params)
}

async function create(apiKey, params) {
  const JOBS_CREATE_PATH = `/accounts/${apiKey}/account_jobs`
  const response = await Client.post(JOBS_CREATE_PATH, params)
  resetCaches(apiKey, ACCOUNT_JOB_CREATE)
  return response
}

async function update(apiKey, id, params) {
  const JOBS_PUT_PATH = `/accounts/${apiKey}/account_jobs/${id}/`
  const response = await Client.put(JOBS_PUT_PATH, params)
  resetCaches(apiKey, ACCOUNT_JOB_UPDATE)
  removeJobWorkingDays(id)
  return response
}

async function deleteJob(apiKey, id) {
  const JOBS_DELETE_PATH = `/accounts/${apiKey}/account_jobs/${id}/`
  const response = await Client.delete(JOBS_DELETE_PATH)
  resetCaches(apiKey, ACCOUNT_JOB_DELETE)
  return response
}

function search(apiKey, query) {
  const JOBS_SEARCH_PATH = `/accounts/${apiKey}/account_jobs/search/`
  return Client.get(JOBS_SEARCH_PATH, { params: query })
}

function companySearch({ apiKey, query, hqTaskGroupId, limit = 10 }) {
  const JOBS_SEARCH_PATH = `/accounts/${apiKey}/account_jobs/company_search/`
  return Client.get(JOBS_SEARCH_PATH, { params: { name: query, per_page: limit, hq_task_group_id: hqTaskGroupId } })
}

function workingDays(apiKey, jobId) {
  const JOBS_WORKING_DAYS_PATH = `/accounts/${apiKey}/account_jobs/${jobId}/working_days`
  return Client.get(JOBS_WORKING_DAYS_PATH)
}

function resetCaches(apiKey, actionName) {
  setIsFirstXJobsLoaded({ apiKey, isLoaded: false })
  EventBus.emit(actionName)
}

export {
  show,
  index,
  create,
  update,
  deleteJob,
  search,
  companySearch,
  workingDays
}
