<template>
  <v-dialog v-model="dialog" max-width="600" persistent>
    <v-card class="org-struct-supplier-new">
      <v-card-title class="text-center pb-0">
        <span class="headline" style="width: 100%">
          {{ dialogTitle }}
        </span>
      </v-card-title>
      <v-card-text>
        <v-form ref="newSupplierForm">
          <v-container grid-list-md>
            <v-row align="start" justify="space-around">
              <v-col cols="12" sm="5">
                <v-textarea
                  :rules="[requiredField]"
                  counter="120"
                  v-model="newSupplier.name"
                  :label="$t('org_supplier_new_dialog.supplier_name')"
                  auto-grow
                  no-resize
                  rows="1">
                </v-textarea>
              </v-col>
              <v-col cols="12" sm="5">
                <v-text-field
                  v-model="newSupplier.email"
                  single-line
                  counter="320"
                  :label="$t('email')">
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="5">
                <v-text-field
                  v-model="newSupplier.phone"
                  single-line
                  counter="20"
                  v-mask="'##############'"
                  :label="$t('org_supplier_new_dialog.supplier_phone')">
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="5">
                <v-text-field
                  v-model="newSupplier.order_phone"
                  single-line
                  counter="20"
                  v-mask="'##############'"
                  :label="$t('org_supplier_new_dialog.supplier_order_phone')">
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <v-card-actions class="pt-0">
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="close(false)" :disabled="isLoading">
                    {{$t('cancel')}}
                  </v-btn>
                  <v-btn color="blue darken-1" depressed tile @click="save" :loading="isLoading" :disabled="isSaveDisabled">
                    {{$t('save')}}
                  </v-btn>
                </v-card-actions>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import { requiredField } from '@/classes/InputValidators.js'
import { newSupplierModel } from '@/store/modules/OrganizationSupplier.js'
import useAccountSupplier from '@/composables/useAccountSupplier.js'
import useAuth from '@/composables/useAuth.js'

import { onDialogClose, onDialogOpen } from '@/classes/DialogScrollBug.js'

import { mask } from 'vue-the-mask'

import { i18n } from '@/i18n.js'

import { ref, computed } from 'vue'

const DialogMode = { create: 0, update: 1 }

export default {
  directives: {
    mask
  },
  setup () {
    const dialog = ref(false)
    const isLoading = ref(false)
    const newSupplierForm = ref(null)
    const newSupplier = ref({})
    const { apiKey } = useAuth()
    const dialogMode = ref(null)
    let resolveFunc = null
    const { createSupplier, updateSupplier } = useAccountSupplier(apiKey.value)

    const openDialog = (supplier, dialogModeVal) => {
      onDialogOpen()
      dialog.value = true
      dialogMode.value = dialogModeVal
      newSupplier.value = newSupplierModel(supplier)
      return new Promise((resolve, reject) => {
        resolveFunc = resolve
      })
    }

    const createNewSupplier = () => {
      const creationParams = newSupplier.value.serialize()
      createSupplier(creationParams)
        .then(() => close(true))
        .finally(() => (isLoading.value = false))
    }

    const updateSupplierCall = () => {
      const updateParams = newSupplier.value.serialize()
      updateSupplier(updateParams)
        .then(() => close(true))
        .finally(() => (isLoading.value = false))
    }
    // Methods

    const openForUpdate = (supplier) => openDialog(supplier, DialogMode.update)
    const openForCreate = () => openDialog({}, DialogMode.create)

    const close = (resolveResult) => {
      onDialogClose()
      dialogMode.value = null
      dialog.value = false
      newSupplierForm.value.reset()
      resolveFunc(resolveResult)
    }
    const save = () => {
      if (!newSupplierForm.value.validate()) return

      isLoading.value = true
      if (dialogMode.value === DialogMode.update) {
        updateSupplierCall()
      } else {
        createNewSupplier()
      }
    }

    // Computed
    const dialogTitle = computed(() => dialogMode.value === DialogMode.update
      ? i18n.t('org_supplier_new_dialog.update_title')
      : i18n.t('org_supplier_new_dialog.create_title')
    )
    const isSaveDisabled = computed(() => {
      if (isLoading.value) return true
      if (!newSupplier.value.name) return true
      return false
    })
    return {
      dialog,
      newSupplier,
      newSupplierForm,
      isLoading,
      dialogTitle,
      isSaveDisabled,
      openForUpdate,
      openForCreate,
      close,
      save,
      requiredField
    }
  }
}
</script>
