import Vue from 'vue'
import { newDeviceOperationModel } from '@/store/modules/DeviceOperation.js'
import { newOperationScheduleModel } from '@/store/modules/OperationSchedule.js'
import { translatedOperation as OpTypeTranslatedOperation } from '@/composables/useOperationType.js'
import { daysOfTheWeek } from '@/helpers/DateTime.js'
const filedList = ['id', 'name', 'email', 'phone', 'order_phone', 'avatar_image']
function SupplierModel (src) {
  const srcOrEmpty = src || {}
  filedList.forEach((field) => {
    this[field] = srcOrEmpty[field]
  })
  Object.assign(this, {
    operations: srcOrEmpty.operations || [],
    operationSchedule: srcOrEmpty.operation_schedule || {}
  })
  if (this.operations.length > 0) {
    this.operations = getModuleSortedOperations(this.operations)
  }
  if (Object.keys(this.operationSchedule).length > 0) {
    getOperationSchedule(this.operationSchedule)
  }
}
SupplierModel.prototype.serialize = function () {
  const creationParams = JSON.parse(JSON.stringify(this))
  const result = filedList.reduce((initValue, currentValue) => {
    initValue[currentValue] = creationParams[currentValue]
    return initValue
  }, {})
  delete result.avatar_image
  return result
}

SupplierModel.prototype.update = function (supplier) {
  const updatedOperations = getModuleSortedOperations(supplier.operations)
  Vue.set(this, 'operations', updatedOperations)
  const operationSchedule = supplier.operation_schedule || {}
  if (Object.keys(this.operationSchedule).length === 0 && Object.keys(operationSchedule).length === 0) return
  getOperationSchedule(operationSchedule)
  for (const dayName in operationSchedule) { // eslint-disable-line no-unused-vars
    Vue.set(this.operationSchedule, dayName, operationSchedule[dayName])
  }
}

function newSupplierModel (src) {
  return new SupplierModel(src)
}
function getModuleSortedOperations (operations) {
  return operations.map((operation) => newDeviceOperationModel(operation))
    .sort((op1, op2) => {
      const opr1Order = op1.getOrder()
      const opr2Order = op2.getOrder()
      if (opr1Order === opr2Order) return 0
      return opr1Order > opr2Order ? 1 : -1
    })
}

function getOperationSchedule (operationSchedule) {
  daysOfTheWeek().forEach((dayOfWeek) => {
    const dayName = dayOfWeek.name
    if (!operationSchedule[dayName]) operationSchedule[dayName] = []
    operationSchedule[dayName] = operationSchedule[dayName].map((operation) => newOperationScheduleModel(operation))
  })
}

function defaultOperations () {
  const operations = [
    { operation_type: 'stock_order' },
    { operation_type: 'stock_receiving' },
    { operation_type: 'stock_encoding_and_arrangement' },
    { operation_type: 'stock_refund' }
  ]
  operations.forEach((op) => (op.operationTypeText = OpTypeTranslatedOperation(op.operation_type)))
  return operations
}
export {
  newSupplierModel, defaultOperations
}
