<template>
  <div>
    <div v-for="(file, index) in files" class="pb-4">
      <v-chip v-if="showFileAsTextInput(file)" close @click:close="removeFile(index)" class="file-chip file-attached">
        <v-icon color="green" @click="openFileUrl(file)">{{ isVideoFile ? 'videocam' : 'attach_file' }}</v-icon>
        <span class="px-2 text-truncate">{{ fileName(file) }}</span>
      </v-chip>
      <v-chip :close="isFileAttached(file)" v-else @click:close="removeFile(index)" class="file-chip"
        :class="{ 'file-attached': isFileAttached(file) }">
        <v-file-input class="file-input" hide-input :accept="isVideoFile ? 'video/mp4,video/x-m4v,video/*' : '*/*'"
          :prepend-icon="isVideoFile ? 'videocam' : 'attach_file'" :rules="sizeValidationRules"
          v-model="file.attachedFile" @change="event => onFileChanged(index, event)">
        </v-file-input>
        <span class="px-2 text-truncate">{{ fileName(file) }}</span>
      </v-chip>
      <div v-if="showSizeLimitError(file)" class="error--text">{{ $t('schedule_new_op_dialog.max_file_size') }}</div>
    </div>
  </div>
</template>
<style lang="scss">
.file-chip {
  padding-right: 36px;
  max-width: 300px !important;

  .file-input {
    margin-top: 0;
    padding-top: 0;
  }

  &.file-attached {
    padding-right: 16px;

    .file-input {
      .v-input__icon--prepend {
        button.v-icon {
          color: #4CAF50;
        }
      }
    }
  }
}
</style>
<script>
import { recordTypes, removeMultipleFilesApiCall, addMultipleFilesApiCall } from '@/classes/FileUploaderApiCalls.js'
import { isFile, isString } from '@/helpers/Utils.js'

import { ref, computed } from 'vue'

import { i18n } from '@/i18n.js'

const MAX_FILE_SIZE = 1024 * 1024 * 10 // 10MB
const MAX_VIDEO_FILE_SIZE = 1024 * 1024 * 80 // 10MB

export default {
  props: {
    recordType: {
      type: String,
      required: true,
      validator: function (recordType) {
        return Object.values(recordTypes).indexOf(recordType) !== -1
      }
    },
    placeholder: {
      type: String,
      default: null
    },
    maxFiles: {
      type: Number,
      required: true
    },
  },
  setup(props) {
    const removedFiles = ref([])
    const files = ref([])

    const getFile = (index) => (files.value[index])
    const newFile = () => {
      return {
        fileId: null,
        attachedFile: null,
        url: null,
      }
    }
    const addEmptyFileIfNeeded = () => {
      const addNewFile = files.value.length < props.maxFiles && hasEmptyFile.value !== true
      if (addNewFile !== true) return
      files.value.push(newFile())
    }

    // Methods
    const showFileAsTextInput = (file) => Number.isInteger(file.fileId)
    const openFileUrl = (file) => window.open(file.url, '_blank').focus()

    const onFileChanged = (index, userFile) => {
      if (!isFile(userFile)) return

      const fileRef = getFile(index)
      fileRef.attachedFile = userFile
      addEmptyFileIfNeeded()
    }


    const reset = () => {
      files.value = []
      removedFiles.value.splice(0, removedFiles.length)
    }
    const sizeValidationRules = [
      (value) => {
        if (typeof value === 'undefined' || value === null || (value.size > 0 && value.size < maxFileSize.value)) return true
        return i18n.t('schedule_new_op_dialog.max_file_size')
      }
    ]


    const isFileAttached = (file) => isFile(file.attachedFile)
    const removeFile = (index) => {
      const fileToRemove = getFile(index)
      if (Number.isInteger(fileToRemove.fileId)) removedFiles.value.push(fileToRemove.fileId)

      files.value.splice(index, 1)
      addEmptyFileIfNeeded()
    }

    const showSizeLimitError = (file) => {
      if (isFileAttached(file) !== true) return false
      return file.attachedFile.size > maxFileSize.value
    }

    const setFiles = (filesData) => {
      reset()
      if (Array.isArray(filesData) === true) {
        filesData.forEach((fileData) => {
          const file = newFile()

          file.url = fileData.url
          file.fileId = fileData.id
          files.value.push(file)
        })
      }
      addEmptyFileIfNeeded()
    }

    const fileName = (file) => {
      if (isFile(file.attachedFile)) return file.attachedFile.name
      if (isString(file.url)) {
        const name = file.url.split('/').pop()
        return name.split('?')[0];
      }
      return i18n.t('file_attach')
    }

    const saveFiles = async ({ apiKey, userId }) => {
      if (removedFiles.value.length > 0) {
        await removeMultipleFilesApiCall({ apiKey, userId, recordType: props.recordType, fileIds: removedFiles.value })
      }
      const filesToUpload = files.value.filter(file => isFileAttached(file)).map((file) => file.attachedFile)
      if (filesToUpload.length > 0) {
        return addMultipleFilesApiCall({ apiKey, userId, recordType: props.recordType, attachedFiles: filesToUpload })
      }
    }

    // Computed
    const maxFileSize = computed(() => isVideoFile.value ? MAX_VIDEO_FILE_SIZE : MAX_FILE_SIZE)
    const isVideoFile = computed(() => props.recordType === recordTypes.brokenItemVideo)
    const hasEmptyFile = computed(() => files.value.some(file => file.attachedFile === null && !Number.isInteger(file.fileId)))

    const hasChanges = computed(() => files.value.some(file => file.attachedFile !== null || removedFiles.value.length > 0))

    return {
      isVideoFile,
      files,
      sizeValidationRules,
      hasChanges,
      showSizeLimitError,
      onFileChanged,
      reset,
      showFileAsTextInput,
      openFileUrl,
      isFileAttached,
      removeFile,
      setFiles,
      fileName,
      saveFiles
    }
  }
}
</script>

<style></style>
