import Client from '@/api_client/client.js'
import CompanyProviderSearchStore from '@/components/shared/company_provider_search/CompanyProviderSearchStore.js'

const search = (apiKey, searchTerm, limit) => {
  const PROVIDERS_SEARCH_PATH = `/accounts/${apiKey}/company_providers/search`
  return Client.get(PROVIDERS_SEARCH_PATH, { params: { name: searchTerm, per_page: limit } })
}
const show = (apiKey, id) => {
  const PROVIDERS_SHOW_PATH = `/accounts/${apiKey}/company_providers/${id}`
  return Client.get(PROVIDERS_SHOW_PATH)
}
const index = (apiKey, params) => {
  const PROVIDERS_INDEX_PATH = `/accounts/${apiKey}/company_providers`
  return Client.get(PROVIDERS_INDEX_PATH, { params })
}
const create = async (apiKey, params) => {
  const PROVIDERS_CREATE_PATH = `/accounts/${apiKey}/company_providers`
  const response = await Client.post(PROVIDERS_CREATE_PATH, params)
  invalidProviderSearchCache(apiKey)
  return response
}
const update = async (apiKey, id, params) => {
  const PROVIDERS_UPDATE_PATH = `/accounts/${apiKey}/company_providers/${id}/`
  const response = await Client.put(PROVIDERS_UPDATE_PATH, params)
  invalidProviderSearchCache(apiKey)
  return response
}
const destroy = async (apiKey, id) => {
  const PROVIDERS_DELETE_PATH = `/accounts/${apiKey}/company_providers/${id}/`
  const response = await Client.delete(PROVIDERS_DELETE_PATH)
  invalidProviderSearchCache(apiKey)
  return response
}

const invalidProviderSearchCache = (apiKey) => {
  const { invalidateCache } = CompanyProviderSearchStore(apiKey)
  invalidateCache()
}
export {
  search,
  show,
  index,
  create,
  update,
  destroy
}
