<template>
  <span @click="onClick">
    <v-avatar color="indigo" :size="size">
      <img :src="userAvatarUrl" v-if="showUserAvatarImage">
      <span class="white--text text-h5" v-else>{{ userInitials }}</span>
    </v-avatar>
    <span v-if="showNameAndAvatar" class="mms-1 t-600-18">
      {{ user?.first_name }} {{ user?.last_name }}
    </span>
  </span>
</template>
<script>
import { ref, computed } from 'vue'
import { me as userClientCurrentUser } from '@/api_client/User.js'
import { isString } from '@/helpers/Utils.js'
import useAuth from '@/composables/useAuth.js'
import { handler as errHandler } from '@/classes/ErrorHandler.js'

export default {
  props: {
    size: {
      type: Number | String,
      default: 66
    },
    showNameAndAvatar: {
      type: Boolean,
      default: false
    }
  },
  setup(_, { emit }) {
    const user = ref(null)
    const { apiKey } = useAuth()
    const showUserAvatarImage = computed(() => isString(user.value?.avatar_url))
    const userAvatarUrl = computed(() => user.value?.avatar_url)
    const userInitials = computed(() => {
      if (user.value === null) return ''
      return `${user.value.first_name.charAt(0)}${user.value.last_name.charAt(0)}`
    })

    // Methods
    const onClick = () => emit('click')
    userClientCurrentUser(apiKey.value)
      .then(response => user.value = response.data.data)
      .catch(errHandler)

    return {
      user,
      showUserAvatarImage,
      userAvatarUrl,
      userInitials,
      onClick
    }
  }
}
</script>