<template>
  <v-row align="center" justify="start"
    :class="{ 'selected': selected }"
    class="single-account elevation-2 pointer"
    @click="changeAccount">
    <v-col class="flex-grow-0 flex-shrink-1">
      <v-img :src="storeImage" class="account-image"
        cover height="60" width="60" max-height="60" max-width="60">
      </v-img>
    </v-col>
    <v-col
      class="flex-grow-1 flex-shrink-0 pps-4 row no-gutters align-center justify-space-between">
      <div>
        <span class="t-700-16">
          {{ account.name }}
        </span>
        <div v-if="showDistrict" class="t-400-16 color3">
          {{ account.district_name }}
        </div>
      </div>
      <div class="t-700-18 color9">
        {{ account.faultsCount }}
      </div>
    </v-col>
  </v-row>
</template>
<style lang="scss">
.single-account {
  border-radius: 16px;
  background-color: white;

  &.selected {
    border: 1px solid #0040FF;
  }

  .account-image {
    border-radius: 16px;
  }
}
</style>
<script>
import { isString } from '@/helpers/Utils.js'

import { computed } from 'vue'
export default {
  props: {
    account: {
      type: Object,
      required: true
    },
    selected: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit }) {

    //Methods
    const changeAccount = () => emit('change-account', props.account.api_key)

    // Compputed
    const storeImage = computed(() => isString(props.account.custom_store_image) ? props.account.custom_store_image : props.account.store_image)
    const showDistrict = computed(() => isString(props.account.district_name) && props.account.district_name.length > 0)
    return {
      storeImage,
      showDistrict,
      changeAccount
    }
  }
}
</script>