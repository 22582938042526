import { ref, computed } from 'vue'

import { Recorder } from 'vmsg'

function AudioRecorder () {
  const isRecordingRef = ref(false)
  const isRecordingComp = computed(() => isRecordingRef.value)
  let recorder = null
  const isRecording = () => isRecordingRef.value === true

  this.start = async () => {
    if (isRecording()) return
    const baseUrl = import.meta.env.BASE_URL
    const wasmURL = `${baseUrl}js/vmsg.wasm`
    recorder = new Recorder({ wasmURL })

    await recorder.initAudio()
    await recorder.initWorker()
    isRecordingRef.value = true
    recorder.startRecording()
  }

  this.stop = async () => {
    if (!isRecording()) return

    return new Promise((resolve) => {
      recorder.stopRecording().then((blob) => {
        isRecordingRef.value = false
        resolve(blob)
      })
    })
  }

  this.delete = function () {
    this.stop()
    recorder = null
  }

  this.getIsRecording = isRecordingComp
}

export default AudioRecorder
