<template>
  <v-dialog v-model="isDialogOpen" max-width="400" persistent>
    <v-card :class="{ 'rtl': isRtl, 'ltr': !isRtl }">
      <v-card-title class="text-center pb-0">
        <span class="headline full-width">
          {{ dialogTitle }}
        </span>
      </v-card-title>
      <v-card-text class="py-0">
        <v-row align="center" justify="center" no-gutters>
          <v-col cols="12">
            <v-textarea class="required" auto-grow
              rows="1"
              :rules="[requiredField]"
              single-line
              v-model="groupName"
              counter="100"
              :label="$t('name')">
            </v-textarea>
          </v-col>
          <v-col cols="12" class="text-center">
            <v-progress-circular indeterminate color="primary" v-if="isLoadingGroupJobs"
              class="mt-4">
            </v-progress-circular>
            <hq-task-job-search :api-key="apiKey" v-else
              v-model="selectedJobs">
            </hq-task-job-search>
          </v-col>
          <v-col cols="12">
            <v-chip :key="job.id" v-for="job in selectedJobs" class="mx-1 mb-2">
              {{ job.name }}
              <v-icon @click="removeJob(job.id)">
                cancel
              </v-icon>
            </v-chip>
          </v-col>
          <v-col cols="12" class="mt-3">
            <v-card-actions class="pt-0 pr-0">
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="close(false)" :disabled="isLoading || isLoadingGroupJobs">
                {{ $t('cancel') }}
              </v-btn>
              <v-btn color="blue darken-1" depressed tile @click="save" :loading="isLoading || isLoadingGroupJobs"
                :disabled="isSaveDisabled">
                {{ $t('save') }}
              </v-btn>
            </v-card-actions>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import HqTaskJobSearch from '@//components/shared/HqTaskJobSearch.vue'

import useHqTaskJobGroup from '@/composables/useHqTaskJobGroup.js'

import { handler as errHandler } from '@/classes/ErrorHandler.js'
import { requiredField } from '@/classes/InputValidators.js'
import { onDialogClose, onDialogOpen } from '@/classes/DialogScrollBug.js'

import { isString } from '@/helpers/Utils.js'

import { i18n, isRtl } from '@/i18n.js'

import { ref, computed } from 'vue'

const DialogMode = { create: 0, update: 1 }
export default {
  props: {
    apiKey: {
      type: String,
      required: true
    }
  },
  components: {
    'hq-task-job-search': HqTaskJobSearch
  },
  setup(props) {
    const isDialogOpen = ref(false)
    const dialogMode = ref(null)
    const groupName = ref('')
    const isLoading = ref(false)
    const isLoadingGroupJobs = ref(false)
    const selectedJobs = ref([])

    const { createHqTaskGroup, updateHqTaskGroup, loadGroupJobs } = useHqTaskJobGroup(props.apiKey)
    let resolveFunc = null
    let groupId = null

    const openDialog = () => {
      onDialogOpen()
      isDialogOpen.value = true

      return new Promise((resolve, reject) => {
        resolveFunc = resolve
      })
    }

    const createOrUpdateApiCall = () => {
      const requestParams = {
        name: groupName.value,
        jobIds: selectedJobs.value.map(job => job.id)
      }
      if (dialogMode.value === DialogMode.create) {
        return createHqTaskGroup(requestParams)
      }
      return updateHqTaskGroup({ id: groupId, ...requestParams })
    }

    // Methods

    const close = (isSaved = false) => {
      onDialogClose()
      isLoading.value = false
      isLoadingGroupJobs.value = false
      groupName.value = ''
      selectedJobs.value = []

      isDialogOpen.value = false
      resolveFunc(isSaved)
    }
    const removeJob = (jobId) => selectedJobs.value = selectedJobs.value.filter(job => job.id !== jobId)

    const openForCreate = () => {
      dialogMode.value = DialogMode.create
      return openDialog()
    }

    const openForUpdate = async (group) => {
      isLoadingGroupJobs.value = true
      dialogMode.value = DialogMode.update
      groupId = group.id
      groupName.value = group.name
      const groupJobsResponse = await loadGroupJobs(groupId)
      selectedJobs.value = groupJobsResponse.data.data
      isLoadingGroupJobs.value = false
      return openDialog()
    }

    const save = () => {
      isLoading.value = true
      createOrUpdateApiCall()
        .catch(errHandler)
        .finally(() => close(true))
    }
    // Computed
    const isSaveDisabled = computed(() => {
      if (selectedJobs.value.length === 0) return true
      const nameVal = groupName.value
      if (isString(nameVal) !== true) return true
      return !(nameVal.trim().length > 0 && nameVal.trim().length <= 100)
    })
    const dialogTitle = computed(() => dialogMode.value === DialogMode.create
      ? i18n.t('hq_task_job_group.create_title')
      : i18n.t('hq_task_job_group.update_title'))

    return {
      isDialogOpen,
      isRtl,
      dialogTitle,
      groupName,
      isLoadingGroupJobs,
      selectedJobs,
      isLoading,
      isSaveDisabled,
      requiredField,
      removeJob,
      close,
      openForCreate,
      openForUpdate,
      save
    }
  }
}
</script>