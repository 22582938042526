import Vue from 'vue'
import vuetify from '@/plugins/vuetify.js'
import App from '@/App.vue'
import router from '@/router.js'
import { i18n } from '@/i18n.js'
import { handler } from '@/classes/ErrorHandler.js'
import '@/service_worker/register.js'
import '@/gtag.js'
import '@/assets/vuetify_general_overrides.css'
import '@/assets/general.css'
import '@/assets/shift_part_operation_schedule.css'

Vue.config.productionTip = false
Vue.config.errorHandler = handler
new Vue({
  i18n,
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')
