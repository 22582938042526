import { workingDays as AccountJobClientWorkingDays } from '@/api_client/AccountJob.js'

const jobsWorkingDays = Object.create(null)

export async function getJobWorkingDays (apiKey, jobId) {
  // return duplicated array to make sure its immutable
  if (jobId in jobsWorkingDays) return jobsWorkingDays[jobId].slice(0)

  return AccountJobClientWorkingDays(apiKey, jobId)
    .then((response) => {
      jobsWorkingDays[jobId] = response.data.data
      return jobsWorkingDays[jobId].slice(0)
    })
}

export function removeJobWorkingDays (jobId) {
  delete jobsWorkingDays[jobId]
}
