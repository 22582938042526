import Client from '@/api_client/client.js'

const batchUpsert = (apiKey, operationId, params) => {
  const OPERATION_BTACH_UPSERT_REPORT = `/accounts/${apiKey}/operation_schedules/${operationId}/reported_item_operation_list_items/batch_upsert`
  return Client.put(OPERATION_BTACH_UPSERT_REPORT, params)
}

const create = (apiKey, operationId, params) => {
  const OPERATION_LIST_ITEM_CREATE_REPORT = `/accounts/${apiKey}/operation_schedules/${operationId}/reported_item_operation_list_items/`
  return Client.post(OPERATION_LIST_ITEM_CREATE_REPORT, params)
}

const update = (apiKey, operationId, itemListId, params) => {
  const OPERATION_LIST_ITEM_UPDATE_REPORT = `/accounts/${apiKey}/operation_schedules/${operationId}/reported_item_operation_list_items/${itemListId}`
  return Client.put(OPERATION_LIST_ITEM_UPDATE_REPORT, params)
}

export {
  batchUpsert,
  create,
  update
}
