import { translatedOperation as OpTypeTranslatedOperation } from '@/composables/useOperationType.js'
import { translateShift as OrgJobTranslateShift } from '@/store/modules/OrganizationJob.js'
import { getFrequencyText } from '@/store/modules/OperationFrequency.js'
import { newDeviceOperationModel } from '@/store/modules/DeviceOperation.js'

import { i18n } from '@/i18n.js'

const filedList = ['id', 'shift_part_name', 'frequency', 'frequency_type', 'scheduled_day',
  'operation_type', 'operation_description', 'operation_color', 'item_name',
  'executor_name', 'shift_name', 'executor_type', 'executor_id', 'item_type',
  'require_image', 'item_operation_id', 'item_id', 'operation_next_date',
  'operation_next_date_raw', 'staff_operation', 'guidance_images', 'guidance_file', 'related_item_id',
  'related_item_name', 'start_time', 'end_date', 'report_tag']

const ItemTypes = { account_suuplier: 'AccountSupplier', item: 'Item' }
const OperationStatuses = {
  done: 'done',
  noNeed: 'no_need',
  notReported: 'not_reported'
}

function OperationScheduleModel (src) {
  const srcOrEmpty = src || { }
  filedList.forEach((field) => {
    this[field] = srcOrEmpty[field]
  })
  if ('first_scheduled_date' in srcOrEmpty) {
    this.operation_next_date_raw = srcOrEmpty.first_scheduled_date
  }
  if ('operation_id' in srcOrEmpty) {
    this.id = srcOrEmpty.operation_id
  }
  if (this.operation_type) {
    this.operationTypeText = OpTypeTranslatedOperation(this.operation_type)
  }
  if (this.shift_name) {
    this.shiftNameText = OrgJobTranslateShift(this.shift_name)
  }
  if (this.shift_part_name) {
    this.shiftPartNameText = translateShiftPartName(this.shift_part_name)
  }
  if (this.staff_operation === true || srcOrEmpty.creator_data) {
    this.creatorFirstName = srcOrEmpty.creator_data.first_name
    this.creatorLastName = srcOrEmpty.creator_data.last_name
  }
  this.frequencyText = getFrequencyText({ frequency: this.frequency, frequencyType: this.frequency_type })
}

function translateShiftPartName (shiftPart) {
  switch (shiftPart) {
    case 'start':
      return i18n.t('shift_start')
    case 'middle':
      return i18n.t('shift_middle')
    case 'end':
      return i18n.t('shift_end')
    case 'shift_transfer':
      return i18n.t('shift_transfer')
    case 'during_shift':
      return i18n.t('during_shift')
  }
}
function colorFromInterval (intervalName) {
  switch (intervalName) {
    case 'start':
      return 'cyan lighten-4'
    case 'middle':
      return 'cyan lighten-5'
    case 'end':
      return 'cyan lighten-1'
    case 'shift_transfer':
      return 'cyan lighten-2'
    case 'during_shift':
      return 'cyan lighten-3'
  }
}

function translateReportTag (tag) {
  switch (tag) {
    case 'report':
      return i18n.t('schedule_new_op_dialog.tag_report')
    case 'monitor':
      return i18n.t('schedule_new_op_dialog.tag_monitor')
    case 'procedure':
      return i18n.t('schedule_new_op_dialog.tag_procedure')
  }
}

OperationScheduleModel.prototype.serialize = function () {
  const creationParams = JSON.parse(JSON.stringify(this))
  return filedList.reduce((initValue, currentValue) => {
    initValue[currentValue] = creationParams[currentValue]
    return initValue
  }, {})
}

OperationScheduleModel.prototype.isItemTypeSupplier = function () {
  return this.item_type === ItemTypes.account_suuplier
}

OperationScheduleModel.prototype.isItemTypeItem = function () {
  return this.item_type === ItemTypes.item
}

OperationScheduleModel.prototype.updateFrequency = function (frequency, frequencyType) {
  this.frequency = frequency
  this.frequency_type = frequencyType
}
OperationScheduleModel.prototype.getItemOperation = function () {
  return newDeviceOperationModel({
    id: this.item_operation_id,
    operation_type: this.operation_type,
    description: this.operation_description,
    color: this.operation_color,
    guidance_images: this.guidance_images
  })
}

function newOperationScheduleModel (src) {
  return new OperationScheduleModel(src)
}

function shiftParts () {
  const shiftsResult = [{ name: 'start' }, { name: 'end' }, { name: 'shift_transfer' }, { name: 'during_shift' }]
  shiftsResult.forEach((shift) => (shift.translateName = translateShiftPartName(shift.name)))
  return shiftsResult
}

function shiftPartsForSelect () {
  const oldShiftParts = shiftParts()
  const start = oldShiftParts.find((shift) => shift.name === 'start')
  const end = oldShiftParts.find((shift) => shift.name === 'end')
  const shiftTransfer = oldShiftParts.find((shift) => shift.name === 'shift_transfer')
  const duringShift = oldShiftParts.find((shift) => shift.name === 'during_shift')
  return [start, duringShift, shiftTransfer, end]
}
export {
  newOperationScheduleModel, colorFromInterval, OperationStatuses, shiftParts, shiftPartsForSelect,
  translateShiftPartName, translateReportTag
}
