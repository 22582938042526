<template>
  <v-row align="center" justify="start" no-gutters class="drive-filter">
    <v-col cols="4" lg="2" sm="3" class="pps-2">
      <account-select class="filter-select"
        v-model="filters.accounts"
        :api-key="apiKey"
        :menu-props="{ offsetY: true }"
        :outlined="false"
        :dense="false"
        clearable
        append-icon="arrow_drop_down"
        @change="onFilterChange"
        multiple>
      </account-select>
    </v-col>
    <v-col cols="4" lg="2" sm="3" class="pps-2">
      <company-user-search
        v-model="filters.users"
        :placeholder="$t('drive.owner_search')"
        :api-key="apiKey"
        :menu-props="{ offsetY: true }"
        show-selection-count
        class="filter-select"
        add-current-user
        @change="onFilterChange">
      </company-user-search>
    </v-col>
  </v-row>
</template>
<style lang="scss">
.drive-filter {
  .v-text-field__details {
    display: none;
  }
}
</style>
<script>
import AccountSelect from '@/components/shared/AccountSelect.vue'
import CompanyUserSearch from '@/components/shared/user_search/CompanyUserSearch.vue'
import useCompanyDriveFile from '@/composables/useCompanyDriveFile.js'
import useCompanyDriveFolder from '@/composables/useCompanyDriveFolder.js'
import useCompanyDriveFilters from '@/composables/useCompanyDriveFilters.js'
import { ref } from 'vue'
export default {
  components: {
    'company-user-search': CompanyUserSearch,
    'account-select': AccountSelect
  },
  props: {
    apiKey: {
      type: String,
      required: true
    }
  },
  setup(props) {
    const { filters } = useCompanyDriveFilters(props.apiKey)
    const { onFilterChange: onFilterChangeForFiles } = useCompanyDriveFile(props.apiKey)
    const { onFilterChange: onFilterChangeForFolders } = useCompanyDriveFolder(props.apiKey)


    const onFilterChange = () => {
      onFilterChangeForFiles()
      onFilterChangeForFolders()
    }
    return {
      filters,
      onFilterChange
    }
  }
}
</script>