<template>
  <v-icon :color="fileColor">
    {{ fileIcon }}
  </v-icon>
</template>

<script>
import { isString } from '@/helpers/Utils';

import { computed } from 'vue'

const defaultColor = '#8993AF'
const fileColors = {
  picture_as_pdf: '#EC2328',
  description: '#3873FF',
  table_chart: '#0DB664',
  slideshow: '#FF9800',
  archive: '#3E8BFE',
  image: defaultColor,
  audiotrack: defaultColor,
  movie: defaultColor,
  insert_drive_file: defaultColor
}

export default {
  props: {
    fileName: {
      type: String,
      default: null
    }
  },
  setup(props) {
    const getFileExtension = () => {
      if (!isString(props.fileName)) return ''
      return props.fileName.split('.').pop().toLowerCase()
    }

    // Computed
    const fileIcon = computed(() => {
      const ext = getFileExtension()
      if (ext === 'pdf') return 'picture_as_pdf'
      if (ext === 'doc' || ext === 'docx' || ext === 'txt') return 'description'
      if (ext === 'xls' || ext === 'xlsx') return 'table_chart'
      if (ext === 'ppt' || ext === 'pptx') return 'slideshow'
      if (ext === 'zip' || ext === 'rar' || ext === '7z') return 'archive'
      if (ext === 'jpg' || ext === 'jpeg' || ext === 'png' || ext === 'gif') return 'image'
      if (ext === 'mp3' || ext === 'wav' || ext === 'flac' || ext === 'ogg') return 'audiotrack'
      if (ext === 'mp4' || ext === 'avi' || ext === 'mkv' || ext === 'mov') return 'movie'
      return 'insert_drive_file'
    })
    const fileColor = computed(() => fileColors[fileIcon.value] || defaultColor)
    return {
      fileIcon,
      fileColor
    }
  }
}
</script>