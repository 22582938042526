
import {
  index, unseenCount as pushNotificationHistoryUnseenCount,
  updateUnseenCount as pushNotificationHistoryUpdateUnseenCount
} from '@/api_client/PushNotificationHistory.js';

import { isString } from '@/helpers/Utils.js'
import { handler as errHandler } from '@/classes/ErrorHandler.js'

import { ref } from 'vue'

let currentApiKey = null

const unseenCount = ref(0)
const lastSeenPushId = ref(null)
const pushHistoryList = ref([])
const isLoading = ref(false)

const pagination = {
  page: 1,
  per_page: 10
}
export default function usePushNotificationsHistory(apiKey) {
  const resetValues = () => {
    isLoading.value = false
    pushHistoryList.value = []
    unseenCount.value = 0
    lastSeenPushId.value = null
  }

  if (isString(apiKey) && currentApiKey !== apiKey) {
    resetValues()
    currentApiKey = apiKey
  }

  // Methods

  const loadUnSeenCount = () =>
    pushNotificationHistoryUnseenCount(currentApiKey)
      .then(({ data: { unseen_count, last_seen_id } }) => {
        unseenCount.value = unseen_count
        lastSeenPushId.value = last_seen_id
      })
      .catch(errHandler)

  const loadPushHistory = () => {
    pushHistoryList.value = []

    isLoading.value = true
    return index({ apiKey: currentApiKey, params: pagination })
      .then(({ data: { data } }) => pushHistoryList.value = data)
      .catch(errHandler)
      .finally(() => isLoading.value = false)
  }

  const markAsSeen = () => {
    pushNotificationHistoryUpdateUnseenCount(currentApiKey)
      .then(({ data: { last_seen_id } }) => {
        unseenCount.value = 0
        lastSeenPushId.value = last_seen_id
      })
      .catch(errHandler)
  }


  return {
    isLoading,
    pushHistoryList,
    unseenCount,
    lastSeenPushId,
    loadPushHistory,
    loadUnSeenCount,
    markAsSeen
  }
}
