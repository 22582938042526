<template>
  <v-dialog v-model="dialog" persistent :fullscreen="$vuetify.breakpoint.xsOnly" :width="500">
    <color-picker-dialog ref="colorPicker"
      @clear="clearColor"
      v-model="newOperation.color">
    </color-picker-dialog>
    <item-operation-list-dialog :api-key="apiKey" ref="itemOperationListDialog"></item-operation-list-dialog>
    <operation-notification ref="operationNotificationDialog"
      :task-un-complete-disabled="!operationHasStartTime">
    </operation-notification>
    <v-card class="device-new-op pt-10"
      :class="{ 'rtl': isRtl, 'ltr': !isRtl }">
      <v-card-title>
        <div class="text-center full-width">{{ $t('schedule_new_op_dialog.title') }}</div>
      </v-card-title>
      <v-card-text class="scrollable">
        <v-form ref="operationForm">
          <v-row align="start" justify="start" no-gutters class="overflow-x-hidden">
            <v-col cols="9">
              <job-selection-search v-model="currentJobSearchResult"
                @input="onJobSelect"
                v-if="dialog"
                ref="jobSearch"
                :api-key="apiKey"
                :label="$t('schedule_new_op_dialog.choose_job')"
                :menu-props="{ 'contentClass': isRtl ? 'rtl' : 'ltr' }"
                :search-for-operation="searchForOperation">
              </job-selection-search>
            </v-col>
            <v-col cols="3" class="pt-6 text-center">
              <v-btn small icon @click="openOperationListItem" :disabled="requireImage">
                <v-icon :color="hasListItem ? 'green' : 'black'">format_list_bulleted</v-icon>
              </v-btn>
            </v-col>
            <v-col cols="9">
              <operation-description-field v-model="newOperation.description"
                :operation-type="newOperation.operation_type">
              </operation-description-field>
            </v-col>
            <v-col cols="3" class="text-center">
              <v-checkbox class="d-inline-block pt-1"
                color="black"
                v-model="requireImage"
                :disabled="hasListItem"
                off-icon="add_a_photo"
                on-icon="add_a_photo">
              </v-checkbox>
            </v-col>
            <operation-frequency-field
              v-bind:frequency-model.sync="frequencyModel"
              v-bind:frequency-exect-date.sync="frequencyExectDate"
              v-bind:frequency-every-year.sync="frequencyYearDate"
              v-bind:end-date.sync="endDate"
              :single-every-year-date="isUpdateMode">
            </operation-frequency-field>
            <v-col cols="9" class="d-flex align-start">
              <v-select
                v-model="shiftPart"
                :items="shiftParts"
                :menu-props="{ 'contentClass': isRtl ? 'rtl' : 'ltr' }"
                :disabled="shiftPartDisabled"
                item-text="translateName"
                item-value="name"
                single-line
                return-object>
              </v-select>
              <time-picker-dialog class="mms-2" v-model="startTime"></time-picker-dialog>
            </v-col>
            <v-col class="text-center pt-3" cols="3">
              <v-btn fab dark small color="pink" @click="openColorPicker">
                <v-icon>color_lens</v-icon>
              </v-btn>
            </v-col>
            <v-col cols="9">
              <operation-guidence-images ref="opImages"
                record-type="ItemOperation">
              </operation-guidence-images>
            </v-col>
            <v-col class="text-center pt-3" cols="3" v-if="!isUpdateMode">
              <v-btn fab dark small color="green" @click="openNotificationDialog">
                <v-icon>notifications</v-icon>
              </v-btn>
            </v-col>
            <v-col cols="9">
              <file-uploader ref="opFile"
                record-type="ItemOperation">
              </file-uploader>
            </v-col>
            <v-col cols="9" class="mt-2">
              <operation-report-tag v-model="reportTag"
                :menu-props="{ top: true }">
              </operation-report-tag>
            </v-col>
            <v-col cols="9">
              <item-search v-model="relatedItem"
                :place-holder="$t('schedule_new_op_dialog.related_item')"
                :api-key="apiKey"
                show-add-item
                show-delete-button
                show-edit-button
                :new-item-place-holder="$t('schedule_new_op_dialog.new_item')"
                load-all>
              </item-search>
            </v-col>
            <v-col cols="12"></v-col>
            <v-row align="start" justify="start" class="mt-6" no-gutters v-if="showDaysSelection">
              <v-col v-for="(day, index) in activeDays"
                cols="3" class="mb-3"
                :key="day.name">
                <v-btn
                  :input-value="day.isActive"
                  active-class="teal darken-1"
                  @click="toggleActiveDay({ index, withReset: isUpdateMode || isCopyMode })">
                  {{ day.translatedName }}
                </v-btn>
              </v-col>
            </v-row>
            <v-col cols="12">
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions class="pr-10">
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="close" :disabled="isLoading">
          {{ $t('cancel') }}
        </v-btn>
        <v-btn color="blue darken-1" depressed tile @click="saveClick" :loading="isLoading"
          :disabled="saveDisabled">
          {{ $t('save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<style lang="scss">
.device-new-op {
  .description-text.v-text-field.rtl {
    label.v-label.theme--light {
      left: 0 !important;
    }
  }

  .v-input {
    .v-label {
      color: black;
      font-weight: 400;
    }

    .v-input__slot ::placeholder {
      color: black;
    }
  }

  @media only screen and (min-width: 600px) {
    .scrollable {
      overflow: auto;
      height: 100%;
      max-height: 60vh;
    }
  }
}
</style>
<script>
import JobSelectionSearch from '@/components/job_selection_search/JobSelectionSearch.vue'
import ColorPickerDialog from '@/components/operation_schedule_dialog/ColorPickerDialog.vue'
import ItemOperationListDialog from '@/components/operation_schedule_dialog/item_operation_list/ItemOperationListDialog.vue'
import OperationGuidenceImages from '@/components/operation_schedule_dialog/OperationGuidenceImages.vue'
import OperationDescriptionField from '@/components/operation_schedule_dialog/OperationDescriptionField.vue'
import OperationFrequencyField from '@/components/operation_schedule_dialog/OperationFrequencyField.vue'
import TimePickerDialog from '@/components/shared/TimePickerDialog.vue'
import OperationNotification from '@/components/operation_schedule_dialog/OperationNotification.vue'

import FileUploader from '@/components/shared/FileUploader.vue'
import ItemSearch from '@/components/shared/item_search/ItemSearch.vue'

import useNewOperation from '@/composables/useNewOperation.js'
import useAuth from '@/composables/useAuth.js'

import { shiftPartsForSelect as OpModuleShiftParts } from '@/store/modules/OperationSchedule.js'

import { EventBus, NEW_ACCOUNT_OPERATION } from '@/EventBus.js'

import { ref, computed, nextTick } from 'vue'

import { isRtl } from '@/i18n.js'
const DialogMode = { create: 1, update: 2, copy: 3 }

export default {
  props: {
    apiKey: {
      type: String,
      required: true
    }
  },
  components: {
    'job-selection-search': JobSelectionSearch,
    'operation-description-field': OperationDescriptionField,
    'operation-frequency-field': OperationFrequencyField,
    'color-picker-dialog': ColorPickerDialog,
    'item-operation-list-dialog': ItemOperationListDialog,
    'operation-guidence-images': OperationGuidenceImages,
    'file-uploader': FileUploader,
    'time-picker-dialog': TimePickerDialog,
    'item-search': ItemSearch,
    'operation-report-tag': () => import('@/components/operation_schedule_dialog/OperationReportTagField.vue'),
    'operation-notification': OperationNotification
  },
  setup(props) {
    const { isUserAccountManager } = useAuth()
    const dialog = ref(false)
    const shiftParts = ref(OpModuleShiftParts())
    const preExistingListItems = ref(false)
    const dialogMode = ref(DialogMode.create)
    const opImages = ref(null)
    const opFile = ref(null)
    const colorPicker = ref(null)
    const itemOperationListDialog = ref(null)
    const operationForm = ref(null)
    const operationNotificationDialog = ref(null)

    let operationSchedule = null
    let originalJobId = null
    const searchForOperation = ref(false)
    const {
      isLoading, newOperation, requireImage, shiftPart, startTime, endDate, frequencyModel,
      currentJobSearchResult, saveDisabled, hasListItem, activeDays, frequencyExectDate, frequencyYearDate,
      shiftPartDisabled, showDaysSelection, opItemList, relatedItem, reportTag, taskNotifications,
      operationHasStartTime,
      onJobSelect, setOperation, toggleActiveDay,
      clearColor, save, reset
    } = useNewOperation(props.apiKey)

    // Methods

    const openDialog = () => {
      dialog.value = true
      dialogMode.value = DialogMode.create
      operationSchedule = null
      reset()
      shiftPart.value = shiftParts.value.find((shiftPart) => shiftPart.name === 'start')
      if (isUserAccountManager.value === true) {
        searchForOperation.value = true
      }
    }

    const openForUpdate = async ({ operation, dayName }) => {
      dialog.value = true
      dialogMode.value = DialogMode.update
      preExistingListItems.value = Array.isArray(operation.item_operation_list)
      originalJobId = operation.account_job_id
      operationSchedule = await setOperation({
        operation, jobId: operation.account_job_id, dayName,
        shiftPartName: operation.shift_part, hasItemList: preExistingListItems.value
      })
      nextTick(() => {
        opImages.value.setImages(operationSchedule.guidance_images)
        opFile.value.setFile(operationSchedule.guidance_file)
      })
    }

    const openForCopy = async ({ operation, dayName }) => {
      dialog.value = true
      dialogMode.value = DialogMode.copy
      const hasItemList = Array.isArray(operation.item_operation_list)

      operationSchedule = await setOperation({
        operation, jobId: operation.account_job_id, dayName,
        shiftPartName: operation.shift_part, hasItemList, copyMode: true
      })
      if (!hasItemList) return

      opItemList.value = operation.item_operation_list
        .map(item => ({ description: item.description, item_type: item.item_type }))
    }

    const openColorPicker = () => colorPicker.value.openDialog()
    const openNotificationDialog = () =>
      operationNotificationDialog.value
        .openDialog({
          taskComplete: taskNotifications.value.taskComplete,
          taskUncomplete: taskNotifications.value.taskUncomplete
        }).then(({ saved, taskComplete, taskUncomplete }) => {
          if (!saved) return
          taskNotifications.value.taskComplete = taskComplete
          taskNotifications.value.taskUncomplete = taskUncomplete
        })

    const openOperationListItem = () => {
      const oldList = JSON.parse(JSON.stringify(opItemList.value))
      if (dialogMode.value === DialogMode.update) {
        const itemOperationId = operationSchedule.item_operation_id
        itemOperationListDialog.value.openDialog(operationSchedule.item_id, itemOperationId)
          .then(({ hasListItems }) => (preExistingListItems.value = hasListItems))
      } else {
        itemOperationListDialog.value.openDialogWithExistingList(opItemList.value)
          .then(({ isSaved, itemList }) => (opItemList.value = isSaved ? itemList : oldList))
      }
    }

    const saveClick = () => {
      const uploadImageCount = opImages.value.uploadImageCount()
      const saveImageCall = opImages.value.saveImages
      const saveFileCall = opFile.value.saveFile
      const attachedFileName = opFile.value.attachedFileName()
      save({ uploadImageCount, saveImageCall, attachedFileName, saveFileCall })
        .then(() => {
          const jobId = Number.isInteger(originalJobId) ? originalJobId : currentJobSearchResult.value.id
          EventBus.emit(NEW_ACCOUNT_OPERATION, { jobId })
          close()
        })
    }

    const close = () => {
      reset()
      dialog.value = false
      preExistingListItems.value = false
      operationSchedule = null
      originalJobId = null
      operationForm.value.reset()
      opImages.value.reset()
      opFile.value.reset()
      if (isUserAccountManager.value === true) {
        searchForOperation.value = false
      }
    }

    // Computed
    const isUpdateMode = computed(() => dialogMode.value === DialogMode.update)
    const isCopyMode = computed(() => dialogMode.value === DialogMode.copy)
    return {
      isLoading,
      newOperation,
      searchForOperation,
      currentJobSearchResult,
      saveDisabled,
      hasListItem,
      requireImage,
      shiftPart,
      startTime,
      endDate,
      shiftPartDisabled,
      frequencyExectDate,
      frequencyYearDate,
      showDaysSelection,
      activeDays,
      frequencyModel,
      shiftParts,
      dialog,
      isUpdateMode,
      isCopyMode,
      opImages,
      opFile,
      colorPicker,
      itemOperationListDialog,
      operationForm,
      relatedItem,
      reportTag,
      isRtl,
      taskNotifications,
      operationHasStartTime,
      operationNotificationDialog,
      openDialog,
      openColorPicker,
      clearColor,
      openOperationListItem,
      onJobSelect,
      toggleActiveDay,
      saveClick,
      close,
      openForUpdate,
      openNotificationDialog,
      openForCopy
    }
  }
}
</script>
