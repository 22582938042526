<template>
  <v-row align="start" justify="start" no-gutters>
    <v-col cols="12" v-if="showCommentsAndLikesRow" class="like-text">
      <v-btn icon v-if="showLikesCounts" @click="openLikesDialog" color="#9E9E9E">
        <v-icon :color="post.liked_by_current_user ? '#E2340D' : '#9E9E9E'">
          favorite
        </v-icon>
        <span class="underline">
          {{ post.likes_count }}
        </span>

      </v-btn>
      <v-btn text v-if="showCommentsCounts" class="ml-4" color="#9E9E9E" @click="showCommentsClick">
        <span class="underline">
          {{ post.comments_count }} {{ $t('comments') }}
        </span>
      </v-btn>
    </v-col>
    <v-col cols="12" class="d-flex align-start justify-start" v-if="!showTwoLines">
      <v-btn :loading="post.isLoadingLike" :class="{ 'like-hover': showLikeAnimation }" @click="likeClick"
        :outlined="!post.liked_by_current_user" small color="#212121">
        <v-icon :color="post.liked_by_current_user ? 'white' : '#212121'" class="mr-1">
          {{ post.liked_by_current_user ? 'thumb_up' : 'thumb_up_off_alt' }}
        </v-icon>
        <span :class="{ 'white--text': post.liked_by_current_user }">
          {{ $t('blog_post.like') }}
        </span>
      </v-btn>
      <v-btn outlined small @click="replyClick" color="#212121" class="mx-4">
        <v-icon color="#212121" class="mr-1">chat</v-icon>
        {{ $t('blog_post.reply') }}
      </v-btn>
      <v-btn outlined small @click="shareClick" color="#212121">
        <v-icon color="#212121" class="mr-1">share</v-icon>
        {{ $t('share') }}
      </v-btn>
    </v-col>
    <v-col cols="12" class="d-flex align-start justify-space-between" v-if="showTwoLines">
      <v-btn :loading="post.isLoadingLike" :class="{ 'like-hover': showLikeAnimation }" @click="likeClick"
        :outlined="!post.liked_by_current_user" small color="#212121">
        <v-icon :color="post.liked_by_current_user ? 'white' : '#212121'" class="mr-1">
          {{ post.liked_by_current_user ? 'thumb_up' : 'thumb_up_off_alt' }}
        </v-icon>
        <span :class="{ 'white--text': post.liked_by_current_user }">
          {{ $t('blog_post.like') }}
        </span>
      </v-btn>
      <v-btn outlined small @click="replyClick" color="#212121">
        <v-icon color="#212121" class="mr-1">chat</v-icon>
        {{ $t('blog_post.reply') }}
      </v-btn>
    </v-col>
    <v-col cols="12" class="text-center mt-4" v-if="showTwoLines">
      <v-btn outlined small @click="shareClick" color="#212121">
        <v-icon color="#212121" class="mr-1">share</v-icon>
        {{ $t('share') }}
      </v-btn>
    </v-col>
  </v-row>
</template>
<style lang="scss">
.single-post {
  .like-text {
    font-size: 14px;
    font-weight: 400;
    color: #9E9E9E;

    .underline {
      text-decoration: underline;
      text-underline-offset: 4px;
    }
  }

  .like-hover {
    /* Start the shake animation and make the animation last for 0.5 seconds */
    animation: shake 0.5s;
    animation-iteration-count: infinite;
  }

  @keyframes shake {
    0% {
      transform: translate(1px, 1px) rotate(0deg);
    }

    10% {
      transform: translate(-1px, -2px) rotate(-1deg);
    }

    20% {
      transform: translate(-3px, 0px) rotate(1deg);
    }

    30% {
      transform: translate(3px, 2px) rotate(0deg);
    }

    40% {
      transform: translate(1px, -1px) rotate(1deg);
    }

    50% {
      transform: translate(-1px, 2px) rotate(-1deg);
    }

    60% {
      transform: translate(-3px, 1px) rotate(0deg);
    }

    70% {
      transform: translate(3px, 1px) rotate(-1deg);
    }

    80% {
      transform: translate(-1px, -1px) rotate(1deg);
    }

    90% {
      transform: translate(1px, 2px) rotate(0deg);
    }

    100% {
      transform: translate(1px, -2px) rotate(-1deg);
    }
  }
}
</style>
<script>
import useAuth from '@/composables/useAuth.js'
import useBlogPost from '@/composables/useBlogPost.js'
import { currentLanguage } from '@/composables/useLanguage.js'
import vuetify from '@/plugins/vuetify.js'
import { computed, ref } from 'vue'
export default {
  props: {
    post: {
      type: Object,
      required: true
    }
  },
  setup(props, { emit }) {
    const { apiKey } = useAuth()
    const { likePost, unLikePost } = useBlogPost(apiKey.value)
    const showLikeAnimation = ref(false)
    const showLikesCounts = computed(() => props.post.likes_count > 0)
    const showCommentsCounts = computed(() => props.post.comments_count > 0)
    const showCommentsAndLikesRow = computed(() => showLikesCounts.value || showCommentsCounts.value)
    const showTwoLines = computed(() => currentLanguage.value === 'es' && vuetify.framework.breakpoint.xsOnly)

    // Methods
    const replyClick = () => emit('reply-click')
    const shareClick = () => emit('share-click')
    const showCommentsClick = () => emit('show-comments-click')

    const openLikesDialog = () => emit('open-likes-dialog')
    const likeClick = () => {
      if (props.post.liked_by_current_user) {
        unLikePost(props.post)
      } else {
        likePost(props.post)
        showLikeAnimation.value = true
        setTimeout(() => {
          showLikeAnimation.value = false
        }, 500)
      }
    }
    return {
      showLikeAnimation,
      showLikesCounts,
      showCommentsCounts,
      showCommentsAndLikesRow,
      showTwoLines,
      likeClick,
      showCommentsClick,
      replyClick,
      shareClick,
      openLikesDialog
    }
  }
}
</script>
