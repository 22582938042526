import 'material-icons/iconfont/material-icons.css'
import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import ClickOutside from 'vuetify/lib/directives/click-outside'
import Intersect from 'vuetify/lib/directives/intersect'
import Ripple from 'vuetify/lib/directives/ripple'

Vue.use(Vuetify, {
  directives: {
    ClickOutside,
    Intersect,
    Ripple
  }
})

export default new Vuetify({
  icons: {
    iconfont: 'md'
  }
})
