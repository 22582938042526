<template>
  <span>
    <confirm-delete ref="deleteDialog"></confirm-delete>
    <v-select
      :value="value"
      :items="companyDistricts"
      :loading="isDistrictsLoading"
      :disabled="disabled"
      item-text="name"
      item-value="id"
      clearable
      single-line
      @change="onChange"
      @input="onInput"
      @click:clear="onClear"
      :no-data-text="$t('no_result')"
      :label="$t('area')">
      <template v-slot:item="{ item }">
        <v-row v-bind:class="{ rtl: isRtl }" align="center" justify="space-between">
          <span class="font-weight-bold px-2"> {{ item.name }}</span>
          <span>
            <v-btn icon v-if="showDelete"
              color="red"
              @click.stop="onDelete(item)">
              <v-icon>delete</v-icon>
            </v-btn>
          </span>
        </v-row>
      </template>
    </v-select>
  </span>
</template>
<script>
import { getCompanyDistricts, loadCompanyDistricts, getIsLoading, destroyCompanyDistrict } from '@/models/CompanyDistrict.js'
import { isRtl } from '@/i18n.js'
import { handler as errHandler } from '@/classes/ErrorHandler.js'
import { i18n } from '@/i18n.js'
import { ref } from 'vue'
export default {
  props: {
    apiKey: {
      type: String,
      required: true
    },
    value: {
      type: Number,
      required: false
    },
    showDelete: {
      type: Boolean,
      required: false,
      default: false
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  components: {
    'confirm-delete': () => import('@/components/shared/ConfirmDelete.vue')
  },
  setup(props, { emit }) {
    const deleteDialog = ref(null)
    const onInput = (value) => emit('input', value)
    const onChange = (value) => emit('change', value)
    const onClear = () => emit('click:clear')
    // Methods

    const onDelete = (area) => {
      const title = i18n.t('delete_distrcit_dialog.title')
      const content = i18n.t('delete_distrcit_dialog.content')

      deleteDialog.value.open({ title, content })
        .then((shouldDelete) => {
          if (!shouldDelete) return
          destroyCompanyDistrict({ apiKey: props.apiKey, districtId: area.id })
            .then(() => emit('district-deleted'))
            .catch(errHandler)
            .finally(() => deleteDialog.value.close())
        })
    }
    loadCompanyDistricts({ apiKey: props.apiKey })
      .catch(errHandler)

    return {
      deleteDialog,
      companyDistricts: getCompanyDistricts,
      isDistrictsLoading: getIsLoading,
      isRtl,
      onDelete,
      onInput,
      onChange,
      onClear
    }
  }
}
</script>