
import { isFunction } from '@/helpers/Utils.js'
import axios from 'axios'

const uploadFile = (file, url, progressCallback) => {
  const options = {
    headers: {
      'Content-Type': file.type
    }
  }
  if (isFunction(progressCallback)) {
    options.onUploadProgress = progressCallback
  }
  return axios.put(url, file, options)
}

export {
  uploadFile
}
