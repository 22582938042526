<template>
  <v-row align="start" justify="start" v-if="showLoadingIndicatorFirsrPage">
    <v-col cols="12">
      <v-skeleton-loader
        type="table: table-heading, table-thead, table-tbody">
      </v-skeleton-loader>
    </v-col>
  </v-row>
  <v-row align="start" justify="start" v-else-if="!isEmpty">
    <confirm-delete ref="deleteDialog" max-width="250"></confirm-delete>
    <v-col cols="12">
      <v-data-table class="elevation-1 drive-files-table"
        :class="isRtl ? 'rtl' : 'ltr'"
        :headers="tableHeaders"
        :items="driveFiles"
        :items-per-page="DEFAULT_PER_PAGE"
        :server-items-length="totalItems"
        hide-default-footer
        mobile-breakpoint="0"
        :loading="isFilesLoading"
        :loading-text="$t('loading')"
        item-key="id">
        <template v-slot:item="{ item }">
          <drive-file-table-row
            :file="item"
            @delete-file="onDelete">
          </drive-file-table-row>
        </template>
        <template v-slot:no-data>
          <v-alert :value="true" color="white black--text">
            {{ $t("no_result") }}
          </v-alert>
        </template>
      </v-data-table>
    </v-col>
    <v-col v-if="showLoadingIndicatorMorePages" class="row align-start justify-start">
      <v-col cols="12">
        <v-skeleton-loader type="table: table-heading, table-thead, table-tbody">
        </v-skeleton-loader>
      </v-col>
    </v-col>
    <v-col cols="12" v-if="showLoadMorePages" v-intersect.once="onLoadNextPage"></v-col>
  </v-row>
</template>
<style lang="scss">
.drive-files-table {
  .v-data-table__wrapper {
    thead {
      .header {
        background-color: rgba(0, 64, 255, 0.05);
        font-size: 16px;
        font-weight: 700;
        color: black;
      }
    }

    table {
      tbody {
        td {
          padding-top: 8px !important;
          padding-bottom: 8px !important;
        }
      }
    }
  }
}
</style>
<script>

import DriveFileTableRow from '@/components/drive/files_table_view/DriveFileTableRow.vue'
import ConfirmDelete from '@/components/shared/ConfirmDelete.vue'

import useCompanyDriveFile from '@/composables/useCompanyDriveFile.js'

import { handler as errHandler } from '@/classes/ErrorHandler.js'

import { i18n, isRtl } from '@/i18n.js'

import { ref, computed } from 'vue'
export default {
  components: {
    'drive-file-table-row': DriveFileTableRow,
    'confirm-delete': ConfirmDelete
  },
  props: {
    apiKey: {
      type: String,
      required: true
    },
    parentFolderId: {
      type: String | Number,
      default: null
    }
  },
  setup(props, { emit }) {
    const deleteDialog = ref(null)

    const { DEFAULT_PER_PAGE, isLoading, isFilesLoading, driveFiles, pagination, isEmpty, loadFiles, deleteFile, loadNextPage } = useCompanyDriveFile(props.apiKey)
    const tableHeaders = [
      { align: 'start', text: i18n.t('name'), class: 'header', sortable: false, width: '30%' },
      { align: 'start', text: i18n.t('date'), class: 'header', sortable: false, width: '20%' },
      { align: 'start', text: i18n.t('drive.owner'), class: 'header', sortable: false, width: '50%' },
      { align: 'start', text: '', class: 'header', sortable: false }
    ]

    // Methods
    const onLoadNextPage = () => loadNextPage(props.parentFolderId)
    const onDelete = (file) => {
      const title = i18n.t('drive.delete_file', { fileName: file.name })
      deleteDialog.value.open({ title })
        .then((shouldDelete) => {
          if (shouldDelete !== true) return
          deleteFile(file.id, props.parentFolderId)
            .then(() => deleteDialog.value.close())
            .catch(errHandler)
        })
    }

    // Computed
    const totalItems = computed(() => pagination.value.total)
    const showLoadMorePages = computed(() => {
      if (isFilesLoading.value || isLoading.value) return false

      return driveFiles.value.length < pagination.value.total
    })

    const showLoadingIndicatorMorePages = computed(() => isFilesLoading.value && pagination.page > 1)
    const showLoadingIndicatorFirsrPage = computed(() => isFilesLoading.value && pagination.page === 1)
    loadFiles({ reset: true, useLoading: true, parentFolderId: props.parentFolderId })
    return {
      DEFAULT_PER_PAGE,
      isRtl,
      tableHeaders,
      isFilesLoading,
      showLoadingIndicatorMorePages,
      showLoadingIndicatorFirsrPage,
      deleteDialog,
      showLoadMorePages,
      driveFiles,
      totalItems,
      isEmpty,
      onDelete,
      onLoadNextPage
    }
  }
}
</script>