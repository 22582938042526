<template>
  <v-row align="center" justify="center" v-if="isLoading">
    <v-progress-circular indeterminate color="primary"> </v-progress-circular>
  </v-row>
  <v-row align="start" justify="start" v-else-if="operationsListNotEmpty">
    <chat-dialog ref="chatDialog"
      record-type="OperationSchedule">
    </chat-dialog>
    <report-item-operation-list-dialog
      ref="reportItemOperationListDialog"
      read-only>
    </report-item-operation-list-dialog>
    <operation-image-overlay
      v-bind:showImageOverlay="showImageOverlay"
      v-bind:imageSrc="imageSrc"
      undo-disabled
      v-on:hide-image="hideImage">
    </operation-image-overlay>
    <share-content ref="shareDialog" :share-content="createShareContent" show-dialog></share-content>
    <v-col cols="12">
      <v-timeline
        class="op-list-row"
        dense>
        <template v-for="{ rowType, value, id } in operationListWithDateHeaders">
          <operations-history-list-item
            v-if="rowType === rowTypes.operation"
            :operation="value" :key="id"
            @open-chat-dialog="openChatDialog"
            @open-item-operation-list="openItemOperationList"
            @show-image="openImageDialog"
            @share-click="onShareClick">
          </operations-history-list-item>
          <v-timeline-item v-else-if="rowType === rowTypes.date" class="date-row"
            :key="id"
            small
            fill-dot
            color="black white--text"
            icon="schedule">
            <v-row align="center" justify="center">
              <v-chip color="black white--text">
                {{ value }}
              </v-chip>
            </v-row>
          </v-timeline-item>
        </template>
      </v-timeline>
    </v-col>
  </v-row>
  <div class="text-center font-weight-bold" v-else>
    {{ $t('no_result') }}
  </div>
</template>
<style lang="scss">
.op-list-row {
  position: relative;

  .date-row {
    position: sticky;
    top: 100px;
    z-index: 3;
    margin-bottom: 15px;
    margin-top: 15px;
  }

  @media only screen and (max-width: 600px) {
    .date-row {
      top: 150px;
    }

  }

  @media only screen and (min-width: 960px) {
    .date-row {
      top: 40px;
    }
  }
}
</style>
<script>
import OperationsHistoryListItem from '@/components/operations_history/OperationsHistoryListItem.vue'
import ChatDialog from '@/components/chat_dialog/ChatDialog.vue'
import ReportItemOperationListDialog from '@/components/shared/item_operation_list_dialog/ReportItemOperationListDialog.vue'
import OperationImageOverlay from '@/components/shared/OperationImageOverlay.vue'
import ShareContent from '@/components/shared/ShareContent.vue'

import { createShareContent as createOperationShareContent } from '@/classes/OperationScheduleShareContent.js'

import useOperationHistory from '@/composables/useOperationHistory.js'
import useAccount from '@/composables/useAccount.js'

import { computed, ref } from 'vue'

import { ISO8601dateToText } from '@/helpers/DateTime.js'

import { i18n } from '@/i18n.js'

const rowTypes = { date: 1, operation: 2 }
export default {
  props: {
    apiKey: {
      type: String,
      required: true
    }
  },
  components: {
    'operations-history-list-item': OperationsHistoryListItem,
    'chat-dialog': ChatDialog,
    'report-item-operation-list-dialog': ReportItemOperationListDialog,
    'operation-image-overlay': OperationImageOverlay,
    'share-content': ShareContent
  },
  setup(props) {
    let shareOperation = null
    const { isLoading, operationList, dateRange } = useOperationHistory(props.apiKey)
    const showImageOverlay = ref(false)
    const imageSrc = ref('')
    const chatDialog = ref(null)
    const reportItemOperationListDialog = ref(null)
    const shareDialog = ref(null)
    const { accountCountryCode } = useAccount()

    // Methods
    const openChatDialog = (operationId) => chatDialog.value.openDialog({ recordId: operationId, apiKey: props.apiKey })
    const openItemOperationList = (operation) => {
      const result = JSON.parse(JSON.stringify(operation))
      const itemsList = (result.item_operation_list || [])
      itemsList.forEach((item) => {
        if (!Array.isArray(item.images)) return
        item.images = item.images.map((image) => ({ url: image }))
      })
      reportItemOperationListDialog.value.openDialog(result, props.apiKey)
    }

    const openImageDialog = (imageUrl) => {
      imageSrc.value = imageUrl
      showImageOverlay.value = true
    }
    const hideImage = () => {
      imageSrc.value = ''
      showImageOverlay.value = false
    }

    const onShareClick = (operation) => {
      shareOperation = operation
      const title = i18n.t('operation_share.title')
      const pdfShareEnabled = (shareOperation.item_operation_list || []).length > 0
      shareDialog.value.openDialog({ title, pdfShareEnabled })
    }
    const createShareContent = (medium) => createOperationShareContent({
      operation: shareOperation,
      currentDate: shareOperation.report_date,
      apiKey: props.apiKey,
      medium
    })
    // Computed

    const operationsListNotEmpty = computed(() => operationList.value.length > 0)
    const operationListWithDateHeaders = computed(() => {
      const operations = operationList.value
      if (operations.length === 0) return operations

      const result = []

      const countryCode = accountCountryCode.value
      let currentDate = operations[0].report_date
      let currentDateFormmated = ISO8601dateToText({ fromDate: currentDate, countryCode })
      result.push({ rowType: rowTypes.date, value: currentDateFormmated, id: currentDate })

      operations.forEach((operation) => {
        if (operation.report_date !== currentDate) {
          currentDate = operation.report_date
          currentDateFormmated = ISO8601dateToText({ fromDate: currentDate, countryCode })
          result.push({ rowType: rowTypes.date, value: currentDateFormmated, id: currentDate })
        }
        result.push({ rowType: rowTypes.operation, value: operation, id: `${currentDate}_${operation.operation_id}` })
      })
      return result
    })

    return {
      operationsListNotEmpty,
      isLoading,
      operationListWithDateHeaders,
      dateRange,
      rowTypes,
      showImageOverlay,
      imageSrc,
      chatDialog,
      reportItemOperationListDialog,
      shareDialog,
      openChatDialog,
      openItemOperationList,
      openImageDialog,
      hideImage,
      onShareClick,
      createShareContent
    }
  }
}
</script>
