<template>
  <v-autocomplete
    :value="value"
    :items="chatSearchList"
    :search-input.sync="search"
    :disabled="disabled"
    autocomplete="off"
    :loading="isLoading"
    @change="emitChange"
    clearable
    @click:clear="clear"
    :filter="returnHeadersAndFilter"
    @input="update"
    hide-selected
    height="38"
    dense
    class="chat-search"
    item-value="uniqId"
    :menu-props="{ 'contentClass': contentClass }"
    item-text="name"
    :placeholder="$t('app_chat.search_group_placeholder')"
    return-object>
    <template v-slot:item="{ item }">
      <v-row align="center" justify="start" class="my-1" :class="isRtl ? 'rtl' : 'ltr'">
        <v-avatar color="yellow darken-2" size="36" class="mx-2">
          <v-icon v-if="item.rowType === rowTypes.group && !item.image_url" color="white">
            groups
          </v-icon>
          <v-img v-else-if="item.rowType === rowTypes.group && item.image_url" :src="item.image_url"></v-img>
          <v-img v-else-if="item.rowType === rowTypes.user && item.avatar_url" :src="item.avatar_url"></v-img>
          <span v-else class="white--text text-h5">
            {{ item.nameInitials }}
          </span>
        </v-avatar>
        {{ item.name }}
      </v-row>
    </template>
  </v-autocomplete>
</template>

<style lang="scss">
.app-chat-search {
  div.v-subheader {
    font-weight: 700;
    background-color: #E8E8E8;
  }
}

.chat-search {
  background-color: white;
  border-radius: 8px;

  .v-select__slot {
    padding: 0 4px;
  }

  .v-text-field__details {
    display: none;
  }

  .v-input__control>.v-input__slot::before {
    border-width: 0;
  }
}
</style>

<script>
import { search as groupChatClientSearch } from '@/api_client/ChatGroup.js'

import { isString } from '@/helpers/Utils.js'

import { handler as errHandler } from '@/classes/ErrorHandler.js'

import { EventBus, NEW_CHAT_GROUP_CREATED } from '@/EventBus.js'

import { ref, watch, onUnmounted, computed } from 'vue'

import { i18n, isRtl } from '@/i18n.js'

const rowTypes = { user: 1, group: 2 }

export default {
  props: {
    apiKey: {
      type: String,
      required: true
    },
    value: {
      type: Object,
      required: false,
      default: () => {
        return {}
      }
    },
    disabled: {
      type: Boolean,
      required: true
    }
  },
  setup(props, { emit }) {
    const isLoading = ref(false)
    const search = ref(null)
    const chatSearchList = ref([])
    const originalChatSearchList = []
    const debounceTimeout = ref(null);

    const debounce = (func, delay) => {
      if (debounceTimeout.value) {
        clearTimeout(debounceTimeout.value);
      }
      debounceTimeout.value = setTimeout(func, delay);
    }

    const resetChatListWithInitialList = () => {
      const chatSearchListVal = chatSearchList.value
      chatSearchListVal.splice(0, chatSearchListVal.length)
      originalChatSearchList.forEach((group) => chatSearchListVal.push(group))
    }

    const reloadChatList = () => {
      callApiSearch({ destinationList: originalChatSearchList })
        .then(resetChatListWithInitialList)
    }

    const callApiSearch = ({ term = null, destinationList } = {}) => {
      isLoading.value = true
      return groupChatClientSearch(props.apiKey, { term })
        .then((response) => {
          destinationList.splice(0, destinationList.length)
          destinationList.push({ header: i18n.t('app_chat.search_group_divide'), id: 'header-1' })
          response.data.data.group_search.forEach(chatGroup => {
            chatGroup.uniqId = `group-${chatGroup.id}`
            chatGroup.rowType = rowTypes.group
            destinationList.push(chatGroup)
          })
          destinationList.push({ header: i18n.t('app_chat.search_user_divide'), id: 'header-2' })
          response.data.data.user_search.forEach(user => {
            user.name = `${user.first_name} ${user.last_name}`
            user.nameInitials = `${user.first_name.charAt(0)}${user.last_name.charAt(0)}`
            user.uniqId = `user-${user.id}`
            user.rowType = rowTypes.user
            destinationList.push(user)
          })
        })
        .catch(errHandler)
        .finally(() => (isLoading.value = false))
    }

    // Methods
    const update = (newValue) => emit('input', newValue)
    const emitChange = (newValue) => {
      if (newValue === null) return

      const isGroup = newValue.rowType === rowTypes.group
      const eventData = JSON.parse(JSON.stringify(newValue))
      delete eventData.rowType
      delete eventData.uniqId

      emit('change', { eventData, isGroup })
    }
    const clear = () => emit('click:clear')
    const returnHeadersAndFilter = (item, queryText, itemText) => {
      if (item.header) {
        return true
      }
      return itemText.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1
    }

    // Computed

    const contentClass = computed(() => 'app-chat-search' + (isRtl.value ? ' rtl' : ' ltr'))
    callApiSearch({ destinationList: chatSearchList.value })
      .then(() => chatSearchList.value.forEach((group) => originalChatSearchList.push(group)))

    watch(() => search.value, (term) => {
      debounce(() => {
        if (isLoading.value === true) return
        const validQuery = isString(term) && term.trim().length > 0
        if (validQuery !== true) {
          resetChatListWithInitialList()
          return
        }

        callApiSearch({ term, destinationList: chatSearchList.value })
      }, 300);
    })

    onUnmounted(() => {
      EventBus.off(NEW_CHAT_GROUP_CREATED, reloadChatList)
    })
    EventBus.on(NEW_CHAT_GROUP_CREATED, reloadChatList)
    return {
      chatSearchList,
      isLoading,
      search,
      rowTypes,
      contentClass,
      clear,
      isRtl,
      emitChange,
      update,
      returnHeadersAndFilter
    }
  }
}
</script>
